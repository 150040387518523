import React, {FC, useEffect} from "react";
import {useDispatch, useSelector} from "react-redux";
import {isEmpty} from "lodash";
import {toast} from "react-toastify";

import {TypedDispatch} from "../../../types";
import {fetchResponseServices, responseServicesSelector} from "../../../redux/responseServiceSlice";
import {
    fetchLastEvents,
    filteredLastEventsByStatusSelector,
    handoverResultSelector, loginAttemptsCountExceededMessageSelector,
    resetHandoverResult, setLoginAttemptsCountExceededMessage, setToiNotifications, toiNotificationSelector,
} from "../../../redux/eventSlice";
import {
    commentGroupsSelector,
    commentsSelector,
    fetchCommentsGroups,
    fetchQuickComments,
} from "../../../redux/commentsSlice";
import {
    fetchTagGroups,
    fetchTags,
    tagGroupsSelector,
    tagsLoadingSelector,
    tagsSelector,
} from "../../../redux/tagsSlice";
import {
    camsGroupsSelector,
    fetchProcessingSuppressedBeforeUtc,
    fetchSegments,
    segmentsGroupsSelector,
} from "../../../redux/disablingCameraIncidentsSlice";
import {incidentTypesSelector} from "../../../redux/incidentTypeSlice";
import {userSelector} from "../../../redux/authSlice";
import useHasPermission from "../../../hook/useHasPermission";
import {Permission} from "../../../models/Permission";
import {fetchResponsibilityZones, responsibilityZonesSelector} from "../../../redux/responsibilitySlice";
import {
    fetchUserSettings,
    IPreparedOptionsRequest,
    saveUserSettings,
    userSettingsSelector,
} from "../../../redux/userSettingsSlice";
import {fetchSettings, settingsSelector} from "../../../redux/settingsSlice";

import HandoverProcessedNotification
    from "../../Notifications/HandoverProcessedNotification/HandoverProcessedNotification";
import {getMinutesDifference} from "../../../utils/datetime";
import CameraDisablingFinishNotification
    from "../../Notifications/CameraDisablingFinishNotification/CameraDisablingFinishNotification";
import ToiNotification from "../../Notifications/ToiNotification/ToiNotification";
import Header from "../../Header";
import Sidebar from "../../Sidebar";

import stylesCommon from "styles/Common.module.scss";
import cameraNotificationStyles
    from "../../Notifications/CameraDisablingFinishNotification/CameraDisablingFinishNotification.module.scss";
import handoverNotificationStyles
    from "../../Notifications/HandoverProcessedNotification/HandoverProcessedNotification.module.scss";
import toiNotificationStyles from "../../Notifications/ToiNotification/ToiNotification.module.scss";

interface ILayoutMainProps {
    theme: string,
    headerFilters?: React.ReactNode,
    children: React.ReactNode | string,
    settingsMode?: boolean,
}

const LayoutMain: FC<ILayoutMainProps> = ({theme, headerFilters, children, settingsMode = false}) => {
    const dispatch = useDispatch<TypedDispatch>();
    const responseServices = useSelector(responseServicesSelector);
    const lastEvents = useSelector(filteredLastEventsByStatusSelector);
    const quickCommentsList = useSelector(commentsSelector);
    const quickCommentGroups = useSelector(commentGroupsSelector);
    const tagGroupsList = useSelector(tagGroupsSelector);
    const tagsList = useSelector(tagsSelector);
    const tagsLoading = useSelector(tagsLoadingSelector);
    const handoverResult = useSelector(handoverResultSelector);
    const incidentTypes = useSelector(incidentTypesSelector);
    const responsibilityZones = useSelector(responsibilityZonesSelector);
    const segmentsGroups = useSelector(segmentsGroupsSelector);
    const camsGroupsList = useSelector(camsGroupsSelector);
    const settings = useSelector(settingsSelector);
    const userSettings = useSelector(userSettingsSelector);
    const loginAttemptsCountExceededMessage = useSelector(loginAttemptsCountExceededMessageSelector);
    const toiNotifications = useSelector(toiNotificationSelector);

    const user = useSelector(userSelector);
    const isManager = useHasPermission(Permission.MANAGER);
    const isAdmin = useHasPermission(Permission.ADMIN);

    useEffect(() => {
        if (!userSettings.widgetsLayout) {
            dispatch(fetchUserSettings())
                .then((response) => {
                    const payload: IPreparedOptionsRequest = response.payload;
                    // При самом первом рендере у пользователей нет ключа widgetsLayout.
                    // Необходимо добавить его вручную.
                    if (payload && !payload.widgetsLayout) {
                        dispatch(saveUserSettings({
                            widgetsLayout: {
                                0: {id: 1, order: 1, isVisible: true},
                                1: {id: 2, order: 2, isVisible: true},
                                2: {id: 3, order: 3, isVisible: true},
                                3: {id: 4, order: 4, isVisible: true},
                            },
                            useLocalStreamUrl: false,
                        }));
                    }
                });
        }

        if (isEmpty(settings)) dispatch(fetchSettings()); // Настройки (содержит список типов камер)

        // Получение списка служб
        if (isEmpty(responseServices)) dispatch(fetchResponseServices());
        if (isEmpty(responsibilityZones)) dispatch(fetchResponsibilityZones());
        // Получение списка сегментов
        if (isEmpty(segmentsGroups)) dispatch(fetchSegments());
        // Получение списка отключенных камер
        if (isEmpty(camsGroupsList)) dispatch(fetchProcessingSuppressedBeforeUtc());
        // Последние инциденты
        if (!lastEvents?.length) dispatch(fetchLastEvents({
            params: {
                sort: "registrationDateTime,desc",
                getAssignedLastShift: "true",
            },
        }));

        // Получение данных по быстрым комментариям
        if (!quickCommentGroups) dispatch(fetchCommentsGroups());
        if (!quickCommentsList) dispatch(fetchQuickComments());

        // Получение данных по тегам
        if (!tagGroupsList) dispatch(fetchTagGroups());
        if (isEmpty(tagsList) && !tagsLoading) dispatch(fetchTags());

        const fetchDictionariesTimer = setInterval(async() => {
            dispatch(fetchCommentsGroups());
            dispatch(fetchQuickComments());
            dispatch(fetchTagGroups());
            dispatch(fetchTags());

            const result = await dispatch(fetchProcessingSuppressedBeforeUtc());

            result.payload.Result?.forEach((currentCamera: any) => {
                if (currentCamera.Data.incidentProcessingSuppressedBeforeUtc) {
                    const minutesDifference =
                        getMinutesDifference(currentCamera.Data.incidentProcessingSuppressedBeforeUtc);

                    if (minutesDifference < 3 && minutesDifference > 0) {
                        showCameraNotification({
                            cameraId: currentCamera?.Data?.roadObjectId,
                            incidentType: "Все",
                            cameraZone: currentCamera?.Data?.zoneId,
                        });
                    }
                }

                currentCamera.Data.json?.prioritySettings?.forEach((setting: any) => {
                    const currentDifference = getMinutesDifference(setting.validUntilUtc);
                    if (currentDifference < 3 && currentDifference > 1) {
                        showCameraNotification({
                            cameraId: currentCamera?.Data?.roadObjectId,
                            incidentType: setting.incidentTypeCode,
                            cameraZone: currentCamera?.Data?.zoneId,
                        });
                    }
                });
            });
        }, 60000);

        return () => {
            if (fetchDictionariesTimer) clearInterval(fetchDictionariesTimer);
        };
    }, []);

    useEffect(() => {
        if (handoverResult?.type === true) showHandoverNotification("success", handoverResult.receiver);
        if (handoverResult?.type === false) showHandoverNotification("reject", handoverResult.receiver);
        dispatch(resetHandoverResult());
    }, [handoverResult]);

    const showCameraNotification = (cameraNotifications: any) => {
        if (cameraNotifications) {
            const incidentTypeName = incidentTypes.find((type) => type.code === cameraNotifications.incidentType);
            // @ts-ignore
            const matchingZone = user?.UserIncidentsModuleSettings?.ResponsibilityZones?.find(
                (zone: any) => zone.id === cameraNotifications.cameraZone
            );

            const isOperator = user?.Roles?.find(
                (zone: any) => zone === "INCIDENT_OPERATOR"
            );

            if ((isOperator && matchingZone) || isManager || isAdmin) {
                return (
                    toast(<CameraDisablingFinishNotification
                        cameraId={cameraNotifications.cameraId}
                        incidentType={incidentTypeName?.name ?? cameraNotifications.incidentType}
                        cameraType={cameraNotifications.subType}
                        cameraZone={cameraNotifications.zoneId}
                    />, {
                        className: cameraNotificationStyles["wrapper"],
                        autoClose: 5000,
                        closeButton: false,
                        hideProgressBar: true,
                    })
                );
            }
        }
    };

    const showHandoverNotification = (type: string, name: any) => {
        if (type) {
            return toast(<HandoverProcessedNotification type={type} name={name.name}/>, {
                className: handoverNotificationStyles["wrapper"],
                autoClose: 5000,
                closeButton: false,
                hideProgressBar: true,
            });
        }
    };

    useEffect(() => {
        if (loginAttemptsCountExceededMessage) {
            toast.info(`Пользователь "${loginAttemptsCountExceededMessage.userFullName}"
                    несколько раз ввел пароль неверно`);
            dispatch(setLoginAttemptsCountExceededMessage(null));
        }
    }, [loginAttemptsCountExceededMessage]);

    // Уведомления по ТОИ
    useEffect(() => {
        if (toiNotifications?.length) {
            toiNotifications.forEach(notification => {
                toast(<ToiNotification
                    notification={notification}
                />, {
                    className: toiNotificationStyles["wrapper"],
                    autoClose: 5000,
                    closeButton: false,
                    hideProgressBar: true,
                });
            });
            dispatch(setToiNotifications([]));
        }
    }, [toiNotifications]);

    return (
        <>
            <Header theme={theme} filters={headerFilters}/>

            <div className={stylesCommon["main__content"]}>
                {!settingsMode && <Sidebar/>}

                <div className={stylesCommon["dashboard-content"]}>
                    {children}
                </div>
            </div>
        </>
    );
};

export default React.memo(LayoutMain);