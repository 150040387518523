/* eslint-disable max-len */
import React from "react";

import {ISvgProps} from "../../types/common";

const ArrowTriangularTopIcon: React.FC<ISvgProps> = ({
    onClick,
    height = 16,
    width = 16,
    ...rest
}) => {
    return (
        <svg onClick={onClick} {...rest} width={width} height={height} viewBox="0 0 16 16" fill="none"
            xmlns="http://www.w3.org/2000/svg">
            <path fillRule="evenodd" clipRule="evenodd"
                d="M12.2931 10.667C12.7123 10.667 12.9454 10.182 12.6835 9.85464L8.39047 4.48837C8.19031 4.23817 7.80977 4.23817 7.60961 4.48837L3.31658 9.85465C3.05468 10.182 3.28777 10.667 3.70702 10.667L12.2931 10.667Z"
                fill="currentColor"/>
        </svg>

    );
};

export default ArrowTriangularTopIcon;
