export const formatDateForArchiveAnalytics = (dateString: string | null, isAddDayIfNoTime: boolean): string  | null=> {
    if (!dateString) return null;
    // Проверяем, содержит ли строка время (после даты идет "-HHMM")
    const timeMatch = dateString.match(/^\d{4}-\d{2}-\d{2}-(\d{4})$/);

    if (timeMatch) {
        // Если найдено время (например, "2025-02-18-1300"), форматируем правильно
        return `${dateString.slice(0, 10)}T${timeMatch[1].slice(0, 2)}:${timeMatch[1].slice(2)}`;
    } else {
        let date = new Date(dateString);

        if (isAddDayIfNoTime) {
            date.setUTCDate(date.getUTCDate() + 1);
        }
        const newDateString = date.toISOString().split("T")[0];

        return `${newDateString}T00:00`;
    }
};