/* eslint-disable max-len */
import React, {FC} from "react";

import {ISvgProps} from "../../types/common";

const PrioritiesIcon:FC<ISvgProps> = ({
    width = 17,
    height = 18,
    ...rest
}) =>  (
    <svg {...rest} width={width} height={height} viewBox="0 0 17 18" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path fillRule="evenodd" clipRule="evenodd"
            d="M8.23848 2.10746C7.79544 1.92286 7.28674 1.99738 6.91527 2.30131L3.76754 4.87673C3.24437 5.30477 3.13654 6.06233 3.5195 6.61936L6.21478 10.5398C6.75088 11.3196 7.9139 11.2836 8.40077 10.4721L11.6093 5.12465C12.0173 4.4446 11.7266 3.56083 10.9945 3.2558L8.23848 2.10746ZM6.02874 1.21777C6.80025 0.586537 7.85679 0.43175 8.77694 0.815148L11.533 1.9635C13.0534 2.59701 13.6572 4.43253 12.8097 5.84494L9.60126 11.1924C8.59008 12.8777 6.17457 12.9525 5.06112 11.3329L2.36584 7.4125C1.57047 6.2556 1.79443 4.68221 2.88101 3.79319L6.02874 1.21777Z"
            fill="currentColor"/>
        <path fillRule="evenodd" clipRule="evenodd"
            d="M8.18392 7.04181C7.62477 6.90203 7.04033 7.14709 6.7481 7.64387L3.59183 13.0095C3.21016 13.6584 3.45766 14.4948 4.13097 14.8314L6.52772 16.0298C6.81568 16.1738 7.14683 16.2058 7.45704 16.1196L14.004 14.301C14.6386 14.1247 15.0411 13.5017 14.941 12.8508L14.4132 9.42025C14.3336 8.90287 13.9515 8.4837 13.4436 8.35674L8.18392 7.04181ZM5.5414 6.93404C6.14832 5.90227 7.36216 5.39329 8.52347 5.68361L13.7832 6.99854C14.8379 7.26223 15.6316 8.13281 15.7969 9.20737L16.3247 12.6379C16.5327 13.9899 15.6967 15.2838 14.3787 15.6499L7.83174 17.4685C7.18746 17.6475 6.4997 17.581 5.90162 17.282L3.50487 16.0836C2.10647 15.3844 1.59242 13.6473 2.38512 12.2997L5.5414 6.93404Z"
            fill="currentColor"/>
    </svg>
);

export default PrioritiesIcon;