import React, {FC} from "react";
import cn from "classnames";

interface IInputProps {
    type: React.HTMLInputTypeAttribute,
    name: string,
    className?: string,
    placeholder?: string,
    required?: boolean,
    onChange?: React.ChangeEventHandler<HTMLInputElement>,
    onClick?: React.MouseEventHandler<HTMLInputElement>,
    onInput?: () => void,
    onKeyUp?: () => void,
    onFocus?: () => void,
    onBlur?: React.FocusEventHandler<HTMLInputElement>,
    onKeyPress?: () => void,
    pattern?: string,
    disabled?: boolean,
    defaultValue?: number | string,
    checked?: boolean,
    defaultChecked?: boolean,
    readOnly?: boolean,
}

const Input = React.forwardRef<HTMLInputElement, React.PropsWithChildren<IInputProps>>((props, ref) => {
    const Component: FC<IInputProps> = (
        {
            type,
            name,
            placeholder,
            required,
            onChange,
            onInput,
            onKeyUp,
            onKeyPress,
            onFocus,
            onBlur,
            pattern,
            disabled,
            defaultValue,
            className,
            checked,
            defaultChecked,
            readOnly,
            onClick,
        }
    ) => (
        <input
            ref={ref}
            type={type}
            name={name}
            className={cn("form-control input", className)}
            placeholder={placeholder}
            checked={checked}
            required={required || false}
            onChange={onChange}
            onInput={onInput}
            onKeyUp={onKeyUp}
            onFocus={onFocus}
            onBlur={onBlur}
            onKeyPress={onKeyPress}
            pattern={pattern || undefined}
            disabled={disabled}
            defaultValue={defaultValue}
            defaultChecked={defaultChecked}
            readOnly={readOnly}
            onClick={onClick}
        />
    );

    return <Component {...props}/>;
});

Input.displayName = "Input";

export default React.memo(Input);