import React, {FC, ReactNode, useCallback, useEffect, useRef, useState} from "react";
import cn from "classnames";
import {CheckPicker} from "rsuite";
import {useTranslation} from "react-i18next";
import {useDispatch, useSelector} from "react-redux";
import SimpleBar from "simplebar-react";
import {PickerInstance} from "rsuite/Picker";
import {toast} from "react-toastify";

import {userSelector} from "../../../../redux/authSlice";
import {
    handoverEvent,
    handoverRequestSendingLoadingSelector, resetHandoverLoading,
    selectedLastEventSelector,
} from "../../../../redux/eventSlice";
import {fetchZoneOperators, zoneOperatorsSelector} from "../../../../redux/zoneOperatorsSlice";
import {TypedDispatch} from "../../../../types";
import {ApiUrls} from "../../../../constants/urls";
import {User} from "../../../../models/User";

import Spinner from "../../../UI/Spinner/Spinner";
import BaseButton from "../../../UI/Button/BaseButton";
import Image from "../../../Icons/Image";
import arrowRightIcon from "../../../../images/icons/arrow-right-curved.svg";
import CrossIcon from "../../../Icons/CrossIcon";
import TriangleBottomIcon from "../../../Icons/TriangleBottomIcon";
import ImageWithToken from "../../../Icons/ImageWithToken";

import styles from "./DashboardTargetDropdowns.module.scss";

interface IHandOverDropdown {
    placement?: "topLeft" | "topRight" | "bottomLeft" | "bottomRight",
    incidentId?: string | null,
}

const HandOverDropdown: FC<IHandOverDropdown> = ({placement = "topStart", incidentId}) => {
    const {t} = useTranslation("common");
    const dispatch = useDispatch<TypedDispatch>();
    const operators = useSelector(zoneOperatorsSelector);
    const currentEvent = useSelector(selectedLastEventSelector);
    const user = useSelector(userSelector);
    const sendingHandoverRequestLoading = useSelector(handoverRequestSendingLoadingSelector);

    const [selectedOperator, setSelectedOperator] = useState<number[]>([]);
    const [comment, setComment] = useState("");

    const checkPickerRef = useRef<PickerInstance | undefined>();
    const handleChange = (value: number[]) => {
        const newValue = value[value.length - 1] ? [value[value.length - 1]] : [];
        setSelectedOperator(newValue);
    };

    useEffect(() => {
        dispatch(fetchZoneOperators({roleId: [1, 5, 8], isLoaderShow: true, isOnline: true}));
    }, []);

    useEffect(() => {
        setSelectedOperator([]);
        setComment("");
    }, [currentEvent]);

    useEffect(() => {
        if (sendingHandoverRequestLoading === false) {
            toast.success("Ваша заявка отправлена", {position: "bottom-right"});
            dispatch(resetHandoverLoading());
        }
    }, [sendingHandoverRequestLoading]);

    const onOpen = () => {
        dispatch(fetchZoneOperators({roleId: [1, 5, 8], isLoaderShow: true, isOnline: true}));
    };

    const onlineOperators = operators?.filter(operator => operator.id !== user?.UserId) ?? [];

    const renderMenuItem = (label: React.ReactNode, item: any) => {

        const typedItem = item as User;

        const zones = typedItem?.zone
            ? Object.keys(typedItem.zone).map(item => typedItem.zone[item]).join(", ")
            : "";

        return (
            <div className={styles["operators__menu-item"]}>
                <ImageWithToken
                    src={ApiUrls.USER_AVATAR(typedItem.id)}
                    alt={"Аватар"}
                    width={36}
                    height={36}
                />
                <div
                    className={styles["operators__name-wrapper"]}
                >
                    <p title={typedItem?.fullName} className={styles["operators__name"]}>{label}</p>
                    {zones &&
                        <p title={zones} className={cn(styles["operators__email"])}>{zones}</p>}
                </div>
            </div>
        );
    };

    const closeDropdown = () => {
        setSelectedOperator([]);
        setComment("");
        checkPickerRef.current?.close?.();
    };

    const renderMenu = (menu: React.ReactNode) => {
        return (
            <>
                <div className={styles["dropdown__header"]}>
                    Выберите доступного оператора

                    <div
                        className={styles["dropdown__header-close-icon"]}
                        onClick={closeDropdown}
                    >
                        <CrossIcon width={12} height={12}/>
                    </div>
                </div>
                <SimpleBar style={{maxHeight: "250px"}}>
                    {menu}
                </SimpleBar>
            </>
        );
    };

    const handleSubmit = async() => {
        let id;
        if (incidentId) {
            id = incidentId;
        } else if (currentEvent?.id) {
            id = currentEvent?.id;
        } else {
            return;
        }

        const params = {
            eventId: id,
            userId: String(selectedOperator[0]),
            comment: comment,
        };
        await dispatch(handoverEvent(params));
        closeDropdown();
    };

    const searchBy = useCallback((keyword: string, label: ReactNode, item: any) => {
        const typedItem = item as User;
        const userZones =
            typedItem?.zone
                ? Object.keys(typedItem.zone).map(item => typedItem.zone[item]).join(", ")
                : "";

        let filterByFullName = false;
        let filterByZone = false;

        if (typedItem.fullName.toLowerCase().includes(keyword.toLowerCase())) filterByFullName = true;
        if (userZones.toLowerCase().includes(keyword.toLowerCase())) filterByZone = true;

        return filterByFullName || filterByZone;
    }, []);

    const renderValue = () => (
        <span className={styles["operators__value"]}>{t("hand_over")}</span>
    );

    const renderExtraFooter = () => (
        <div className={styles["operators__footer"]}>
            <input
                type="text"
                className={styles["operators__comment"]}
                value={comment}
                onChange={(e) => setComment(e.target.value)}
                placeholder={t("comment")}
            />
            <BaseButton
                tag="button"
                className={cn(styles["operators__button"], {
                    [styles["operators__button--disabled"]]:
                        (!selectedOperator?.length || !!sendingHandoverRequestLoading),
                })}
                onClick={handleSubmit}
                disabled={!selectedOperator?.length || !!sendingHandoverRequestLoading}
            >
                {sendingHandoverRequestLoading ? <Spinner/> : t("confirm")}
            </BaseButton>
        </div>
    );

    return (
        <>
            <div className={styles["operators-wrapper"]}>
                <Image
                    src={arrowRightIcon}
                    alt={"Arrow"}
                    className={styles["hand-over__info--icon"]}
                    width={14}
                    height={14}
                />
                <CheckPicker
                    //@ts-ignore
                    ref={checkPickerRef}
                    caretAs={TriangleBottomIcon}
                    data={onlineOperators}
                    className={cn(styles["operators"], styles["operators-picker"])}
                    menuClassName={styles["operators-picker__menu"]}
                    //@ts-ignore
                    placement={placement}
                    cleanable={false}
                    placeholder={t("hand_over")}
                    labelKey={"fullName"}
                    valueKey={"id"}
                    locale={{
                        searchPlaceholder: "Имя или зона",
                        noResultsText: t("nothing_found"),
                    }}
                    onOpen={onOpen}
                    value={selectedOperator}
                    onChange={handleChange}
                    renderValue={renderValue}
                    renderMenu={renderMenu}
                    renderMenuItem={renderMenuItem}
                    renderExtraFooter={renderExtraFooter}
                    searchBy={searchBy}
                />
            </div>
        </>
    );
};

export default React.memo(HandOverDropdown);