import {createAsyncThunk, createSelector, createSlice, PayloadAction} from "@reduxjs/toolkit";

import {RootState} from "../store";
import createHttpRequest from "../utils/http";
import {ApiUrls} from "../constants/urls";
import {getObjectFromLocalStorage, setLocalStorageValue} from "../utils/common";

export interface ICreateRepairRequest {
    "Subject"?: string,
    "Status": "New" | "Processing" | "Resolved" | "Rejected",
    "Description"?: string,
    "OrganizationId": number,
    "TicketTypeId": number,
    "RoadObjectId": number,
}

interface IFetchUserHelped {
    Sent: number,
    Accepted: number,
    Rejected: number,
}

export interface IDashboardAnalytics {
    userTime: {
        processingTimeSec: number | null,
        reactionTimeSec: number | null,
    },
    serviceTime: {
        processingTimeSec: number | null,
        reactionTimeSec: number | null,
    },
}

export interface IFullMapWidgetsVisibility {
    isEventList: boolean,
    isInfo: boolean,
}

export interface IInitialState {
    fullMapWidgetsVisibility: IFullMapWidgetsVisibility,
    heatmapVisible: boolean,
    isOneOfStreamFullscreen: boolean,
    isNotifyService: boolean,
    isCreateRepairLoading: boolean,
    isCreateRepairSuccess: boolean,
    isCreateRepairError: boolean,
    analytics: IDashboardAnalytics,
    dashboardHelped: IFetchUserHelped | null,
    analyticsLoading: boolean,
    helpedLoading: boolean,
    isZoneBarShow: boolean,
}

const initialState: IInitialState = {
    fullMapWidgetsVisibility: getObjectFromLocalStorage<IFullMapWidgetsVisibility>("fullMapWidgetsVisibility") || {
        isEventList: true,
        isInfo: true,
    },
    heatmapVisible: false,
    isOneOfStreamFullscreen: false, // Один из стримов открыт на весь экран
    isNotifyService: false,
    isCreateRepairLoading: false,
    isCreateRepairSuccess: false,
    isCreateRepairError: false,
    analytics: {
        userTime: {
            processingTimeSec: null,
            reactionTimeSec: null,
        },
        serviceTime: {
            processingTimeSec: null,
            reactionTimeSec: null,
        },
    },
    dashboardHelped: null,
    analyticsLoading: false,
    helpedLoading: false,
    isZoneBarShow: false,
};

// Создать заявку на ремонт
export const createRepairRequest = createAsyncThunk<void, ICreateRepairRequest>("event/createRepairRequest",
    async(data) => {
        const response = await createHttpRequest({
            method: "POST",
            path: ApiUrls.SERVICE_TICKET(),
            data,
            errorMessage: "messages:create_repair_request",
        });

        return response.data;
    });

export const fetchUserAnalytics = createAsyncThunk<any, number>("dashboard/fetchUserAnalytics",
    async(userId) => {
        const [userResponse, serviceResponse] = await Promise.all([
            createHttpRequest({
                method: "GET",
                path: ApiUrls.GET_USER_DASHBOARD_ANALYTICS(userId),
            }),
            createHttpRequest({
                method: "GET",
                path: ApiUrls.GET_SERVICE_DASHBOARD_ANALYTICS(),
            }),
        ]);

        return [userResponse.data, serviceResponse.data];
    });

// Помог
export const fetchUserHelped = createAsyncThunk<IFetchUserHelped>("dashboard/fetchUserHelped",
    async() => {
        const response = await createHttpRequest({
            method: "GET",
            path: ApiUrls.GET_USER_HELPED(),
        });

        return response.data;
    });

const dashboard = createSlice({
    reducers: {
        setZoneBarShow(state, action: PayloadAction<boolean>) {
            state.isZoneBarShow = action.payload;
        },

        toggleWidgetsVisibility(state, action: PayloadAction<keyof IFullMapWidgetsVisibility>) {
            const stateValue = state.fullMapWidgetsVisibility;
            stateValue[action.payload] = !stateValue[action.payload];
            setLocalStorageValue("fullMapWidgetsVisibility", JSON.stringify(stateValue));
        },

        toggleHeatmap(state, {payload}) {
            state.heatmapVisible = payload;
        },

        setIsOneOfStreamFullscreen(state, {payload}) {
            state.isOneOfStreamFullscreen = payload;
        },

        setIsNotifyService(state, {payload}) {
            state.isNotifyService = payload;
        },
    },
    name: "dashboard",
    initialState,
    extraReducers: (builder) => {
        // Заявка на ремонт
        builder.addCase(createRepairRequest.pending, (state) => {
            state.isCreateRepairError = false;
            state.isCreateRepairSuccess = false;
            state.isCreateRepairLoading = true;
        });
        builder.addCase(createRepairRequest.fulfilled, (state) => {
            state.isCreateRepairSuccess = true;
            state.isCreateRepairLoading = false;
        });
        builder.addCase(createRepairRequest.rejected, (state) => {
            state.isCreateRepairError = true;
            state.isCreateRepairLoading = false;
        });

        // Поработал
        builder.addCase(fetchUserAnalytics.pending, (state) => {
            state.analyticsLoading = true;
        });
        builder.addCase(fetchUserAnalytics.fulfilled, (state, {payload}) => {
            state.analytics.userTime = payload[0];
            state.analytics.serviceTime = payload[1];
            state.analyticsLoading = false;
        });
        builder.addCase(fetchUserAnalytics.rejected, (state) => {
            state.analyticsLoading = false;
        });

        // Помог
        builder.addCase(fetchUserHelped.pending, (state) => {
            state.helpedLoading = true;
        });
        builder.addCase(fetchUserHelped.fulfilled, (state, {payload}) => {
            state.dashboardHelped = payload;
            state.helpedLoading = false;
        });
        builder.addCase(fetchUserHelped.rejected, (state) => {
            state.helpedLoading = false;
        });
    },
});

export default dashboard.reducer;

// Экшены
export const {
    toggleHeatmap,
    setIsOneOfStreamFullscreen,
    setIsNotifyService,
    toggleWidgetsVisibility,
    setZoneBarShow,
} = dashboard.actions;

// Селекторы
const slice = ({dashboardReducer}: RootState) => dashboardReducer;

export const fullMapWidgetsVisibilitySelector = createSelector(
    slice,
    ({fullMapWidgetsVisibility}) => fullMapWidgetsVisibility
);

export const heatmapVisibleSelector = createSelector(
    slice,
    ({heatmapVisible}) => heatmapVisible
);

export const isOneOfStreamFullscreenSelector = createSelector(
    slice,
    ({isOneOfStreamFullscreen}) => isOneOfStreamFullscreen
);

export const isNotifyServiceSelector = createSelector(
    slice,
    ({isNotifyService}) => isNotifyService
);
export const isCreateRepairLoadingSelector = createSelector(
    slice,
    ({isCreateRepairLoading}) => isCreateRepairLoading
);

export const isCreateRepairSuccessSelector = createSelector(
    slice,
    ({isCreateRepairSuccess}) => isCreateRepairSuccess
);

export const userDashboardAnalyticsSelector = createSelector(
    slice,
    ({analytics}) => analytics
);

export const userDashboardHelpedSelector = createSelector(
    slice,
    ({dashboardHelped}) => dashboardHelped
);

export const userDashboardAnalyticsLoadingSelector = createSelector(
    slice,
    ({analyticsLoading}) => analyticsLoading
);

export const userDashboardHelpedLoadingSelector = createSelector(
    slice,
    ({helpedLoading}) => helpedLoading
);

export const isZoneBarShowSelector = createSelector(
    slice,
    ({isZoneBarShow}) => isZoneBarShow
);