import {Tab, TabList, TabPanel, Tabs} from "react-tabs";
import React, {FC, useState} from "react";
import PrioritiesIcon from "../../Icons/PrioritiesIcon";
import SettingsPriorities from "../SettingsPriorities/SettingsPriorities";
import SettingsIncidents from "../SettingsIncidents/SettingsIncidents";
import CamsIncidentsIcon from "../../Icons/CamsIncidentsIcon";
import styles from "../QuickSettingsTabs/QuickSettingsTabs.module.scss";

interface ISettingsCamsProps {
    cameraId: number | null | undefined,
    cameraType: string | null | undefined,
    isAdmin?: boolean,
    isManager?: boolean,
    cameraZone?: number | null | undefined,
}

const SettingsCams: FC<ISettingsCamsProps> = ({cameraId, cameraType, isAdmin, isManager, cameraZone}) => {
    const [tabIndex, setTabIndex] = useState(0);

    const isCameraIdValid = typeof cameraId === "number";

    return (
        <div>
            <Tabs selectedIndex={tabIndex} onSelect={(index) => setTabIndex(index)}>
                <TabList>
                    <Tab>
                        <CamsIncidentsIcon className={styles["tabs-list__icon"]}/>
                        Инциденты
                    </Tab>
                    {(isAdmin || isManager) && (
                        <Tab>
                            <PrioritiesIcon className={styles["tabs-list__icon"]}/>
                            Приоритеты
                        </Tab>
                    )}

                </TabList>

                {isCameraIdValid ? (
                    <TabPanel>
                        <SettingsIncidents cameraId={cameraId} cameraType={cameraType} cameraZone={cameraZone}/>
                    </TabPanel>
                ) : (
                    <TabPanel>
                        Выберите камеру
                    </TabPanel>
                )}

                {(isAdmin || isManager) && (
                    <TabPanel>
                        <SettingsPriorities/>
                    </TabPanel>
                )}

            </Tabs>
        </div>
    );
};

export default SettingsCams;
