import React, {FC} from "react";
import {generatePath} from "react-router-dom";

import {IToiNotification} from "../../../redux/eventSlice";
import {AppUrls} from "../../../constants/urls";

import CrossIcon from "../../Icons/CrossIcon";
import CheckInRoundedBox from "../../Icons/CheckInRoundedBox";

import styles from "./ToiNotification.module.scss";

interface IToiNotificationProps {
    notification: IToiNotification,
}

const ToiNotification: FC<IToiNotificationProps> = ({notification}) => {
    const goToIncident = (e: React.MouseEvent) => {
        e.stopPropagation();

        if (notification.incidentId) {
            window.open(generatePath(`${process.env.PUBLIC_URL}${AppUrls.EVENT_ARCHIVE}/:id`,
                {id: notification.incidentId}), "_blank");
        }
    };

    return (
        <div className={styles["notification"]}>
            <div className={styles["notification__icon"]}>
                <CheckInRoundedBox/>
            </div>

            <div className={styles["notification__content"]}>
                <div className={styles["notification__message"]}>
                    {notification.comment}
                </div>

                <div className={styles["notification__more"]}
                    title={`Перейти к инциденту ${notification.incidentId}`}
                    onClick={goToIncident}
                >
                    Узнать статус
                </div>
            </div>

            <div className={styles["close"]}><CrossIcon/></div>
        </div>
    );
};

export default ToiNotification;