import moment from "moment";
import {isEqual} from "lodash";

import {
    AnalyticsFiltersTypesEnum,
    DataFilterValuesEnum,
    DateGroupingValuesType,
    DaysOfWeekEnum,
    DefaultAnalyticsFiltersPartialType,
    HoursInDayType,
    IAEventsAndIncidentsResItem,
    IDefaultAnalyticsFilters,
    IFetchFilterOptions,
    IGetPreparedDateXProps,
    IWeekByTime,
    PrioritiesFilterEnum,
    PrioritiesFilterFetchEnum,
} from "../models/Analytics";
import {getLocalStorageValue} from "./common";
import {initialAnalyticsFiltersList} from "../redux/analyticsSlice";
import {
    createdDateTimeFilterDefaultValue,
    dateFormatAnalytics,
    defaultAnalyticsFromTime,
    defaultAnalyticsToTime,
} from "./AnalyticsUtils";

// список фильтров по умолчанию
export const defaultAnalyticsFilters: IDefaultAnalyticsFilters = {
    dataFilter: {
        value: DataFilterValuesEnum.events,
    },
    segmentIdsFilter: {
        value: [],
    },
    operatorsFilter: {
        value: [],
    },
    zoneFilter: {
        value: [],
    },
    responseServicesFilter: {
        value: [],
    },
    sourcesFilter: {
        value: [],
    },
    // shiftsFilter: {
    //     value: [],
    // },
    prioritiesFilter: {
        value: [],
    },
    typesFilter: {
        value: [],
    },
    tagsFilter: {
        value: [],
        modeTags: "AND",
    },
    hoursTimeFilter: {
        value: {
            from: defaultAnalyticsFromTime,
            to: defaultAnalyticsToTime,
        },
    },
    roadObjectsIds: {
        value: [],
    },
    createdDateTimeFilter: {
        value: {
            date: createdDateTimeFilterDefaultValue(),
            time: {
                from: defaultAnalyticsFromTime,
                to: defaultAnalyticsToTime,
            },
        },
    },
    incidentId: {
        value: "",
    },
};

export const getFilterFromLocalStorage = <T>(key: AnalyticsFiltersTypesEnum): T => {
    const value = getLocalStorageValue<T>(key);
    if (value) {
        if (typeof value === "string") {
            try {
                return JSON.parse(value) as T;
            } catch (error) {
                return initialAnalyticsFiltersList[key] as T;
            }
        }
    }
    return initialAnalyticsFiltersList[key] as T;
};

/* ========================= IncidentsByTime Виджет ==============================*/
export const compareDateFormat = "DD.MM.YY";

export const compareNoDate = "н/д";
// Дефолтный объект
export const defaultCompareItem: IAEventsAndIncidentsResItem = {
    date: compareNoDate,
    incidentCount: 0,
    trackedCount: 0,
    favoriteCount: 0,
    incidentCountHighPriority: 0,
    incidentCountMiddlePriority: 0,
    incidentCountLowPriority: 0,
    injuredCount: 0,
    deadCount: 0,
    injuredChildCount: 0,
    deadChildCount: 0,
};

// Секунды в минуты и секунды (MM:SS)
export const timeFormatter = (seconds: number) => {
    const minutes = Math.floor(seconds / 60);
    const currentSeconds = Math.floor((seconds % 60));
    return `${String(minutes).padStart(2, "0")}:${String(currentSeconds).padStart(2, "0")}`;
};

// Объект на основе HoursInDayType для графика
const defaultHoursInDay: HoursInDayType = {
    0: 0, 1: 0, 2: 0, 3: 0, 4: 0, 5: 0, 6: 0, 7: 0, 8: 0, 9: 0,
    10: 0, 11: 0, 12: 0, 13: 0, 14: 0, 15: 0, 16: 0, 17: 0, 18: 0,
    19: 0, 20: 0, 21: 0, 22: 0, 23: 0,
};

// Объекта недели по типу IWeekByTime для графика
export const defaultWeekByTime: IWeekByTime = {
    [DaysOfWeekEnum.Monday]: defaultHoursInDay,
    [DaysOfWeekEnum.Tuesday]: defaultHoursInDay,
    [DaysOfWeekEnum.Wednesday]: defaultHoursInDay,
    [DaysOfWeekEnum.Thursday]: defaultHoursInDay,
    [DaysOfWeekEnum.Friday]: defaultHoursInDay,
    [DaysOfWeekEnum.Saturday]: defaultHoursInDay,
    [DaysOfWeekEnum.Sunday]: defaultHoursInDay,
};

// Функция округления до нижнего ближайшего значения, кратного половине порядка числа
export const roundToNearestHalfOrder = (value: number): number => {
    if (value === 0) return 0;

    const order = Math.pow(10, Math.floor(Math.log10(value))); // Порядок числа (например, для 250 это 100)
    const halfOrder = order / 2; // Половина порядка (для 250 это 50)

    return Math.floor(value / halfOrder) * halfOrder;
};

// Функция для создания массива с шагами от максимального значения до нуля
export const createSteppedRange = (steps: number, maxValue: number): number[] => {

    const stepSize = maxValue / (steps - 1); // Размер одного шага

    const range = Array.from({length: steps}, (_, index) => {
        const rawValue = stepSize * index;
        return roundToNearestHalfOrder(rawValue);
    });
    return range.sort((a, b) => b - a);
};

export const findOpacity = (length: number, currentId: number) => {
    if (length === 0) return 0;
    return Math.floor((100 / (length)) * (length - currentId)) / 100;
};

export const findCurrentOpacity = (legendSteps: number[], count: number) => {
    let opacity = 0;
    if (count === 0) return findOpacity(legendSteps.length, legendSteps.length - 1);

    for (let i = 0; i < legendSteps.length; i++) {
        if (count >= legendSteps[i]) {
            opacity = findOpacity(legendSteps.length, i);
            break;
        }
    }
    return opacity;
};

// сокращает число до тысяч
export const reduceToThousands = (value: any): string => {
    value = isNaN(+value) ? 0 : +value;

    if (value > 1000) {
        const strValue = `${(value / 1000).toFixed(1)}К`;
        return strValue.split(".")[1] === "0К" ? strValue.split(".")[0] + "К" : strValue;
    }

    return value + "";
};

const getMonth = (month: string) => {
    const months = {
        January: "Янв",
        February: "Фев",
        March: "Март",
        April: "Апр",
        May: "Май",
        June: "Июнь",
        July: "Июль",
        August: "Авг",
        September: "Сен",
        October: "Окт",
        November: "Ноя",
        December: "Дек",
    };

    if (month in months) return months[month as keyof typeof months];
    return compareNoDate;
};

// Подготовка тиков по линии X
export const getPreparedDateX = ({
    chartData,
    date,
    id,
    isEmpty,
    groupingType,
    isCompare,
    info,
}: IGetPreparedDateXProps) => {

    if (!isEmpty) {

        const filterDate = info.createdDate.date;
        const filterTime = info.createdDate.time;

        const startName = id === 0;
        let endName = date === chartData[chartData.length - 1]?.date;
        const compareEndName = date === chartData[chartData.length - 1]?.dateCompare;

        endName = isCompare ? compareEndName : endName;

        if (date === compareNoDate) {
            return compareNoDate;
        }

        let notFullStart = "";
        let notFullEnd = "";

        if (startName) {
            const startDate = moment(filterDate.from, dateFormatAnalytics);
            const startDay = startDate.clone().format("DD");

            if (groupingType === "month") {
                if (+startDay !== 1) {
                    const endMonthDay = startDate.clone().endOf("month").format("DD");
                    notFullStart = `${startDay}..${endMonthDay}`;
                }
            }

            if (groupingType === "week") {

                const startDayWeek = startDate.clone().startOf("isoWeek").format("DD");
                const endWeekDay = startDate.clone().endOf("isoWeek").format("DD");

                if (+startDay !== +startDayWeek) {
                    notFullStart = `${startDay}..${endWeekDay}`;
                }
            }

            if (groupingType === "day") {
                if (filterTime.from !== "00:00") {
                    notFullStart = `${filterTime.from}..00:00`;
                }
            }

            if (groupingType === "hour") {
                if (filterTime.from.split(":")[1] !== "00") {
                    notFullStart = `${filterTime.from}`;
                }
            }

        }

        if (endName) {
            const endDate = moment(filterDate.to, dateFormatAnalytics);
            const endDay = endDate.clone().format("DD");

            if (groupingType === "month") {
                const endDayMonth = endDate.clone().endOf("month").format("DD");

                if (endDay !== endDayMonth) {
                    notFullEnd = `01..${endDay}`;
                }
            }

            if (groupingType === "week") {

                const startDayWeek = endDate.clone().startOf("isoWeek").format("DD");
                const endWeekDay = endDate.clone().endOf("isoWeek").format("DD");

                if (+endDay !== +endWeekDay) {
                    notFullEnd = `${startDayWeek}..${endDay}`;
                }
            }

            if (groupingType === "day") {
                if (filterTime.to !== "00:00") {
                    notFullEnd = `00:00..${filterTime.to}`;
                }
            }

            if (groupingType === "hour") {
                if (filterTime.to.split(":")[1] !== "00") {
                    notFullEnd = `${filterTime.to}`;
                }
            }

        }

        const month = moment(date).format("MMMM");
        const monthShort = moment(date).format("MM");
        const day = moment(date).format("DD");
        const hour = moment(date).format("HH");
        const week = moment(date).format("w");
        const year = moment(date).format("YYYY");
        const yearShort = moment(date).format("YY");

        if (groupingType === "hour") {
            return startName || endName
                ? `${hour}_${notFullStart || notFullEnd} ${day}.${monthShort}`
                : `${hour}`;
        }

        if (groupingType === "day") {
            return startName || endName
                ? `${day}_${notFullStart || notFullEnd} ${getMonth(month)}`
                : `${day}`;
        }

        if (groupingType === "week") {
            return startName || endName
                ? `${week}_${notFullStart || notFullEnd} ${getMonth(month)} ${yearShort}`
                : `${week}`;
        }

        if (groupingType === "month") {
            return startName || endName
                ? `${getMonth(month)}_${notFullStart || notFullEnd} ${year}`
                : `${getMonth(month)}`;
        }

    }
    return compareNoDate;
};

/* =======================================================*/

/* ========================= Подготовка данных для отправки ==============================*/

export const exportTimeFormat = "YYYY-MM-DDTHH:mm:ss";

const prepareCreatedDateTime = (filters: DefaultAnalyticsFiltersPartialType) => {

    let newFrom = moment().startOf("day").format(exportTimeFormat);
    let newTo = moment().add(1, "day").startOf("day").format(exportTimeFormat);
    let excludingUpperBound = false;

    if (filters?.createdDateTimeFilter) {
        const {date, time} = filters.createdDateTimeFilter.value;

        newFrom = moment(date.from + "-" + time.from, dateFormatAnalytics + "-HH:mm").format(exportTimeFormat);
        newTo = moment(date.to + "-" + time.to, dateFormatAnalytics + "-HH:mm").format(exportTimeFormat);

        // если время верхней границы 00:00, то добавляем 1 день, чтоб захватить весь последний день
        if (time.to === "00:00") {
            // eslint-disable-next-line max-len
            newTo = moment(date.to + "-" + time.to, dateFormatAnalytics + "-HH:mm").add(1, "d").format(exportTimeFormat);
            excludingUpperBound = true;
        }
    }
    return {
        lowerBound: newFrom, // 8601 формат даты и времени
        upperBound: newTo, // 8601 формат даты и времени
        excludingUpperBound,
    };
};

const prepareCreatedTime = (filters: DefaultAnalyticsFiltersPartialType) => {
    if (filters?.hoursTimeFilter) {
        const time = filters.hoursTimeFilter.value;
        if (time.from === "00:00" && time.to === "00:00") {
            return undefined;
        }
        return {
            lowerBound: time.from + ":00", // формат HH:MM:SS
            upperBound: time.to + ":00", // формат HH:MM:SS
        };
    }
    return undefined;
};

const preparePriorities = (filters: DefaultAnalyticsFiltersPartialType) => {
    let tracked = false;
    let favorite = false;
    let priorities: PrioritiesFilterFetchEnum[] = [];

    if (filters?.prioritiesFilter) {
        filters.prioritiesFilter.value.forEach(item => {
            if (item === PrioritiesFilterEnum.tracked) {
                tracked = true;
            }
            if (item === PrioritiesFilterEnum.favorite) {
                favorite = true;
            }
            if (item === PrioritiesFilterEnum.high) {
                priorities.push(PrioritiesFilterFetchEnum.high);
            }
            if (item === PrioritiesFilterEnum.middle_and_low) {
                priorities.push(PrioritiesFilterFetchEnum.middle);
                priorities.push(PrioritiesFilterFetchEnum.low);
            }
        });
    }
    return {
        tracked,
        favorite,
        priorities: !favorite && !tracked && !priorities.length ? undefined : priorities,
    };
};

export const prepareFiltersToFetch = (filters: DefaultAnalyticsFiltersPartialType): IFetchFilterOptions => {
    const {priorities, tracked, favorite} = preparePriorities(filters);

    const roadObjectsIds = filters.roadObjectsIds?.value.map(item => Number(item.id));

    const preparedFilters: IFetchFilterOptions = {
        createdDateTime: prepareCreatedDateTime(filters), // Поле обязательно
        kind: [filters?.dataFilter?.value || DataFilterValuesEnum.incidents],
        tracked: tracked, // На контроле
        favorite: favorite, // В ПроТВ
        createdTime: prepareCreatedTime(filters),
        priorities: priorities,
        tags: filters.tagsFilter?.value.length ? filters.tagsFilter.value : undefined,
        tagsMode: filters.tagsFilter?.modeTags ? filters.tagsFilter.modeTags : "AND",
        sourcesTags: filters.sourcesFilter?.value.length ? filters.sourcesFilter.value : undefined,
        segmentIds: filters.segmentIdsFilter?.value.length ? filters.segmentIdsFilter.value : undefined,
        types: filters.typesFilter?.value.length ? filters.typesFilter.value : undefined,
        zones: filters.zoneFilter?.value.length ? filters.zoneFilter.value : undefined,
        // eslint-disable-next-line max-len
        responseServices: filters.responseServicesFilter?.value.length ? filters.responseServicesFilter.value : undefined,
        operators: filters.operatorsFilter?.value.length ? filters.operatorsFilter.value : undefined,
        roadObjectsIds: filters.roadObjectsIds?.value.length ? roadObjectsIds : undefined,
        incidentId: filters.incidentId?.value,
    };

    // Фильтруем undefined поля
    const optionalFields = Object.fromEntries(
        Object.entries(preparedFilters).filter(([key, value]) => key === "createdDateTime" || value !== undefined)
    );

    return optionalFields as IFetchFilterOptions;
};

export const comparisonOfFilters = (modifiedFilter: DefaultAnalyticsFiltersPartialType) => {
    let modifiedCount = 0;

    for (const key in modifiedFilter) {
        const typedKey = key as keyof DefaultAnalyticsFiltersPartialType;
        if (!(isEqual(modifiedFilter[typedKey]?.value, defaultAnalyticsFilters[typedKey].value))) {
            modifiedCount += 1;
        }
    }

    return modifiedCount;
};

//Подготовка dateGrouping
export const prepareDateGrouping =
    ({lowerBound, upperBound}: {lowerBound: string, upperBound: string}): DateGroupingValuesType => {
        const lowerBoundDate = moment(lowerBound);
        const upperBoundDate = moment(upperBound);

        // Разница в миллисекундах
        const diffInMilliseconds = Math.abs(lowerBoundDate.diff(upperBoundDate));

        // Переводим разницу в часы, дни и недели
        const diffInHours = moment.duration(diffInMilliseconds).asHours();
        const diffInDays = moment.duration(diffInMilliseconds).asDays();
        const diffInWeeks = moment.duration(diffInMilliseconds).asWeeks();

        let result: DateGroupingValuesType;

        if (diffInHours <= 24) {
            result = "hour";
        } else if (diffInDays <= 31) {
            result = "day";
        } else if (diffInWeeks <= 25) {
            result = "week";
        } else {
            result = "month";
        }

        return result;
    };

/* =======================================================*/

export const getDayWord = (count: number, type: DateGroupingValuesType): string => {
    if (count % 10 === 1 && count % 100 !== 11) {
        return type === "hour" ? "час" : "день";
    } else if ([2, 3, 4].includes(count % 10) && ![12, 13, 14].includes(count % 100)) {
        return type === "hour" ? "часа" : "дня";
    } else {
        return type === "hour" ? "часов" : "дней";
    }
};

type PolygonType = [number, number][];

interface IGetTwoPointsToPolygon {
    initMinLng: number,
    initMaxLng: number,
    initMinLat: number,
    initMaxLat: number,
    polygon: PolygonType,
}

export interface ITwoPointsToPolygon {
    minLng: number,
    maxLng: number,
    minLat: number,
    maxLat: number,
}

// Поиск двух точек для полигона
export const getTwoPointsToPolygon = ({
    initMinLng,
    initMaxLng,
    initMinLat,
    initMaxLat,
    polygon,
}: IGetTwoPointsToPolygon): ITwoPointsToPolygon => {

    polygon.forEach(([lng, lat]) => {
        if (lng < initMinLng) initMinLng = lng;
        if (lng > initMaxLng) initMaxLng = lng;
        if (lat < initMinLat) initMinLat = lat;
        if (lat > initMaxLat) initMaxLat = lat;
    });

    return {
        minLng: initMinLng,
        maxLng: initMaxLng,
        minLat: initMinLat,
        maxLat: initMaxLat,
    };
};

export const getFiltersForPriority = (selectedPriorities: PrioritiesFilterEnum[]) => {

    const isHigh =
        selectedPriorities.length === 0 ? true : selectedPriorities.includes(PrioritiesFilterEnum.high);

    const isTracked =
        selectedPriorities.length === 0 ? true : selectedPriorities.includes(PrioritiesFilterEnum.tracked);

    const isFavorite =
        selectedPriorities.length === 0 ? true : selectedPriorities.includes(PrioritiesFilterEnum.favorite);

    const isMiddleAndLow =
        selectedPriorities.length === 0
            ? true
            : selectedPriorities.includes(PrioritiesFilterEnum.middle_and_low);

    return {
        isHigh,
        isTracked,
        isFavorite,
        isMiddleAndLow,
    };
};