import React, {FC, useEffect} from "react";

import useBroadcastChanel from "../../../hook/useBroadcastChanel";
import {BroadcastChannelEvents} from "../../../models/BroadcastChannelEvents";
import {SecondScreenModel} from "../../../models/SecondScreenModel";

import HeaderAuthorization from "../../Header/HeaderAuthorization";

import stylesCommon from "../../../styles/Common.module.scss";

interface ILayoutAuthorizationProps {
    children: React.ReactNode | string,
}

const LayoutAuthorization: FC<ILayoutAuthorizationProps> = ({children}) => {
    const {postMessage} = useBroadcastChanel({name: BroadcastChannelEvents.LOGOUT});

    useEffect(() => {
        if (window.name === SecondScreenModel.SECOND_SCREEN_NAME) window.close();

        postMessage(BroadcastChannelEvents.LOGGED_OUT);

        return () => postMessage(BroadcastChannelEvents.LOGGED_OUT);
    }, [children, postMessage]);

    return (
        <>
            <HeaderAuthorization/>

            <div className={stylesCommon["main__content"]}>
                {children}
            </div>
        </>
    );
};

export default LayoutAuthorization;