import React, {FC, useEffect, useRef, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {TypedDispatch} from "../../../types";
import {formatTimeDifference} from "../../../utils/formatTimeDifference";
import {TIME_OPTIONS} from "../../../constants/common";
import cn from "classnames";
import {
    createProcessingSuppressedBeforeUtc,
    editProcessingSuppressedBeforeUtc,
    ICams, successSelector,
} from "../../../redux/disablingCameraIncidentsSlice";
import styles from "../SettingsIncidents/SettingsIncidents.module.scss";

interface ISettingsIncidentsProps {
    cameraId: any,
    matchedCam: any,
    matchedId: any,
    noMatchedCam: any,
    prevUtc: any,
    prevJson: any,
    zoneId?: number,
    isSelected: string | null,
}

const SettingsIncidentsAllCam: FC<ISettingsIncidentsProps> = ({
    cameraId,
    matchedCam,
    matchedId,
    noMatchedCam,
    prevUtc,
    prevJson,
    zoneId,
    isSelected,
}) => {
    const dispatch = useDispatch<TypedDispatch>();
    const [isChecked, setIsChecked] = useState<boolean>(false);
    const selectRef = useRef<HTMLDivElement>(null);
    const [openSelect, setOpenSelect] = useState<string | null>(null);
    const [selectedTime, setSelectedTime] = useState<any>();
    const success = useSelector(successSelector);

    useEffect(() => {
        function handleClickOutside(event: MouseEvent) {
            if (selectRef.current && !selectRef.current.contains(event.target as Node)) {
                setOpenSelect(null);
            }
        }

        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, []);

    useEffect(() => {
        const value = isSelected ? new Date(isSelected) > new Date() : false;
        setIsChecked(value);
    }, [isSelected]);

    const handleCheckboxAllIncidentsChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        prevUtc.utc = matchedCam?.Data.incidentProcessingSuppressedBeforeUtc;
        prevJson.json = matchedCam?.Data.json;

        let validUntilUtc;
        if (selectedTime === "forever") {
            validUntilUtc = new Date(2099, 11, 31).toISOString();
        } else {
            const timeValue = Number(selectedTime);
            if (!isNaN(timeValue) && timeValue > 0) {
                validUntilUtc = new Date(Date.now() + Number(selectedTime) * 60 * 1000).toISOString();
            } else {
                validUntilUtc = "";
            }
        }

        if (!noMatchedCam) {
            //добавить в пустой
            const newCamData: ICams = {
                name: cameraId,
                data: {
                    roadObjectId: cameraId,
                    zoneId: zoneId,
                    incidentProcessingSuppressedBeforeUtc: validUntilUtc,
                    json: {
                        prioritySettings: prevJson.json?.prioritySettings || [],
                    },
                },
            };
            dispatch(createProcessingSuppressedBeforeUtc({
                data: {
                    data: newCamData?.data,
                    name: cameraId,
                    typeId: "INCIDENTS_CAMERASETTINGS.1",
                },
            }));
            setIsChecked(true);
        } else if (event.target.checked) {
            //добавить
            const newCamData: ICams = {
                name: cameraId,
                data: {
                    roadObjectId: cameraId,
                    zoneId: zoneId,
                    incidentProcessingSuppressedBeforeUtc: validUntilUtc,
                    json: {
                        prioritySettings: prevJson.json?.prioritySettings || [],
                    },
                },
            };

            if (matchedId) {
                const preparedData = {
                    data: newCamData.data,
                    name: cameraId,
                    typeId: "INCIDENTS_CAMERASETTINGS.1",
                };
                dispatch(editProcessingSuppressedBeforeUtc({id: matchedId, data: preparedData}));
            }
            setIsChecked(true);
        } else {
            //удалить
            const newCamData: ICams = {
                name: cameraId,
                data: {
                    roadObjectId: cameraId,
                    zoneId: zoneId,
                    incidentProcessingSuppressedBeforeUtc: undefined,
                    json: {
                        prioritySettings: prevJson.json?.prioritySettings || [],
                    },
                },
            };

            if (matchedId) {
                const preparedData = {
                    data: newCamData.data,
                    name: cameraId,
                    typeId: "INCIDENTS_CAMERASETTINGS.1",
                };
                dispatch(editProcessingSuppressedBeforeUtc({id: matchedId, data: preparedData}));
            }
            setIsChecked(false);
        }
    };

    function toggleSelect(selectName: string) {
        setOpenSelect(prev => (prev === selectName ? null : selectName));
    }

    const handleOptionSelect = (type: string, value: any) => {
        if (type === "time") {
            setSelectedTime(value);
            setOpenSelect(null);
        }
    };

    return (
        <div className={styles["settings-incidents__disabling-all"]}>
            <div data-value={"all_cam"}
                className={styles["settings-incidents__types-item"]} ref={selectRef}>
                <div className={styles["settings-incidents__disabling-all-name"]}>
                    Отключить камеру полностью
                </div>
                <div className="cams-info__custom-select custom-select__time">
                    <div
                        className={cn(openSelect === "time" && styles["select__trigger-active"],
                            "cams-info__select-trigger")}
                        onClick={() => toggleSelect("time")}
                    >
                        {selectedTime ? TIME_OPTIONS[selectedTime] :
                            formatTimeDifference(matchedCam?.Data?.incidentProcessingSuppressedBeforeUtc)}
                    </div>
                    {openSelect === "time" && (
                        <div className={cn(styles["select__options-active"], "cams-info__options")}>
                            {Object.keys(TIME_OPTIONS).map((key) => (
                                <div key={key} className="cams-info__option" data-value={key}
                                    onClick={() => {
                                        handleOptionSelect("time", key);
                                        setIsChecked(false);
                                    }}>
                                    {TIME_OPTIONS[key]}
                                </div>
                            ))}
                        </div>
                    )}
                </div>

                <label className="cams-info__custom-switch">
                    <input
                        type="checkbox"
                        className="disable-tracking-checkbox"
                        disabled={!success}
                        onChange={(event) => {
                            handleCheckboxAllIncidentsChange(event);
                        }}
                        checked={isChecked}
                    />
                    <span className="slider round"></span>
                </label>
            </div>
        </div>
    );
};

export default SettingsIncidentsAllCam;
