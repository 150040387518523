import {createAsyncThunk, createSelector, createSlice} from "@reduxjs/toolkit";
import {toast} from "react-toastify";

import {RootState} from "store";
import createHttpRequest from "../utils/http";
import {ApiUrls} from "../constants/urls";

export interface ICams {
    name: string,
    data: {
        roadObjectId: number,
        zoneId?:number,
        incidentProcessingSuppressedBeforeUtc?: string,
        json?: {
            prioritySettings: ICamsJson[],
        },
    },
}

export interface ICamsJson {
    priority?: number,
    segmentId?: string,
    incidentTypeCode?: string,
    validUntilUtc: string,
}

interface IInitialState {
    cams: any,
    camsGroups: any,
    camsLoading: boolean,
    success: any,
    requestGroupLoading: boolean,
    requestGroupSuccess: any,
    segments: any,
    segmentsGroup: any,
    segmentsLoading: boolean,
    segmentsSuccess: any,
    currentCameraRules: any,
}

const initialState: IInitialState = {
    cams: [],
    camsGroups: null,
    camsLoading: false,
    success: null,
    requestGroupLoading: false,
    requestGroupSuccess: null,
    segments: [],
    segmentsGroup: null,
    segmentsLoading: false,
    segmentsSuccess: null,
    currentCameraRules: {},
};
export const fetchSegments
    = createAsyncThunk<any>("cams/fetchSegments", async() => {
        const response = await createHttpRequest({
            method: "GET",
            path: ApiUrls.INCIDENT_DICTIONARIES("INCIDENTS_CAMERASEGMENTS.1"),
            errorMessage: "messages:fetch_camera_segments_error",
        });

        return response.data;
    });
export const fetchProcessingSuppressedBeforeUtc
    = createAsyncThunk<any>("cams/fetchProcessingSuppressedBeforeUtc", async() => {
        const response = await createHttpRequest({
            method: "GET",
            path: ApiUrls.INCIDENT_DICTIONARIES("INCIDENTS_CAMERASETTINGS.1"),
            errorMessage: "messages:fetch_processing_suppressed_before_utc_error",
        });

        return response.data;
    });

export const fetchCameraSettings
    = createAsyncThunk<any, number>("cams/fetchCameraSettings", async(id: number) => {
        const response = await createHttpRequest({
            method: "GET",
            path: ApiUrls.CAMERA_SETTINGS(id),
            errorMessage: "messages:cam_get_disable_settings_error",
        });

        return response.data;
    });

export const createProcessingSuppressedBeforeUtc = createAsyncThunk<any, any>(
    "cams/createProcessingSuppressedBeforeUtc",
    async({data}) => {
        const response = await createHttpRequest({
            method: "POST",
            path: ApiUrls.INCIDENT_DICTIONARIES("INCIDENTS_CAMERASETTINGS.1"),
            data,
            errorMessage: "messages:create_processing_suppressed_before_utc_error",
        });

        return response.data;
    }
);

export const editProcessingSuppressedBeforeUtc = createAsyncThunk<any, any>(
    "cams/editProcessingSuppressedBeforeUtc",
    async({id, data}) => {
        const response = await createHttpRequest({
            method: "PUT",
            path: ApiUrls.INCIDENT_DICTIONARY(id),
            data,
            errorMessage: "messages:edit_processing_suppressed_before_utc_error",
        });

        return response.data;
    }
);

export const deleteProcessingSuppressedBeforeUtc = createAsyncThunk<any, any>(
    "cams/deleteProcessingSuppressedBeforeUtc",
    async({id, segmentId, incidentTypeCode}) => {
        const response = await createHttpRequest({
            method: "DELETE",
            path: ApiUrls.INCIDENT_DICTIONARY_OFF_DISABLING(id, incidentTypeCode, segmentId),
            errorMessage: "messages:delete_processing_suppressed_before_utc_error",
        });

        return response.data;
    }
);

export const updateProcessingSuppressedBeforeUtc = createAsyncThunk<any, any>(
    "cams/updateProcessingSuppressedBeforeUtc",
    async({id, segmentId, validUntilUtc, incidentTypeCode}) => {
        const response = await createHttpRequest({
            method: "PUT",
            path: ApiUrls.INCIDENT_DICTIONARY_UPDATE_DISABLING(id, segmentId, validUntilUtc, incidentTypeCode),
            errorMessage: "messages:update_processing_suppressed_before_utc_error",
        });

        return response.data;
    }
);

const disablingCamera = createSlice({
    reducers: {
        resetCameraRule(state) {
            state.currentCameraRules = {};
        },
    },
    name: "responsibility",
    initialState,
    extraReducers: (builder) => {
        builder.addCase(fetchSegments.pending, (state) => {
            state.segmentsSuccess = null;
            state.segmentsLoading = true;
        });
        builder.addCase(fetchSegments.fulfilled, (state, {payload}) => {
            state.segmentsGroup = payload.Result;
            state.segmentsSuccess = true;
            state.segmentsLoading = false;
        });
        builder.addCase(fetchSegments.rejected, (state) => {
            state.segmentsSuccess = null;
            state.segmentsLoading = false;
        });

        builder.addCase(fetchCameraSettings.pending, (state) => {
            state.segmentsLoading = true;
        });
        builder.addCase(fetchCameraSettings.fulfilled, (state, {payload}) => {
            state.currentCameraRules = payload;
            state.segmentsLoading = false;
        });
        builder.addCase(fetchCameraSettings.rejected, (state) => {
            state.currentCameraRules = {};
            state.segmentsLoading = false;
        });

        builder.addCase(fetchProcessingSuppressedBeforeUtc.pending, (state) => {
            state.success = null;
            state.camsLoading = true;
        });
        builder.addCase(fetchProcessingSuppressedBeforeUtc.fulfilled, (state, {payload}) => {
            state.camsGroups = payload.Result;
            state.success = true;
            state.camsLoading = false;
        });
        builder.addCase(fetchProcessingSuppressedBeforeUtc.rejected, (state) => {
            state.success = null;
            state.camsLoading = false;
        });

        builder.addCase(createProcessingSuppressedBeforeUtc.pending, (state) => {
            state.requestGroupSuccess = null;
            state.requestGroupLoading = true;
        });
        builder.addCase(createProcessingSuppressedBeforeUtc.fulfilled, (state) => {
            state.requestGroupSuccess = true;
            state.requestGroupLoading = false;
            toast.success("Время снижения приоритетов для камеры успешно создано", {position: "bottom-right"});
        });
        builder.addCase(createProcessingSuppressedBeforeUtc.rejected, (state) => {
            state.requestGroupSuccess = null;
            state.requestGroupLoading = false;
            toast.error("Не удалось создать время снижения приоритетов для камеры", {position: "bottom-right"});
        });

        builder.addCase(editProcessingSuppressedBeforeUtc.pending, (state) => {
            state.requestGroupSuccess = null;
            state.requestGroupLoading = true;
        });
        builder.addCase(editProcessingSuppressedBeforeUtc.fulfilled, (state) => {
            state.requestGroupSuccess = true;
            state.requestGroupLoading = false;
            toast.success("Время снижения приоритетов для камеры успешно изменено", {position: "bottom-right"});
        });
        builder.addCase(editProcessingSuppressedBeforeUtc.rejected, (state) => {
            state.requestGroupSuccess = null;
            state.requestGroupLoading = false;
            toast.error("Не удалось изменить время снижения приоритетов для камеры", {position: "bottom-right"});
        });
        builder.addCase(deleteProcessingSuppressedBeforeUtc.pending, (state) => {
            state.requestGroupSuccess = null;
            state.requestGroupLoading = true;
        });
        builder.addCase(deleteProcessingSuppressedBeforeUtc.fulfilled, (state) => {
            state.requestGroupSuccess = true;
            state.requestGroupLoading = false;
            toast.success("Время снижения приоритетов для камеры успешно удалено", {position: "bottom-right"});
        });
        builder.addCase(deleteProcessingSuppressedBeforeUtc.rejected, (state) => {
            state.requestGroupSuccess = null;
            state.requestGroupLoading = false;
            toast.error("Не удалось удалить время снижения приоритетов для камеры", {position: "bottom-right"});
        });
        builder.addCase(updateProcessingSuppressedBeforeUtc.pending, (state) => {
            state.requestGroupSuccess = null;
            state.requestGroupLoading = true;
        });
        builder.addCase(updateProcessingSuppressedBeforeUtc.fulfilled, (state) => {
            state.requestGroupSuccess = true;
            state.requestGroupLoading = false;
            toast.success("Время снижения приоритетов для камеры успешно обновлено", {position: "bottom-right"});
        });
        builder.addCase(updateProcessingSuppressedBeforeUtc.rejected, (state) => {
            state.requestGroupSuccess = null;
            state.requestGroupLoading = false;
            toast.error("Не удалось обновить время снижения приоритетов для камеры", {position: "bottom-right"});
        });
    },
});

export default disablingCamera.reducer;

export const {
    resetCameraRule,
} = disablingCamera.actions;

const slice = ({disablingCameraReducer}: RootState) => disablingCameraReducer;

//Селекторы
export const requestGroupSuccessSelector = createSelector(
    slice,
    ({requestGroupSuccess}) => requestGroupSuccess
);

export const camsGroupsSelector = createSelector(
    slice,
    ({camsGroups}) => camsGroups
);
export const segmentsGroupsSelector = createSelector(
    slice,
    ({segmentsGroup}) => segmentsGroup
);
export const successSelector = createSelector(
    slice,
    ({success}) => success
);

export const currentCameraRulesSelector = createSelector(
    slice,
    ({currentCameraRules}) => currentCameraRules
);