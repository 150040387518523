export const formatTimeDifference = (validUntilUtc: string): string => {
    const now = new Date();
    const validUntil = new Date(validUntilUtc);
    const differenceInMs = validUntil.getTime() - now.getTime();

    if (differenceInMs <= 0) return "Время";

    const minutes = Math.round(differenceInMs / (1000 * 60));

    if (minutes < 60) {
        return `${minutes} мин`;
    } else if (minutes < 1440) {
        const hours = Math.round(minutes / 60);
        return `${hours} час${hours > 1 ? "а" : ""}`;
    } else if (minutes >= 1440 && minutes < 10080) {
        const days = Math.round(minutes / 1440);
        return `${days} день${days > 1 ? "дней" : ""}`;
    } else if (validUntilUtc === "2099-12-30T19:00:00Z" || minutes > 500000) {
        return "Навсегда";
    }

    return "Время";
};