import {createSelector, createSlice} from "@reduxjs/toolkit";

import {RootState} from "store";
import IncidentEventInfoModel from "../models/IncidentEventInfoModel";

interface IInitialState {
    incident: IncidentEventInfoModel | null,
    selectedMediaId: string | null,
    isOpen: boolean,
}

const initialState: IInitialState = {
    incident: null,
    selectedMediaId: null,
    isOpen: false,
};

const media = createSlice({
    reducers: {
        openViewer(state, {payload}){
            state.incident = payload.incident;
            state.selectedMediaId = payload.selectedMediaId;
            state.isOpen = true;
        },
        closeViewer(state){
            state.incident = null;
            state.selectedMediaId = null;
            state.isOpen = false;
        },
    },
    name: "media",
    initialState,
});

export const {
    openViewer,
    closeViewer,
} = media.actions;

export default media.reducer;

//Селекторы
const slice = ({mediaReducer}: RootState) => mediaReducer;

export const incidentSelector = createSelector(
    slice,
    ({incident}) => incident
);

export const selectedMediaIdSelector = createSelector(
    slice,
    ({selectedMediaId}) => selectedMediaId
);

export const isOpenSelector = createSelector(
    slice,
    ({isOpen}) => isOpen
);
