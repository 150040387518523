import React, {FC} from "react";

import MessageIcon from "../../Icons/MessageIcon";

import styles from "./DashboardChat.module.scss";

interface IDashboardChatProps {
    onClick: () => void,
    unreadMessagesCount: number,
}

const DashboardChat: FC<IDashboardChatProps> = ({onClick, unreadMessagesCount}) => {

    return (
        <div className={styles["chat"]} onClick={onClick}>
            <MessageIcon className={styles["chat__icon"]}/>
            {unreadMessagesCount > 0 && (
                <div className={styles["chat-unread-messages-count"]}>
                    {unreadMessagesCount}
                </div>
            )}
        </div>
    );
};

export default DashboardChat;