/* eslint-disable max-len */
import React from "react";

import {ISvgProps} from "../../../../types/common";

const EventNewIcon: React.FC<ISvgProps> = ({
    height = 24,
    width = 24,
    ...rest
}) => (
    <svg {...rest} width={width} height={height} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <rect x="1" y="1" width="22" height="22" rx="4" fill="currentColor"/>
        <path fillRule="evenodd" clipRule="evenodd" d="M12.0038 3.34961C10.887 3.3483 9.85406 3.94191 9.29313 4.90741L4.03763 13.8916C3.46524 14.8544 3.45269 16.0598 4.00671 17.0427C4.56143 18.0267 5.60008 18.6391 6.72983 18.6483L6.73105 18.6483L17.2594 18.6484L17.2609 18.6484C18.4013 18.6373 19.4382 18.0271 19.993 17.0436C20.5469 16.0616 20.5344 14.8585 19.9605 13.8881L14.7071 4.90744C14.1526 3.94662 13.1201 3.35092 12.0038 3.34961ZM10.8484 5.81364C11.0879 5.40129 11.527 5.14905 12.0017 5.14961C12.4767 5.15017 12.9159 5.40456 13.1509 5.81202L18.4094 14.8014C18.6578 15.2214 18.663 15.7378 18.4252 16.1592C18.1874 16.5808 17.7426 16.8434 17.2513 16.8484L6.73781 16.8483C6.25908 16.8442 5.81303 16.5815 5.57475 16.1588C5.33665 15.7364 5.34252 15.2189 5.58774 14.8065L5.58829 14.8056L10.8484 5.81364ZM12.903 7.84837H11.1025V13.3984H12.903V7.84837ZM12.0005 13.8484C11.5034 13.8484 11.1003 14.2513 11.1003 14.7484C11.1003 15.2455 11.5034 15.6484 12.0005 15.6484C12.4976 15.6484 12.9007 15.2455 12.9007 14.7484C12.9007 14.2513 12.4976 13.8484 12.0005 13.8484Z" fill="white"/>
    </svg>
);

export default EventNewIcon;