import React, {ReactElement} from "react";
import {isEmpty} from "lodash";
import {Route} from "react-router";

import {Permission} from "../models/Permission";
import ProtectedElement from "../components/Security/ProtectedElement";

export interface IProtectedRouteProps {
    path: string,
    element: ReactElement,
    auth?: Permission[],
    title?: string,
    icon?: ReactElement,
    children?: IProtectedRouteProps[],
}

export function isGrantedAccess(userPermissions: Permission[], auth: Permission[] | undefined): boolean {
    if (!auth || auth.length === 0) {
        return true;
    }

    return userPermissions.some(userPermission => auth.includes(userPermission));
}

export function getProtectedRoute({
    path,
    element,
    auth,
    children,
}: IProtectedRouteProps) {
    const protectedElement = auth && !isEmpty(auth)
        ? <ProtectedElement auth={auth} element={element}/>
        : element;

    if (children?.length) {
        return (
            <Route
                key={path}
                path={path}
                element={protectedElement}
            >
                {children.map(routeProps => getProtectedRoute(routeProps))}
            </Route>
        );
    }

    return (
        <Route
            key={path}
            path={path}
            element={protectedElement}
        />
    );
}