import React, {useEffect, useState} from "react";
import cn from "classnames";
import {useTranslation} from "react-i18next";

import PaginationChevronLeftIcon from "components/Icons/PaginationChevronLeftIcon";
import LightTooltip from "components/UI/Tooltip/LightTooltip";

import {getPaginationLabels} from "utils/paginationHelper";

import styles from "./Pagination.module.scss";

export interface IPaginationClickHandler {
    // eslint-disable-next-line no-unused-vars
    (page: number): void,
}

interface IPaginationProps{
    initialPage: number,
    lastPage: number,
    onClick?: IPaginationClickHandler,
}

const renderPaginationButtons = (
    currentPage: number,
    lastPage: number,
    onClick: IPaginationClickHandler
) => {
    const buttonsArray = getPaginationLabels(currentPage, lastPage);

    return buttonsArray.map((label) => {
        const isActive = label === currentPage;
        return typeof label === "string" ? (
            <div
                key={label}
                className={cn(
                    styles["pagination__item"],
                    styles["pagination__item--disabled"]
                )}>
                ...
            </div>
        ) : (
            <div
                className={cn(
                    styles["pagination__item"],
                    {[styles["pagination__item--active"]]: isActive}
                )}
                key={label}
                onClick={() => !isActive ? onClick(label) : null}
            >
                {label}
            </div>
        );
    });
};

const Pagination: React.FC<IPaginationProps> = ({
    initialPage,
    lastPage,
    onClick,
}) => {
    useEffect(() => {
        setCurrentPage(initialPage);
    }, [initialPage]);

    const {t} = useTranslation("common");
    const [currentPage, setCurrentPage] = useState<number>(initialPage);
    const clickHandler: IPaginationClickHandler = (page) => {
        setCurrentPage(page);
        if (onClick) {
            onClick(page);
        }
    };

    // Отключаем кнопки пагинации если текущая страница равна первой/последней или общее число страниц не больше одной
    const isPrevDisabled = currentPage === 1 || lastPage <= 1;
    const isNextDisabled = currentPage === lastPage || lastPage <= 1;

    return (
        <div className={styles["pagination"]}>
            <div
                onClick={() => !isPrevDisabled ? clickHandler(currentPage - 1) : null}
                className={cn(
                    styles["pagination__item"],
                    styles["pagination__arrow"],
                    {[styles["pagination__arrow--disabled"]]: isPrevDisabled}
                )}
            >
                <LightTooltip
                    title={t("pagination.prev_page")}
                >
                    <div><PaginationChevronLeftIcon/></div>
                </LightTooltip>
            </div>

            {renderPaginationButtons(currentPage, lastPage, clickHandler)}

            <div
                onClick={() => !isNextDisabled ? clickHandler(currentPage + 1) : null}
                className={cn(
                    styles["pagination__item"],
                    styles["pagination__arrow"],
                    styles["pagination__arrow--next"],
                    {[styles["pagination__arrow--disabled"]]: isNextDisabled}
                )}
            >
                <LightTooltip
                    title={t("pagination.next_page")}
                >
                    <div><PaginationChevronLeftIcon/></div>
                </LightTooltip>
            </div>
        </div>
    );
};

export default Pagination;