/* eslint-disable max-len */
import React, {FC} from "react";

import {ISvgProps} from "../../types/common";

const CloseGrayIcon: FC<ISvgProps> = ({
    width = 22,
    height = 22,
    ...rest
}) => {
    return (
        <svg {...rest} width={width} height={height} viewBox="0 0 23 22" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M0.5 6C0.5 2.68629 3.18629 0 6.5 0H16.5C19.8137 0 22.5 2.68629 22.5 6V16C22.5 19.3137 19.8137 22 16.5 22H6.5C3.18629 22 0.5 19.3137 0.5 16V6Z"
                fill="#464963"/>
            <path fillRule="evenodd" clipRule="evenodd"
                d="M12.325 10.9996L16.0373 14.7119L15.2123 15.5368L11.5 11.8245L7.78769 15.5368L6.96273 14.7119L10.675 10.9996L6.96273 7.28727L7.78769 6.46231L11.5 10.1746L15.2123 6.46231L16.0373 7.28727L12.325 10.9996Z"
                fill="#666B8F"/>
        </svg>

    );
};

export default CloseGrayIcon;