/* eslint-disable max-len */
import React from "react";

import {ISvgProps} from "../../../../types/common";

const EventRejectedIcon: React.FC<ISvgProps> = ({
    height = 24,
    width = 24,
    ...rest
}) => (
    <svg {...rest} width={width} height={height} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <rect x="1" y="1" width="22" height="22" rx="4" fill="currentColor"/>
        <path fillRule="evenodd" clipRule="evenodd" d="M12.0001 13.4143L7.70718 17.7072L6.29297 16.293L10.5859 12.0001L6.29297 7.70718L7.70718 6.29297L12.0001 10.5859L16.293 6.29297L17.7072 7.70718L13.4143 12.0001L17.7072 16.293L16.293 17.7072L12.0001 13.4143Z" fill="white"/>
    </svg>
);

export default EventRejectedIcon;