//TODO: Удалить после исправления на бэке дублирования адреса инцидента

export const removeTextDuplication = (text: string) => {

    const splitDuplicatedText = text.split(";");

    if (splitDuplicatedText.length > 1) {
        return splitDuplicatedText[0];
    }

    return text;
};