/* eslint-disable no-unused-vars */
export enum EventSource {
    service112 = "service112",
    stabilizer = "stabilizer",
    api = "api",
    incidentCamera = "incidentCamera"
}

// Источники для архива
export enum ArchiveSources {
    incidentCamera = 1,
    api = 2,
    service112 = 112,
}