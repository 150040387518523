import {createAsyncThunk, createSlice} from "@reduxjs/toolkit";

import createHttpRequest from "../utils/http";
import {ApiUrls} from "../constants/urls";
import {GeoJSON} from "geojson";

/* eslint-disable no-unused-vars */
export enum transportRouteType {
    car = "car",
    emergency = "emergency",
}
/* eslint-enable no-unused-vars */

export interface ICreateRouteResponse {
    routes: {
        geoJson: GeoJSON,
    }[],
    totalDistance: string,
    totalDuration: string,
}

interface ICreateRoutePayload {
    startPoint: {
        lat: number,
        lon: number,
    },
    endPoint: {
        lat: number,
        lon: number,
    },
    routeMode?: "fastest" | "shortest",
    trafficMode?: "jam" | null,
    transport?: transportRouteType,
}

const initialState = {
    loading: false,
    success: false,
};

// Создание маршрута
export const createRoute = createAsyncThunk<ICreateRouteResponse, ICreateRoutePayload>(
    "mobile/createRoute",
    async({
        startPoint,
        endPoint,
        routeMode = "fastest",
        trafficMode = "jam",
        transport = transportRouteType.car,
    }) => {
        const response = await createHttpRequest({
            method: "POST",
            path: ApiUrls.CREATE_ROUTE,
            data: {
                points: [startPoint, endPoint],
                route_mode: routeMode,
                traffic_mode: trafficMode,
                transport: transport,
            },
            errorMessage: "messages:create_route_error",
        });
        return response.data;
    }
);

const mapRouting = createSlice({
    reducers: {},
    name: "mapRouting",
    initialState,
});

export default mapRouting.reducer;
