import {createAsyncThunk, createSlice} from "@reduxjs/toolkit";

import createHttpRequest from "utils/http";
import {ApiUrls} from "constants/urls";
import IncidentEventModel from "../models/IncidentEventModel";

// Расширенный интерфейс контента ТОИ
export interface IToiIncident {
    id: number,
    boardId: number,
    blockedLanes: boolean[] | null,
    distance: number,
    uiDistance: string | null,
    isActive: boolean,
    incidentType: 0 | 1,
    createdAt: string,
    updatedAt: string,
}

export interface IGenerateToiImageResponse {
    incidentImage: string,
    udzImages: string[],
}

export interface IToiIncidentPayload {
    boardId: string,
    data: {
        distance: number,
        blockedLanes: boolean[] | null,
        incidentType: 0 | 1,
        isActive: boolean,
    },
}

export interface IToiQueueItem {
    incidentId: string | null,
    isPriority: boolean,
    distanceToBoard: number,
    blockedLanes: boolean[] | null,
    incidentType: 0 | 1,
    isActive: boolean,
    createAt?: string,
    toiData?: {
        uiDistance: string | null,
        createdAt: string,
        updatedAt: string,
    },
    incident?: IncidentEventModel,
}

export interface IAddIncidentToToiQueuePayload {
    boardId: string,
    data: Partial<IToiQueueItem>,
}

const initialState = {};

// Получить инцидент по идентификатору табло
export const getToiIncident = createAsyncThunk<IToiIncident, string>(
    "toi/getToiIncident",
    async(boardId) => {
        const response = await createHttpRequest({
            method: "GET",
            path: ApiUrls.TOI.INCIDENT(boardId),
            silent: true,
            errorMessage: "messages:toi.toi_get_incident_error",
        });

        return response.data;
    }
);

// Создать контент инцидента
export const postToiIncident = createAsyncThunk<IToiIncident, IToiIncidentPayload>(
    "toi/postToiIncident",
    async({boardId, data}) => {
        const response = await createHttpRequest({
            method: "POST",
            path: ApiUrls.TOI.INCIDENT(boardId),
            data: data,
            errorMessage: "messages:toi.toi_post_incident_error",
        });

        return response.data;
    }
);

// Обновить контент инцидента
export const updateToiIncident = createAsyncThunk<IToiIncident, IToiIncidentPayload>(
    "toi/updateToiIncident",
    async({boardId, data}) => {
        const response = await createHttpRequest({
            method: "PUT",
            path: ApiUrls.TOI.INCIDENT(boardId),
            data: data,
            errorMessage: "messages:toi.toi_update_incident_error",
        });

        return response.data;
    }
);

// Удалить контент инцидента
export const deleteToiIncident = createAsyncThunk<IToiIncident, string>(
    "toi/deleteToiIncident",
    async(boardId) => {
        const response = await createHttpRequest({
            method: "DELETE",
            path: ApiUrls.TOI.INCIDENT(boardId),
            errorMessage: "messages:toi.toi_delete_incident_error",
        });

        return response.data;
    }
);

// Создать контент инцидента
export const generateToiImage = createAsyncThunk<IGenerateToiImageResponse, IToiIncidentPayload>(
    "toi/generateToiImage",
    async({data, boardId}) => {
        const response = await createHttpRequest({
            method: "POST",
            path: ApiUrls.TOI.GENERATE(boardId),
            data: data,
            errorMessage: "messages:toi.toi_generate_image_error",
        });

        return response.data;
    }
);

// Очередь инцидентов в ТОИ
// Получение очереди инцидентов
export const fetchToiQueue = createAsyncThunk<IToiQueueItem[], string>(
    "toi/queue",
    async(boardId) => {
        const response = await createHttpRequest({
            method: "GET",
            path: ApiUrls.TOI.QUEUE(boardId),
            errorMessage: "messages:toi.queue.get_queue_error",
        });

        return response.data;
    }
);

// Добавление/изменение инцидента в очередь (если в очереди не было инцидентов, будет создана запись на табло)
export const addIncidentToToiQueue = createAsyncThunk<null, IAddIncidentToToiQueuePayload>(
    "toi/addIncidentToToiQueue",
    async({data, boardId}) => {
        const response = await createHttpRequest({
            method: "PUT",
            path: ApiUrls.TOI.QUEUE(boardId),
            data: data,
            errorMessage: "messages:toi.queue.add_queue_incident_error",
        });

        return response.data;
    }
);

// Удаление инцидента из очереди
export const deleteIncidentToToiQueue = createAsyncThunk<null, {boardId: string, incidentId: string}>(
    "toi/deleteIncidentToToiQueue",
    async({boardId, incidentId}) => {
        const response = await createHttpRequest({
            method: "DELETE",
            path: ApiUrls.TOI.QUEUE(boardId, incidentId),
            errorMessage: "messages:toi.queue.delete_queue_incident_error",
        });

        return response.data;
    }
);

// Назначение инцидента приоритетным в очереди
export const setQueueIncidentPriority = createAsyncThunk<null, {boardId: string, incidentId: string}>(
    "toi/setQueueIncidentPriority",
    async({boardId, incidentId}) => {
        const response = await createHttpRequest({
            method: "PUT",
            path: ApiUrls.TOI.QUEUE_INCIDENT_PRIORITY(boardId, incidentId),
            errorMessage: "messages:toi.queue.update_queue_incident_priority_error",
        });

        return response.data;
    }
);

const toi = createSlice({
    name: "toi",
    initialState,
    reducers: {},
});

export default toi.reducer;

// Селекторы
// const slice = ({toiReducer}: RootState) => toiReducer;