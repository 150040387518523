/* eslint-disable no-unused-vars, max-len */
import {ModeTagsType} from "../redux/eventSlice";
import Feature from "./Feature";

// Страницы аналитики (возможные значения)
export enum AnalyticsSectionEnum {
    OVERVIEW = "overview",
    EVENTS_AND_INCIDENTS = "events-and-incidents",
    SERVICES = "services",
    //EXPORT_DATA = "export-data",
}

// Группы фильтров (возможные значения)
export enum AnalyticsFiltersTypesEnum {
    OVERVIEW = "overviewFilters",
    EVENTS_AND_INCIDENTS = "eventsAndIncidentsFilters",
    SERVICES = "servicesFilters",
}

/* ========================= Изначальная группа фильтров ==============================*/

export interface IFilterValue<T> {
    value: T,
}

export interface ITagsFilterValue<T> extends IFilterValue<T> {
    modeTags: ModeTagsType,
}

// date format: DD-MM-YYYY | Time format: HH:mm
export interface ITimeFilterValue {
    from: string,
    to: string,
}

export interface IDateTimeFilterValue {
    time: ITimeFilterValue,
    date: ITimeFilterValue,
}

export type AnalyticsZoneType = number;
export type AnalyticsOperatorsType = number;
export type AnalyticsResponseServicesType = string;
export type AnalyticsSourcesType = number;
export type AnalyticsPrioritiesType = PrioritiesFilterEnum;
export type AnalyticsTypesType = string;
export type AnalyticsTagsType = number;
export type AnalyticsSegmentIdsType = SegmentIdsFilterEnum;
export type AnalyticsRoadObjectsIdsType = Feature;
export type AnalyticsIncidentIdType = string;

// Изначальная группа фильтров
export interface IDefaultAnalyticsFilters {
    dataFilter: IFilterValue<DataFilterValuesEnum>,
    hoursTimeFilter: IFilterValue<ITimeFilterValue>,
    createdDateTimeFilter: IFilterValue<IDateTimeFilterValue>,
    zoneFilter: IFilterValue<AnalyticsZoneType[]>,
    operatorsFilter: IFilterValue<AnalyticsOperatorsType[]>,
    responseServicesFilter: IFilterValue<AnalyticsResponseServicesType[]>,
    sourcesFilter: IFilterValue<AnalyticsSourcesType[]>,
    //shiftsFilter: IFilterValue<(number | string)[]>,
    prioritiesFilter: IFilterValue<AnalyticsPrioritiesType[]>,
    typesFilter: IFilterValue<AnalyticsTypesType[]>,
    tagsFilter: ITagsFilterValue<AnalyticsTagsType[]>,
    segmentIdsFilter: IFilterValue<AnalyticsSegmentIdsType[]>,
    roadObjectsIds: IFilterValue<AnalyticsRoadObjectsIdsType[]>,
    incidentId: IFilterValue<AnalyticsIncidentIdType>,
}

// Изначальная группа фильтров (все значения не обязательные - T | undefined)
export type DefaultAnalyticsFiltersPartialType = Partial<IDefaultAnalyticsFilters>;

export interface IFilterSavedParams extends Omit<DefaultAnalyticsFiltersPartialType, "createdDateTimeFilter"> {
    filterType: AnalyticsFiltersTypesEnum,
}

// Закрепы на основе ключей входящего типа фильтра
export type AnalyticsFiltersPinsType<T> = {
    [K in keyof T]: boolean
};

// Внутри фильтра находятся сами фильтры и закрепы
export interface IAnalyticsFilterData<T> {
    filters: T,
    pins?: AnalyticsFiltersPinsType<T>,
}

// Закрепы изначальной группы фильтров (все значения не обязательные)
export type DefaultAnalyticsFiltersPinsType = Partial<AnalyticsFiltersPinsType<DefaultAnalyticsFiltersPartialType>>;

// Параметры изначальной группы фильтров
export type AnalyticsFilterKeys = keyof IDefaultAnalyticsFilters;

/* =======================================================*/

/* ========================= Наследуемые группы фильтров ==============================*/

// Группа фильтров страницы Овервью
export type OverviewFiltersType = Pick<IDefaultAnalyticsFilters,
    "dataFilter" | "createdDateTimeFilter" | "typesFilter" | "tagsFilter" | "prioritiesFilter"
>;

// Группа фильтров страницы События и инциденты
export type EventsAndIncidentsFiltersType = Omit<IDefaultAnalyticsFilters,
    "operatorsFilter" | "responseServicesFilter" | "incidentId"
>;

// Группа фильтров страницы Сит-центр и службы
export type ServicesFiltersType = Omit<IDefaultAnalyticsFilters, "segmentIdsFilter" | "roadObjectsIds" | "incidentId">;

/* =======================================================*/

// Дефолтный тип параметров для редьюсера фильтров (payload)
export interface ISetDefaultFilter {
    filterType: AnalyticsFiltersTypesEnum,
    filterKey: AnalyticsFilterKeys,
}

export interface ISetAnalyticsFilter extends ISetDefaultFilter {
    value: any,
}

export interface IOverviewToAnother {
    anotherFilterType: AnalyticsFiltersTypesEnum,
    overviewFilterData: OverviewFiltersType,
}

export interface IFromCurrentToAnotherPage {
    anotherFilterType: AnalyticsFiltersTypesEnum,
    currentFilterData: OverviewFiltersType | EventsAndIncidentsFiltersType | ServicesFiltersType,
}

/* ========================= DataFilter (инциденты и/или события) ==============================*/

// DataFilter все возможные значения
export enum DataFilterValuesEnum {
    incidents = "Incident",
    events = "Event",
}

// Тип, который ожидают на бэке
export type DataFilterFetchValues = Array<DataFilterValuesEnum.incidents | DataFilterValuesEnum.events>;

// DataFilter тип items внутри фильтра
export interface IDataFilterItem {
    code: DataFilterValuesEnum,
    name: string,
}

/* =======================================================*/

/* ========================= CheckboxFilter (фильтры с чекбоксом) ==============================*/

// CheckboxFilter тип items внутри фильтра
export interface ICheckboxFilterItem<T> {
    code: T,
    name: string,
    color?: string,
    svg?: JSX.Element,
}

// CheckboxFilter payload для dispatch
export interface ISetCheckboxFilter extends ISetDefaultFilter {
    data: (string | number)[],
    addOrRemove: "add" | "remove",
}

// SegmentIdsFilter все возможные значения
export enum SegmentIdsFilterEnum {
    no_segments = 0,
    main_roadway,
    left_shoulder,
    right_shoulder_OOT_pockets,
    parking_pockets_near_OTUS,
    sidewalks_OTT,
    safety_islands,
    sidewalks_in_the_same_direction,
    exit_or_entry_to_SVH,
    left_shoulder_of_SVH,
    right_shoulder_of_SVH,
}

//PrioritiesFilter все возможные значения (что выбираем в фильтре)
export enum PrioritiesFilterEnum {
    middle_and_low = 0, //Средний и ниже
    high, //Высокий
    tracked, //На контроле
    favorite, //Про ТВ
}

//PrioritiesFilter Что принимает бэк
export enum PrioritiesFilterFetchEnum {
    low, //Низкий
    middle, //Средний
    high, //Высокий
}

/* =======================================================*/

/* ========================= Тело запроса ==============================================*/

export interface IAnalyticsPayload<T> {
    fetchData: T,
    signal?: AbortSignal,
}

// Если поле со значением по-умолчанию, то оно не передается.
// Бэк просит убирать такие значения из запроса для быстрой обработки

export type DateGroupingValuesType = "hour" | "day" | "week" | "month";

export interface IDateGroupingPeriod {
    lowerBound: string,   // ISO строка даты
    upperBound: string,   // ISO строка даты
}

export interface IAnalyticsFetchInformation {
    dateGrouping: DateGroupingValuesType,
    datePeriod: IDateGroupingPeriod,
    eventsOrIncidents: DataFilterValuesEnum,
    modifiedFieldsCount: number,
    selectedRoadObjects: Feature[],
    createdDate: IDateTimeFilterValue,
    selectedPriorities: PrioritiesFilterEnum[],
}

export interface IContainerProps {
    filterType: AnalyticsFiltersTypesEnum,
    filter: Partial<IDefaultAnalyticsFilters>,
}

// для виджета Priority
export interface IPriorityItem<T> {
    code: T,
    name: string,
    Svg?: JSX.Element,
    incidentCount?: number,
    incidentCountHighPriority?: number,
    trackedCount?: number,
    favoriteCount?: number,
    isChecked: boolean,
    injuredCount?: number,
    deadCount?: number,
    timeClosedAvg?: number,
    timeReactionAvg?: number,
    percentClosedIncidents?: number,
}

// Доп параметр для запросов для построения графиков
export interface IDateGrouping {
    dateGrouping: DateGroupingValuesType,
}

export interface IFetchFilterOptions {
    createdDateTime: {
        lowerBound: string,   // ISO строка даты
        upperBound: string,   // ISO строка даты
        excludingUpperBound?: boolean, // Если true < границы, если false <= границе
    },
    kind: DataFilterFetchValues,
    tracked?: boolean, // На контроле
    favorite?: boolean, // Про ТВ
    createdTime?: {
        lowerBound: string, // формат HH:MM:SS
        upperBound: string,  // формат HH:MM:SS
        excludingUpperBound?: boolean, // Если true < границы, если false <= границе
    },
    priorities?: PrioritiesFilterFetchEnum[],
    tags?: AnalyticsTagsType[],
    tagsMode?: ModeTagsType,
    sourcesTags?: AnalyticsSourcesType[],
    segmentIds?: AnalyticsSegmentIdsType[],
    types?: AnalyticsTypesType[],
    zones?: AnalyticsZoneType[],
    responseServices?: AnalyticsResponseServicesType[],
    operators?: AnalyticsOperatorsType[],
    roadObjectsIds?: number[],
    incidentId?: AnalyticsIncidentIdType,
}

/* =======================================================*/

/* ========================= Модели ответных данных ==============================================*/

// Тело ответа
export interface IAnalyticsResponse<T> {
    items: T[],
}

// EventsAndIncidents
export interface IAEventsAndIncidentsResItem {
    date: string,
    incidentCount: number,
    trackedCount: number,
    favoriteCount: number,
    incidentCountHighPriority: number,
    incidentCountMiddlePriority: number,
    incidentCountLowPriority: number,
    injuredCount: number,
    deadCount: number,
    injuredChildCount: number,
    deadChildCount: number,
}

export type IAEventsAndIncidentsResItemCompare = {
    [K in keyof IAEventsAndIncidentsResItem as `${K}Compare`]: IAEventsAndIncidentsResItem[K];
};

export type IAEventsAndIncidentsResItemAnother = {
    [K in keyof IAEventsAndIncidentsResItem as `${K}Another`]: IAEventsAndIncidentsResItem[K];
};

export interface IAEventsAndIncidentsOriginalAndCompare
    extends Partial<IAEventsAndIncidentsResItemCompare>, Partial<IAEventsAndIncidentsResItemAnother>, IAEventsAndIncidentsResItem {
    id: number,
}

// WeeklyIncidentsByHour
export interface IAWeeklyIncidentsByHourResItem {
    date: string,
    hour: HoursType,
    incidentCount: number,
    trackedCount: number,
    favoriteCount: number,
    injuredCount: number,
    deadCount: number,
}

// Summary
export interface IASummaryResItem {
    incidentCount: number,
    injuredCount: number,
    incidentsCountYesterday: number,
    incidentsCountToday: number,
    averageDailyIncidentsLast7Days: number,
    incidentsCountSameDayLastYear: number,
    deadCount: number,
    deadChildCount: number,
    injuredChildCount: number,
}

// IncidentsByZone
export interface IAIncidentsByZone {
    zone: number,
    incidentCount: number,
    incidentCountHighPriority: number,
    trackedCount: number,
    favoriteCount: number,
    injuredCount: number,
    deadCount: number,
}

export interface IAListIncidents {
    id: string,
    name: string,
    typeCode: string,
    createdDate: string,
    injuredCount: number,
    deadCount: number,
    injuredChildCount: number,
    deadChildCount: number,
    incidentPriority: PrioritiesFilterFetchEnum,
    tags: string,
    favorite: 0 | 1,
    tracked: 0 | 1,
}

// Services
export interface IAServices {
    service: string,
    incidentCount: number,
    answeredCount: number,
    arrivedCount: number,
    trackedCount: number,
    favoriteCount: number,
    rejectedCount: number,
    rejectedPercent: number,
    arrivalAvg: number | null,
    callAvg: number | null,
}

// ProcessCenterWorkflow
export interface IAProcessCenterWorkflowResItem {
    id: number,
    stage: string,
    incidentCount: number,
    incidentCountAll: number,
    middleTimeMark: number,
    middleTimeMarkAll: number,
    firstQuartileTime: number,
    firstQuartileTimeAll: number,
    thirdQuartileTime: number,
    thirdQuartileTimeAll: number,
}

// StringEventsAndIncidents
export interface IAStringEventsAndIncidentsResItem {
    incidentCount: number,
    trackedCount: number,
    favoriteCount: number,
    injuredCount: number,
    deadCount: number,
    injuredChildCount: number,
    deadChildCount: number,
    roadDifficultM: number,
    serviceCallCount: number,
    arrivedServicesCount: number,
    eventsCount: number,
    responseTimeAvg: number | null,
    closeTimeAvg: number | null,
    callServicesCount: number,
}

// MapEventsAndIncidents
export interface IAMapEventsAndIncidentsProperties {
    roadObjectId: number,
    name: string,
    latitude: number,
    longitude: number,
    incidentCount: number,
    incidentCountHighPriority: number,
    trackedCount: number,
    favoriteCount: number,
    injuredCount: number,
}

export interface IAMapEventsAndIncidents {
    type: "FeatureCollection",
    features: [
        {
            type: "Feature",
            id: string,
            geometry: {
                type: "Point",
                coordinates: [number, number],
            },
            properties: IAMapEventsAndIncidentsProperties,
        }
    ],
}

// PriorityNumbers
export interface IAPriorityNumbersResItem {
    favoriteCount: number,
    trackedCount: number,
    incidentCountHighPriority: number,
    incidentCountMediumPriority: number,
    incidentCountLowPriority: number,
}

// TimePriority
export interface IATimePriority {
    hour: HoursType,
    incidentCount: number,
    trackedCount: number,
    favoriteCount: number,
    incidentCountHighPriority: number,
}

// Types
export interface IATypes {
    typeCode: string,
    incidentCount: number,
    incidentCountHighPriority: number,
    trackedCount: number,
    favoriteCount: number,
}

// Tags
export interface IATags {
    tag: number,
    incidentCount: number,
    incidentCountHighPriority: number,
    trackedCount: number,
    favoriteCount: number,
}

// ReactionOperators
export interface IAReactionOperators {
    operator: number,
    percentClosedIncidents: number,
    timeReactionAvg: number,
    timeClosedAvg: number,
}

// ReactionZones
export interface IAReactionZones {
    zone: number | null,
    percentClosedIncidents: number | null,
    timeReactionAvg: number | null,
    timeClosedAvg: number | null,
}

/* =======================================================*/

/* ========================= IncidentsByTime Виджет ==============================================*/

// Часы
export type HoursType =
    0
    | 1
    | 2
    | 3
    | 4
    | 5
    | 6
    | 7
    | 8
    | 9
    | 10
    | 11
    | 12
    | 13
    | 14
    | 15
    | 16
    | 17
    | 18
    | 19
    | 20
    | 21
    | 22
    | 23;

// Инциденты в часе
export type HoursInDayType = {
    [hour in HoursType]: number;
};

export interface IDayKind extends HoursInDayType {
    dayOfWeek: DaysOfWeekEnum,
}

// Дни недели
export enum DaysOfWeekEnum {
    Monday,
    Tuesday,
    Wednesday,
    Thursday,
    Friday,
    Saturday,
    Sunday
}

// Инциденты в неделе по часам
export type IWeekByTime = {
    [day in DaysOfWeekEnum]: HoursInDayType;
};

// Подготовка тиков по линии X
export interface IGetPreparedDateXProps {
    date: string, // входящая дата
    id: number, // id текущей даты
    isEmpty: boolean,
    chartData: IAEventsAndIncidentsOriginalAndCompare[],
    groupingType: DateGroupingValuesType,
    isCompare?: boolean,
    info: IAnalyticsFetchInformation,
}

/* =======================================================*/

export interface ISelectSavedParam {
    savedFilterParams: IFilterSavedParams,
    filterType: AnalyticsFiltersTypesEnum,
}

export interface IGetAnalyticsFetchInformation {
    filterType: AnalyticsFiltersTypesEnum,
    filter: Partial<DefaultAnalyticsFiltersPartialType>,
}