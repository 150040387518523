import React, {FC, useEffect, useRef, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {isArray} from "lodash";
import SimpleBar from "simplebar-react";
import "simplebar/dist/simplebar.min.css";

import {
    createGroup,
    createTag,
    deleteGroup,
    deleteTag,
    editGroup,
    editTag,
    fetchTagGroups,
    fetchTags,
    requestGroupSuccessSelector,
    requestTagSuccessSelector,
    resetSuccess,
    tagGroupsSelector,
    tagsSelector,
} from "../../../redux/tagsSlice";
import ITag from "../../../models/TagModel";
import {TypedDispatch} from "../../../types";
import {incidentTypesSelector} from "../../../redux/incidentTypeSlice";

import {TagGroup} from "./TagGroup/TagGroup";
import {Tag} from "./Tag/Tag";
import AddButton from "./AddButton";
import AddInput from "./AddInput";
import Spinner from "../../UI/Spinner/Spinner";
import AssignTagGroupForm from "./AssignTagGroupForm";
import AssignButton from "./AssignButton";

import styles from "./SettingsTags.module.scss";

export interface IIncidentType {
    code: string,
    name: string,
}

export interface ITagGroup {
    id: number | "default",
    title: string,
    count: number,
    data: any,
    tags: ITag[],
}

export interface IAssignedEntity {
    id: number | null,
    type: string,
}

interface ISettingsTagsProps {
    isService?: boolean,
}

const SettingsTags: FC<ISettingsTagsProps> = ({isService}) => {
    const dispatch = useDispatch<TypedDispatch>();
    const tagGroupsList = useSelector(tagGroupsSelector);
    const tagsList = useSelector(tagsSelector);
    const requestTagSuccess = useSelector(requestTagSuccessSelector);
    const requestGroupSuccess = useSelector(requestGroupSuccessSelector);
    const incidentTypes = useSelector(incidentTypesSelector);

    const [groups, setGroups] = useState<ITagGroup[]>([]);
    const [defaultGroups, setDefaultGroups] = useState<ITagGroup[]>([]);

    const [activeGroup, setActiveGroup] = useState<null | number | "default">("default");
    const [activeGroupTags, setActiveGroupTags] = useState<ITag[] | null>();

    const [groupInputVisible, setGroupInputVisible] = useState(false);
    const [tagInputVisible, setTagInputVisible] = useState(false);

    const [editableGroup, setEditableGroup] = useState<false | number>(false);
    const [editableTag, setEditableTag] = useState<false | number>(false);

    const [assignTagGroupId, setAssignTagGroupId] = useState<IAssignedEntity | null>(null);

    const inputRef = useRef(null);

    // Получение данных по тегам
    useEffect(() => {
        dispatch(fetchTagGroups());
        dispatch(fetchTags());
    }, []);

    // Обновление тегов или групп тегов после crud-операций
    useEffect(() => {
        if (requestTagSuccess) dispatch(fetchTags());
        if (requestGroupSuccess) dispatch(fetchTagGroups());
        if (assignTagGroupId) setAssignTagGroupId(null);
    }, [requestTagSuccess, requestGroupSuccess]);

    useEffect(() => {
        if (!tagGroupsList?.find((group: any) => group.Id === activeGroup)) {
            setActiveGroup("default");
        }
    }, [tagGroupsList]);

    // Формирование данных групп тегов
    const prepareData = (groups: any, tags: ITag[]) => {
        const defaultGroup = {
            id: "default",
            title: "Все теги",
            count: tagsList.length,
            data: {},
            tags: [],
        };
        const preparedGroups = groups.map((group: any) => ({
            id: group.Id,
            data: group.Data,
            title: group.Name,
            count: 0,
            tags: [],
        }));

        const preparedTags = tags.map((tag: ITag) => {
            const groupId = tag?.viewJson?.groupId;
            return {
                id: tag.id,
                groupId: isArray(groupId) ? groupId : [groupId],
                name: tag.name,
                description: tag.description,
            };
        });

        const groupList = [defaultGroup, ...preparedGroups];

        groupList.forEach((group: any) => {
            const includesTags = preparedTags.filter((tag: any) => {
                return tag?.groupId?.includes(group.id);
            });

            group.tags = includesTags;

            if (group.id !== "default") {
                group.count = includesTags.length;
            }
        });

        setGroups(groupList);
        setDefaultGroups(preparedGroups);
    };

    useEffect(() => {
        if (isArray(tagGroupsList) && isArray(tagsList)) {
            prepareData(tagGroupsList, tagsList);
        }
    }, [tagGroupsList, tagsList]);

    useEffect(() => {
        setActiveGroupTags(groups?.find(item => item.id === activeGroup)?.tags);
    }, [activeGroup, groups]);

    const addGroupHandler = (value: string) => {
        dispatch(createGroup({
            data: {
                data: {
                    group: value,
                },
                name: value,
                typeId: "INCIDENTS_TAGGROUP.1",
            },
        }));
    };

    const addTagHandler = (value: string) => {
        dispatch(createTag({
            data: {
                name: value,
                description: value,
                viewJson: {groupId: [activeGroup], incidentTypes: []},
            },
        }));
    };

    const editGroupHandler = (id: number, value: string) => {
        const currentGroup = groups.find((group: any) => group.id === activeGroup);

        const preparedData = {
            data: currentGroup?.data,
            name: value,
            typeId: "INCIDENTS_TAGGROUP.1",
        };

        dispatch(editGroup({id, data: preparedData}));
    };

    const editTagHandler = (id: number, value: string, groupId: (string | number)[]) => {
        const currentTag = tagsList.find((tag: ITag) => tag.id === id);
        dispatch(editTag({
            id,
            data: {
                name: value,
                description: value,
                viewJson: {
                    ...currentTag?.viewJson,
                    groupId: groupId,
                },
            },
        }));
    };

    const handleAddNewTag = (value: string) => {
        const currentGroup = groups.find((group: any) => group.id === activeGroup);
        // @ts-ignore
        const existingTag = tagsList.find(({name}) => name === value);

        let groupId: any;
        if (existingTag?.viewJson?.groupId) {
            groupId = Array.isArray(existingTag?.viewJson?.groupId)
                ? existingTag?.viewJson?.groupId : [existingTag?.viewJson?.groupId];
        } else {
            groupId = [];
        }

        // Если создаваемый тег уже существует и не относится к активной группе
        if (existingTag && !groupId.includes(currentGroup?.id)) {
            editTagHandler(existingTag.id, value.trim(), [...groupId, currentGroup?.id]);
            return;
        }

        addTagHandler(value.trim());
    };

    const handleDeleteTag = (tagIdToDelete: number, groupId?: number | "default") => {

        // Ищем все группы где используется удаляемый тег
        const allUsesTagToDelete = groups.filter(({tags}) => {
            if (!tags.length) return;

            return tags.find(({id}) => id === tagIdToDelete);
        });

        // Если тег используется в нескольких группах отвязываем его от активной группы
        if (allUsesTagToDelete.length > 1) {
            const currentTag = tagsList.find(({id}) => id === tagIdToDelete);

            if (currentTag) {
                const groupIdWithoutIdToDelete = currentTag.viewJson?.groupId?.filter((id) => (
                    id !== (groupId || activeGroup)
                ));

                if (groupIdWithoutIdToDelete) {
                    editTagHandler(tagIdToDelete, String(currentTag.description), groupIdWithoutIdToDelete);
                    return;
                }
            }
        }

        // Удаляем его если он последний
        dispatch(deleteTag(tagIdToDelete));
    };

    return (
        <div className={styles["tags"]}>
            {(!tagsList?.length || !tagGroupsList?.length) ? <Spinner size={32} className="mt-4"/> : (
                assignTagGroupId
                    ? <AssignTagGroupForm
                        incidentTypes={incidentTypes}
                        setAssignGroupId={setAssignTagGroupId}
                        editGroup={editGroup}
                        editTag={editTag}
                        groups={[...tagGroupsList, ...tagsList]}
                        activeGroup={assignTagGroupId}
                    />
                    : <>
                        {/*Группы тегов*/}
                        <div className={styles["tags-groups"]}>
                            <SimpleBar style={{maxHeight: "500px", height: "500px", paddingRight: "20px"}}>
                                <div>
                                    {groups && groups?.length > 0 ? groups.map(group => (
                                        <TagGroup
                                            key={group.id}
                                            data={group}
                                            isActive={activeGroup === group?.id}
                                            onClick={() => setActiveGroup(group?.id)}
                                            editGroup={editGroupHandler}
                                            inputRef={inputRef}
                                            setEditableGroup={setEditableGroup}
                                            editableGroup={editableGroup}
                                            requestSuccess={requestGroupSuccess}
                                            resetSuccess={resetSuccess}
                                            deleteGroup={deleteGroup}
                                            type={"tag"}
                                        />
                                    )) : <div className={styles["no-data"]}>Нет групп</div>}
                                    {groupInputVisible && (
                                        <AddInput
                                            placeholder="Введите название группы и нажмите Enter"
                                            ref={inputRef}
                                            addData={addGroupHandler}
                                            type="group"
                                            hideInput={setGroupInputVisible}
                                            requestSuccess={requestTagSuccess}
                                            requestGroupSuccess={requestGroupSuccess}
                                            resetSuccess={resetSuccess}
                                        />
                                    )}
                                </div>
                            </SimpleBar>
                            {!isService && (
                                <AddButton text={"Добавить группу"} onClick={() => setGroupInputVisible(true)}/>
                            )}
                        </div>

                        {/*Список тегов*/}
                        <div className={styles["tags-items"]}>
                            <SimpleBar style={{maxHeight: "500px", height: "500px", paddingRight: "20px"}}>
                                <div>
                                    {groups?.length > 0
                                        ? activeGroup === "default"
                                            ? <>
                                                {(groups[0].tags?.length > 0)
                                                    && groups[0].tags
                                                        ?.filter((tag: ITag) => tag.groupId?.length === 1
                                                            && tag.groupId[0] === "default")
                                                        ?.map(tag => {
                                                            const currentTag = tagsList
                                                                ?.find((fullTag: ITag) => tag.id === fullTag.id);
                                                            return (
                                                                <div key={tag?.id}>
                                                                    {!isService && (
                                                                        <AssignButton
                                                                            data={tag}
                                                                            onClick={() =>
                                                                                setAssignTagGroupId({
                                                                                    id: Number(tag?.id) ?? 0,
                                                                                    type: "tag",
                                                                                })}
                                                                            className={styles["tags__button"]}
                                                                            currentTag={currentTag}
                                                                        />
                                                                    )}
                                                                    {/* eslint-disable max-len */}
                                                                    <Tag
                                                                        key={tag.id}
                                                                        data={tag}
                                                                        editTag={editTagHandler}
                                                                        customId={tag.id}
                                                                        inputRef={inputRef}
                                                                        setEditableTag={setEditableTag}
                                                                        editableTag={editableTag}
                                                                        placeholder={"Введите название тега и нажмите Enter"}
                                                                        deleteTag={handleDeleteTag}
                                                                        resetSuccess={resetSuccess}
                                                                        requestSuccess={requestTagSuccess}
                                                                        isService={isService}
                                                                    />
                                                                    {/* eslint-enable max-len */}
                                                                </div>
                                                            );
                                                        })}

                                                {defaultGroups.map(group => (
                                                    <div key={group.id}>
                                                        <div className={styles["tags-items__title"]}>
                                                            {group.title}
                                                        </div>

                                                        {!isService && (
                                                            <>
                                                                {group.id !== "default" && (
                                                                    <AssignButton
                                                                        data={group}
                                                                        onClick={() =>
                                                                            setAssignTagGroupId({
                                                                                id: Number(group?.id) ?? 0,
                                                                                type: "group",
                                                                            })}
                                                                        className="mt-2 mb-3"
                                                                    />
                                                                )}
                                                            </>
                                                        )}
                                                        {group?.tags?.length > 0 ? group.tags.map(tag => (
                                                            <Tag
                                                                key={tag.id}
                                                                data={tag}
                                                                editTag={editTagHandler}
                                                                customId={`${tag.id}-${group.id}`}
                                                                inputRef={inputRef}
                                                                setEditableTag={setEditableTag}
                                                                editableTag={editableTag}
                                                                placeholder={"Введите название тега и нажмите Enter"}
                                                                deleteTag={(id) => handleDeleteTag(id, group.id)}
                                                                resetSuccess={resetSuccess}
                                                                requestSuccess={requestTagSuccess}
                                                                isService={isService}
                                                            />
                                                        )) : <div className={styles["no-data"]}>Нет тегов</div>}
                                                    </div>
                                                ))}
                                            </>
                                            : activeGroupTags && activeGroupTags?.length > 0
                                                ?
                                                <div>
                                                    {!isService && (
                                                        <AssignButton
                                                            data={groups?.find(item => item.id === activeGroup)}
                                                            onClick={() => setAssignTagGroupId({
                                                                id: activeGroup,
                                                                type: "group",
                                                            })}
                                                            className={"mb-4"}
                                                        />
                                                    )}
                                                    {activeGroupTags.map(tag => (
                                                        <Tag
                                                            key={tag.id}
                                                            data={tag}
                                                            editTag={editTagHandler}
                                                            customId={tag.id}
                                                            inputRef={inputRef}
                                                            setEditableTag={setEditableTag}
                                                            editableTag={editableTag}
                                                            placeholder={"Введите название тега и нажмите Enter"}
                                                            deleteTag={handleDeleteTag}
                                                            resetSuccess={resetSuccess}
                                                            requestSuccess={requestTagSuccess}
                                                            isService={isService}
                                                        />
                                                    ))}
                                                </div>
                                                : <>
                                                    {!isService && (
                                                        <AssignButton
                                                            data={groups?.find(item => item.id === activeGroup)}
                                                            onClick={() => setAssignTagGroupId({
                                                                id: activeGroup,
                                                                type: "group",
                                                            })}
                                                        />
                                                    )}
                                                    <div className={styles["no-data"]}>Нет тегов</div>
                                                </>
                                        :
                                        <div className={styles["no-data"]}>Нет тегов</div>
                                    }
                                    {tagInputVisible && (
                                        <AddInput
                                            placeholder="Введите название тега и нажмите Enter"
                                            ref={inputRef}
                                            addData={handleAddNewTag}
                                            type="tag"
                                            hideInput={setTagInputVisible}
                                            requestSuccess={requestTagSuccess}
                                            requestGroupSuccess={requestGroupSuccess}
                                            resetSuccess={resetSuccess}
                                            autocomplete
                                            tagsList={tagsList}
                                        />
                                    )}
                                </div>
                            </SimpleBar>
                            {!isService && (
                                <AddButton text={"Добавить тег"} onClick={() => setTagInputVisible(true)}/>
                            )}
                        </div>
                    </>
            )}
        </div>
    );
};

export default SettingsTags;