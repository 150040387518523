import React, {FC} from "react";
import cn from "classnames";

import PlusIcon from "../../../Icons/PlusIcon";

import styles from "./AddButton.module.scss";

interface IAddButtonProps {
    text: string,
    onClick: any,
    svgHeightWidth?: number,
    className?: string,
}

const AddButton: FC<IAddButtonProps> = ({text, onClick, svgHeightWidth, className}) => {
    return (
        <div className={cn(styles["button"], className)} onClick={onClick}>
            <PlusIcon className={styles["button__icon"]} width={svgHeightWidth} height={svgHeightWidth}/>
            <p className={styles["button__text"]}>{text}</p>
        </div>
    );
};

export default AddButton;