/* eslint-disable max-len */
import React from "react";

import {ISvgProps} from "../../types/common";

// import icon from "images/icons/report.svg"

const ReportIcon: React.FC<ISvgProps> = ({
    width = 24,
    height = 24,
    ...rest
}) => (
    <svg {...rest} width={width} height={height} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path fillRule="evenodd" clipRule="evenodd" d="M5 1H15.4142L21 6.58579V21C21 22.1046 20.1046 23 19 23H5C3.89543 23 3 22.1046 3 21V3C3 1.89543 3.89543 1 5 1ZM13 3H5V21H19V9H15C13.8954 9 13 8.10457 13 7V3ZM15 3.41421V7H18.5858L15 3.41421ZM8 17V15H14V17H8ZM8 11V13H16V11H8Z" fill="currentColor"/>
    </svg>
);

export default ReportIcon;