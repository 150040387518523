/* eslint-disable max-len */
import React from "react";

import {ISvgProps} from "../../types/common";

//import icon from "images/icons/arrow-left.svg";

const ArrowLeftIcon: React.FC<ISvgProps> = ({
    width = 24,
    height = 24,
    ...rest
}) => (
    <svg {...rest} width={width} height={height} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g id="24 / arrows / arrow-left">
            <path id="icon" fillRule="evenodd" clipRule="evenodd" d="M6.41436 13.0001L12.7073 19.293L11.293 20.7072L2.58594 12.0001L11.293 3.29297L12.7073 4.70718L6.41436 11.0001H21.0002V13.0001H6.41436Z" fill="currentColor"/>
        </g>
    </svg>

);

export default ArrowLeftIcon;