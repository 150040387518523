import React from "react";
import {ISpinnerProps} from "../UI/Spinner/Spinner";

// import icon from "images/icons/spinner.svg";

import styles from "../UI/Spinner/Spinner.module.scss";

const SpinnerIcon: React.FC<Omit<ISpinnerProps, "centerMode">> = (
    {
        size,
        color,
    }
) => (
    <svg
        className={styles.spinner}
        style={{maxWidth: size, maxHeight: size}}
        viewBox="22 22 44 44"
        xmlns="http://www.w3.org/2000/svg"
    >
        <circle className={styles.circle} cx="44" cy="44" r="20.2"
            fill="none" strokeWidth="3.6" stroke={color}>
        </circle>
    </svg>
);

export default React.memo(SpinnerIcon);