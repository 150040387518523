import React, {FC} from "react";
import cn from "classnames";
import {Placeholder} from "rsuite";
import {useDispatch} from "react-redux";
import {useTranslation} from "react-i18next";
import {generatePath, useNavigate} from "react-router-dom";

import {IncidentEventStatus} from "../../../../models/IncidentEventStatus";
import ISegmentGroup from "../../../../models/SegmentGroup";

import {ApiUrls, AppUrls} from "constants/urls";
import {getFormattedDate} from "utils/datetime";
import {setSelectedLastEvent} from "redux/eventSlice";
import {TypedDispatch} from "../../../../types";
import LastIncidentEventModel from "models/LastIncidentEventModel";
// import {IncidentEventPriority} from "../../../../models/IncidentEventPriority";
import {setSelectedRoadObjectsForStream} from "../../../../redux/roadObjectSlice";
import {removeTextDuplication} from "../../../../utils/removeTextDuplication";
import LightTooltip from "../../../UI/Tooltip/LightTooltip";

import Image from "../../../Icons/Image";
import TypeIcon from "components/Icons/TypeIcon";
import DeviceIcon from "components/Icons/DeviceIcon";
import StatusIcon from "components/Icons/StatusIcon";
import ArrowLeftIcon from "../../../Icons/ArrowLeftIcon";
import ImageWithToken from "components/Icons/ImageWithToken";
import MoreActionsItem from "../../../UI/MoreActions/MoreActionsItem";
import MoreActionsDots from "../../../UI/MoreActions/MoreActionsDots";
import PriorityIcon from "../../../Icons/PriorityIcon";

import styles from "./DashboardEventsTable.module.scss";

interface IDashboardEventsRowProps {
    event?: LastIncidentEventModel | null,
    loading?: boolean,
    isActive: boolean,
    zoneColor?: string | null,
    zoomMapOnEventHandle: any,
    segments: any,
}

const DashboardEventsRow: FC<IDashboardEventsRowProps> = ({
    event,
    loading,
    isActive,
    zoneColor,
    zoomMapOnEventHandle,
    segments,
}) => {

    const {t} = useTranslation("common");
    const dispatch = useDispatch<TypedDispatch>();
    const eventId = event?.id;
    const navigate = useNavigate();

    const currentSegment = segments?.find((segment: ISegmentGroup) =>
        segment.Data.segmentId === Number(event?.segmentId));

    // Отменяем скроллирование при клике колесом мыши
    const rowMouseDownHandle = (e: React.MouseEvent) => {
        if (e.button === 1) {
            e.preventDefault();
            return false;
        }
    };

    // Клик по строке - выбор активного инцидента для блока DashboardTarget
    // При двойном клике - переходим на страницу инцидента
    // При клике колесом мыши - инцидент открывается в отдельной вкладке
    const rowMouseUpHandle = (e: React.MouseEvent) => {
        if (!eventId) return;

        if (e.button === 1 || (e.button === 0 && e.ctrlKey)) {
            window.open(generatePath(`${process.env.PUBLIC_URL}${AppUrls.EVENT_ARCHIVE}/:id`, {
                id: eventId,
            }), "_blank");
        }

        if (e.button === 0 && !e.ctrlKey) {
            if (event && !isActive) {
                dispatch(setSelectedLastEvent(event));
            }
            dispatch(setSelectedRoadObjectsForStream({
                selectedObject: null,
                roadObjectKey: "dashboardTarget",
                delete: true,
            }));
        }
    };

    // Переход на страницу инцидента
    const goToIncident = () => {
        eventId && navigate(generatePath(`${AppUrls.EVENT_ARCHIVE}/:id` + `?return=${AppUrls.DASHBOARD}`, {
            id: eventId,
        }));
    };

    const handleDoubleClick = () => {
        goToIncident && goToIncident();
    };

    /*const priorityKey = {
        [IncidentEventPriority.$0]: "low",
        [IncidentEventPriority.$1]: "middle",
        [IncidentEventPriority.$2]: "high",
    }[String(event?.priority)];*/

    const getStatusText = (event: LastIncidentEventModel | undefined | null) => {
        if (!event) return "";
        if (event.tracked?.enabled) return "На контроле";
        switch (event.status) {
            case IncidentEventStatus.New:
                return "В ожидании";
            case IncidentEventStatus.Processing:
                return "В обработке";
            case IncidentEventStatus.Resolved:
                return "Закрыто";
            case IncidentEventStatus.Rejected:
                return "Отклонено";
            default:
                return "";
        }
    };

    return (
        <tr
            className={cn({[styles["active"]]: isActive})}
            onMouseDown={rowMouseDownHandle}
            onMouseUp={rowMouseUpHandle}
            onDoubleClick={handleDoubleClick}
        >
            <td>
                {loading
                    ? <Placeholder.Graph style={{height: 20}} active/>
                    : getFormattedDate(event?.beginDateTime, {format: "HH:mm, DD.MM"})
                }
                <div className={cn({[styles["selected"]]: isActive})}/>
            </td>
            <td>
                {loading
                    ? <Placeholder.Graph style={{height: 20}} active/>
                    : (
                        <div
                            className={styles["address"]}
                            title={removeTextDuplication(event?.location?.addressText || "")}
                        >
                            {removeTextDuplication(event?.location?.addressText || "")}
                        </div>
                    )
                }
            </td>
            <td>
                {loading
                    ? <Placeholder.Graph style={{height: 20}} active/>
                    : (
                        <div className={styles["zone"]}>
                            {Number(event?.segmentId) ? (
                                <LightTooltip title={currentSegment?.Data?.name}>
                                    <div className={styles["zone__segment"]}>{event?.segmentId}</div>
                                </LightTooltip>
                            ) : null}

                            <div className={cn(styles["zone__camera-icon"], !Number(event?.segmentId)
                                && styles["zone__camera-icon--padding"])}>
                                <DeviceIcon
                                    deviceType={event?.deviceType}
                                    deviceSubType={
                                        event?.object?.subType
                                            ? event?.object?.subType
                                            : event?.eventSource
                                    }
                                    width={36}
                                    height={36}
                                />
                            </div>

                            <div
                                className={styles["zone__color"]}
                                style={{backgroundColor: zoneColor ?? "#3589F8FF"}}
                            />
                        </div>
                    )
                }
            </td>
            <td>
                {loading
                    ? <Placeholder.Graph style={{height: 30, width: 30, borderRadius: 4}} active/>
                    : (
                        <div className={styles["incident"]}>
                            <TypeIcon
                                classname={styles["incident__type-icon"]}
                                type={event?.type}
                                width={30}
                                height={30}
                            />
                            <LightTooltip title={getStatusText(event)} placement={"top"}>
                                <div className={cn(
                                    styles["incident__status-icon"],
                                    {[styles["incident__status-icon--new"]]: event?.status === IncidentEventStatus.New},
                                    // eslint-disable-next-line max-len
                                    {[styles["incident__status-icon--processing"]]: event?.status === IncidentEventStatus.Processing},
                                    // eslint-disable-next-line max-len
                                    {[styles["incident__status-icon--resolved"]]: event?.status === IncidentEventStatus.Resolved},
                                    // eslint-disable-next-line max-len
                                    {[styles["incident__status-icon--rejected"]]: event?.status === IncidentEventStatus.Rejected}
                                )}>
                                    <StatusIcon
                                        status={event?.status}
                                        event={event}
                                    />
                                </div>
                            </LightTooltip>
                        </div>
                    )
                }
            </td>
            <td>
                <div className={styles["photo"]}>
                    {loading
                        ? <Placeholder.Graph style={{height: 36, width: 36, borderRadius: 4}} active/>
                        : event?.id && event?.thumbnail?.url
                            ? <Image
                                src={`${process.env.REACT_APP_BASE_HOST}${event?.thumbnail?.url}`}
                                width={36}
                                height={36}
                                objectFit={"cover"}
                                alt={"Фото"}
                            />
                            : event?.thumbnail?.id && (
                                <ImageWithToken
                                    src={ApiUrls.INCIDENTS_MEDIA_THUMB("incident", event?.id, event?.thumbnail?.id)}
                                    width={36}
                                    height={36}
                                    alt="Фото"
                                    withLoader={true}
                                    centerMode={true}
                                    objectFit={"cover"}
                                />
                            )
                    }
                </div>
            </td>

            <td>
                <div className={styles["priority-and-more"]}>
                    {loading
                        ? <Placeholder.Graph style={{height: 24, width: 24, borderRadius: 4}} active/>
                        : <PriorityIcon
                            priority={event?.priority}
                            relCount={event?.relCount}
                            width={22}
                            height={22}
                        />
                    }

                    {loading
                        ? <></>
                        : (
                            <MoreActionsDots placement={"bottomEnd"} arrow={false}>
                                <MoreActionsItem
                                    className={styles["dropdown__item"]}
                                    onClick={goToIncident}
                                    label={t("to_go")}
                                    icon={<ArrowLeftIcon/>}
                                />
                                <MoreActionsItem
                                    className={styles["dropdown__item"]}
                                    onClick={() => zoomMapOnEventHandle(event?.location?.geoPoint, event)}
                                    label={"Карта"}
                                    icon={<ArrowLeftIcon/>}
                                />
                            </MoreActionsDots>
                        )
                    }
                </div>
            </td>
        </tr>
    );
};

export default React.memo(DashboardEventsRow);