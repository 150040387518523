import React, {MouseEventHandler, ReactElement} from "react";
import cn from "classnames";

import Image from "components/Icons/Image";
import Spinner from "../Spinner/Spinner";

import styles from "./MoreActions.module.scss";

interface IMoreActionsItemProps {
    label: string,
    icon?: string | ReactElement,
    onClick?: MouseEventHandler,
    className?: string,
    loading?: boolean,
}

const MoreActionsItem: React.FC<IMoreActionsItemProps> = (
    {
        label,
        icon,
        onClick,
        className,
        loading,
    }
) => {

    return (
        <div
            className={cn(styles["dropdown__item"], className)}
            onMouseUp={onClick}
        >
            {icon && (
                <div className={styles["dropdown__item-icon"]}>
                    {typeof icon === "string" ? <Image src={icon} alt={label}/> : icon}
                </div>
            )}
            {label}
            {loading && (
                <div className={styles["dropdown__item-spinner"]}>
                    <Spinner size={14}/>
                </div>
            )}
        </div>
    );
};

export default React.memo(MoreActionsItem);