import React, {FC, useEffect} from "react";
import cn from "classnames";
import {useTranslation} from "react-i18next";
import {useSelector} from "react-redux";
import i18n from "i18next";

import {SignalRService} from "../../utils/signalr/SignalRService";
import {clearLocalStorageWithExceptions, getLocalStorageValue, setLocalStorageValue} from "../../utils/common";
import {signalRSettingsSelector, themeSelector} from "../../redux/commonSlice";

import LayoutMain from "./LayoutMain";
import LayoutAuthorization from "./LayoutAuthorization";
import LayoutDemonstration from "./LayoutDemonstration";
import SpinnerWithOverlay from "../UI/SpinnerWithOverlay";
import LayoutArm from "./LayoutArm/LayoutArm";

import stylesCommon from "../../styles/Common.module.scss";

interface ILayoutProps {
    type: "authorization" | "main" | "demonstration" | "arm" | "empty" | "proTv-demo",
    headerFilters?: React.ReactNode,
    children: string | React.ReactNode,
    settingsMode?: boolean,
}

const Layout: FC<ILayoutProps> = (
    {
        type,
        headerFilters,
        children,
        settingsMode,
        ...props
    }
) => {

    const {t} = useTranslation("common");
    const signalRSettings = useSelector(signalRSettingsSelector);
    const theme = useSelector(themeSelector);

    // Меняем метку если у пользователей принудительно нужно очистить local и session storage
    useEffect(() => {
        const currentTag = "2025-03-05-18-15";
        const savedTag = getLocalStorageValue("versionTag");

        if (!savedTag || !(savedTag && savedTag === currentTag)) {
            clearLocalStorageWithExceptions(["theme", "accessToken", "refreshToken"]);
            window.sessionStorage.clear();
            setLocalStorageValue("versionTag", currentTag);
            i18n.reloadResources();
            window.location.reload();
        }
    }, []);

    useEffect(() => {
        if (!signalRSettings.connectionSuccess || signalRSettings.isDisconnected) {
            const signalRService = SignalRService.getInstance();
            signalRService.send("UnsubscribeFromIncidentEvents");
            signalRService.send("SubscribeToIncidentEvents");
        }
    }, [signalRSettings.isDisconnected]);

    // Меняем класс у body при изменении темы
    useEffect(() => {
        if (typeof document !== "undefined") {
            document.querySelector("body")?.classList.remove(theme === "light" ? "dark" : "light");
            document.querySelector("body")?.classList.add(theme);
        }
    }, [theme]);

    const isDemonstrationDashboard = type === "demonstration";

    return (
        <div className={cn(
            stylesCommon["main"],
            isDemonstrationDashboard && stylesCommon["main--demonstration"],
            theme,
            {[stylesCommon["empty"]]: type === "empty"},
            {[stylesCommon["proTv-demo"]]: type === "proTv-demo"}
        )}>
            {type === "authorization" && (
                <LayoutAuthorization {...props}>
                    {children}
                </LayoutAuthorization>
            )}

            {type === "main" && (
                <LayoutMain
                    {...props}
                    theme={theme}
                    headerFilters={headerFilters}
                    settingsMode={settingsMode}
                >
                    {children}
                </LayoutMain>
            )}

            {type === "demonstration" && (
                <LayoutDemonstration
                    isDemonstrationDashboard={isDemonstrationDashboard}
                    {...props}
                >
                    {children}
                </LayoutDemonstration>
            )}

            {type === "arm" && (
                <LayoutArm theme={theme}>
                    {children}
                </LayoutArm>
            )}

            {type === "empty" && <>{children}</>}

            {type === "proTv-demo" && <>{children}</>}

            {/*Нет подключения к серверу*/}
            {signalRSettings.isDisconnected && type !== "proTv-demo" && (
                <SpinnerWithOverlay>
                    {t("common:no_server_connection")}
                </SpinnerWithOverlay>
            )}
        </div>
    );
};

export default React.memo(Layout);