/* eslint-disable max-len */
import React, {FC} from "react";

import {ISvgProps} from "../../types/common";

// import "../../images/icons/refresh-arrows.svg"

const RefreshArrowsIcon:FC<ISvgProps> = ({
    height = 12,
    width = 12,
    ...rest
}) => (
    <svg {...rest} width={width} height={height} viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path fillRule="evenodd" clipRule="evenodd"
            d="M5 3.5H3.09653C3.77677 2.53935 4.80319 2 6 2C8.20914 2 10 3.79086 10 6H11C11 3.23858 8.76142 1 6 1C4.59788 1 3.36255 1.59126 2.5 2.63517V1H1.5V4.5H5V3.5ZM7 8.5H8.90347C8.22323 9.46065 7.19681 10 6 10C3.79086 10 2 8.20914 2 6H1C1 8.76142 3.23858 11 6 11C7.40212 11 8.63745 10.4087 9.5 9.36483V11H10.5V7.5H7V8.5Z"
            fill="currentColor"/>
    </svg>
);

export default RefreshArrowsIcon;