// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".Zone_zone__fEqMG {\n  display: flex;\n  align-items: center;\n  padding: 16px;\n  border-radius: 4px;\n  cursor: pointer;\n}\n.Zone_zone__fEqMG:hover {\n  background-color: var(--bg-tertiary);\n}\n.Zone_zone--active__H\\+M2N {\n  background-color: var(--bg-tertiary);\n}\n.Zone_zone--priority__SKmIV {\n  flex-direction: row-reverse;\n  justify-content: space-between;\n  padding: 8px;\n}\n.Zone_zone__indicator__tbYkz {\n  min-width: 16px;\n  width: 16px;\n  height: 16px;\n  border-radius: 2px;\n  margin-right: 4px;\n}\n.Zone_zone__name__Hq9yP {\n  white-space: nowrap;\n  overflow: hidden;\n  text-overflow: ellipsis;\n}", "",{"version":3,"sources":["webpack://./src/components/Settings/SettingsOperators/Zone/Zone.module.scss"],"names":[],"mappings":"AAAA;EACE,aAAA;EACA,mBAAA;EACA,aAAA;EACA,kBAAA;EACA,eAAA;AACF;AACE;EACE,oCAAA;AACJ;AAEE;EACE,oCAAA;AAAJ;AAGE;EACE,2BAAA;EACA,8BAAA;EACA,YAAA;AADJ;AAIE;EACE,eAAA;EACA,WAAA;EACA,YAAA;EACA,kBAAA;EACA,iBAAA;AAFJ;AAKE;EACE,mBAAA;EACA,gBAAA;EACA,uBAAA;AAHJ","sourcesContent":[".zone {\r\n  display: flex;\r\n  align-items: center;\r\n  padding: 16px;\r\n  border-radius: 4px;\r\n  cursor: pointer;\r\n\r\n  &:hover {\r\n    background-color: var(--bg-tertiary);\r\n  }\r\n\r\n  &--active {\r\n    background-color: var(--bg-tertiary);\r\n  }\r\n\r\n  &--priority {\r\n    flex-direction: row-reverse;\r\n    justify-content: space-between;\r\n    padding: 8px;\r\n  }\r\n\r\n  &__indicator {\r\n    min-width: 16px;\r\n    width: 16px;\r\n    height: 16px;\r\n    border-radius: 2px;\r\n    margin-right: 4px;\r\n  }\r\n\r\n  &__name {\r\n    white-space: nowrap;\r\n    overflow: hidden;\r\n    text-overflow: ellipsis;\r\n  }\r\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"zone": "Zone_zone__fEqMG",
	"zone--active": "Zone_zone--active__H+M2N",
	"zone--priority": "Zone_zone--priority__SKmIV",
	"zone__indicator": "Zone_zone__indicator__tbYkz",
	"zone__name": "Zone_zone__name__Hq9yP"
};
export default ___CSS_LOADER_EXPORT___;
