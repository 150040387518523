import React from "react";
import i18n from "../i18n";
import {toast} from "react-toastify";

import {deleteIncidentFromFolder, getFolderIncident, postIncidentIntoFolder} from "../redux/favoiteFoldersSlice";
import {TypedDispatch} from "../types";

export interface IToggleFolderIncident {
    isChecked: boolean,
    folderId: number,
    incidentId: string,
    setIsLoading?: React.Dispatch<React.SetStateAction<boolean>>,
    dispatch: TypedDispatch,
}

export const toggleFolderIncident = async({
    incidentId,
    isChecked,
    folderId,
    setIsLoading,
    dispatch,
}: IToggleFolderIncident) => {

    setIsLoading && setIsLoading(true);

    const action = isChecked
        ? deleteIncidentFromFolder({folderId, incidentId})
        : postIncidentIntoFolder({folderId, incidentId});

    await dispatch(action);
    await dispatch(getFolderIncident({id: incidentId}));

    setIsLoading && setIsLoading(false);
};

interface IFoldersErrorHandler {
    status?: number,
    error400?: string,
    error404?: string,
    error409?: string,
    info409?: string,
    error500?: string,
    defaultError: string,
}

export const errorHandler = ({
    status,
    error400,
    error404,
    error409,
    info409,
    error500,
    defaultError,
}: IFoldersErrorHandler) => {

    const errorsMap: Record<number, () => void> = {
        400: () => toast.error(i18n.t(error400 ?? defaultError) as string),
        404: () => toast.error(i18n.t(error404 ?? defaultError) as string),
        409: () => {
            error409
                ? toast.error(i18n.t(error409) as string)
                : info409
                    ? toast.info(i18n.t(info409) as string)
                    : toast.error(i18n.t(defaultError) as string);
        },
        500: () => toast.error(i18n.t(error500 ?? defaultError) as string),
    };

    // Если статус не определен, выводим defaultError
    if (!status) {
        toast.error(i18n.t(defaultError) as string);
        return;
    }

    // Вызываем функцию обработки ошибки в зависимости от статуса, если он есть в errorsMap
    const handleError = errorsMap[status] || (() => toast.error(i18n.t(defaultError) as string));
    handleError();
};