import React from "react";
import {useTranslation} from "react-i18next";

import styles from "./Table.module.scss";

interface ITableHeadProps {
    columns: string[],
    columnsTranslationNamespace: string,
    customStyles?: object,
    headerCustomComponent?: React.ReactNode,
    headerCustomCheckbox?: React.ReactNode,
    headerCustomHide?: React.ReactNode,
}

const TableHead: React.FC<ITableHeadProps> = ({
    columns,
    columnsTranslationNamespace,
    customStyles,
    headerCustomComponent,
    headerCustomCheckbox,
    headerCustomHide,
}) => {

    const {t} = useTranslation(columnsTranslationNamespace);

    return (
        <thead>
            <tr style={customStyles}>
                {columns.map((col, i) => {
                    const component =
                    col === "headerCustomComponent"
                        ? headerCustomComponent
                        : col === "headerCustomCheckbox"
                            ? headerCustomCheckbox
                            : col === "headerCustomHide"
                                ? headerCustomHide
                                : t(`${columnsTranslationNamespace}:table.${col}`);

                    return (
                        <th key={i} className={styles["table_head"]}>
                            {component}
                        </th>
                    );
                })}
            </tr>
        </thead>
    );
};

export default React.memo(TableHead);