/* eslint-disable max-len */
import React from "react";

import {ISvgProps} from "../../../types/common";

// import icon from "images/icons/notifications/alert-fill-circle.svg";

const AlertFillCircleIcon: React.FC<ISvgProps> = ({
    width = 24,
    height = 24,
    ...rest
}) => (
    <svg {...rest} width={width} height={height} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <mask id="mask0_8110_293673" style={{maskType: "alpha"}} maskUnits="userSpaceOnUse" x="2" y="2" width="20" height="20">
            <path fillRule="evenodd" clipRule="evenodd" d="M12 2C6.48 2 2 6.48 2 12C2 17.52 6.48 22 12 22C17.52 22 22 17.52 22 12C22 6.48 17.52 2 12 2ZM12 13C11.45 13 11 12.55 11 12V8C11 7.45 11.45 7 12 7C12.55 7 13 7.45 13 8V12C13 12.55 12.55 13 12 13ZM11 15V17H13V15H11Z" fill="black"/>
        </mask>
        <g mask="url(#mask0_8110_293673)">
            <rect width={width} height={height} fill="currentColor"/>
        </g>
    </svg>
);

export default AlertFillCircleIcon;