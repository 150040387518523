import {createAsyncThunk, createSelector, createSlice, PayloadAction} from "@reduxjs/toolkit";
import {AxiosError, AxiosResponse} from "axios";
import i18n from "../i18n";
import {toast} from "react-toastify";

import {RootState} from "../store";
import {ApiUrls} from "../constants/urls";
import createHttpRequest from "../utils/http";
import IncidentEventModel from "../models/IncidentEventModel";
import {IFetchEventRequest, updateEventFolder, updateIncidentEvent} from "./eventSlice";
import {errorHandler} from "../utils/favoriteFolderUtils";

export interface IFavoriteFolderUser {
    id: number,
    userName: string,
    name: string,
}

export interface IFavoriteFolder {
    id: number,
    name: string,
    description: string,
    editable: boolean,
    owner: IFavoriteFolderUser,
    users: IFavoriteFolderUser[],
    created: string,
    modified: string,
}

export type PostFavoriteFolderType = Pick<IFavoriteFolder, "name" | "description"> & {
    userIds: number[],
};

export type PutFavoriteFolderType = Pick<IFavoriteFolder, "name" | "description" | "id"> & {
    userIds: number[],
};

interface IFavoriteFolderResponseData {
    pagination: {
        page: number,
        firstPage: boolean,
        lastPage: boolean,
        pageSize: number,
        totalElements: number,
        totalPages: number,
        sort: string,
    },
    data: IFavoriteFolder[],
}

export interface IFavoriteFoldersInitialState {
    favoriteFolders: IFavoriteFolder[],
    favoriteFoldersStatus: "idle" | "loading" | "succeeded" | "failed",
    folderIncidentStatus: "loading" | "succeeded" | "failed",
    editFolderStatus: | "loading" | "succeeded" | "failed",
    folderIncident: null | IncidentEventModel,
    activeFavoriteFolder: null | IFavoriteFolder,
}

interface IGetFavoriteFoldersParams {
    pageNumber?: number,
    pageSize?: number,
}

const initialState: IFavoriteFoldersInitialState = {
    favoriteFolders: [],
    favoriteFoldersStatus: "idle",
    folderIncidentStatus: "succeeded",
    editFolderStatus: "succeeded",
    folderIncident: null,
    activeFavoriteFolder: null,
};

// Получаем папки
export const getFavoriteFolders = createAsyncThunk<
    IFavoriteFolder[],
    IGetFavoriteFoldersParams,
    {rejectValue: AxiosError<any>}
>(
    "favoriteFolders/getFavoriteFolders",
    async({pageSize, pageNumber}, {rejectWithValue}) => {
        let urlPage = ApiUrls.FAVORITE_FOLDERS;

        // Создаем массив для параметров запроса
        const queryParams: string[] = [];

        // Добавляем pageNumber, если он определен
        if (pageNumber !== undefined) {
            queryParams.push(`Page=${pageNumber}`);
        }

        // Добавляем pageSize, если он определен
        if (pageSize !== undefined) {
            queryParams.push(`PageSize=${pageSize}`);
        }

        // Если есть параметры запроса, добавляем их к URL
        if (queryParams.length) {
            urlPage += `?${queryParams.join("&")}`;
        }

        try {
            const response = await createHttpRequest({
                method: "GET",
                path: urlPage,
                silent: true,
            }) as AxiosResponse<IFavoriteFolderResponseData>;

            return response.data.data;
        } catch (error) {
            return rejectWithValue(error as AxiosError<any>);
        }
    }
);

// Создаем папку IFavoriteFolder
export const postFavoriteFolder = createAsyncThunk<
    IFavoriteFolder,
    PostFavoriteFolderType,
    {rejectValue: AxiosError<any>}
>(
    "favoriteFolders/postFavoriteFolder",
    async(data, {rejectWithValue}) => {
        try {
            const response = await createHttpRequest({
                method: "POST",
                path: ApiUrls.FAVORITE_FOLDERS,
                data,
                silent: true,
            }) as AxiosResponse<IFavoriteFolder>;
            toast.success(i18n.t("archive:favorite.success_create_folder", {folderName: data.name}) as string, {
                autoClose: 1400,
            });
            return response.data;
        } catch (error) {
            return rejectWithValue(error as AxiosError<any>);
        }
    }
);

// Редактирование папки
export const putFavoriteFolder =
    createAsyncThunk<
        IFavoriteFolder,
        PutFavoriteFolderType,
        {rejectValue: AxiosError<any>}
    >(
        "favoriteFolders/putFavoriteFolder",
        async({id, name, description, userIds}, {rejectWithValue}) => {
            try {
                const res = await createHttpRequest({
                    method: "PUT",
                    path: ApiUrls.FAVORITE_FOLDERS_ID(id),
                    data: {
                        name,
                        description,
                        userIds,
                    },
                    silent: true,
                }) as AxiosResponse<IFavoriteFolder>;
                return res.data;
            } catch (error) {
                return rejectWithValue(error as AxiosError<any>);
            }
        }
    );

interface IDeleteFavoriteFolder {
    id: number,
}

// Удаление папки
export const deleteFavoriteFolder = createAsyncThunk<
    IDeleteFavoriteFolder,
    IDeleteFavoriteFolder,
    {rejectValue: AxiosError<any>}
>(
    "favoriteFolders/deleteFavoriteFolder",
    async({id}, {rejectWithValue}) => {
        try {
            await createHttpRequest({
                method: "DELETE",
                path: ApiUrls.FAVORITE_FOLDERS_ID(id),
                silent: true,
            });
            return {id};
        } catch (error) {
            return rejectWithValue(error as AxiosError<any>);
        }
    }
);

interface IIncidentInFolder {
    incidentId: string,
    folderId: number,
}

// Добавление инцидента в папку
export const postIncidentIntoFolder =
    createAsyncThunk<
        void,
        IIncidentInFolder,
        {rejectValue: AxiosError<any>}
    >(
        "favoriteFolders/postIncidentIntoFolder",
        async({incidentId, folderId}, {rejectWithValue}) => {
            try {
                await createHttpRequest({
                    method: "POST",
                    path: ApiUrls.FAVORITE_FOLDERS_ID_INCIDENTS(folderId),
                    data: incidentId,
                    silent: true,
                });
            } catch (error) {
                return rejectWithValue(error as AxiosError<any>);
            }
        }
    );

// Удаление инцидента из папки
export const deleteIncidentFromFolder =
    createAsyncThunk<
        void,
        IIncidentInFolder,
        {rejectValue: AxiosError<any>}
    >(
        "favoriteFolders/deleteIncidentFromFolder",
        async({incidentId, folderId}, {rejectWithValue}) => {
            try {
                await createHttpRequest({
                    method: "DELETE",
                    path: ApiUrls.FAVORITE_FOLDERS_ID_INCIDENTS_ID(folderId, incidentId),
                    silent: true,
                });
            } catch (error) {
                return rejectWithValue(error as AxiosError<any>);
            }
        }
    );

// Получение инцидента
export const getFolderIncident = createAsyncThunk<
    IncidentEventModel,
    IFetchEventRequest,
    {rejectValue: AxiosError<any>}
>(
    "favoriteFolders/getFolderIncident",
    async({id, includeHiddenEvents}, {rejectWithValue, dispatch}) => {
        try {
            const response = await createHttpRequest({
                method: "GET",
                path: ApiUrls.EVENT(id as string, includeHiddenEvents),
                silent: true,
            }) as AxiosResponse<IncidentEventModel>;
            dispatch(updateEventFolder(response.data));
            dispatch(updateIncidentEvent(response.data));
            return response.data;
        } catch (error) {
            return rejectWithValue(error as AxiosError<any>);
        }
    }
);

const favoriteFolders = createSlice({
    name: "favoriteFolders",
    initialState,
    reducers: {
        setActiveFolder: (state, action: PayloadAction<IFavoriteFolder>) => {
            state.activeFavoriteFolder = action.payload;
        },

        clearActiveFolder: (state) => {
            state.activeFavoriteFolder = null;
        },

        clearFolderIncident: (state) => {
            state.folderIncident = null;
        },
    },
    extraReducers: (builder) => {
        builder
            // getFavoriteFolders
            .addCase(getFavoriteFolders.pending, (state) => {
                state.favoriteFoldersStatus = "loading";
            })
            .addCase(getFavoriteFolders.fulfilled, (state, action) => {
                state.favoriteFolders = action.payload;
                state.favoriteFoldersStatus = "succeeded";
            })
            .addCase(getFavoriteFolders.rejected, (state, action) => {
                state.favoriteFoldersStatus = "failed";

                const status = action.payload?.response?.status;
                errorHandler({
                    status,
                    error500: "messages:folders.500_server_error_get_folders",
                    defaultError: "messages:folders.default_get_folders_error",
                });
            });

        builder
            // postFavoriteFolder
            .addCase(postFavoriteFolder.pending, (state) => {
                state.editFolderStatus = "loading";
            })
            .addCase(postFavoriteFolder.fulfilled, (state, action) => {
                state.favoriteFolders = [...state.favoriteFolders, action.payload];
                state.editFolderStatus = "succeeded";
            })
            .addCase(postFavoriteFolder.rejected, (state, action) => {
                state.editFolderStatus = "failed";

                const status = action.payload?.response?.status;
                errorHandler({
                    status,
                    error400: "messages:folders.400_bad_request_create_folder",
                    error500: "messages:folders.500_server_error_create_folder",
                    defaultError: "messages:folders.default_create_folder_error",
                });
            });

        builder
            // putFavoriteFolder
            .addCase(putFavoriteFolder.pending, (state) => {
                state.editFolderStatus = "loading";
            })
            .addCase(putFavoriteFolder.fulfilled, (state, action) => {
                state.favoriteFolders = state.favoriteFolders.map(folder =>
                    folder.id === action.payload.id ? action.payload : folder);
                state.editFolderStatus = "succeeded";
            })
            .addCase(putFavoriteFolder.rejected, (state, action) => {
                state.editFolderStatus = "failed";

                const status = action.payload?.response?.status;
                errorHandler({
                    status,
                    error400: "messages:folders.400_bad_request_update_folder",
                    error404: "messages:folders.404_not_found_folder",
                    error500: "messages:folders.500_server_error_update_folder",
                    defaultError: "messages:folders.default_update_folder_error",
                });
            });

        builder
            // deleteFavoriteFolder
            .addCase(deleteFavoriteFolder.pending, (state) => {
                state.editFolderStatus = "loading";
            })
            .addCase(deleteFavoriteFolder.fulfilled, (state, action) => {
                state.favoriteFolders = state.favoriteFolders.filter(folder => folder.id !== action.payload.id);
                state.editFolderStatus = "succeeded";
            })
            .addCase(deleteFavoriteFolder.rejected, (state, action) => {
                state.editFolderStatus = "failed";

                const status = action.payload?.response?.status;
                errorHandler({
                    status,
                    error404: "messages:folders.404_not_found_folder",
                    error500: "messages:folders.500_server_error_delete_folder",
                    defaultError: "messages:folders.default_delete_folder_error",
                });
            });

        builder
            // postIncidentIntoFolder
            .addCase(postIncidentIntoFolder.pending, (state) => {
                state.folderIncidentStatus = "loading";
            })
            .addCase(postIncidentIntoFolder.fulfilled, (state) => {
                state.folderIncidentStatus = "succeeded";
            })
            .addCase(postIncidentIntoFolder.rejected, (state, action) => {
                state.folderIncidentStatus = "failed";

                const status = action.payload?.response?.status;
                errorHandler({
                    status,
                    error404: "messages:folders.404_not_found_folder",
                    info409: "messages:folders.409_conflict_incident_already_in_folder",
                    error500: "messages:folders.500_server_error_add_incident_to_folder",
                    defaultError: "messages:folders.default_add_incident_to_folder_error",
                });

            });

        builder
            // deleteIncidentFromFolder
            .addCase(deleteIncidentFromFolder.pending, (state) => {
                state.folderIncidentStatus = "loading";
            })
            .addCase(deleteIncidentFromFolder.fulfilled, (state) => {
                state.folderIncidentStatus = "succeeded";
            })
            .addCase(deleteIncidentFromFolder.rejected, (state, action) => {
                state.folderIncidentStatus = "failed";

                const status = action.payload?.response?.status;
                errorHandler({
                    status,
                    error404: "messages:folders.404_not_found_folder",
                    error500: "messages:folders.500_server_error_delete_incident_from_folder",
                    defaultError: "messages:folders.default_delete_incident_from_folder_error",
                });

            });

        builder
            // getFolderIncident
            .addCase(getFolderIncident.pending, (state) => {
                state.folderIncidentStatus = "loading";
            })
            .addCase(getFolderIncident.fulfilled, (state, action) => {
                state.folderIncident = action.payload;
                state.folderIncidentStatus = "succeeded";
            })
            .addCase(getFolderIncident.rejected, (state) => {
                state.folderIncidentStatus = "failed";

                errorHandler({
                    defaultError: "messages:fetch_event_error",
                });
            });
    },
});

export default favoriteFolders.reducer;

export const {
    clearFolderIncident,
    setActiveFolder,
    clearActiveFolder,
} = favoriteFolders.actions;

const slice = ({favoriteFoldersReducer}: RootState) => favoriteFoldersReducer;

export const favoriteFoldersSelector = createSelector(
    slice,
    (state) => state.favoriteFolders
);

export const favoriteFoldersStatusSelector = createSelector(
    slice,
    (state) => state.favoriteFoldersStatus
);

export const folderIncidentStatusSelector = createSelector(
    slice,
    (state) => state.folderIncidentStatus
);

export const editFolderStatusSelector = createSelector(
    slice,
    (state) => state.editFolderStatus
);

export const folderIncidentSelector = createSelector(
    slice,
    (state) => state.folderIncident
);

export const activeFavoriteFolderSelector = createSelector(
    slice,
    (state) => state.activeFavoriteFolder
);
