/* eslint-disable max-len */
import React, {FC} from "react";

import {ISvgProps} from "../../types/common";

const CommentsIcon:FC<ISvgProps> = ({
    width = 17,
    height = 16,
    ...rest
}) => (
    <svg {...rest} width={width} height={height} viewBox="0 0 17 16" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path fillRule="evenodd" clipRule="evenodd"
            d="M14.8685 4.66659V3.33325H1.53516V4.66659H14.8685ZM10.8685 5.99992V7.33325H1.53516V5.99992H10.8685ZM14.8685 9.99992V8.66659H1.53516V9.99992H14.8685ZM10.8685 12.6666V11.3333H1.53516V12.6666H10.8685Z"
            fill="currentColor"/>
    </svg>
);

export default CommentsIcon;