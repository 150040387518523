import React, {FC} from "react";
import cn from "classnames";

import SuccessIcon from "../../Icons/SuccessIcon";
import RejectIcon from "../../Icons/RejectIcon";
import CrossIcon from "../../Icons/CrossIcon";

import styles from "./HandoverProcessedNotification.module.scss";

interface IHandoverProcessedNotification {
    type: string,
    name: string,
}

const HandoverProcessedNotification: FC<IHandoverProcessedNotification> = ({
    type,
    name,
}) => {
    const isSuccess = type === "success";
    return (
        <div className={cn(styles["notification"], isSuccess ? styles["success"] : styles["reject"])}>
            <div className={styles["notification__left"]}>
                {isSuccess ? <SuccessIcon/> : <RejectIcon/> }
                <div>Оператор {name} {isSuccess ? "принял" : "отклонил"} заявку на передачу инцидента</div>
            </div>
            <CrossIcon className={styles["notification__close"]} width={14} height={14}/>
        </div>
    );
};

export default HandoverProcessedNotification;