import React, {FC, useState} from "react";
import cn from "classnames";
import {Fade, Popover} from "@material-ui/core";
import {useTranslation} from "react-i18next";
import {useLocation, useNavigate} from "react-router-dom";
import {useDispatch, useSelector} from "react-redux";

import {logout, userSelector} from "../../../redux/authSlice";
import {isHeaderCollapsedSelector, setIsHeaderCollapsed} from "../../../redux/commonSlice";
import {TypedDispatch} from "../../../types";
import {ApiUrls, AppUrls} from "../../../constants/urls";
import {clearTokens} from "../../../utils/auth";

import UserIcon from "../../../images/icons/user.svg";
import ChevronBottomIcon from "../../Icons/ChevronBottomIcon";
import DoubleChevronLeft from "../../Icons/DoubleChevronLeft";
import ImageWithToken from "../../Icons/ImageWithToken";
import HeaderSidebarArch from "../../Icons/HeaderSidebarArch";
import SettingsHeader from "../../Settings/SettingsHeader";
import QuickSettings from "../../Header/QuickSettings";
import LogoIcon from "../../Icons/LogoIcon";

import styles from "./Header.module.scss";

const Header: FC = () => {
    const dispatch = useDispatch<TypedDispatch>();
    const navigate = useNavigate();
    const {t} = useTranslation(["dashboardDemonstration", "common"]);
    const {pathname} = useLocation();
    const settingsMode = pathname.includes("settings");

    const user = useSelector(userSelector);
    const isHeaderCollapsed = useSelector(isHeaderCollapsedSelector);

    // User dropdown menu
    const [anchorUserDropdownEl, setAnchorUserDropdownEl] = useState<null | HTMLElement>(null);
    const isUserDropdownOpen = Boolean(anchorUserDropdownEl);

    const handleUserDropdownClick = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorUserDropdownEl(event.currentTarget);
    };
    const handleUserDropdownClose = () => {
        setAnchorUserDropdownEl(null);
    };

    const handleLogout = async(event: React.MouseEvent) => {
        event.preventDefault();
        clearTokens();
        await dispatch(logout());
        navigate(AppUrls.AUTH);
    };

    const toggleHeaderSize = () => dispatch(setIsHeaderCollapsed(!isHeaderCollapsed));

    return (
        <header className={cn(styles["header"], isHeaderCollapsed && styles["header--collapsed"])}>
            {settingsMode
                ? <div className={styles["header__title"]}>Настройки</div>
                : <>
                    <div className={styles["header__logo"]}>
                        <p className={styles["header__logo-title"]}>
                            <LogoIcon/>
                        </p>
                    </div>

                    <div
                        className={styles["header__visibility-toggle"]}
                        onClick={toggleHeaderSize}
                    >
                        <DoubleChevronLeft/>
                    </div>
                </>
            }

            {settingsMode
                ? <SettingsHeader/>
                : <>
                    <div className="d-flex align-items-center ml-auto">
                        <QuickSettings/>

                        <div className={cn(styles["header__user"], styles["header-user"])}>
                            <div
                                className={styles["header-user__btn"]}
                                onClick={handleUserDropdownClick}
                            >
                                <div className={styles["header-user__icon"]}>
                                    {
                                        user?.UserId ?
                                            <ImageWithToken
                                                src={ApiUrls.USER_AVATAR(user?.UserId)}
                                                width={24}
                                                height={24}
                                                alt="User"
                                                fallbackSrc={UserIcon}
                                            />
                                            : <></>
                                    }
                                </div>

                                <div className={styles["header-user__chevron-btm"]}>
                                    <ChevronBottomIcon/>
                                </div>
                            </div>

                            <Popover
                                className={styles["header-user__popover"]}
                                open={isUserDropdownOpen}
                                anchorEl={anchorUserDropdownEl}
                                transitionDuration={0}
                                onClose={handleUserDropdownClose}
                                TransitionComponent={Fade}
                                TransitionProps={{timeout: 0}}
                                anchorOrigin={{
                                    vertical: "bottom",
                                    horizontal: "right",
                                }}
                                transformOrigin={{
                                    vertical: "top",
                                    horizontal: "right",
                                }}
                            >
                                <div className={styles["header-user__dropdown"]}>
                                    {user && (
                                        <div className={styles["header-user__dropdown-item"]}>
                                            {user.FullName}
                                        </div>
                                    )}
                                    <div
                                        className={styles["header-user__dropdown-item"]}
                                        onClick={handleLogout}
                                    >
                                        {t("common:exit")}
                                    </div>
                                </div>
                            </Popover>
                        </div>
                    </div>
                    <HeaderSidebarArch className={styles["header__arch-icon"]}/>
                </>}
        </header>
    );
};

export default React.memo(Header);