import React, {FC, useRef} from "react";
import cn from "classnames";

import styles from "./Header.module.scss";

interface IThemeToggleProps {
    theme: string,
    handleThemeChange: () => void,
}

const ThemeToggle: FC<IThemeToggleProps> = ({theme, handleThemeChange}) => {
    const isDarkTheme = theme === "dark";
    const circleRef = useRef<HTMLDivElement>(null);

    const handleToggleChangeAnimation = () => {
        if (circleRef.current) {
            if (isDarkTheme) {
                circleRef.current.classList.add(styles["move-right"]);
                circleRef.current.classList.remove(styles["move-left"]);
            } else {
                circleRef.current.classList.add(styles["move-left"]);
                circleRef.current.classList.remove(styles["move-right"]);
            }
        }
        handleThemeChange();
    };

    return (
        <div
            className={cn(styles["theme-toggle"], {[styles["theme-toggle--dark"]]: isDarkTheme})}
            onClick={handleToggleChangeAnimation}
        >
            <div ref={circleRef} className={styles["theme-toggle__circle"]}/>
            <div className={cn(styles["theme-toggle__background"],
                {[styles["theme-toggle__background--active"]]: isDarkTheme})}>
                <svg className={styles["theme-toggle__background--icon"]}
                    width="15" height="14" viewBox="0 0 15 14" fill="none" 
                    xmlns="http://www.w3.org/2000/svg">
                    {/* eslint-disable-next-line max-len */}
                    <path d="M7.08778 0.345703C6.11114 1.25825 5.50065 2.55773 5.50065 3.99979C5.50065 6.7612 7.73925 8.9998 10.5007 8.9998C11.9427 8.9998 13.2422 8.38933 14.1547 7.41266C13.9415 10.9023 11.0439 13.6665 7.50065 13.6665C3.81875 13.6665 0.833984 10.6817 0.833984 6.9998C0.833984 3.45656 3.59818 0.558923 7.08778 0.345703Z" fill={isDarkTheme ? "#579cf9" : "#666b8f"}/>
                </svg>
            </div>
            <div className={cn(styles["theme-toggle__background"],
                {[styles["theme-toggle__background--active"]]: !isDarkTheme})}>
                <svg className={styles["theme-toggle__background--icon"]}
                    width="16" height="16" viewBox="0 0 16 16" fill="none"
                    xmlns="http://www.w3.org/2000/svg">
                    <g clipPath="url(#clip0_23463_469534)">
                        {/* eslint-disable-next-line max-len */}
                        <path d="M7.99935 12.0003C5.79021 12.0003 3.99935 10.2095 3.99935 8.00033C3.99935 5.79119 5.79021 4.00033 7.99935 4.00033C10.2085 4.00033 11.9993 5.79119 11.9993 8.00033C11.9993 10.2095 10.2085 12.0003 7.99935 12.0003ZM7.33268 0.666992H8.66602V2.66699H7.33268V0.666992ZM7.33268 13.3337H8.66602V15.3337H7.33268V13.3337ZM2.3425 3.28628L3.2853 2.34347L4.69952 3.75769L3.75671 4.70049L2.3425 3.28628ZM11.2991 12.243L12.242 11.3001L13.6562 12.7144L12.7134 13.6572L11.2991 12.243ZM12.7134 2.34347L13.6562 3.28628L12.242 4.70049L11.2991 3.75769L12.7134 2.34347ZM3.75671 11.3001L4.69952 12.243L3.2853 13.6572L2.3425 12.7144L3.75671 11.3001ZM15.3327 7.33366V8.66699H13.3327V7.33366H15.3327ZM2.66602 7.33366V8.66699H0.666016V7.33366H2.66602Z" fill={!isDarkTheme ? "#579cf9" : "#666b8f"}/>
                    </g>
                    <defs>
                        <clipPath id="clip0_23463_469534">
                            <rect width="16" height="16" fill="white"/>
                        </clipPath>
                    </defs>
                </svg>
            </div>
        </div>
    );
};

export default React.memo(ThemeToggle);