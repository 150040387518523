import React, {FC, useEffect, useRef, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {TypedDispatch} from "../../../types";
import cn from "classnames";
import {formatTimeDifference} from "../../../utils/formatTimeDifference";
import {TIME_OPTIONS} from "../../../constants/common";
import {
    createProcessingSuppressedBeforeUtc, currentCameraRulesSelector, deleteProcessingSuppressedBeforeUtc,
    editProcessingSuppressedBeforeUtc, fetchCameraSettings,
    ICams,
    ICamsJson, resetCameraRule, successSelector,
    updateProcessingSuppressedBeforeUtc,
} from "../../../redux/disablingCameraIncidentsSlice";
import styles from "../SettingsIncidents/SettingsIncidents.module.scss";

interface ISettingsIncidentsProps {
    cameraId: any,
    selectedSegmentId: any,
    matchedCam: any,
    matchedId: any,
    noMatchedCam: any,
    prevUtc: any,
    prevJson: any,
    zoneId?: any,
}

const SettingsIncidentsSegment: FC<ISettingsIncidentsProps> = ({
    cameraId,
    selectedSegmentId,
    matchedCam,
    matchedId,
    noMatchedCam,
    prevUtc,
    prevJson,
    zoneId,
}) => {
    const dispatch = useDispatch<TypedDispatch>();
    const currentCameraRules = useSelector(currentCameraRulesSelector);
    const success = useSelector(successSelector);
    const [openSelect, setOpenSelect] = useState<string | null>(null);
    const selectRef = useRef<HTMLDivElement>(null);

    const foundSetting = currentCameraRules?.Data?.json?.prioritySettings
        ?.find((setting: ICamsJson) => setting.segmentId === selectedSegmentId &&
            !setting.incidentTypeCode);

    const [isChecked, setIsChecked] = useState<boolean>(!!foundSetting?.validUntilUtc);
    const [selectedTime, setSelectedTime] = useState<any>(null);

    useEffect(() => {
        if (matchedId) {
            dispatch(fetchCameraSettings(matchedId));
        } else {
            dispatch(resetCameraRule());
        }
    }, []);

    useEffect(() => {
        const value = new Date(foundSetting?.validUntilUtc) > new Date();
        setIsChecked(value);
    }, [selectedSegmentId, foundSetting]);

    function toggleSelect(selectName: string) {
        setOpenSelect(prev => (prev === selectName ? null : selectName));
    }

    const handleOptionSelect = (type: string, value: any) => {
        if (type === "time") {
            setSelectedTime(value);
            setOpenSelect(null);
        }
    };

    const calculateValidUntilUtc = (minutes: number): string => {
        const now = new Date();
        // Добавляем выбранные минуты
        now.setMinutes(now.getMinutes() + minutes);
        return now.toISOString();  // Возвращаем в формате ISO
    };

    const handleCheckboxChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        prevUtc.utc = matchedCam?.Data.incidentProcessingSuppressedBeforeUtc;
        prevJson.json = matchedCam?.Data.json;

        let validUntilUtc = null;

        // Если выбрано какое-то время
        if (selectedTime && selectedTime !== "forever") {
            validUntilUtc = calculateValidUntilUtc(Number(selectedTime));
        } else if (selectedTime === "forever") {
            validUntilUtc = "2099-12-31T00:00:00.000Z";  // Навсегда
        }

        const newSegmentData = {
            segmentId: selectedSegmentId ? selectedSegmentId : undefined,
            validUntilUtc: validUntilUtc,
        };

        const filteredPrioritySettings = prevJson.json?.prioritySettings?.filter((setting: {
            validUntilUtc: string | number | Date,
        }) => {
            return new Date(setting.validUntilUtc) > new Date();
        }) || [];

        const updatedPrioritySettings = [...filteredPrioritySettings, newSegmentData];

        const existingSetting = prevJson.json?.prioritySettings?.find((setting: any) =>
            (setting.segmentId === selectedSegmentId &&
                !setting.incidentTypeCode));

        const newCamData: ICams = {
            name: cameraId,
            data: {
                roadObjectId: cameraId,
                zoneId: zoneId,
                incidentProcessingSuppressedBeforeUtc: prevUtc.utc || undefined,
                json: {
                    prioritySettings: updatedPrioritySettings,
                },
            },
        };

        if (event.target.checked) {
            if (!noMatchedCam) {
                dispatch(createProcessingSuppressedBeforeUtc({
                    data: {
                        data: newCamData?.data,
                        name: cameraId,
                        typeId: "INCIDENTS_CAMERASETTINGS.1",
                    },
                }));
                setIsChecked(true);
            } else {
                const preparedData = {
                    data: newCamData?.data,
                    name: cameraId,
                    typeId: "INCIDENTS_CAMERASETTINGS.1",
                };

                dispatch(editProcessingSuppressedBeforeUtc({id: matchedId, data: preparedData}))
                    .then(({meta}: any) => {
                        prevUtc.utc = meta.arg.data.data.incidentProcessingSuppressedBeforeUtc;
                        prevJson.json = meta.arg.data.data.json;
                    });
                setIsChecked(true);
            }
        } else if (!event.target.checked) {
            dispatch(deleteProcessingSuppressedBeforeUtc({
                id: matchedId,
                segmentId: selectedSegmentId,
                incidentTypeCode: null,
            }));
            setIsChecked(false);
        } else if (existingSetting) {
            dispatch(updateProcessingSuppressedBeforeUtc({
                id: matchedId,
                validUntilUtc: new Date(Date.now() + Number(selectedTime) * 60 * 1000).toISOString(),
                segmentId: selectedSegmentId,
                incidentTypeCode: null,
            }));
            setIsChecked(true);
        }
    };

    useEffect(() => {
        function handleClickOutside(event: MouseEvent) {
            if (selectRef.current && !selectRef.current.contains(event.target as Node)) {
                setOpenSelect(null);
            }
        }

        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, []);

    return (
        <div
            className={styles["settings-incidents__types-item"]} ref={selectRef}>
            <div className={styles["settings-incidents__type-name"]}>
                Весь сегмент
            </div>
            <div className="cams-info__custom-select custom-select__time">
                <div
                    className={cn(openSelect === "time" && styles["select__trigger-active"],
                        "cams-info__select-trigger")}
                    onClick={() => toggleSelect("time")}
                >
                    {selectedTime ? TIME_OPTIONS[selectedTime] : formatTimeDifference(foundSetting?.validUntilUtc)}
                </div>
                {openSelect === "time" && (
                    <div className={cn(styles["select__options-active"], "cams-info__options")}>
                        {Object.keys(TIME_OPTIONS).map((key) => (
                            <div key={key} className="cams-info__option" data-value={key}
                                onClick={() => {
                                    handleOptionSelect("time", key);
                                    setIsChecked(false);
                                }}>
                                {TIME_OPTIONS[key]}
                            </div>
                        ))}
                    </div>
                )}

            </div>
            <label className="cams-info__custom-switch">
                <input
                    type="checkbox"
                    className="disable-tracking-checkbox"
                    disabled={!success}
                    onChange={(event) => {
                        handleCheckboxChange(event);
                    }}
                    checked={isChecked}
                />
                <span className="slider round"></span>
            </label>
        </div>
    );
};

export default SettingsIncidentsSegment;
