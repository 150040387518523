export const DEFAULT_LOCALE = "ru";
export const ACCESS_TOKEN_NAME = "accessToken";
export const REFRESH_TOKEN_NAME = "refreshToken";
export const DEFAULT_PAGE_SIZE = 10;
export const DEFAULT_EVENTS_SORT = "registrationDateTime,desc";
export const DEFAULT_USERS_SORT = "userId,asc";

export const DEFAULT_EFFICIENCY_JOURNAL_SORT = "roadObjectId,asc";

export const MONTH_NAMES: { [index: string]: string } = {
    0: "Январь",
    1: "Февраль",
    2: "Март",
    3: "Апрель",
    4: "Май",
    5: "Июнь",
    6: "Июль",
    7: "Август",
    8: "Сентябрь",
    9: "Октябрь",
    10: "Ноябрь",
    11: "Декабрь",
};

export const TIME_OPTIONS: {[key: string]: string} = {
    15: "15 мин",
    30: "30 мин",
    60: "1 час",
    180: "3 часа",
    1440: "Весь день",
    forever: "Навсегда",
};

// LocalStorage constants
export const storageKeys = {
    analyticsSection: "analyticsSectionSwitcher",
    archivePageSwitcher: "archivePageSwitcher",
    favoriteFoldersFilter: "favoriteFoldersFilter",
};

export const defaultZoneColor = "#6342E7";

export const TIME_OPTIONS_ARM: {[key: string]: string} = {
    30: "30 секунд",
    60: "1 минута",
    180: "3 минуты",
};
