/* eslint-disable max-len */
import React from "react";

import {ISvgProps} from "../../types/common";

const BarChartIcon: React.FC<ISvgProps> = ({
    width = 16,
    height = 16,
    ...rest
}) => (
    <svg xmlns="http://www.w3.org/2000/svg" {...rest} width={width} height={height} viewBox="0 0 17 16" fill="none">
        <path fillRule="evenodd" clipRule="evenodd"
            d="M6.83301 4.5C6.83301 4.22386 7.05687 4 7.33301 4H9.66634C9.94248 4 10.1663 4.22386 10.1663 4.5V11.5C10.1663 11.7761 9.94248 12 9.66634 12H7.33301C7.05687 12 6.83301 11.7761 6.83301 11.5V4.5ZM2.5 9.5C2.5 9.22386 2.72386 9 3 9H5.33333C5.60948 9 5.83333 9.22386 5.83333 9.5V11.5C5.83333 11.7761 5.60948 12 5.33333 12H3C2.72386 12 2.5 11.7761 2.5 11.5V9.5ZM11.667 6C11.3909 6 11.167 6.22386 11.167 6.5V11.5C11.167 11.7761 11.3908 12 11.667 12H14.0003C14.2765 12 14.5003 11.7761 14.5003 11.5V6.5C14.5003 6.22386 14.2765 6 14.0003 6H11.667Z"
            fill="currentColor"/>
    </svg>
);

export default BarChartIcon;