import React, {FC} from "react";

import styles from "./RadioButton.module.scss";

interface IRadioButtonProps {
    label: string,
    onClick?: any,
    checked?: boolean,
    value?: string,
    name?: string,
    readOnly?: boolean,
    onChange?: any,
}

// eslint-disable-next-line react/display-name
const RadioButton:FC<IRadioButtonProps> = ({
    label,
    onClick,
    checked,
    name,
    value,
    readOnly,
    onChange,
}) => {

    return (
        <div className={styles["radio--light"]} onClick={onClick}>
            <div className={styles["custom-radio"]}>
                <label>
                    <input
                        onChange={onChange}
                        type="radio"
                        name={name}
                        checked={checked}
                        value={value}
                        readOnly={readOnly}
                    />
                    <div className={styles["custom-radio__label"]}>
                        {label}
                    </div>
                </label>
            </div>
        </div>
    );
};

export default RadioButton;
