/* eslint-disable max-len */
import React from "react";

const SuccessIcon: React.FC<object> = () => {
    return (
        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fillRule="evenodd" clipRule="evenodd" d="M3.26172 3.26172C4.58232 1.94112 6.37343 1.19922 8.24103 1.19922H16.1574C18.025 1.19922 19.8161 1.94112 21.1367 3.26172C22.4573 4.58232 23.1992 6.37343 23.1992 8.24103V16.1574C23.1992 18.025 22.4573 19.8161 21.1367 21.1367C19.8161 22.4573 18.025 23.1992 16.1574 23.1992H8.24103C6.37343 23.1992 4.58232 22.4573 3.26172 21.1367C1.94112 19.8161 1.19922 18.025 1.19922 16.1574V8.24103C1.19922 6.37343 1.94112 4.58232 3.26172 3.26172Z" fill="#3DD598"/>
            <path d="M8.27875 10.8127C7.75267 10.2806 6.90522 10.2862 6.38592 10.8253C5.86661 11.3643 5.8721 12.2326 6.39817 12.7647L9.79914 16.2046C10.3587 16.7705 11.2714 16.7228 11.7718 16.1014L17.2939 9.24431C17.7644 8.6601 17.6836 7.79571 17.1134 7.31366C16.5432 6.8316 15.6996 6.91441 15.2292 7.49862L10.6383 13.1993L8.27875 10.8127Z" fill="white"/>
        </svg>
    );
};

export default SuccessIcon;