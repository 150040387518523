import React, {FC, useEffect, useState} from "react";
import cn from "classnames";
import {useDispatch, useSelector} from "react-redux";
import {useForm} from "react-hook-form";
import SimpleBar from "simplebar-react";
import {useTranslation} from "react-i18next";
import {debounce, lowerCase} from "lodash";
import "simplebar/dist/simplebar.min.css";

import {
    availableOperatorsSelector, fetchAvailableOperators,
    resetUpdateResponsibilityZoneUsersSuccess,
    updateResponsibilityZoneUsers,
    updateResponsibilityZoneUsersLoadingSelector,
    updateResponsibilityZoneUsersSuccessSelector, zoneOperatorsSelector,
} from "../../../../redux/zoneOperatorsSlice";
import {TypedDispatch} from "../../../../types";
import {User} from "../../../../models/User";

import Checkbox from "../../../UI/Checkbox";
import BaseButton from "../../../UI/Button/BaseButton";
import Spinner from "../../../UI/Spinner/Spinner";
import CrossIcon from "../../../Icons/CrossIcon";
import SearchIcon from "../../../Icons/SearchIcon";
import TextFormInput, {ComponentType} from "../../../UI/Input/TextFormInput";

import modalStyles from "../../../ArchiveIncidentPage/IncidentInfo/IncidentInfoModals/IncidentInfoModals.module.scss";
import styles from "./AddOperatorsForm.module.scss";

interface IAddOperatorsFormProps {
    onClose: () => void,
    operatorRole: number | null,
    activeZone: number | null | undefined,
}
const AddOperatorsForm:FC<IAddOperatorsFormProps> = (
    {
        onClose,
        operatorRole,
        activeZone,
    }
) => {

    const {t} = useTranslation(["common"]);
    const dispatch = useDispatch<TypedDispatch>();
    const zoneOperators = useSelector(zoneOperatorsSelector);
    const availableOperators = useSelector(availableOperatorsSelector);
    const loading = useSelector(updateResponsibilityZoneUsersLoadingSelector);
    const success = useSelector(updateResponsibilityZoneUsersSuccessSelector);

    const {handleSubmit, register, control, watch} = useForm({mode: "onChange"});

    // Выбранные операторы (нужны для defaultChecked, иначе при ререндере сбрасывается к дефолтным zoneOperators)
    const [selectedOperators, setSelectedOperators] = useState<User[]>([]);
    useEffect(() => {
        if (zoneOperators) {
            setSelectedOperators(zoneOperators);
        }
    }, [zoneOperators]);

    // Менялось ли что-либо в форме
    const [isDirty, setIsDirty] = useState<boolean>(false);

    useEffect(() => {
        const subscription = watch((value) => {
            // Сравниваем изменились ли данные формы
            if (zoneOperators && availableOperators) {
                const addedOperators = Object.keys(value)
                    .filter(key => +key && value[key])
                    .map(key => Number(key))
                    .sort()
                    .join("");
                const defaultOperators = zoneOperators.map(operator => operator.id).sort().join("");

                const newSelectedOperators = availableOperators
                    .filter(operator => Object.keys(value)
                        .filter(key => +key && value[key])
                        .map(key => Number(key))
                        .includes(operator.id));
                // Обновляем данные дефолтных значений
                setSelectedOperators(newSelectedOperators);
                // Если данные изменились меняем флаг
                setIsDirty(!(addedOperators === defaultOperators));
            }
        });
        return () => subscription.unsubscribe();
    }, [watch, zoneOperators, availableOperators]);

    useEffect(() => {
        if (!availableOperators?.length && operatorRole) {
            dispatch(fetchAvailableOperators({roleId: operatorRole}));
        }
    }, []);

    useEffect(() => {
        if (success) {
            dispatch(resetUpdateResponsibilityZoneUsersSuccess());
            onClose();
        }
    }, [success]);

    // Фильтрованный список операторов (при поиске)
    const [filteredOperators, setFilteredOperators] = useState<User[]>([]);
    useEffect(() => {
        if (availableOperators) {
            setFilteredOperators(availableOperators);
        }
    }, [availableOperators]);

    const searchUsersHandle = debounce((value: string) => {
        const newUsersList = availableOperators?.filter((user: User) => {
            return user.fullName?.toLowerCase()?.includes(lowerCase(value));
        });
        setFilteredOperators(newUsersList || []);
    }, 250);

    const onSubmit = (data: {[key: string]: boolean}) => {
        delete data["operators-search"]; // оставляем только id операторов

        const addedOperators = Object.keys(data).filter(key => data[key]).map(key => Number(key));
        const deletedOperators = Object.keys(data).filter(key => !data[key]).map(key => Number(key));

        if (activeZone) {
            dispatch(updateResponsibilityZoneUsers({
                zoneId: activeZone,
                data: {
                    usersToAdd: addedOperators,
                    usersToRemove: deletedOperators,
                },
            }));
        }
    };

    return <>
        {!loading && (
            <form className={styles["form"]} onSubmit={handleSubmit(onSubmit)}>
                <div className={styles["form-title"]}>
                    <div>Выбор операторов</div>
                    <div onClick={onClose} className={styles["form-title__icon"]}>
                        <CrossIcon width={16} height={16}/>
                    </div>
                </div>

                <TextFormInput
                    className={styles["operators__search"]}
                    name={"operators-search"}
                    control={control}
                    placeholder={t("common:search")}
                    onChange={searchUsersHandle}
                    componentType={ComponentType.Uncontrolled}
                    icon={
                        <SearchIcon
                            className={styles["operators__search-icon"]}
                            width={14}
                            height={14}
                        />
                    }
                />

                <SimpleBar style={{maxHeight: "400px", height: "100%", paddingRight: "20px"}}>
                    <div>
                        {filteredOperators?.map(item => (
                            <div key={item.id} className={styles["form__item"]}>
                                <Checkbox
                                    {...register(`${item.id}`)}
                                    defaultChecked={selectedOperators?.some(operator => operator.id === item.id)}
                                >
                                    {item?.fullName}
                                </Checkbox>
                            </div>
                        ))}
                    </div>
                </SimpleBar>
                <div className={styles["actions"]}>
                    <BaseButton
                        tag={"button"}
                        className={cn(modalStyles["actions__btn"], "btn--secondary", "btn--md", "w-100", "mt-4")}
                        type={"submit"}
                        disabled={!isDirty}
                    >
                        {loading ? <Spinner/> : "Сохранить"}
                    </BaseButton>
                </div>
            </form>
        )}
    </>;
};

export default React.memo(AddOperatorsForm);