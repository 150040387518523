/* eslint-disable max-len */
import React, {FC} from "react";

import {ISvgProps} from "../../types/common";

const TagsIcon:FC<ISvgProps> = ({
    width = 17,
    height = 16,
    ...rest
}) => (
    <svg {...rest} width={width} height={height} viewBox="0 0 17 16" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path fillRule="evenodd" clipRule="evenodd"
            d="M7.93294 1.33337H6.59961L6.19961 5.33337H3.39961L3.26628 6.66671H6.06627L5.79961 9.33337H2.73294L2.59961 10.6667H5.66627L5.26627 14.6667H6.59961L6.99961 10.6667H9.66627L9.26627 14.6667H10.5996L10.9996 10.6667H13.7996L13.9329 9.33337H11.1329L11.3996 6.66671H14.4663L14.5996 5.33337H11.5329L11.9329 1.33337H10.5996L10.1996 5.33337H7.53294L7.93294 1.33337ZM7.39961 6.66671L7.13294 9.33337H9.79961L10.0663 6.66671H7.39961Z"
            fill="currentColor"/>
    </svg>
);

export default TagsIcon;