import React, {FC, useEffect, useState} from "react";
import cn from "classnames";

import {IncidentEventPriority} from "../../../../models/IncidentEventPriority";
import {IncidentEventType} from "../../../../models/IncidentEventType";

import TypeIcon from "../../../Icons/TypeIcon";
import PriorityIcon from "../../../Icons/PriorityIcon";

import styles from "./IncidentTypeWithPriority.module.scss";

interface IIncidentTypeWithPriorityProps {
    type: IncidentEventType,
    // eslint-disable-next-line no-unused-vars
    updateActiveZonePriorityHandle: (type: IncidentEventType) => void,
}

const IncidentTypeWithPriority: FC<IIncidentTypeWithPriorityProps> = (
    {
        type,
        updateActiveZonePriorityHandle,
    }
) => {

    const [activePriority, setActivePriority] = useState<number | null | undefined>(null);

    useEffect(() => {
        setActivePriority(type?.incidentPriority);
    }, [type]);

    const priorityHandler = (priority: IncidentEventPriority) => {
        setActivePriority(priority);
        // Обновление приоритета текущей зоны
        updateActiveZonePriorityHandle({
            code: type?.code,
            incidentPriority: priority,
        });
    };

    return (
        <div className={styles["type"]}>
            <div className={styles["type-info"]}>
                <TypeIcon classname={styles["type__icon"]} type={type} width={31} height={31}/>
                <span className={styles["type__name"]}>{type.name}</span>
            </div>
            <div className={styles["type-priorities"]}>
                {[2, 1, 0].map(item => (
                    <div
                        key={item}
                        className={cn(styles["type-priorities__icon"],
                            {[styles["type-priorities__icon--active"]]: item === activePriority})}
                        onClick={() => priorityHandler(item)}
                    >
                        <PriorityIcon
                            priority={item}
                        />
                    </div>
                ))}
            </div>
        </div>
    );
};

export default IncidentTypeWithPriority;