import React, {useEffect} from "react";
import {useDispatch} from "react-redux";

import {getAccessToken} from "../../utils/auth";
import {TypedDispatch} from "../../types";
import {fetchRoadObjectListForIncidentsFiltered} from "../../redux/roadObjectSlice";

const ForTest = () => {
    const dispatch = useDispatch<TypedDispatch>();

    useEffect(() => {
        // eslint-disable-next-line max-len
        fetch(`${process.env.REACT_APP_BASE_HOST}${process.env.REACT_APP_BASE_API_URL}/RoadObject-List-ForIncidents?virtualSources=incCamera&mode=OR`, {
            headers: {
                Accept: "application/json",
                Authorization: "Bearer " + getAccessToken(),
            },
        })
            .then(response => response.json());

        dispatch(fetchRoadObjectListForIncidentsFiltered({
            currentFilters: "roadObjectsFilters",
        })); // Дорожные объекты
    }, []);

    return  <h2 className={"text-center mt-5"}>Test</h2>;
};

export default ForTest;