/* eslint-disable max-len */
import React from "react";

import {ISvgProps} from "../../../../types/common";

const EventProcessingIcon: React.FC<ISvgProps> = ({
    height = 24,
    width = 24,
    ...rest
}) => (
    <svg {...rest} width={width} height={height} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <rect x="1" y="1" width="22" height="22" rx="4" fill="currentColor"/>
        <path fillRule="evenodd" clipRule="evenodd" d="M12 19C8.13401 19 5 15.866 5 12C5 8.13401 8.13401 5 12 5C15.866 5 19 8.13401 19 12C19 15.866 15.866 19 12 19ZM12 17.25C14.8995 17.25 17.25 14.8995 17.25 12C17.25 9.1005 14.8995 6.75 12 6.75C9.1005 6.75 6.75 9.1005 6.75 12C6.75 14.8995 9.1005 17.25 12 17.25ZM11.1275 12.8736H14.6275V11.1236H12.878V8.49859H11.1275V12.8736Z" fill="white"/>
    </svg>
);

export default EventProcessingIcon;