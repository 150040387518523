import {mediaType} from "../types";
import {AnalyticsSectionEnum} from "../models/Analytics";

/* eslint-disable max-len */
export const ApiUrls = {
    TOKEN: "/Account/Token",
    REFRESH_TOKEN: "/Account/RefreshToken",
    LOGOUT: "/Account/Logout",
    USER_SETTINGS: "/user-settings",
    SETTINGS: "/settings",
    USER_SETTINGS_VIEW: "/user-settings/frontend-properties",
    INCIDENT_TYPES: "/incidents/incident-types",
    ADMIN_INCIDENT_TYPES: "/Admin/incident-types",
    ADMIN_INCIDENT_TYPE: (code: number) => `/Admin/incident-types/${code}`,
    VIOLATIONS: "/incidents/violation",
    RESPONSIBILITY_ZONES: "/responsibility-zones",
    RESPONSIBILITY_ZONE: (id: number) => `/responsibility-zones/${id}`,
    RESPONSIBILITY_ZONE_BY_COORD: ({lat, lon}: {
        lat: number,
        lon: number,
    }) => `/responsibility-zones/by-coordinates?lon=${lon}&lat=${lat}`,
    RESPONSE_SERVICES: "/response-services",
    ROUTES: "/publicTransport/route",
    LAST_EVENTS: "/incidents/events/last",
    SUMMARY_EVENTS: "/incidents/events/summary",
    EVENTS: "/incidents/events",
    INDICATORS: (zoneIds?: string[]) => `/incidents/events/indicators${zoneIds?.length ? "?ZoneIds=" + zoneIds : ""}`,
    INDENTS_ON_CONTROL: "/Incidents/events/tracked",
    INDENTS_FAVORITE: "/Incidents/events/favorite",
    EVENT: (id: string, includeHiddenEvents?: boolean) =>
        (`/incidents/events/${id}${includeHiddenEvents ? "?includeHiddenEvents=true" : ""}`),
    UPDATE_ADDITIONAL_INFO: (incidentId: string) => `/Incidents/events/${incidentId}/data`,
    EVENT_REOPEN: (id: string) => `/Incidents/events/${id}/reopen`,
    EVENT_UPDATE_INCIDENT_NAME: (id: string) => `/incidents/events/${id}/name`,
    EVENT_UPDATE_INCIDENT_LOCATION: (id: string) => `/Incidents/events/${id}/location`,
    EVENT_ASSIGNMENT: (id: string) => `/incidents/events/${id}/assignment`,
    EVENT_REJECTION: (id: string) => `/incidents/events/${id}/rejection`,
    EVENT_FINALIZATION: (id: string) => `/Incidents/events/${id}/finalization`,
    REMOVE_FAVORITE: (id: string) => `/Incidents/events/${id}/commands/remove-favorite`,
    SET_FAVORITE: (id: string) => `/Incidents/events/${id}/commands/set-favorite`,
    EVENT_HIDE: (id: string) => `/Incidents/events/${id}/commands/hide`,
    EVENT_UNHIDE: (id: string) => `/Incidents/events/${id}/commands/unhide`,
    EVENT_ADD_CAMERA: (id: string) => `/Incidents/events/${id}/commands/add-camera`,
    EVENT_REMOVE_CAMERA: (id: string) => `/Incidents/events/${id}/commands/remove-camera`,
    EVENT_COMMENTS: (id: string, showDeleted?: boolean) => `/incidents/events/${id}/comments` + (showDeleted ? "?ShowDeleted=true" : ""),
    EVENT_COMMENTS_ADD: (id: string, typeComments: string, showDeleted?: boolean) => `/incidents/events/${id}/comments/${typeComments}` + (showDeleted ? "?ShowDeleted=true" : ""),
    EVENT_COMMENTS_ADD_WITH_ATTACHMENTS: (id: string, typeComments: string) => `/incidents/events/${id}/comments-attachments/${typeComments}`,
    EVENT_COMMENTS_MEDIA: (incidentId: string) => `/Incidents/events/${incidentId}/comments/withmediatype`,
    EVENT_COMMENT_MEDIA_FILE: (incidentId: string, fileId: string) => `/Incidents/events/${incidentId}/attachments/${fileId}`,
    EVENT_NEW_COMMENT_ATTACHMENTS: (incidentId: string) => `/Incidents/events/${incidentId}/attachments`,
    EVENT_COMMENT_ATTACHMENTS: (incidentId: string, commentId: string) => `/Incidents/events/${incidentId}/comments/${commentId}/attachments`,
    COMMENT_ATTACHMENTS_TO_MEDIA: (incidentId: string) => `/Incidents/events/${incidentId}/attachments/copy-to-media`,
    EVENT_RESTORE_COMMENT: (incidentId: string, commentId: string) => `Incidents/events/${incidentId}/comments/operator/${commentId}/restore`,
    EVENT_DELETE_COMMENT: (commentId: string, incidentId: string, typeComments: string, showDeleted?: boolean) => (
        `/Incidents/events/${incidentId}/comments/${typeComments}/${commentId}` + (showDeleted ? "?returnDeleted=true" : "")
    ),
    EVENT_UPDATE_COMMENT: (incidentId: string, typeComments: string, commentId: string) => `/Incidents/events/${incidentId}/comments/${typeComments}/${commentId}`,
    EVENT_COMMENT_NOTIFY_RESPONSE_SERVICE: (incidentId: string, commentId: string) => `/Incidents/events/${incidentId}/comments/${commentId}/notify-response-service`,
    LAST_FIXATIONS: "/incidents/fixations/last",
    FIXATIONS: "/incidents/fixations",
    FIXATIONS_DOWNLOAD: "/incidents/fixations/download",
    EVENTS_DOWNLOAD: "/incidents/events/download",
    FIXATION: (id: string) => `/incidents/fixations/${id}`,
    ROAD_OBJECT_LIST_FOR_INCIDENTS: "/RoadObject-List-ForIncidents",
    HEATMAP_ROAD_OBJECTS: "/Incidents/events/heatMap",
    INCIDENTS_MEDIA_THUMB: (type: mediaType, incidentId?: string | null, mediaId?: string | null) => type === "incident"
        ? `/incidents/media/${incidentId}/thumb/${mediaId}`
        : `/incidents/${type}-media-thumb?${type}Id=${incidentId}&mediaId=${mediaId}`,
    INCIDENT_MEDIA: (type: mediaType, incidentId?: string | null, mediaId?: string | null, fullPath = false) => type === "incident"
        ? `/Incidents/media/${incidentId}/full/${mediaId}`
        : `${fullPath ? process.env.REACT_APP_BASE_API_URL : ""}/Incidents/${type}-media?${type}Id=${incidentId}&mediaId=${mediaId}`,
    USER_AVATAR: (id: number) => `/User/${id}/AvatarImage`,
    INCIDENT_TYPE_ICON: (type: string) => `/incidents/incident-types/${type}/icon`,
    SET_AGGREGATOR: (incidentId: string) => `/Incidents/events/${incidentId}/commands/set-aggregator`,
    REMOVE_AGGREGATOR: (incidentId: string) => `/Incidents/events/${incidentId}/commands/remove-aggregator`,
    TO_INCIDENT: (incidentId: string) => `/Incidents/events/${incidentId}/commands/to-incident`,
    TO_INCIDENTS: "/Incidents/events/commands/to-incident",
    TO_EVENT: (incidentId: string, masterIncidentId: string, incidentIds: string[]) =>
        `/Incidents/events/${incidentId}/commands/to-event?masterIncidentId=${masterIncidentId}${incidentIds && incidentIds.length ? incidentIds.map(id => `&incidentIds=${id}`).join("") : ""}`,
    SET_TRACKED: (incidentId: string) => `/Incidents/events/${incidentId}/commands/set-tracked`,
    REMOVE_TRACKED: (incidentId: string) => `/Incidents/events/${incidentId}/commands/remove-tracked`,
    ADD_MEDIA: (incidentId: string) => `/Incidents/events/${incidentId}/add-media`,
    REMOVE_MEDIA: (incidentId: string) => `/Incidents/events/${incidentId}/remove-media`,
    SET_MASTER_REPRESENTATION_EVENT: (incidentId?: string | null, repIncidentId?: string | null) => {
        if (repIncidentId) {
            return `Incidents/events/${incidentId}/commands/set-master-representation-event?representationIncidentId=${repIncidentId}`;
        } else {
            return `Incidents/events/${incidentId}/commands/set-master-representation-event`;
        }
    },
    GET_ROAD_OBJECT_CAMERA_CAPABILITIES: (roadObjectId: number) => `/RoadObjects/GetCameraCapabilities?roadObjectId=${roadObjectId}`,
    ROAD_OBJECTS_CAMERA_PTZ: (roadObjectId: number) => `/RoadObjects/CameraPtz?roadObjectId=${roadObjectId}`,
    CAMERAS_PRESETS: (cameraId: string | number) => `/cameras/${cameraId}/ptz/presets`,
    NEARBY_INCIDENTS: (incidentId: string) => `/Incidents/events/${incidentId}/nearby-incidents`,
    ENVIRONMENT: "/incidents/environments/environment.json",
    JOURNAL: "/Incidents/events/comments",
    EFFICIENCY_JOURNAL: "Incidents/roadobjects",
    GET_ROAD_OBJECT_TYPES: (id?: number) => "/RoadObjects/GetRoadObjectTypes" + (id ? (`?objectSourceId=${id}`) : ""),
    GET_ROLES: "/Incidents/roles",
    GET_USERS: "/Incidents/users",
    GET_OPERATORS: "/Incidents/users",
    CREATE_USER: "/Incidents/users/add",
    SESSION_INFO_DOWNLOAD: (id?: number) => `/Incidents/users/${id}/sessionInfo/download`,
    GET_USER: (id: string) => `/Incidents/users/${id}`,
    EDIT_USER: (id: number) => `/Incidents/users/${id}/update`,
    DELETE_USER: (id: string) => `/Incidents/users/${id}/delete`,
    CHECK_LOGIN_EXISTS: (login: string) => `/Account/CheckLoginExists?login=${login}`,
    UPDATE_USER_PASSWORD: (id: string) => `/Incidents/users/${id}/updatePassword`,
    GET_USER_SESSIONS: (id: string, count: number) => `/Incidents/users/${id}/sessionInfo?pageSize=${count}`,
    GET_USER_INDICATORS: (id: string, dateUnit: string) => `/Incidents/users/${id}/indicators?dateUnit=${dateUnit}`,
    GET_USER_COUNT_INCIDENTS: (id: string, dateUnit: string) => `/Incidents/users/${id}/countIncidents?dateUnit=${dateUnit}`,
    GET_USER_DASHBOARD_ANALYTICS: (userId: string | number) => `/Incidents/users/${userId}/statsProcessingTime`,
    GET_USER_HELPED: () => "/incidents/shares/summary",
    HANDOVER_EVENT: (incidentId: string) => `/Incidents/events/${incidentId}/shares`,
    TAKE_HANDOVER: (handoverId: string) => `/incidents/shares/${handoverId}/acceptance`,
    REJECT_HANDOVER: (handoverId: string) => `/incidents/shares/${handoverId}/rejection`,
    GET_SERVICE_DASHBOARD_ANALYTICS: () => "/Incidents/users/statsProcessingTime",
    ROAD_OBJECT_INFO: (roadObjectId: number) => `/Incidents/roadobject?roadObjectId=${roadObjectId}`,
    TIME_INDICATORS: (days: string | null, time: string, onlyWithNested: boolean) => `/Incidents/events/indicators/mini?date.days=${days}&date.time=${time}&groupByRangeOfDays=true&onlyWithNested=${onlyWithNested}`,
    SERVICE_TICKET: (RoadObjects?: number[]) => "/Service/ticket" + (RoadObjects?.length ? `?RoadObjects=${RoadObjects.join(",")}` : ""),
    SERVICE_TICKET_DOWNLOAD: (RoadObjects?: number[]) => "/Service/ticket/download" + (RoadObjects?.length ? `?RoadObjects=${RoadObjects.join(",")}` : ""),
    CREATE_INCIDENT: "/Incidents/incident",
    ADDRESS_AUTOCOMPLETE: (searchText: string) => `/nom/search.php?q=${searchText}&polygon_geojson=1&format=jsonv2&viewbox=38.09008,56.022171,37.31926,55.4907&bounded=1`,
    TWO_GIS_SEARCH: (searchText: string) => `https://catalog-test.map-moscowapp.mos.ru/3.0/items?q=${searchText}&sort_point=37.385366%2C55.584062&fields=items.point,items.full_name&key=578c1215-2594-4d6a-9035-f7f9c751f0f6`,
    TWO_GIS_REVERSE_GEOCODE: (lat: number, lon: number) => `https://catalog-test.map-moscowapp.mos.ru/3.0/items/geocode?point=${lon},${lat}&fields=items.point,items.full_name&type=adm_div.city,adm_div.region,adm_div.settlement,road,adm_div.district_area,adm_div.division,adm_div.district,street,adm_div.living_area,crossroad,building,adm_div.place,gate&key=578c1215-2594-4d6a-9035-f7f9c751f0f6`,
    CREATE_ROUTE: "/map-routing/route",
    ORGANIZATIONS: "/Admin/organization",
    ORGANIZATION: (id: string | number) => `/Admin/organization/${id}`,
    EVENT_DELETE_ATTACHMENT: (incidentId: string, fileId: string) => `/Incidents/events/${incidentId}/attachments/${fileId}`,
    ROAD_OBJECT_ADD: (name: string, roadObjectTypeId: number) => `/Admin/RoadObjectAdd?name=${name}&roadObjectTypeId=${roadObjectTypeId}`,
    ROAD_OBJECT_UPDATE: "/RoadObject/Update",
    ROAD_OBJECT_ADMIN_DELETE: (id: number, objectSourceId: number) => `/Admin/RoadObjectDelete?id=${id}&objectSourceId=${objectSourceId}`,
    ROAD_OBJECT_ADMIN_UPDATE: "/Admin/RoadObjectUpdate",
    ROAD_OBJECT_ADMIN_UPDATE_PROPERTIES: "/Admin/forms/road_object/additional_properties",
    ADMIN_RESPONSIBILITY_ZONES: "/Admin/responsibility-zones",
    ADMIN_RESPONSIBILITY_ZONE: (id: number) => `/Admin/responsibility-zones/${id}`,
    RESPONSIBILITY_ZONES_ROAD_OBJECTS: (id: number) => `/Admin/responsibility-zones/${id}/road-objects`,
    RESPONSIBILITY_ZONES_ROAD_OBJECTS_ATTACHING: (id: number) => `/Admin/responsibility-zones/${id}/road-objects/attaching`,
    RESPONSIBILITY_ZONES_ROAD_OBJECTS_DETACHING: (id: number) => `/Admin/responsibility-zones/${id}/road-objects/detaching`,
    UPDATE_RESPONSIBILITY_ZONES_USERS: (mode?: string) => `/Incidents/users/updateResponsibilityZones?mode=${mode ?? ""}`,
    RESPONSIBILITY_ZONE_UPDATE_USERS: (zoneId: number) => `/Incidents/responsibility-zone/${zoneId}/update-users`,
    RESPONSIBILITY_ZONES_UPDATE_PRIORITIES: (zoneIds: number[]) => "/Incidents/responsibility-zones/update-priorities" + (zoneIds?.length ? `?ids=${zoneIds.join(",")}` : ""),
    INCIDENT_DICTIONARIES: (type?: string) => `/incidents/dictionaries?Type=${type ?? ""}`,
    CAMERA_SETTINGS: (id: number) => `/incidents/dictionaries/${id}/cameraSettings`,
    INCIDENT_DICTIONARY: (id: number) => `/incidents/dictionaries/${id}`,
    INCIDENT_DICTIONARY_OFF_DISABLING: (id: number, incidentTypeCode: string, segmentId: number) => `/incidents/dictionaries/${id}/prioritySetting?${incidentTypeCode ? "&incidentTypeCode=" + incidentTypeCode : ""}${segmentId ? "&segmentId=" + segmentId : ""}`,
    INCIDENT_DICTIONARY_UPDATE_DISABLING: (id: number, segmentId: number, validUntilUtc: string, incidentTypeCode: string) => `/incidents/dictionaries/${id}/prioritySetting?${segmentId ? "&segmentId=" + segmentId : ""}${validUntilUtc ? "&validUntilUtc=" + validUntilUtc : ""}${incidentTypeCode ? "&incidentTypeCode=" + incidentTypeCode : ""}`,
    TAGS: "/incidents/tags",
    TAG: (id: number) => `/incidents/tags/${id}`,
    UPDATE_INCIDENT_TAGS: (id: number) => `/incidents/events/${id}/tags`,
    CAMERAS_VIDEO_STREAM: (cameraId: number) => `/cameras/${cameraId}/video-stream`,
    GET_MAP_KSODD_TOKEN: "/mos-ru/token",

    // FavoriteFolders
    FAVORITE_FOLDERS: "/incidents-folders",
    FAVORITE_FOLDERS_ID: (id: number) => `/incidents-folders/${id}`,
    FAVORITE_FOLDERS_ID_USERS: (id: number) => `/incidents-folders/${id}/users`,
    FAVORITE_FOLDERS_ID_INCIDENTS: (id: number) => `/incidents-folders/${id}/incidents`,
    FAVORITE_FOLDERS_ID_INCIDENTS_ID: (folderId: number, incidentId: string) => `/incidents-folders/${folderId}/incidents/${incidentId}`,

    SHARED_EVENTS: {
        EVENTS: "/incidents/shares",
        ACCEPTANCE: (id: string) => `/incidents/shares/${id}/acceptance`,
        REJECTION: (id: string) => `/incidents/shares/${id}/rejection`,
        SUMMARY: "/incidents/shares/summary",
    },

    // Analytics
    ANALYTICS: {
        INCIDENTS: "/incidents/analytics/reports/incidents", // Формирование отчета по инцидентам. Применяется на страницах: Овервью, События и инциденты
        LIST_INCIDENTS: "/incidents/analytics/reports/listIncidents", // Формирование отчета Список инцидентов. Применяется на страницах: События и инциденты
        WEEKLY_INCIDENTS_BY_HOUR: "/incidents/analytics/reports/weeklyIncidentsByHour", // Инциденты Неделя по часам. Применяется на страницах: События и инциденты
        SUMMARY: "/incidents/analytics/reports/summary", // Формирование отчета Сводка за X дней. Применяется на страницах: Овервью
        INCIDENTS_BY_ZONE: "/incidents/analytics/reports/incidentsByZone", // Формирование отчета Инциденты по зонам. Применяется на страницах: Овервью, События и инциденты
        SERVICES: "/incidents/analytics/reports/services", // Формирование отчета Службы. Применяется на страницах: Овервью, Сит-центр и службы, Аналитика по инциденту
        PROCESS_CENTER_WORKFLOW: "/incidents/analytics/reports/processCenterWorkflow", // Формирование отчета Работа Сит-центра или Этапы. Применяется на страницах: Овервью, Сит-центр и службы, Аналитика по инциденту
        STRING_EVENTS_AND_INCIDENTS: "/incidents/analytics/reports/stringEventsAndIncidents", // Формирование отчета Строка Событий и инциденты. Применяется на страницах: Овервью, События и инциденты
        MAP_EVENTS_AND_INCIDENTS: "/incidents/analytics/reports/mapEventsAndIncidents", // Формирование отчета Карта Событий и инциденты. Применяется на страницах: События и инциденты
        PRIORITY_NUMBERS: "/incidents/analytics/reports/priorityNumbers", // Формирование отчета Цифры Приоритет. Применяется на страницах: События и инциденты, Сит-центр и службы
        TIME_PRIORITY: "/incidents/analytics/reports/timePriority", // Формирование отчета Время Приоритет. Применяется на страницах: События и инциденты, Сит-центр и службы
        TYPES: "/incidents/analytics/reports/types", // Формирование отчета Типы. Применяется на страницах: События и инциденты
        TAGS: "/incidents/analytics/reports/tags", // Формирование отчета Теги. Применяется на страницах: События и инциденты
        REACTION_OPERATORS: "/incidents/analytics/reports/reactionOperators", // Формирование отчета Операторы: реакция. Применяется на страницах: События и инциденты
        REACTION_ZONES: "/incidents/analytics/reports/reactionZones", // Формирование отчета Зоны: реакция. Применяется на страницах: События и инциденты
    },

    // ТОИ
    TOI: {
        INCIDENT: (boardId: string) => `/incidents/toi/boards/${boardId}/incident`, // Получить/Создать/Обновить/Удалить инцидент по идентификатору табло
        // Сгенерировать примеры конечных изображений. Изображение инцидента + 5 УДЗ.
        // Если дистанция до точки инцидента больше 2000 метров, изображения УДЗ не будут показывать перекрытия.
        GENERATE: (boardId: string) => `/incidents/toi/boards/${boardId}/incident/generate`,
        MODE: (boardId: string) => `/incidents/toi/boards/${boardId}/incident/mode`, // Активировать или деактивировать контент инцидента
        IMAGES_BY_BORD_ID: (boardId: string) => `/incidents/toi/images/${boardId}`, // Получить изображение динамического контента
        IMAGES: () => "/incidents/toi/images", // Получить изображение динамического контента по номеру табло (не id)
        IMAGES_udz: () => "/incidents/toi/images/udz", // Получить изображение для УДЗ по номеру

        // Очереди
        // Получение/добавление/изменение/удаление
        QUEUE: (boardId: string, incidentId?: string) => `/incidents/toi/queue/${boardId}?${incidentId ? "incidentId=" + incidentId : ""}`,
        // Назначение приоритетного контента
        QUEUE_INCIDENT_PRIORITY: (boardId: string, incidentId?: string) => `/incidents/toi/queue/${boardId}/priority?incidentId=${incidentId}`,
    },
};

export const AppUrls = {
    HOME: "/",
    ERROR: "/:errorCode",
    ERROR_NOT_FOUND: "*",
    AUTH: "/auth",
    FORBIDDEN: "/forbidden",
    DASHBOARD: "/dashboard",
    ANALYTICS: "/analytics",
    ANALYTICS_OVERVIEW: `/analytics/${AnalyticsSectionEnum.OVERVIEW}`,
    ANALYTICS_EVENTS_AND_INCIDENTS: `/analytics/${AnalyticsSectionEnum.EVENTS_AND_INCIDENTS}`,
    ANALYTICS_SERVICES: `/analytics/${AnalyticsSectionEnum.SERVICES}`,
    ANALYTICS_CARD: "/analytics/card",
    ANALYTICS_CARD_ID: "/analytics/card/:id",
    //ANALYTICS_EXPORT_DATA: `/analytics/${AnalyticsSectionEnum.EXPORT_DATA}`,
    DASHBOARD_SCHEME: "/dashboard/scheme",
    DEMONSTRATION: "/demonstration",
    EVENT_ARCHIVE: "/event-archive",
    EVENT: "/event-archive/:eventId",
    JOURNAL: "/journal",
    EFFICIENCY_JOURNAL: "/efficiency-journal",
    EFFICIENCY_JOURNAL_CAMERA_PAGE: "/efficiency-journal/:cameraId",
    EFFICIENCY_TICKETS: "/efficiency-tickets",
    SUPERADMIN: "/superadmin",
    OPERATOR_CARD: "/superadmin/operator/:operatorId",
    FIXATIONS_ARCHIVE: "/fixations-archive",
    FIXATION: "/fixations-archive/:fixationId",
    PROTV_VIEWER: "/pro-tv-viewer",
    INCIDENTS_ON_CONTROL: "/incidents-on-control",
    SETTINGS_VIEW: "/settings/view",
    SETTINGS_ACCOUNTS: "/settings/accounts",
    SETTINGS_ACCOUNT: "/settings/accounts/:operatorId",
    SETTINGS_ZONES: "/settings/zones",
    SETTINGS_SYSTEM: "/settings/system",
    FOR_TEST: "/for_test",
    VIDEO_ARCHIVE: (roadObjectId: number | null) => `${process.env.REACT_APP_BASE_HOST}/video-archive?id=${roadObjectId ?? 0}&useToken=true`,
    OPERATOR_GUIDE: "/docs/operator_guide.pdf",
    ADMIN_GUIDE: "/docs/admin_guide.pdf",
};

export const mapThemeUrls = {
    light: `${process.env.PUBLIC_URL}/style-light.json${window.location.port ? "?:" + window.location.port : ""}`,
    dark: `${process.env.PUBLIC_URL}/style-dark.json${window.location.port ? "?:" + window.location.port : ""}`,
};

export const mapTrafficUrl = `${window.location.origin}/megapolis-tiles/traffic`;
export const mapKsoddUrl = `${window.location.origin}/ksodd-tiles`;