import React, {FC, ReactElement, useEffect, useState} from "react";
import cn from "classnames";
import {useTranslation} from "react-i18next";
import {NavLink} from "react-router-dom";
import {useSelector} from "react-redux";
import {AxiosResponse} from "axios";

import {Permission} from "models/Permission";
import {isHeaderCollapsedSelector, isSidebarShowSelector} from "../../redux/commonSlice";
import {userPermissionsSelector} from "../../redux/authSlice";
import {isGrantedAccess} from "../../utils/permissionsHelper";
import createHttpRequest from "../../utils/http";

import {routes} from "../../Router";
import LightTooltip from "../UI/Tooltip/LightTooltip";
import DashboardChat from "../Dashboard/DashboardChat/DashboardChat";
import DashboardRocketChat from "../Dashboard/DashboardRocketChat/DashboardRocketChat";

import styles from "./Sidebar.module.scss";

interface IMenuItemProps {
    path: string,
    title: string,
    icon: ReactElement,
    auth?: Permission[],
}

interface ISidebar {
    isDemonstrationDashboard?: boolean,
}

const Sidebar: FC<ISidebar> = ({isDemonstrationDashboard}) => {
    const {t} = useTranslation("common");
    const userPermissions = useSelector(userPermissionsSelector);
    const isSidebarShow = useSelector(isSidebarShowSelector);
    const isHeaderCollapsed = useSelector(isHeaderCollapsedSelector);

    const menu = routes.filter(item => item.title && item.icon) as IMenuItemProps[];

    const [isChatOpen, setIsChatOpen] = useState(false);
    const [chatToken, setChatToken] = useState<string | null>(null);

    const handleChatToggle = () => {
        setIsChatOpen(!isChatOpen);
    };

    useEffect(() => {
        createHttpRequest({
            method: "GET",
            path: "/rocketchat/token",
        })
            .then((response: AxiosResponse<{token: string, userId: string}>) => {
                if (response.status === 200) {
                    setChatToken(response.data.token);
                }
            });
    }, []);

    const [unreadMessagesCount, setUnreadMessagesCount] = useState<number>(0);

    return (
        <div className={cn(
            styles["sidebar"],
            {[styles["sidebar--show"]]: isSidebarShow},
            isDemonstrationDashboard && styles["sidebar--demonstration"],
            isHeaderCollapsed && styles["sidebar--hidden"]
        )}>
            {
                menu.map(item => isGrantedAccess(userPermissions, item.auth)
                    ? <NavLink
                        to={item.path}
                        key={item.path}
                        className={({isActive}) => cn(
                            styles["sidebar__item"],
                            {[styles["sidebar__item--active"]]: isActive},
                            {[styles["sidebar__item--has-notifications"]]: false}
                        )}
                    >
                        <LightTooltip title={t(item.title)} placement="right">
                            <div className={styles["sidebar__item-icon"]}>
                                {item.icon}
                            </div>
                        </LightTooltip>
                    </NavLink>
                    : null)
            }
            <div className={styles["chat"]}>
                <DashboardRocketChat
                    closeHandler={() => setIsChatOpen(false)}
                    chatToken={chatToken}
                    isChatOpen={isChatOpen}
                    setUnreadMessagesCount={setUnreadMessagesCount}
                />
                <DashboardChat onClick={handleChatToggle} unreadMessagesCount={unreadMessagesCount}/>
            </div>
        </div>
    );
};

export default React.memo(Sidebar);