import {IEventsFilters, IArchiveAnalyticsFilters} from "../redux/eventSlice";
import {formatDateForArchiveAnalytics} from "./formatDateForArchiveAnalytics";

/**
 * Трансформация фильтров для блока аналитики в архиве
 * @param {IEventsFilters} filters - Фильтры Архива
 * @return {IArchiveAnalyticsFilters} analyticsFilters - Фильтры Аналитики
 */
export const transformFiltersToAnalytics = (filters: IEventsFilters): IArchiveAnalyticsFilters => {
    const archiveAnalyticsFilters: IArchiveAnalyticsFilters = {
        createdDateTime: {
            lowerBound: formatDateForArchiveAnalytics(filters.sinceDay, false),
            upperBound: formatDateForArchiveAnalytics(filters.untilDay, true),
            excludingUpperBound: true,
        },
        kind: filters.onlyWithNested ? ["Event"] : ["Incident"],
        tracked: filters.tracked,
        favorite: filters.favorite,
        priorities: filters.eventPriority.length > 0 ? filters.eventPriority.map(Number) : undefined,
        tags: filters.tags.length ? filters.tags.map(tag => Number(tag)) : undefined,
        tagsMode: filters.modeTags,
        roadObjectsIds: filters.roadObjectIds.length ? filters.roadObjectIds.map(Number) : undefined,
        zones: filters.zoneIds.length ? filters.zoneIds.map(Number) : undefined,
        sourcesTags: filters.incidentSource.length ? filters.incidentSource.map(source => Number(source)) : undefined,
        types: filters.incidentTypeCodes.length ? filters.incidentTypeCodes : undefined,
    };

    if (filters.incidentSubtypeCodes) {
        archiveAnalyticsFilters.types = [...(archiveAnalyticsFilters.types || []), filters.incidentSubtypeCodes];
    }

    return archiveAnalyticsFilters;
};