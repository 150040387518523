import React, {FC, useEffect, useRef, useState} from "react";
import {ClickAwayListener, Box, Popper} from "@mui/material";
import cn from "classnames";
import {useDispatch, useSelector} from "react-redux";

import {ISortConfig, useSortingData} from "../../../utils/customHooks";
import {RowFactory} from "../../../utils/tableHelper";
import {responsibilityZonesBgColorHashMapSelector} from "../../../redux/responsibilitySlice";
import {TypedDispatch} from "../../../types";
import {segmentsGroupsSelector} from "../../../redux/disablingCameraIncidentsSlice";
import {
    getSharedEvents,
    sharedEventsSelector,
    sharedEventsStatusSelector,
} from "../../../redux/eventSharedSlice";
import {selectedLastEventSelector, setSelectedLastIncidentLocation} from "../../../redux/eventSlice";

import ArrowTriangularDownIcon from "../../Icons/ArrowTriangularDownIcon";
import RefreshArrowsIcon from "../../Icons/RefreshArrowsIcon";
import TableWithVirtualScroll from "../../UI/Table/TableWithVirtualScroll";
import LastIncidentEventModel from "../../../models/LastIncidentEventModel";
import DashboardEventsRow from "../../Dashboard/DashboardEvents/DashboardEventsTable/DashboardEventsRow";

import dashboardEventsTableStyles
    from "../../Dashboard/DashboardEvents/DashboardEventsTable/DashboardEventsTable.module.scss";
import styles from "./HeaderSharedIncidents.module.scss";

const sortConfigInit: ISortConfig<LastIncidentEventModel> = {direction: "asc", field: "beginDateTime"};

interface IHeaderSharedIncidentsProps {
    minRowHeight?: number,
}

const HeaderSharedIncidents: FC<IHeaderSharedIncidentsProps> = ({
    minRowHeight,
}) => {
    const dispatch = useDispatch<TypedDispatch>();

    const [anchorEl, setAnchorEl] = useState<HTMLDivElement | null>(null);

    const zoneBgColorHashMap = useSelector(responsibilityZonesBgColorHashMapSelector);
    const selectedEvent = useSelector(selectedLastEventSelector);
    const loading = useSelector(sharedEventsStatusSelector) === "loading";
    const segments = useSelector(segmentsGroupsSelector);
    const sharedEvents = useSelector(sharedEventsSelector);

    const wrapperRef = useRef<HTMLDivElement | null>(null);

    const open = Boolean(anchorEl);

    const tableCols = ["beginDateTime", "place", "deviceType", "incident", "photo", "headerCustomHide"];
    const sortableTableCols = ["beginDateTime"];

    const {sortedItems, sortConfig, sortRequest} =
        useSortingData<LastIncidentEventModel>(sharedEvents, sortConfigInit);

    useEffect(() => {
        handleFetchEvents();
    }, []);

    const handleClick = (event: React.MouseEvent<HTMLDivElement>) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const handleRefresh = (e: React.MouseEvent) => {
        e.stopPropagation();
        handleFetchEvents();
    };

    const handleFetchEvents = () => {
        dispatch(getSharedEvents());
    };

    const zoomMapOnEventHandle = (geoPoints: any, incident: any) => {
        const {longitude, latitude} = geoPoints;
        dispatch(setSelectedLastIncidentLocation({
            coordinates: [longitude, latitude],
            incident: incident,
        }));
    };

    const rowFactory: RowFactory<LastIncidentEventModel> = (item, isLoading, idx) => (
        <DashboardEventsRow
            event={item}
            key={`last_event_${item?.id ?? idx}`}
            loading={loading}
            zoneColor={item?.zoneId ? zoneBgColorHashMap?.get(item?.zoneId) : ""}
            isActive={(item?.id ?? "") === selectedEvent?.id}
            zoomMapOnEventHandle={zoomMapOnEventHandle}
            segments={segments}
        />
    );

    return <>
        {sortedItems?.length > 0 && (
            <div className={styles["container-wrapper"]} ref={wrapperRef}>
                <div
                    className={cn(
                        styles["container"],
                        open && styles["container--opened"]
                    )}
                    onClick={handleClick}
                >
                    <div className={cn(
                        styles["refresh"],
                        loading && styles["refresh--refreshing"]
                    )} onClick={handleRefresh}>
                        <RefreshArrowsIcon/>
                    </div>

                    <div className={styles["container__title"]}>Переданные инциденты</div>

                    <div className={styles["container__counter"]}>
                        <div className={styles["container__counter-number"]}>{sharedEvents.length}</div>
                    </div>

                    <ArrowTriangularDownIcon
                        className={cn(
                            styles["arrow"],
                            open && styles["arrow--opened"]
                        )}
                    />
                </div>
                <Popper
                    open={open}
                    anchorEl={anchorEl}
                    placement={"bottom-start"}
                    style={{zIndex: 20}}
                >
                    <ClickAwayListener onClickAway={handleClose}>
                        <Box>
                            <div className={cn(styles["container-wrapper__dropdown"], styles["dropdown"])}
                                style={{width: 750}}
                            >
                                <TableWithVirtualScroll
                                    cols={tableCols}
                                    sortableCols={sortableTableCols}
                                    items={sortedItems}
                                    isLoading={loading}
                                    rowFactory={rowFactory}
                                    handleSort={sortRequest}
                                    sort={sortConfig}
                                    customStyles={dashboardEventsTableStyles["table"]}
                                    columnsTranslationNamespace={"dashboard"}
                                    minRowHeight={minRowHeight || 64}
                                    totalItems={sortedItems.length}
                                    visibleItemsCount={6}
                                    buffer={6}
                                    simpleBarStyles={{
                                        height:
                                            sortedItems.length * (minRowHeight || 64) + 40 > 400
                                                ? 400
                                                : sortedItems.length * (minRowHeight || 64) + 40,
                                    }}
                                />
                            </div>
                        </Box>
                    </ClickAwayListener>
                </Popper>
            </div>
        )}
    </>;
};
export default HeaderSharedIncidents;
