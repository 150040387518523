import React, {FC} from "react";
import cn from "classnames";

import Input from "../Input/Input";

import {CheckboxContainer} from "./styled/CheckboxContainer";
import styles from "./Checkbox.module.scss";

interface ICheckboxProps {
    className?: string,
    ref?: React.Ref<HTMLInputElement>,
    name: string,
    label?: string,
    children?: any,
    bgColor?: string,
    bgCheckedColor?: string,
    checked?: boolean,
    defaultChecked?: boolean,
    onChange?: React.ChangeEventHandler<HTMLInputElement>,
    onClick?: React.MouseEventHandler<HTMLInputElement>,
    disabled?: boolean,
    readOnly?: boolean,
}

const Checkbox: FC<ICheckboxProps> = React.forwardRef<HTMLInputElement, React.PropsWithChildren<ICheckboxProps>>(
    (props, ref) => {
        const Component: FC<ICheckboxProps> = ({
            className,
            label,
            bgColor,
            bgCheckedColor,
            children,
            onChange,
            onClick,
            defaultChecked,
            disabled,
            readOnly = false,
            ...props
        }) => (
            <CheckboxContainer $bgColor={bgColor} $bgCheckedColor={bgCheckedColor} className={"checkbox"}>
                <label className={cn(styles["custom-checkbox"], className)}>
                    <Input
                        type="checkbox"
                        {...props}
                        ref={ref}
                        defaultChecked={defaultChecked}
                        onChange={onChange}
                        disabled={disabled}
                        readOnly={readOnly}
                        onClick={onClick}
                    />
                    <span className={styles["custom-checkbox__inner"]}>
                        {label || children}
                    </span>
                </label>
            </CheckboxContainer>
        );

        return <Component {...props}/>;
    }
);

Checkbox.displayName = "Checkbox";

export default React.memo(Checkbox);