import {createAsyncThunk, createSelector, createSlice} from "@reduxjs/toolkit";
import {AxiosResponse} from "axios";
import {RootState} from "store";

import createHttpRequest from "../utils/http";
import {ApiUrls} from "../constants/urls";
import {IDictionaries, IDictionary} from "./commentsSlice";
import {IIncidentTypeWithSubType, IncidentEventType} from "models/IncidentEventType";

interface IInitialState {
    incidentTypes: IncidentEventType[],
    typesWithSubTypes: IDictionary<IIncidentTypeWithSubType>[],
    adminIncidentTypes: IncidentEventType[],
    isLoading: boolean,
    isTypesWithSubTypes: boolean,
}

const initialState: IInitialState = {
    incidentTypes: [],
    typesWithSubTypes: [],
    adminIncidentTypes: [],
    isLoading: false,
    isTypesWithSubTypes: false,
};

export const fetchIncidentTypes = createAsyncThunk<IncidentEventType[]>("incidentType/collection", async() => {
    const response = await createHttpRequest({
        method: "GET",
        path: ApiUrls.INCIDENT_TYPES,
        errorMessage: "messages:fetch_incident_types_error",
    });

    return response.data;
});

export const fetchTypesWithSubTypes = createAsyncThunk<IDictionaries<IIncidentTypeWithSubType>>(
    "incidentSubType/collection", async() => {
        const response = await createHttpRequest({
            method: "GET",
            path: ApiUrls.INCIDENT_DICTIONARIES("INCIDENTS_TYPESUBTYPE.1"),
            errorMessage: "messages:fetch_incident_subtypes_error",
        }) as AxiosResponse<IDictionaries<IIncidentTypeWithSubType>>;

        return response.data;
    }
);

export const fetchAdminIncidentTypes = createAsyncThunk<IncidentEventType[]>("adminIncidentType/collection",
    async() => {
        const response = await createHttpRequest({
            method: "GET",
            path: ApiUrls.ADMIN_INCIDENT_TYPES,
            errorMessage: "messages:fetch_incident_types_error",
        });

        return response.data;
    });

const incidentType = createSlice({
    reducers: {
        updateIncidentTypesList(state, {payload}) {
            state.adminIncidentTypes = state.adminIncidentTypes.map(type => {
                if (type.code === payload.code) {
                    return {...type, incidentPriority: payload.priority};
                } else {
                    return type;
                }
            });
        },
    },
    name: "incidentType",
    initialState,
    extraReducers: (builder) => {

        builder
            .addCase(fetchTypesWithSubTypes.pending, (state) => {
                state.isTypesWithSubTypes = true;
            })

            .addCase(fetchTypesWithSubTypes.fulfilled, (state, {payload}) => {
                state.typesWithSubTypes = payload.Result;
                state.isTypesWithSubTypes = false;
            })

            .addCase(fetchTypesWithSubTypes.rejected, (state) => {
                state.isTypesWithSubTypes = false;
            });

        builder
            .addCase(fetchIncidentTypes.pending, (state) => {
                state.incidentTypes = [];
                state.isLoading = true;
            })
            .addCase(fetchIncidentTypes.fulfilled, (state, {payload}) => {
                state.incidentTypes = payload;
                state.isLoading = false;
            })
            .addCase(fetchIncidentTypes.rejected, (state) => {
                state.incidentTypes = [];
                state.isLoading = false;
            });

        builder
            .addCase(fetchAdminIncidentTypes.pending, (state) => {
                state.adminIncidentTypes = [];
                state.isLoading = true;
            })
            .addCase(fetchAdminIncidentTypes.fulfilled, (state, {payload}) => {
                state.adminIncidentTypes = payload;
                state.isLoading = false;
            })
            .addCase(fetchAdminIncidentTypes.rejected, (state) => {
                state.adminIncidentTypes = [];
                state.isLoading = false;
            });
    },
});

export const {updateIncidentTypesList} = incidentType.actions;

export default incidentType.reducer;

//Селекторы
const slice = ({incidentTypeReducer}: RootState) => incidentTypeReducer;

export const incidentTypesSelector = createSelector(
    slice,
    ({incidentTypes}) => [...incidentTypes].sort((a, b) => {
        if (!a.name || !b.name) return 0;
        return a.name.localeCompare(b.name);
    })
);

export const incidentTypesForManualCreationSelector = createSelector(
    slice,
    ({incidentTypes}) =>
        incidentTypes.filter(item => item.viewJson?.forManualCreating)
);

export const adminIncidentTypesSelector = createSelector(
    slice,
    ({adminIncidentTypes}) => adminIncidentTypes
);

export const isIncidentTypesLoadingSelector = createSelector(
    slice,
    ({isLoading}) => isLoading
);

export const typesWithSubTypesSelector = createSelector(
    slice,
    ({typesWithSubTypes}) => typesWithSubTypes
);

export const isTypesWithSubTypesLoadingSelector = createSelector(
    slice,
    ({isTypesWithSubTypes}) => isTypesWithSubTypes
);
