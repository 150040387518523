import {createAsyncThunk, createSelector, createSlice} from "@reduxjs/toolkit";
import {toast} from "react-toastify";
import {AxiosResponse} from "axios";

import {RootState} from "../store";
import createHttpRequest from "../utils/http";
import {ApiUrls} from "../constants/urls";
import {OperatorSession, OperatorSessionData} from "../models/OperatorSession";
import {DEFAULT_PAGE_SIZE, DEFAULT_USERS_SORT, MONTH_NAMES} from "../constants/common";
import Pagination from "../models/Pagination";
import {buildQuery} from "../utils/query";
import {ICreateUserForm, User} from "models/User";
import CollectionWithPagination from "../models/CollectionWithPagination";

export interface IUsersFilters {
    ZoneIds: number[],
    roleIds: number[],
    UserFullName: string | null,
}

interface IUsersSort {
    field: "userId" | "organizationName" | "roles",
    direction: "desc" | "asc",
}

interface IUsersQueryParams extends IUsersFilters {
    page?: number | null,
    pageSize: number,
    sort: string,
}

export interface IRole {
    DisplayName: string,
    Id: number,
    Name: string,
}

interface IFetchRolesResponse {
    Data: IRole[],
    IsSuccess: boolean,
    Message: string,
}

interface ResponsibilityZone {
    code: number,
    name: string | null,
    cameraCount: number | null,
    mapRoadObjectId: number | null,
    viewJson: {[key: string]: string},
}

interface IChartsIndicatorsArgs {
    id: string,
    dateUnit: string,
}

export interface IChartsIndicator {
    dateUnit: number | string,
    incidentAllCount: number,
    incidentRejectedCount: number,
    incidentResolvedCount: number,
}

interface IChartsIndicators {
    byDateUnit: IChartsIndicator[],
    dateUnit: string,
}

interface ICountIncidents {
    id: number,
    countIncidentRejected: number,
    countIncidentResolved: number,
    countIncidentAll: number,
    countSession: number,
}

interface ICheckLoginExistResponse {
    Data: boolean,
    IsSuccess: boolean,
    Message: string,
}

export interface IInitialState {
    users: User[],
    usersLoading: boolean,
    user: User | null,
    indicators: IChartsIndicator[] | null,
    indicatorsLoading: boolean,
    loading: boolean,
    success: any,
    requestError: any,
    passwordSuccess: any,
    countIncidents: ICountIncidents | null,
    countIncidentsLoading: boolean,
    sessions: OperatorSessionData[] | [],
    sessionsLoading: boolean,
    requestLoading: boolean,
    roles: IRole[] | null,
    rolesLoading: boolean,
    responsibilityZones: ResponsibilityZone[],
    filters: IUsersFilters,
    pagination: Pagination,
    sessionsPagination: Pagination,
    isUniqueLogin: boolean,
}

const initialState: IInitialState = {
    users: [],
    usersLoading: false,
    user: null,
    indicators: null,
    indicatorsLoading: false,
    loading: false,
    success: null,
    requestError: null,
    passwordSuccess: false,
    countIncidents: null,
    countIncidentsLoading: false,
    sessions: [],
    sessionsLoading: false,
    sessionsPagination: {
        page: 1,
        firstPage: true,
        lastPage: true,
        pageSize: DEFAULT_PAGE_SIZE,
        totalElements: 0,
        totalPages: 1,
    },
    requestLoading: false,
    roles: null,
    rolesLoading: false,
    responsibilityZones: [],
    filters: {
        ZoneIds: [],
        roleIds: [],
        UserFullName: "",
    },
    pagination: {
        page: 1,
        firstPage: true,
        lastPage: true,
        pageSize: DEFAULT_PAGE_SIZE,
        totalElements: 0,
        totalPages: 1,
        sort: DEFAULT_USERS_SORT,
    },
    isUniqueLogin: true,
};

function getQueryParams(rootState: RootState): IUsersQueryParams {
    const state = rootState.superadminReducer;
    return {
        page: state.pagination.page,
        pageSize: state.pagination.pageSize ?? DEFAULT_PAGE_SIZE,
        sort: state.pagination.sort ?? "",
        ...state.filters,
    };
}

// Получить список пользователей
export const fetchUsers
    = createAsyncThunk<CollectionWithPagination<User>>("superadmin/fetchUsers", async(_, {getState}) => {
        const path = buildQuery(ApiUrls.GET_USERS, getQueryParams(getState() as RootState));
        const response = await createHttpRequest({
            method: "GET",
            path,
            errorMessage: "messages:fetch_users_error",
        });

        return response.data;
    });

// Получить список ролей
export const fetchRoles = createAsyncThunk<IFetchRolesResponse>("superadmin/fetchRoles", async() => {
    const response = await createHttpRequest({
        method: "GET",
        path: ApiUrls.GET_ROLES,
        errorMessage: "messages:fetch_roles_error",
    });

    return response.data;
});

// Создание пользователя
export const createUser = createAsyncThunk<void, ICreateUserForm>("superadmin/createUser",
    async(data) => {
        const response = await createHttpRequest({
            method: "POST",
            path: ApiUrls.CREATE_USER,
            data,
            errorMessage: "messages:create_user_error",
        });

        return response.data;
    });

// Редактирование пользователя
export const editUser = createAsyncThunk<void, {id: number, data: ICreateUserForm}>("superadmin/editUser",
    async({id, data}) => {
        const response = await createHttpRequest({
            method: "POST",
            path: ApiUrls.EDIT_USER(id),
            data: {...data},
            errorMessage: "messages:edit_user_error",
        }) as AxiosResponse<any>;

        return response.data;
    });

// Изменение пароля пользователя
export const updatePassword = createAsyncThunk<void, any>("superadmin/updatePassword", async({id, data}) => {
    // @ts-ignore
    const response = await createHttpRequest({
        method: "POST",
        path: ApiUrls.UPDATE_USER_PASSWORD(id),
        data,
        errorMessage: "messages:change_password_error",
    });

    return response.data;
});

// Получить список зон ответственности
export const fetchResponsibilityZones = createAsyncThunk<any>("superadmin/fetchResponsibilityZones", async() => {
    const response = await createHttpRequest({
        method: "GET",
        path: ApiUrls.RESPONSIBILITY_ZONES,
        errorMessage: "messages:fetch_responsibility_zones_error",
    });

    return response.data;
});

// Получить конкретного пользователя
export const fetchUser = createAsyncThunk<User, string>("superadmin/fetchUser", async(id) => {
    const response = await createHttpRequest({
        method: "GET",
        path: ApiUrls.GET_USER(id),
        errorMessage: "messages:fetch_user_error",
    });

    return response.data;
});

// Получение данных для графика
export const fetchIndicators = createAsyncThunk<IChartsIndicators, IChartsIndicatorsArgs>(
    "superadmin/fetchIndicators", async(args) => {
        const response = await createHttpRequest({
            method: "GET",
            path: ApiUrls.GET_USER_INDICATORS(args.id, args.dateUnit),
            errorMessage: "messages:fetch_indicators_error",
        });

        return response.data;
    }
);

// Получить статистики пользователя
export const fetchCountIncidents = createAsyncThunk<any, IChartsIndicatorsArgs>(
    "superadmin/fetchCountIncidents", async(args) => {
        const response = await createHttpRequest({
            method: "GET",
            path: ApiUrls.GET_USER_COUNT_INCIDENTS(args.id, args.dateUnit),
            errorMessage: "messages:fetch_count_incidents_error",
        });

        return response.data;
    }
);

// Получить сессии пользователя
export const fetchUserSessions = createAsyncThunk<OperatorSession, any>(
    "superadmin/fetchUserSessions", async(args) => {
        const response = await createHttpRequest({
            method: "GET",
            path: ApiUrls.GET_USER_SESSIONS(args.operatorId, args.sessionsCount),
            errorMessage: "messages:fetch_sessions_error",
        });

        return response.data;
    }
);

// Удалить пользователя
export const deleteUser = createAsyncThunk<any, string>("superadmin/deleteUser", async(id) => {
    const response = await createHttpRequest({
        method: "POST",
        path: ApiUrls.DELETE_USER(id),
        errorMessage: "messages:delete_user_error",
    });

    return response.data;
});

// Проверка логина на существование
export const checkLoginExists = createAsyncThunk<ICheckLoginExistResponse, string>(
    "superadmin/checkLoginExists", async(login) => {
        const response = await createHttpRequest({
            method: "GET",
            path: ApiUrls.CHECK_LOGIN_EXISTS(login),
            errorMessage: "messages:check_login_exist_error",
        });

        return response.data;
    }
);

const superadmin = createSlice({
    reducers: {
        setFilters(state, {payload}) {
            const filtersItem = state.filters?.[payload.filterType as keyof typeof state.filters];
            const nextValue = typeof payload.selectedFilters === "string"
                ? payload.selectedFilters
                : JSON.stringify(payload.selectedFilters);
            const currentValue = typeof filtersItem === "string" ? filtersItem : JSON.stringify(filtersItem);

            if (nextValue !== currentValue) {
                state.filters = {
                    ...state.filters,
                    [payload.filterType]: payload.selectedFilters,
                };
            }
        },
        setPagination(state, {payload}) {
            state.pagination = {
                ...state.pagination,
                ...payload,
            };
        },
        setIsUniqueLogin(state, {payload}) {
            state.isUniqueLogin = payload;
        },
        setInitQueryParams(state, {payload: {stateUpdateFields, params}}) {
            stateUpdateFields?.forEach((field: keyof typeof state) => {
                const stateField = state[field] as keyof IInitialState;
                const newState: keyof IInitialState | {[key: string]: any} = {};
                // Перебираем переданные квери параметры и заменяем значения в существующих полях стейт
                Object.entries(params).forEach((([paramKey, paramValue]) => {
                    const key = paramKey as keyof IUsersFilters;
                    const value = paramValue as string;
                    if (Object.prototype.hasOwnProperty.call(stateField, key)) {
                        // @ts-ignore
                        stateField[key] && Array.isArray(stateField[key])
                            ? newState[key] = value.split(",")
                            : newState[key] = value;
                    }
                }));

                if (Object.keys(newState).length) {
                    (state[field] as IInitialState) = {
                        ...state[field] as IInitialState,
                        ...newState,
                    };
                }
            });
        },
    },
    name: "superadmin",
    initialState,
    extraReducers: (builder) => {
        builder.addCase(fetchUsers.pending, (state) => {
            state.success = null;
            state.usersLoading = true;
        });
        builder.addCase(fetchUsers.fulfilled, (state, {payload}) => {
            state.users = payload.data;
            state.pagination = payload.pagination;
            state.usersLoading = false;
        });
        builder.addCase(fetchUsers.rejected, (state) => {
            state.usersLoading = false;
            state.success = null;
        });

        builder.addCase(fetchUser.pending, (state) => {
            state.user = null;
            state.loading = true;
        });
        builder.addCase(fetchUser.fulfilled, (state, {payload}) => {
            state.user = payload;
            state.loading = false;
        });
        builder.addCase(fetchUser.rejected, (state) => {
            state.user = null;
            state.loading = false;
        });

        // График - Статистика работы
        builder.addCase(fetchIndicators.pending, (state) => {
            state.indicators = null;
            state.indicatorsLoading = true;
        });
        builder.addCase(fetchIndicators.fulfilled, (state, {payload}) => {
            if (payload.dateUnit === "byMonth") {
                state.indicators = payload.byDateUnit.map((item, i) => ({...item, dateUnit: MONTH_NAMES[`${i}`]}));
            } else {
                state.indicators = payload.byDateUnit;
            }
            state.indicatorsLoading = false;
        });
        builder.addCase(fetchIndicators.rejected, (state) => {
            state.indicators = null;
            state.indicatorsLoading = false;
        });

        //Получить статистики пользователя
        builder.addCase(fetchCountIncidents.pending, (state) => {
            state.countIncidents = null;
            state.countIncidentsLoading = true;
        });
        builder.addCase(fetchCountIncidents.fulfilled, (state, {payload}) => {
            state.countIncidents = payload;
            state.countIncidentsLoading = false;
        });
        builder.addCase(fetchCountIncidents.rejected, (state) => {
            state.countIncidentsLoading = false;
        });

        //Получить сессии пользователя
        builder.addCase(fetchUserSessions.pending, (state) => {
            state.sessionsLoading = true;
        });
        builder.addCase(fetchUserSessions.fulfilled, (state, {payload}) => {
            state.sessions = payload?.data;
            state.sessionsPagination = payload?.pagination;
            state.sessionsLoading = false;
        });
        builder.addCase(fetchUserSessions.rejected, (state) => {
            state.sessions = [];
            state.sessionsLoading = false;
        });

        // Удалить пользователя
        builder.addCase(deleteUser.pending, (state) => {
            state.requestLoading = true;
        });
        builder.addCase(deleteUser.fulfilled, (state, {payload}) => {
            state.success = payload;
            state.requestLoading = false;
            toast.success("Запись успешно удалена");
        });
        builder.addCase(deleteUser.rejected, (state) => {
            state.success = null;
            state.requestLoading = false;
        });

        // Проверить логин на существование
        builder.addCase(checkLoginExists.pending, (state) => {
            state.requestLoading = true;
        });
        builder.addCase(checkLoginExists.fulfilled, (state, {payload}) => {
            state.isUniqueLogin = !payload.Data;
            state.requestLoading = false;
        });
        builder.addCase(checkLoginExists.rejected, (state) => {
            state.isUniqueLogin = true;
            state.requestLoading = false;
        });

        // Получить список ролей
        builder.addCase(fetchRoles.pending, (state) => {
            state.loading = true;
            state.rolesLoading = true;

        });
        builder.addCase(fetchRoles.fulfilled, (state, {payload}) => {
            state.roles = payload.Data;
            state.loading = false;
            state.rolesLoading = false;
        });
        builder.addCase(fetchRoles.rejected, (state) => {
            state.roles = null;
            state.loading = false;
            state.rolesLoading = false;
        });

        // Получить список зон ответственности
        builder.addCase(fetchResponsibilityZones.pending, (state) => {
            state.loading = true;
        });
        builder.addCase(fetchResponsibilityZones.fulfilled, (state, {payload}) => {
            state.responsibilityZones = payload;
            state.loading = false;
        });
        builder.addCase(fetchResponsibilityZones.rejected, (state) => {
            state.responsibilityZones = [];
            state.loading = false;
        });

        // Создание пользователя
        builder.addCase(createUser.pending, (state) => {
            state.requestError = null;
            state.requestLoading = true;
        });
        builder.addCase(createUser.fulfilled, (state, {payload}) => {
            state.success = payload;
            state.requestLoading = false;
            toast.success("Пользователь успешно создан");
        });
        builder.addCase(createUser.rejected, (state) => {
            state.success = null;
            state.requestError = true;
            state.requestLoading = false;
        });

        // Изменение пользователя
        builder.addCase(editUser.pending, (state) => {
            state.success = null;
            state.requestLoading = true;
        });
        builder.addCase(editUser.fulfilled, (state, {payload}) => {
            state.success = payload;
            state.requestLoading = false;
            toast.success("Пользователь успешно изменен");
        });
        builder.addCase(editUser.rejected, (state) => {
            state.success = null;
            state.requestLoading = false;
        });

        // Изменение пользователя
        builder.addCase(updatePassword.pending, (state) => {
            state.requestLoading = true;
        });
        builder.addCase(updatePassword.fulfilled, (state, {payload}) => {
            state.passwordSuccess = payload;
            state.requestLoading = false;
            toast.success("Пароль успешно изменен");
        });
        builder.addCase(updatePassword.rejected, (state) => {
            state.passwordSuccess = null;
            state.requestLoading = false;
        });
    },
});

export default superadmin.reducer;

//Экшены
export const {
    setFilters,
    setPagination,
    setIsUniqueLogin,
    setInitQueryParams,
} = superadmin.actions;

const slice = ({superadminReducer}: RootState) => superadminReducer;

export const usersSelector = createSelector(
    slice,
    ({users}) => users
);

export const usersLoadingSelector = createSelector(
    slice,
    ({usersLoading}) => usersLoading
);

export const userSelector = createSelector(
    slice,
    ({user}) => user
);

export const indicatorsSelector = createSelector(
    slice,
    ({indicators}) => indicators
);

export const indicatorsLoadingSelector = createSelector(
    slice,
    ({indicatorsLoading}) => indicatorsLoading
);

export const countIncidentsSelector = createSelector(
    slice,
    ({countIncidents}) => countIncidents
);

export const countIncidentsLoadingSelector = createSelector(
    slice,
    ({countIncidentsLoading}) => countIncidentsLoading
);

export const sessionsSelector = createSelector(
    slice,
    ({sessions}) => sessions
);

export const sessionsLoadingSelector = createSelector(
    slice,
    ({sessionsLoading}) => sessionsLoading
);

export const loadingSelector = createSelector(
    slice,
    ({loading}) => loading
);

export const successSelector = createSelector(
    slice,
    ({success}) => success
);

export const requestLoadingSelector = createSelector(
    slice,
    ({requestLoading}) => requestLoading
);

export const rolesSelector = createSelector(
    slice,
    ({roles}) => roles
);

export const rolesLoadingSelector = createSelector(
    [({superadminReducer}: RootState) => superadminReducer.rolesLoading],
    (rolesLoading) => rolesLoading
);

export const responsibilityZonesSelector = createSelector(
    slice,
    ({responsibilityZones}) => responsibilityZones
);

export const filtersSelector = createSelector(
    slice,
    ({filters}) => filters
);

export const sortSelector = createSelector(
    slice,
    ({pagination}): IUsersSort => {
        const [field, direction] = (pagination?.sort ?? DEFAULT_USERS_SORT).split(",");
        return {field, direction} as IUsersSort;
    }
);

export const usersPaginationSelector = createSelector(
    slice,
    ({pagination}) => ({
        ...pagination,
        page: Number(pagination.page),
        totalElements: Number(pagination.totalElements),
        totalPages: Number(pagination.totalPages),
    })
);

export const passwordSuccessSelector = createSelector(
    slice,
    ({passwordSuccess}) => passwordSuccess
);

export const requestErrorSelector = createSelector(
    slice,
    ({requestError}) => requestError
);

export const sessionsPaginationSelector = createSelector(
    slice,
    ({sessionsPagination}) => sessionsPagination
);

export const sessionReportLinkSelector = createSelector(
    slice,
    (state) => buildQuery(
        ApiUrls.SESSION_INFO_DOWNLOAD(state?.user?.id)
    )
);

export const checkLoginSelector = createSelector(
    slice,
    ({isUniqueLogin}) => isUniqueLogin
);