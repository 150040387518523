import {createAsyncThunk, createSelector, createSlice} from "@reduxjs/toolkit";

import {RootState} from "store";

import createHttpRequest from "../utils/http";
import {ApiUrls} from "../constants/urls";
import SettingsResponse from "../models/SettingsResponse";

interface IInitialState {
    isSettingsLoading: boolean,
    settings: SettingsResponse | null,
    zones: {
        activeDrawColor: string,
    },
    isQuickSettingsOpen: {
        isOpen: boolean,
        selectedTab: number | null | undefined,
        cameraId: number | null | undefined,
        cameraType: string | null | undefined,
        cameraZone: number | null | undefined,
    },
}

const initialState: IInitialState = {
    isSettingsLoading: false,
    settings: null,
    zones: {
        activeDrawColor: "transparent",
    },
    isQuickSettingsOpen: {
        isOpen: false,
        selectedTab: null,
        cameraId: null,
        cameraType: null,
        cameraZone: null,
    },
};

export const fetchSettings = createAsyncThunk<SettingsResponse>("settings/fetchSettings", async() => {
    const response = await createHttpRequest({
        method: "GET",
        path: ApiUrls.SETTINGS,
        errorMessage: "messages:fetch_settings_error",
    });

    return response.data;
});

const settings = createSlice({
    reducers: {
        setActiveDrawColor(state, {payload}) {
            state.zones.activeDrawColor = payload;
        },
        setIsQuickSettingsOpen(state, {payload}) {
            state.isQuickSettingsOpen = payload;
        },
    },
    name: "settings",
    initialState,
    extraReducers: (builder) => {
        builder.addCase(fetchSettings.pending, (state) => {
            state.settings = null;
            state.isSettingsLoading = true;
        });
        builder.addCase(fetchSettings.fulfilled, (state, {payload}) => {
            state.settings = payload;
            state.isSettingsLoading = false;
        });
        builder.addCase(fetchSettings.rejected, (state) => {
            state.isSettingsLoading = false;
        });
    },
});

export const {
    setActiveDrawColor,
    setIsQuickSettingsOpen,
} = settings.actions;

export default settings.reducer;

//Селекторы
const slice = ({settingsReducer}: RootState) => settingsReducer;

export const settingsSelector = createSelector(
    slice,
    ({settings}) => settings
);

export const isSettingsLoadingSelector = createSelector(
    slice,
    ({isSettingsLoading}) => isSettingsLoading
);

export const activeDrawColorSelector = createSelector(
    slice,
    ({zones}) => zones.activeDrawColor
);

export const isQuickSettingsOpenSelector = createSelector(
    slice,
    ({isQuickSettingsOpen}) => isQuickSettingsOpen
);