/* eslint-disable max-len */
import React from "react";
import {ISvgProps} from "../../../types/common";

const TabIcon2: React.FC<ISvgProps> = ({
    width = 20,
    height = 20,
    ...rest
}) => (
    <svg {...rest} width={width} height={height} viewBox="0 0 21 20" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path fillRule="evenodd" clipRule="evenodd" d="M10.4993 2.5C8.48193 2.5 7.16602 3.96313 7.16602 5.83333C7.16602 8.68948 8.6334 10 10.4993 10C12.3478 10 13.8327 8.73301 13.8327 6C13.8327 4.10132 12.5112 2.5 10.4993 2.5ZM10.4995 8.33447C9.51466 8.33447 8.83281 7.72551 8.83281 5.83447C8.83281 4.84765 9.44426 4.1678 10.4995 4.1678C11.5125 4.1678 12.1661 4.95981 12.1661 6.00113C12.1661 7.74979 11.4809 8.33447 10.4995 8.33447Z" fill="currentColor"/>
        <path fillRule="evenodd" clipRule="evenodd" d="M15.7704 13.4993C16.2671 13.8492 16.999 12.2495 17.149 12.9995C17.499 16.9995 14.369 17.666 10.4986 17.666C6.62821 17.666 3.49904 17.4995 3.84904 12.9995C3.99904 12.2495 4.73004 13.8492 5.22678 13.4993H5.49858V14.7495C5.49858 15.7478 7.28541 15.9993 10.4986 15.9993C13.7118 15.9993 15.4986 15.3309 15.4986 14.3327V13.4993H15.7704Z" fill="currentColor"/>
        <path fillRule="evenodd" clipRule="evenodd" d="M5.22805 14.1667C4.7313 13.8169 4.27086 13.4189 3.85352 12.9797C4.10292 10.8707 6.62947 10 10.4998 10C14.3702 10 16.8968 10.8707 17.1462 12.9797C16.7288 13.4189 16.2684 13.8169 15.7716 14.1667H15.4998V13.3333C15.4998 12.3351 13.713 11.6667 10.4998 11.6667C7.28668 11.6667 5.49985 12.3351 5.49985 13.3333V14.1667H5.22805Z" fill="currentColor"/>
    </svg>
);

export default TabIcon2;