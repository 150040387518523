/* eslint-disable max-len */
import React from "react";

import {ISvgProps} from "../../types/common";

// import icon from "images/icons/eye.svg";

const EyeIcon: React.FC<ISvgProps> = ({
    width = 24,
    height = 24,
    ...rest
}) => (
    <svg {...rest} width={width} height={height} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g id="24 / basic / eye">
            <path id="icon" fillRule="evenodd" clipRule="evenodd" d="M12 4C14.7276 4 17.3357 5.43062 19.7663 7.78115C20.5955 8.58305 21.3457 9.43916 22.0061 10.2956C22.4046 10.8124 22.6875 11.219 22.8425 11.4612L23.1871 12L22.8425 12.5388C22.6875 12.781 22.4046 13.1876 22.0061 13.7044C21.3457 14.5608 20.5955 15.417 19.7663 16.2189C17.3357 18.5694 14.7276 20 12 20C9.27247 20 6.66434 18.5694 4.23373 16.2189C3.40451 15.417 2.65433 14.5608 1.99393 13.7044C1.59543 13.1876 1.3125 12.781 1.15759 12.5388L0.812988 12L1.15759 11.4612C1.3125 11.219 1.59543 10.8124 1.99393 10.2956C2.65433 9.43916 3.40451 8.58305 4.23373 7.78115C6.66434 5.43062 9.27247 4 12 4ZM20.4223 11.5169C19.8176 10.7327 19.1302 9.9482 18.376 9.21885C16.2825 7.19438 14.1051 6 12 6C9.89495 6 7.71751 7.19438 5.62406 9.21885C4.86986 9.9482 4.18241 10.7327 3.57777 11.5169C3.44718 11.6862 3.32651 11.8478 3.21619 12C3.32651 12.1522 3.44718 12.3138 3.57777 12.4831C4.18241 13.2673 4.86986 14.0518 5.62406 14.7811C7.71751 16.8056 9.89495 18 12 18C14.1051 18 16.2825 16.8056 18.376 14.7811C19.1302 14.0518 19.8176 13.2673 20.4223 12.4831C20.5529 12.3138 20.6735 12.1522 20.7839 12C20.6735 11.8478 20.5529 11.6862 20.4223 11.5169ZM8.00002 12C8.00002 14.2091 9.79088 16 12 16C14.2092 16 16 14.2091 16 12C16 9.79086 14.2092 8 12 8C9.79088 8 8.00002 9.79086 8.00002 12ZM14 12C14 13.1046 13.1046 14 12 14C10.8955 14 10 13.1046 10 12C10 10.8954 10.8955 10 12 10C13.1046 10 14 10.8954 14 12Z" fill="currentColor"/>
        </g>
    </svg>
);

export default EyeIcon;