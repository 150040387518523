import {isArray, isEmpty, isObject} from "lodash";

export function buildQuery(path: string, params?: object, commaJoinParams: string[] = []): string {
    if (!params || isEmpty(params)) {
        return path;
    }
    const query = [] as string[];

    Object.entries(params).forEach(([key, val]) => {
        if (isArray(val)) {
            if (commaJoinParams.includes(key)) {
                if (!isEmpty(val)) {
                    query.push(`${key}=${val.join(",")}`);
                }
            } else {
                val.forEach(((subValue:string | number | boolean) => {
                    query.push(`${key}=${subValue}`);
                }));
            }

        } else if (null !== val){
            query.push(`${key}=${val}`);
        }
    });

    return `${path}?${query.join("&")}`;
}

export const buildQueryObject = (params:{[key: string]: unknown}) => (
    Object.entries(params).reduce((acc, [key, val]) => {
        if ((isObject(val) && !isEmpty(val)) || (!isObject(val) && val !== null)) {
            //@ts-ignore
            acc[key as keyof typeof acc] = val;
        }

        return acc;
    }, {})
);