/* eslint-disable max-len */
import React, {FC} from "react";

import {ISvgProps} from "../../types/common";

const CamsIncidentsIcon: FC<ISvgProps> = ({
    width = 17,
    height = 18,
    ...rest
}) => (
    <svg {...rest} width={width} height={height} viewBox="0 0 17 18" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path fillRule="evenodd" clipRule="evenodd"
            d="M15.4609 10.6351L10.7907 2.65142C10.3219 1.83861 9.44797 1.33412 8.50289 1.33301C7.55771 1.33191 6.68352 1.83431 6.20883 2.65146L1.53687 10.6381C1.05242 11.4527 1.04146 12.4734 1.5107 13.3058C1.98034 14.1389 2.85969 14.6574 3.81618 14.6652L13.1748 14.6652C14.1412 14.6558 15.0189 14.139 15.4884 13.3066C15.9574 12.4752 15.947 11.4567 15.4609 10.6351ZM2.68531 11.3155L7.36073 3.32295C7.5975 2.91537 8.03176 2.66579 8.50133 2.66634C8.97085 2.66689 9.40495 2.9175 9.63781 3.32116L14.3117 11.3112C14.5567 11.7253 14.562 12.2352 14.3271 12.6515C14.092 13.0683 13.6524 13.3272 13.1683 13.3319L3.82161 13.3319C3.34787 13.328 2.9074 13.0683 2.67221 12.6511C2.43728 12.2343 2.44276 11.7233 2.68531 11.3155ZM8.50014 11.9986C8.86845 11.9986 9.16703 11.7001 9.16703 11.3319C9.16703 10.9637 8.86845 10.6652 8.50014 10.6652C8.13183 10.6652 7.83325 10.9637 7.83325 11.3319C7.83325 11.7001 8.13183 11.9986 8.50014 11.9986ZM9.169 5.33191H7.83522V9.99857H9.169V5.33191Z"
            fill="currentColor"/>
    </svg>
);

export default CamsIncidentsIcon;