import {useEffect, useRef, useState} from "react";

interface UseBroadcastChannelOptions {
    name: string,
    // eslint-disable-next-line no-unused-vars
    onMessage?: (event: MessageEvent) => void,
}

interface UseBroadcastChannelReturn<D, P> {
    isSupported: boolean,
    channel: BroadcastChannel | null,
    data: D | undefined,
    // eslint-disable-next-line no-unused-vars
    postMessage: (data: P) => void,
    close: () => void,
    isClosed: boolean,
}

const useBroadcastChanel = <D, P>({
    name,
    onMessage,
}:UseBroadcastChannelOptions):UseBroadcastChannelReturn<D, P> => {
    const [isClosed, setIsClosed] = useState(false);
    const [isSupported, setIsSupported] = useState(false);
    const [data, setData] = useState<D | undefined>();

    const channelRef = useRef<BroadcastChannel | null>(null);

    const handleMessage = (event: MessageEvent) => {
        setData(event.data as D);
        onMessage?.(event);
    };

    const postMessage = (messageData: P) => {
        if (channelRef.current && !isClosed) {
            channelRef.current.postMessage(messageData);
        }
    };

    const close = () => {
        if (channelRef.current && !isClosed) {
            channelRef.current.close();
            setIsClosed(true);
        }
    };

    useEffect(() => {
        setIsSupported("BroadcastChannel" in window);
    }, []);

    useEffect(() => {
        if (!isSupported) return;

        const newChannel = new BroadcastChannel(name);
        channelRef.current = newChannel;

        // TODO: Добавить обработку ошибок
        newChannel.addEventListener("message", handleMessage);

        return () => {
            newChannel.removeEventListener("message", handleMessage);

            if (!isClosed) newChannel.close();

            channelRef.current = null;
        };
    }, [isSupported, name]);

    return {
        isSupported,
        channel: channelRef.current,
        data,
        postMessage,
        close,
        isClosed,
    };
};

export default useBroadcastChanel;