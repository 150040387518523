import React from "react";
import {useSelector} from "react-redux";
import {Navigate} from "react-router-dom";

import {userPermissionsSelector} from "redux/authSlice";
import {AppUrls} from "../../constants/urls";
import {isGrantedAccess} from "../../utils/permissionsHelper";
import {Permission} from "../../models/Permission";
import {toast} from "react-toastify";

const DashBoardRedirect = () => {
    const userPermissions = useSelector(userPermissionsSelector);

    let uri = AppUrls.AUTH;
    if (isGrantedAccess(userPermissions, [
        Permission.ADMIN,
        Permission.EVENT,
        Permission.SERVICE,
        Permission.MANAGER,
    ])) {
        uri = AppUrls.DASHBOARD;
    } else if (isGrantedAccess(userPermissions, [Permission.FIXATION])){
        uri = AppUrls.FIXATIONS_ARCHIVE;
    } else if (isGrantedAccess(userPermissions, [Permission.PRO_TV_VIEWER])) {
        uri = AppUrls.PROTV_VIEWER;
    } else if (isGrantedAccess(userPermissions, [Permission.REGISER])) {
        uri = AppUrls.INCIDENTS_ON_CONTROL;
    } else {
        toast.error("Не настроен редирект!");
    }

    return (
        <Navigate to={uri} replace/>
    );
};

export default DashBoardRedirect;