import React, {FC} from "react";
import cn from "classnames";

import Header from "../../Header";
import Sidebar from "../../Sidebar";

import stylesCommon from "styles/Common.module.scss";

interface ILayoutArm {
    theme: string,
    children: React.ReactNode | string,
}

const LayoutArm: FC<ILayoutArm> = ({theme, children}) => {
    return (
        <>
            <Header theme={theme}/>
            <div className={stylesCommon["main__content"]}>
                <Sidebar isDemonstrationDashboard={true}/>

                <div className={cn(
                    stylesCommon["dashboard-content"],
                    stylesCommon["dashboard-content--demonstration"]
                )}>
                    {children}
                </div>
            </div>
        </>
    );
};

export default LayoutArm;