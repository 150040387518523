import {createAsyncThunk, createSelector, createSlice} from "@reduxjs/toolkit";
import moment from "moment";
import {AxiosError, AxiosResponse} from "axios";
import {isArray} from "lodash";

import {RootState} from "../store";
import LastIncidentEventModel from "../models/LastIncidentEventModel";
import {LoadingStatusType} from "./analyticsSlice";
import createHttpRequest from "../utils/http";
import {ApiUrls} from "../constants/urls";
import {buildQuery} from "../utils/query";
import {IncidentEventStatus} from "../models/IncidentEventStatus";

export interface IInitialState {
    sharedEvents: LastIncidentEventModel[],
    sharedEventsStatus: LoadingStatusType,
}

export const initialState: IInitialState = {
    sharedEvents: [],
    sharedEventsStatus: "idle",
};

interface ISharedEventListItem {
    incident: LastIncidentEventModel,
    share: any,
}

interface IGetSharedEventsResponse {
    List: ISharedEventListItem[],
    Count: number,
}

export const getSharedEvents = createAsyncThunk<
    LastIncidentEventModel[]
>(
    "sharedEvents/getSharedEvents",
    async(_, {rejectWithValue}) => {

        const DateFrom = moment().subtract(3, "days").startOf("day").toISOString();

        try {
            const resShared = await createHttpRequest({
                method: "GET",
                path:
                    buildQuery(ApiUrls.SHARED_EVENTS.EVENTS, {
                        DateFrom,
                        Status: 1,
                    }),
                silent: true,
            }) as AxiosResponse<IGetSharedEventsResponse>;

            return resShared.data.List.map(item => item.incident);

        } catch (error) {
            return rejectWithValue(error as AxiosError<any>);
        }
    }
);

interface IUpsertSharedIncidentPayload {
    eventModelType?: string,
    incidentId?: string,
}

export const upsertSharedIncident = createAsyncThunk<
    void, IUpsertSharedIncidentPayload | IUpsertSharedIncidentPayload[]
>(
    "sharedEvents/upsertSharedIncident",
    async(data, {dispatch, getState}) => {
        const {eventSharedReducer} = getState() as RootState;

        const upsertLastEventIncidentHandler = (state: IInitialState, payload: IUpsertSharedIncidentPayload) => {
            // Обновление инцидентов через signalR
            const index = state.sharedEvents.findIndex(event => {
                return event.id === payload.incidentId;
            });

            if (index > -1) {
                dispatch(getSharedEvents());
            }
        };

        if (isArray(data)) {
            data.forEach((item) => upsertLastEventIncidentHandler(eventSharedReducer, item));
        } else upsertLastEventIncidentHandler(eventSharedReducer, data);
    }
);

const eventSharedSlice = createSlice({
    reducers: {},
    extraReducers: (builder) => {

        builder
            .addCase(getSharedEvents.pending, (state) => {
                state.sharedEventsStatus = "loading";
            })

            .addCase(getSharedEvents.fulfilled, (state, {payload}) => {
                state.sharedEvents = payload;
                state.sharedEventsStatus = "succeeded";
            })

            .addCase(getSharedEvents.rejected, (state) => {
                state.sharedEventsStatus = "failed";
            });

    },
    name: "eventSharedSlice",
    initialState,
});

export default eventSharedSlice.reducer;

// export const {
// } = eventSharedSlice.actions;

// Селекторы
const slice = ({eventSharedReducer}: RootState) => eventSharedReducer;

export const sharedEventsSelector = createSelector(
    slice,
    (state) => state.sharedEvents.filter(item =>
        item.status === IncidentEventStatus.Processing || item.status === IncidentEventStatus.New)
);

export const sharedEventsStatusSelector = createSelector(
    slice,
    (state) => state.sharedEventsStatus
);
