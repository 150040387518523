import React, {FC, useEffect, useRef, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {useTranslation} from "react-i18next";
import {TypedDispatch} from "../../../types";
import {formatTimeDifference} from "../../../utils/formatTimeDifference";
import {TIME_OPTIONS} from "../../../constants/common";
import SimpleBar from "simplebar-react";
import cn from "classnames";
import {toast} from "react-toastify";
import {isEmpty} from "lodash";

import {
    camsGroupsSelector,
    createProcessingSuppressedBeforeUtc, currentCameraRulesSelector, deleteProcessingSuppressedBeforeUtc,
    editProcessingSuppressedBeforeUtc, fetchCameraSettings,
    fetchProcessingSuppressedBeforeUtc,
    fetchSegments,
    ICams,
    ICamsJson,
    requestGroupSuccessSelector,
    resetCameraRule,
    segmentsGroupsSelector, successSelector, updateProcessingSuppressedBeforeUtc,
} from "../../../redux/disablingCameraIncidentsSlice";
import {fetchIncidentTypes, incidentTypesSelector} from "../../../redux/incidentTypeSlice";
import CloseGrayIcon from "../../Icons/CloseGrayIcon";
import TypeIcon from "../../Icons/TypeIcon";
import SettingsIncidentsAllCam from "./SettingsIncidentsAllCam";
import SettingsIncidentsSegment from "./SettingIncidentsSegment";

import styles from "../SettingsIncidents/SettingsIncidents.module.scss";

interface ISettingsIncidentsProps {
    cameraId: any,
    cameraType: any,
    cameraZone?: any,
}

const SettingsIncidents: FC<ISettingsIncidentsProps> = ({cameraId, cameraType, cameraZone}) => {
    const dispatch = useDispatch<TypedDispatch>();
    const incidentTypes = useSelector(incidentTypesSelector);
    const camsGroupsList = useSelector(camsGroupsSelector);
    const requestGroupSuccess = useSelector(requestGroupSuccessSelector);
    const segmentsGroups = useSelector(segmentsGroupsSelector);
    const success = useSelector(successSelector);
    const {t} = useTranslation("maps");
    const translatedCam = t(cameraType);
    const selectRefs = useRef<{ [key: string]: HTMLDivElement | null }>({});
    const matchedCam = camsGroupsList?.find((cam: any) => cam.Name === String(cameraId));
    const matchedId = matchedCam?.Id;
    const currentCameraRules = useSelector(currentCameraRulesSelector);
    const noMatchedCam = camsGroupsList?.find((cam: any) => String(cameraId) === cam.Name);

    const [selectedSegmentId, setSelectedSegmentId] = useState<string | null>(null);
    const [openSelects, setOpenSelects] = useState<{ [key: string]: string | null }>({});
    const [selectedTimes, setSelectedTimes] = useState<{ [key: string]: any }>({});
    const [checkedStates, setCheckedStates] = useState<{ [key: string]: boolean }>({});

    useEffect(() => {
        if (!incidentTypes?.length) dispatch(fetchIncidentTypes());
        if (isEmpty(segmentsGroups)) dispatch(fetchSegments());
        if (isEmpty(camsGroupsList)) dispatch(fetchProcessingSuppressedBeforeUtc());
        // Если нет айди - нужно сбросить данные предыдущей камеры, у которой был справочник
        if (matchedId) {
            dispatch(fetchCameraSettings(matchedId));
        } else {
            dispatch(resetCameraRule());
        }
    }, []);

    useEffect(() => {
        if (requestGroupSuccess) {
            dispatch(fetchProcessingSuppressedBeforeUtc());
        }
    }, [requestGroupSuccess, dispatch]);

    function toggleSelect(typeCode: string) {
        setOpenSelects((prevSelects) => ({
            ...prevSelects,
            [typeCode]: prevSelects[typeCode] === "time" ? null : "time",
        }));
    }
    
    const handleOptionSelect = (typeCode: string, value: any) => {
        setSelectedTimes((prevTimes) => ({
            ...prevTimes,
            [typeCode]: value,
        }));
        setCheckedStates((prevStates) => ({
            ...prevStates,
            [typeCode]: false, // Делаем свитч неактивным после выбора опции в селекте
        }));
        setOpenSelects((prevSelects) => ({
            ...prevSelects,
            [typeCode]: null,
        }));
    };

    const prevUtc = {utc: null};
    const prevJson: ICams["data"]["json"] | { json: null | { prioritySettings: ICamsJson[] } } = {json: null};

    const handleCheckboxChange = (typeCode: string, event: React.ChangeEvent<HTMLInputElement>) => {
        event.stopPropagation();

        const isChecked = event.target.checked;
        const selectedTimeForType = selectedTimes[typeCode];

        // Проверяем, выбрано ли время, если свич включен
        if (isChecked && !selectedTimeForType) {
            toast.error("Пожалуйста, выберите время для инцидента", {position: "bottom-right"});
            return;
        }

        setCheckedStates((prev) => ({
            ...prev,
            [typeCode]: isChecked,
        }));

        prevUtc.utc = matchedCam?.Data.incidentProcessingSuppressedBeforeUtc;
        prevJson.json = matchedCam?.Data.json;

        let validUntilUtc;
        if (selectedTimeForType === "forever") {
            validUntilUtc = new Date(2099, 11, 31).toISOString();
        } else {
            const timeValue = Number(selectedTimeForType);
            if (!isNaN(timeValue) && timeValue > 0) {
                validUntilUtc = new Date(Date.now() + Number(selectedTimeForType) * 60 * 1000).toISOString();
            } else {
                validUntilUtc = "";
            }
        }
        const newSegmentData = {
            segmentId: selectedSegmentId || undefined,
            incidentTypeCode: typeCode,
            validUntilUtc: validUntilUtc,
        };

        const existingSetting = prevJson.json?.prioritySettings?.find(setting =>
            (setting.segmentId === selectedSegmentId || setting.incidentTypeCode === typeCode));

        const filteredPrioritySettings = prevJson.json?.prioritySettings?.filter(setting => {
            return new Date(setting.validUntilUtc) > new Date();
        }) || [];

        const updatedPrioritySettings = [...filteredPrioritySettings, newSegmentData];

        const updatedIncidentProcessingSuppressedBeforeUtc = prevUtc.utc && new Date(prevUtc.utc) > new Date()
            ? prevUtc.utc
            : undefined;

        const newCamData: ICams = {
            name: cameraId,
            data: {
                roadObjectId: cameraId,
                zoneId: cameraZone,
                incidentProcessingSuppressedBeforeUtc: (!selectedSegmentId && !typeCode)
                    ? validUntilUtc
                    : updatedIncidentProcessingSuppressedBeforeUtc,
                json: (!selectedSegmentId && !typeCode)
                    ? prevJson.json || undefined : {
                        prioritySettings: updatedPrioritySettings,
                    },
            },
        };
        if (isChecked) {
            if (!noMatchedCam) {
                dispatch(createProcessingSuppressedBeforeUtc({
                    data: {
                        data: newCamData?.data,
                        name: cameraId,
                        typeId: "INCIDENTS_CAMERASETTINGS.1",
                    },
                }));
            } else {
                const preparedData = {
                    data: newCamData?.data,
                    name: cameraId,
                    typeId: "INCIDENTS_CAMERASETTINGS.1",
                };
                dispatch(editProcessingSuppressedBeforeUtc({id: matchedId, data: preparedData}))
                    .then(({meta}: any) => {
                        prevUtc.utc = meta.arg.data.data.incidentProcessingSuppressedBeforeUtc;
                        prevJson.json = meta.arg.data.data.json;
                    });
            }
        } else if (!isChecked) {
            dispatch(deleteProcessingSuppressedBeforeUtc({
                id: matchedId,
                incidentTypeCode: typeCode,
                segmentId: selectedSegmentId,
            }));

        } else if (existingSetting) {
            dispatch(updateProcessingSuppressedBeforeUtc({
                id: matchedId,
                validUntilUtc: validUntilUtc,
                incidentTypeCode: typeCode,
                segmentId: selectedSegmentId,
            }));
        }
    };

    const isSwitchChecked = (typeCode: string): boolean => {
        const settingForTypeAndSegment = currentCameraRules?.Data?.json?.prioritySettings?.find((setting: any) => (
            setting.incidentTypeCode === typeCode &&
            (new Date(setting.validUntilUtc) > new Date()) &&
            (setting.segmentId === `${selectedSegmentId}`
                || setting.segmentId === selectedSegmentId
                || !selectedSegmentId)
        ));
        return !!settingForTypeAndSegment;
    };

    const formatTimeForTypeAndSegment = (typeCode: string): string => {
        const segmentHasRuleOnThisType = matchedCam?.Data?.json?.prioritySettings.find((setting: any) => {
            return (setting.incidentTypeCode === typeCode && selectedSegmentId === setting.segmentId)
                || (setting.incidentTypeCode === typeCode && `${selectedSegmentId}` === setting.segmentId);
        });

        if (!matchedCam?.Data?.json?.prioritySettings || (!segmentHasRuleOnThisType && selectedSegmentId)) {
            return "Время";
        }

        // Поиск настроек для конкретного типа инцидента и сегмента (если сегмент выбран)
        const matchingSetting = matchedCam.Data.json.prioritySettings.find((setting: any) =>
            setting.incidentTypeCode === typeCode &&
            (setting.segmentId === selectedSegmentId
                || setting.segmentId === String(selectedSegmentId)
                || !selectedSegmentId));

        // Если подходящая настройка найдена, возвращаем разницу во времени для нее
        if (matchingSetting && matchingSetting.validUntilUtc) {
            return formatTimeDifference(matchingSetting.validUntilUtc);
        }

        if (!selectedSegmentId) return "Время";
        return "Время";
    };

    const handleChangeSegmentId = (segment: any) => {
        setSelectedSegmentId(prev => prev === segment.Data.segmentId ? null : segment.Data.segmentId);
        const currentsSelectedSegments = matchedCam?.Data ?
            matchedCam?.Data.json.prioritySettings
                .filter((setting: any) => setting.segmentId === segment.Data.segmentId) : [];
        const newSelectedState: any = {};
        for (const segment of currentsSelectedSegments) {
            newSelectedState[segment] = true;
        }
        setCheckedStates(newSelectedState);
        setSelectedTimes({});
    };

    useEffect(() => {
        const handleClickOutside = (event: MouseEvent) => {
            Object.keys(selectRefs.current).forEach((typeCode) => {
                if (openSelects[typeCode] && selectRefs.current[typeCode]
                    && !selectRefs.current[typeCode]?.contains(event.target as Node)) {
                    setOpenSelects((prevSelects) => ({
                        ...prevSelects,
                        [typeCode]: null,
                    }));
                }
            });
        };

        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, [openSelects]);

    return (
        <div className={styles["settings-incidents"]}>
            <div className={styles["settings-incidents__cam-info"]}>
                {translatedCam} камера #{cameraId} <CloseGrayIcon/>
            </div>

            <SettingsIncidentsAllCam
                cameraId={cameraId}
                matchedCam={matchedCam}
                matchedId={matchedId}
                noMatchedCam={noMatchedCam}
                prevUtc={prevUtc}
                prevJson={prevJson}
                zoneId={cameraZone}
                isSelected={currentCameraRules?.Data?.incidentProcessingSuppressedBeforeUtc}
            />

            <div className={styles["settings-incidents__bar"]}>
                <div className={styles["settings-incidents__segments"]}>
                    <SimpleBar style={{maxHeight: "570px", height: "100%", paddingRight: "20px"}}>
                        {segmentsGroups.map((segment: any) => (
                            <div
                                data-value={segment.Data.segmentId}
                                className={cn(styles["settings-incidents__segments-item"],
                                    selectedSegmentId === segment.Data.segmentId &&
                                    styles["settings-incidents__segments-item-active"])}
                                key={segment.Data.segmentId}
                                onClick={() => handleChangeSegmentId(segment)}
                            >
                                {segment.Data.name}
                            </div>
                        ))}
                    </SimpleBar>
                </div>

                <div className={styles["settings-incidents__types"]}>
                    <SimpleBar style={{maxHeight: "570px", height: "100%", paddingRight: "20px"}}>
                        {
                            selectedSegmentId && (
                                <SettingsIncidentsSegment
                                    selectedSegmentId={selectedSegmentId}
                                    cameraId={cameraId}
                                    matchedCam={matchedCam}
                                    matchedId={matchedId}
                                    noMatchedCam={noMatchedCam}
                                    prevUtc={prevUtc}
                                    prevJson={prevJson}
                                    zoneId={cameraZone}
                                />
                            )
                        }

                        {incidentTypes.map((type: any, index: number) => (
                            <div data-value={type.code} key={index}
                                className={styles["settings-incidents__types-item"]}
                                ref={(el) => (selectRefs.current[type.code] = el)}
                            >
                                <TypeIcon classname={styles["type__icon"]} type={type} width={31} height={31}/>
                                <div className={styles["settings-incidents__type-name"]} title={type.name}>
                                    {type.name}
                                </div>
                                <div className="cams-info__custom-select custom-select__time">
                                    <div
                                        className={cn(openSelects[type.code] === "time"
                                            && styles["select__trigger-active"], "cams-info__select-trigger")}
                                        onClick={() => toggleSelect(type.code)}
                                    >
                                        {selectedTimes[type.code] ? TIME_OPTIONS[selectedTimes[type.code]]
                                            : formatTimeForTypeAndSegment(type.code)}
                                    </div>
                                    {openSelects[type.code] === "time" && (
                                        <div className={cn(styles["select__options-active"], "cams-info__options")}>
                                            {Object.keys(TIME_OPTIONS).map((key) => (
                                                <div key={key} className="cams-info__option" data-value={key}
                                                    onClick={() => handleOptionSelect(type.code, key)}>
                                                    {TIME_OPTIONS[key]}
                                                </div>
                                            ))}
                                        </div>
                                    )}
                                </div>

                                <label className="cams-info__custom-switch">
                                    <input
                                        type="checkbox"
                                        className="disable-tracking-checkbox"
                                        disabled={!success}
                                        onChange={(event) => handleCheckboxChange(type.code, event)}
                                        checked={checkedStates[type.code] !== undefined
                                            ? checkedStates[type.code]
                                            : isSwitchChecked(type.code)}
                                    />

                                    <span className="slider round"></span>
                                </label>
                            </div>
                        ))}
                    </SimpleBar>
                </div>
            </div>
        </div>
    );
};

export default SettingsIncidents;
