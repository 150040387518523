import React from "react";

import Image from "components/Icons/Image";

import Priority0Icon from "images/icons/priority/0.svg";
import Priority0SeveralIcon from "images/icons/priority/0s.svg";
import Priority1Icon from "images/icons/priority/1.svg";
import Priority1SeveralIcon from "images/icons/priority/1s.svg";
import Priority2Icon from "images/icons/priority/2.svg";
import Priority2SeveralIcon from "images/icons/priority/2s.svg";

import {IncidentEventPriority} from "models/IncidentEventPriority";

interface IPriorityIconProps {
    priority?: IncidentEventPriority,
    relCount?: number,
    className?: string,
    width?: number,
    height?: number,
}

const PriorityIcon: React.FC<IPriorityIconProps> = ({
    priority,
    relCount,
    className,
    width = 22,
    height = 22,
}) => {
    let icon;
    const isSeveral = relCount ?? 0 > 0;
    switch (priority) {
        case IncidentEventPriority.$0:
            icon = isSeveral ? Priority0SeveralIcon : Priority0Icon;
            break;
        case IncidentEventPriority.$1:
            icon = isSeveral ? Priority1SeveralIcon : Priority1Icon;
            break;
        case IncidentEventPriority.$2:
            icon = isSeveral ? Priority2SeveralIcon : Priority2Icon;
            break;
    }

    return (
        icon &&
        <Image
            src={icon}
            style={{width: "fit-content", objectFit: "cover"}}
            width={width}
            height={height}
            className={className}
            alt={"Приоритет"}
        />
    );
};

export default PriorityIcon;