/* eslint-disable no-unused-vars */
export enum BroadcastChannelEvents {
    LOGOUT = "logout",
    LOGGED_OUT = "loggedOut",

    CLOSE_SECOND_SCREEN = "closeSecondScreen",
    SECOND_SCREEN_IS_CLOSED = "secondScreenIsClosed",

    CHANGE_DISPLAY_MODE = "changeDisplayMode",

    CHANGE_THEME = "changeTheme",
}