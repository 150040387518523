import React from "react";

import {IncidentEventStatus} from "models/IncidentEventStatus";
import {ISvgProps} from "../../types/common";

import EventNewIcon from "./Events/Statuses/EventNewIcon";
import EventProcessingIcon from "./Events/Statuses/EventProcessingIcon";
import EventResolvedIcon from "./Events/Statuses/EventResolvedIcon";
import EventRejectedIcon from "./Events/Statuses/EventRejectedIcon";
import LastIncidentEventModel from "../../models/LastIncidentEventModel";

interface IStatusIconProps extends ISvgProps {
    status?: IncidentEventStatus,
    event?: LastIncidentEventModel | null,
}

const StatusIcon: React.FC<IStatusIconProps> = ({status, ...rest}) => {
    switch (status) {
        case IncidentEventStatus.New:
            return <EventNewIcon {...rest}/>;
        case IncidentEventStatus.Processing:
            return <EventProcessingIcon {...rest}/>;
        case IncidentEventStatus.Resolved:
            return <EventResolvedIcon {...rest}/>;
        case IncidentEventStatus.Rejected:
            return <EventRejectedIcon {...rest}/>;
        default:
            return <>N/A</>;
    }
};

export default React.memo(StatusIcon);