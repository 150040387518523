import React, {FC} from "react";

import LogoIcon from "../Icons/LogoIcon";

import styles from "./Header.module.scss";

const HeaderAuthorization: FC = () => {
    return (
        <header className={styles["header"]}>
            <div className={styles["header__logo"]}>
                <LogoIcon/>
            </div>
        </header>
    );
};

export default React.memo(HeaderAuthorization);