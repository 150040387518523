import React, {FC, useState} from "react";

import {Controller} from "react-hook-form";
import cn from "classnames";

import EyeIcon from "../../Icons/EyeIcon";
import EyeNoIcon from "../../Icons/EyeNoIcon";

import styles from "./Input.module.scss";

interface IPasswordFormInputProps {
    name: string,
    control: any,
    label?: string,
    placeholder?: string,
    rules?: any,
    disabled?: boolean | null,
}

const PasswordFormInput: FC<IPasswordFormInputProps> = (
    {name, control, label, placeholder, rules, disabled}
) => {

    const [showPassword, setShowPassword] = useState(false);
    const [value, setValue] = useState("");

    const handleChange = (e: any) => {
        e.preventDefault();
        setValue(e.target.value);
    };

    return (
        <Controller
            name={name}
            control={control}
            rules={{
                ...rules,
            }}
            render={({
                field: {onChange, ref}, fieldState: {error},
            }) => (
                <div className={styles["form-group"]}>
                    <div className={styles["form-container"]}>
                        <input
                            type={showPassword ? "text" : "password"}
                            placeholder={placeholder}
                            defaultValue={value}
                            onBlur={handleChange}
                            onChange={onChange}
                            ref={ref}
                            className={cn({[styles["error"]]: error})}
                            disabled={disabled ?? false}
                        />
                        <label className={cn(
                            {[styles["error-label"]]: error},
                            {[styles["required"]]: rules.required.value}
                        )}>
                            {label}
                        </label>
                    </div>

                    <div className={cn(styles["form__input-icon"], styles["password-icon"])}
                        onClick={() => setShowPassword(!showPassword)}
                    >
                        {showPassword ? <EyeNoIcon/> : <EyeIcon/>}
                    </div>

                    {(error) && <div className={styles["error-text"]}>{error.message}</div>}
                </div>
            )}
        />
    );
};

export default React.memo(PasswordFormInput);