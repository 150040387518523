export function getPaginationLabels(
    currentPage: number,
    lastPage: number,
    range = 6
):Array<string | number>{
    const start      = ((currentPage - range) > 0) ? currentPage - range : 1;
    const end        = ((currentPage + range) < lastPage) ? currentPage + range : lastPage;

    const buttonsArray: Array<string | number> = [];
    if (start > 1) {
        buttonsArray.push(1);
    }
    if (start > 2) {
        buttonsArray.push("el1");
    }
    for (let page = start; page <= end; page++) {
        buttonsArray.push(page);
    }

    if (end < lastPage - 1) {
        buttonsArray.push("el2");
    }

    if (end < lastPage) {
        buttonsArray.push(lastPage);
    }

    return buttonsArray;
}