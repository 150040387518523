import React from "react";
import cn from "classnames";
import {useTranslation} from "react-i18next";

import {HandleSortType} from "types/table";

import ArrowTriangularTopIcon from "components/Icons/ArrowTriangularTopIcon";
import ArrowTriangularDownIcon from "components/Icons/ArrowTriangularDownIcon";

import styles from "./Table.module.scss";

interface ISortableThProps {
    sortableCol: string,
    sort: {direction: string, field: string},
    /* eslint-disable */
    handleSort: HandleSortType,
    columnsTranslationNamespace: string
}

const SortableTh: React.FC<ISortableThProps> = ({
    sortableCol,
    sort,
    handleSort,
    columnsTranslationNamespace
}) => {
    const {t} = useTranslation(columnsTranslationNamespace);

    const clickHandler = () => {
        const direction = (sort?.field === sortableCol && sort?.direction === "asc") ? "desc" : "asc";
        handleSort(sortableCol, direction);
    }

    return (
        <th
            className={styles["sortable"]}
            onClick={clickHandler}
        >
            <div className={
                cn(styles["table__sorting"],
                    {[styles[`table__sorting--${sort?.direction}`]]: sort?.field === sortableCol}
                )}
            >
                {t(`${columnsTranslationNamespace}:table.${sortableCol}`)}
                <div className={styles["table__sorting-icons"]}>
                    <div className={styles["table__sorting-icon"]}>
                        <ArrowTriangularTopIcon/>
                    </div>
                    <div className={styles["table__sorting-icon"]}>
                        <ArrowTriangularDownIcon/>
                    </div>
                </div>
            </div>
        </th>
    );
};

export default React.memo(SortableTh);