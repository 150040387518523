/* eslint-disable no-unused-vars, max-len  */
import {createAsyncThunk, createSelector, createSlice, PayloadAction} from "@reduxjs/toolkit";
import {AxiosError, AxiosResponse} from "axios";

import {RootState} from "../store";
import {
    AnalyticsFiltersPinsType,
    AnalyticsFiltersTypesEnum,
    AnalyticsSectionEnum,
    DataFilterValuesEnum,
    DateGroupingValuesType,
    DefaultAnalyticsFiltersPartialType,
    EventsAndIncidentsFiltersType,
    IAEventsAndIncidentsResItem,
    IAIncidentsByZone,
    IAListIncidents,
    IAMapEventsAndIncidents,
    IAnalyticsFetchInformation,
    IAnalyticsFilterData,
    IAnalyticsPayload,
    IAnalyticsResponse,
    IAPriorityNumbersResItem,
    IAProcessCenterWorkflowResItem,
    IAReactionOperators,
    IAReactionZones,
    IAServices,
    IAStringEventsAndIncidentsResItem,
    IASummaryResItem,
    IATags,
    IATimePriority,
    IATypes,
    IAWeeklyIncidentsByHourResItem,
    IDateGrouping,
    IDefaultAnalyticsFilters,
    IFetchFilterOptions,
    IFilterSavedParams,
    IGetAnalyticsFetchInformation,
    IOverviewToAnother,
    ISelectSavedParam,
    ISetAnalyticsFilter,
    ISetCheckboxFilter,
    ISetDefaultFilter,
    OverviewFiltersType,
    ServicesFiltersType,
} from "../models/Analytics";
import {storageKeys} from "../constants/common";
import {getLocalStorageValue, getObjectFromLocalStorage} from "../utils/common";
import {
    comparisonOfFilters,
    defaultAnalyticsFilters,
    getFilterFromLocalStorage,
    prepareDateGrouping,
    prepareFiltersToFetch,
} from "../utils/AnalyticsUtilsTs";
import createHttpRequest from "../utils/http";
import {ApiUrls} from "../constants/urls";
import Feature from "../models/Feature";

export type LoadingStatusType = "loading" | "succeeded" | "failed" | "idle";

interface IInitialState {
    section: AnalyticsSectionEnum,
    [AnalyticsFiltersTypesEnum.OVERVIEW]: IAnalyticsFilterData<OverviewFiltersType>,
    [AnalyticsFiltersTypesEnum.EVENTS_AND_INCIDENTS]: IAnalyticsFilterData<EventsAndIncidentsFiltersType>,
    [AnalyticsFiltersTypesEnum.SERVICES]: IAnalyticsFilterData<ServicesFiltersType>,

    analyticsFetchInformation: IAnalyticsFetchInformation,

    eventsAndIncidents: IAEventsAndIncidentsResItem[],
    eventsAndIncidentsStatus: LoadingStatusType,
    compareEventsAndIncidents: IAEventsAndIncidentsResItem[],
    compareEventsAndIncidentsStatus: LoadingStatusType,
    anotherEventsAndIncidents: IAEventsAndIncidentsResItem[],
    anotherEventsAndIncidentsStatus: LoadingStatusType,

    weeklyIncidentsByHour: IAWeeklyIncidentsByHourResItem[],
    weeklyIncidentsByHourStatus: LoadingStatusType,

    listIncidents: IAListIncidents[],
    listIncidentsStatus: LoadingStatusType,
    isListIncidentsLoading: boolean,

    summary: IASummaryResItem[],
    summaryByZone: IASummaryResItem[],
    summaryStatus: LoadingStatusType,
    summaryByZoneStatus: LoadingStatusType,
    incidentsByZone: IAIncidentsByZone[],
    incidentsByZoneStatus: LoadingStatusType,

    services: IAServices[],
    servicesStatus: LoadingStatusType,

    processCenterWorkflow: IAProcessCenterWorkflowResItem[],
    processCenterWorkflowStatus: LoadingStatusType,

    stringEventsAndIncidents: IAStringEventsAndIncidentsResItem[],
    stringEventsAndIncidentsStatus: LoadingStatusType,

    mapEventsAndIncidents: IAMapEventsAndIncidents[],
    mapEventsAndIncidentsStatus: LoadingStatusType,
    mapEventsAndIncidentsLoading: boolean,

    priorityNumbers: IAPriorityNumbersResItem[],
    priorityNumbersStatus: LoadingStatusType,

    timePriority: IATimePriority[],
    timePriorityStatus: LoadingStatusType,

    types: IATypes[],
    typesStatus: LoadingStatusType,

    tags: IATags[],
    tagsStatus: LoadingStatusType,

    reactionOperators: IAReactionOperators[],
    reactionOperatorsStatus: LoadingStatusType,

    reactionZones: IAReactionZones[],
    reactionZonesStatus: LoadingStatusType,

    savedParams: IGetSavedParam[],
    savedParamsStatus: LoadingStatusType,
    postSavedParamsLoading: boolean,
    deleteSavedParamsLoading: boolean,

    openCreatedDateTimeFilterCounter: number,
}

// Overview
export const initialOverviewFilters: OverviewFiltersType = {
    dataFilter: defaultAnalyticsFilters.dataFilter,
    createdDateTimeFilter: defaultAnalyticsFilters.createdDateTimeFilter,
    tagsFilter: defaultAnalyticsFilters.tagsFilter,
    prioritiesFilter: defaultAnalyticsFilters.prioritiesFilter,
    typesFilter: defaultAnalyticsFilters.typesFilter,
};

const initialOverviewPins = Object.keys(initialOverviewFilters).reduce((acc, key) => {
    acc[key as keyof OverviewFiltersType] = false;
    return acc;
}, {} as { [K in keyof OverviewFiltersType]: boolean });

// EventsAndIncidents
export const initialEventsAndIncidentsFilters: EventsAndIncidentsFiltersType = {
    zoneFilter: defaultAnalyticsFilters.zoneFilter,
    dataFilter: defaultAnalyticsFilters.dataFilter,
    sourcesFilter: defaultAnalyticsFilters.sourcesFilter,
    //shiftsFilter: defaultAnalyticsFilters.shiftsFilter,
    prioritiesFilter: defaultAnalyticsFilters.prioritiesFilter,
    typesFilter: defaultAnalyticsFilters.typesFilter,
    tagsFilter: defaultAnalyticsFilters.tagsFilter,
    hoursTimeFilter: defaultAnalyticsFilters.hoursTimeFilter,
    createdDateTimeFilter: defaultAnalyticsFilters.createdDateTimeFilter,
    segmentIdsFilter: defaultAnalyticsFilters.segmentIdsFilter,
    roadObjectsIds: defaultAnalyticsFilters.roadObjectsIds,
};

const initialEventsAndIncidentsPins = Object.keys(initialEventsAndIncidentsFilters).reduce((acc, key) => {
    acc[key as keyof EventsAndIncidentsFiltersType] = false;
    return acc;
}, {} as { [K in keyof EventsAndIncidentsFiltersType]: boolean });

// Services
export const initialServicesFilters: ServicesFiltersType = {
    zoneFilter: defaultAnalyticsFilters.zoneFilter,
    dataFilter: defaultAnalyticsFilters.dataFilter,
    operatorsFilter: defaultAnalyticsFilters.operatorsFilter,
    responseServicesFilter: defaultAnalyticsFilters.responseServicesFilter,
    sourcesFilter: defaultAnalyticsFilters.sourcesFilter,
    //shiftsFilter: defaultAnalyticsFilters.shiftsFilter,
    prioritiesFilter: defaultAnalyticsFilters.prioritiesFilter,
    typesFilter: defaultAnalyticsFilters.typesFilter,
    tagsFilter: defaultAnalyticsFilters.tagsFilter,
    hoursTimeFilter: defaultAnalyticsFilters.hoursTimeFilter,
    createdDateTimeFilter: defaultAnalyticsFilters.createdDateTimeFilter,
};

const initialServicesPins = Object.keys(initialServicesFilters).reduce((acc, key) => {
    acc[key as keyof ServicesFiltersType] = false;
    return acc;
}, {} as { [K in keyof ServicesFiltersType]: boolean });

export const initialAnalyticsFiltersList = {
    [AnalyticsFiltersTypesEnum.OVERVIEW]: initialOverviewFilters,
    [AnalyticsFiltersTypesEnum.EVENTS_AND_INCIDENTS]: initialEventsAndIncidentsFilters,
    [AnalyticsFiltersTypesEnum.SERVICES]: initialServicesFilters,
};

export const initialAnalyticsState: IInitialState = {
    section: getLocalStorageValue<AnalyticsSectionEnum>(storageKeys.analyticsSection)
        || AnalyticsSectionEnum.OVERVIEW,

    [AnalyticsFiltersTypesEnum.OVERVIEW]: {
        filters: getFilterFromLocalStorage<OverviewFiltersType>(AnalyticsFiltersTypesEnum.OVERVIEW),
        pins: getObjectFromLocalStorage<AnalyticsFiltersPinsType<OverviewFiltersType>>
        (AnalyticsFiltersTypesEnum.OVERVIEW + "Pins")
            || initialOverviewPins,
    },

    [AnalyticsFiltersTypesEnum.EVENTS_AND_INCIDENTS]: {
        filters: getFilterFromLocalStorage<EventsAndIncidentsFiltersType>(AnalyticsFiltersTypesEnum.EVENTS_AND_INCIDENTS),
        pins: getObjectFromLocalStorage<AnalyticsFiltersPinsType<EventsAndIncidentsFiltersType>>
        (AnalyticsFiltersTypesEnum.EVENTS_AND_INCIDENTS + "Pins")
            || initialEventsAndIncidentsPins,
    },

    [AnalyticsFiltersTypesEnum.SERVICES]: {
        filters: getFilterFromLocalStorage<ServicesFiltersType>(AnalyticsFiltersTypesEnum.SERVICES),
        pins: getObjectFromLocalStorage<AnalyticsFiltersPinsType<ServicesFiltersType>>
        (AnalyticsFiltersTypesEnum.SERVICES + "Pins")
            || initialServicesPins,
    },
    analyticsFetchInformation: {
        dateGrouping: "day",
        datePeriod: {
            lowerBound: "",
            upperBound: "",
        },
        eventsOrIncidents: DataFilterValuesEnum.events,
        modifiedFieldsCount: 0,
        selectedRoadObjects: [],
        createdDate: defaultAnalyticsFilters.createdDateTimeFilter.value,
        selectedPriorities: [],
    },
    eventsAndIncidents: [],
    eventsAndIncidentsStatus: "idle",
    compareEventsAndIncidents: [],
    compareEventsAndIncidentsStatus: "idle",
    anotherEventsAndIncidents: [],
    anotherEventsAndIncidentsStatus: "idle",

    stringEventsAndIncidents: [],
    stringEventsAndIncidentsStatus: "idle",

    listIncidents: [],
    listIncidentsStatus: "idle",
    isListIncidentsLoading: false,

    summary: [],
    summaryByZone: [],
    summaryStatus: "idle",
    summaryByZoneStatus: "idle",
    incidentsByZone: [],
    incidentsByZoneStatus: "idle",

    services: [],
    servicesStatus: "idle",

    processCenterWorkflow: [],
    processCenterWorkflowStatus: "idle",

    weeklyIncidentsByHour: [],
    weeklyIncidentsByHourStatus: "idle",

    mapEventsAndIncidents: [],
    mapEventsAndIncidentsStatus: "idle",
    mapEventsAndIncidentsLoading: false,

    priorityNumbers: [],
    priorityNumbersStatus: "idle",

    timePriority: [],
    timePriorityStatus: "idle",

    types: [],
    typesStatus: "idle",

    tags: [],
    tagsStatus: "idle",

    reactionOperators: [],
    reactionOperatorsStatus: "idle",

    reactionZones: [],
    reactionZonesStatus: "idle",

    savedParams: [],
    savedParamsStatus: "idle",
    deleteSavedParamsLoading: false,
    postSavedParamsLoading: false,

    openCreatedDateTimeFilterCounter: 1,
};

export const postEventsAndIncidents = createAsyncThunk<
    IAnalyticsResponse<IAEventsAndIncidentsResItem>,
    IAnalyticsPayload<DefaultAnalyticsFiltersPartialType>,
    {rejectValue: AxiosError<any>}
>(
    "analytics/eventsAndIncidents",
    async(params, {rejectWithValue}) => {
        const data = prepareFiltersToFetch(params.fetchData);

        const readyDateGrouping = prepareDateGrouping(data.createdDateTime);

        const dataWithGrouping: IFetchFilterOptions & IDateGrouping = {
            ...data,
            dateGrouping: readyDateGrouping,
        };

        try {
            const res = await createHttpRequest({
                method: "POST",
                path: ApiUrls.ANALYTICS.INCIDENTS,
                data: dataWithGrouping,
                silent: true,
                signal: params.signal,
            }) as AxiosResponse<IAnalyticsResponse<IAEventsAndIncidentsResItem>>;

            return {...res.data, filterData: dataWithGrouping};
        } catch (error) {
            return rejectWithValue(error as AxiosError<any>);
        }
    }
);

interface IPostCompareEventsAndIncidents {
    dateGrouping: DateGroupingValuesType,
    createdDateTime: {
        lowerBound: string,
        upperBound: string,
    },
    filterType: AnalyticsFiltersTypesEnum,
}

export const postCompareEventsAndIncidents = createAsyncThunk<
    IAnalyticsResponse<IAEventsAndIncidentsResItem>,
    IAnalyticsPayload<IPostCompareEventsAndIncidents>,
    {rejectValue: AxiosError<any>, state: RootState}
>(
    "analytics/compareEventsAndIncidents",
    async(params, {rejectWithValue, getState}) => {

        const filterState = getState().analyticsReducer[params.fetchData.filterType].filters;

        const data = prepareFiltersToFetch(filterState as Partial<IDefaultAnalyticsFilters>);

        const readyDateGrouping = params.fetchData.dateGrouping;

        const dataWithGrouping: IFetchFilterOptions & IDateGrouping = {
            ...data,
            createdDateTime: {
                ...params.fetchData.createdDateTime,
                excludingUpperBound: params.fetchData.createdDateTime.upperBound.split("T")[1] === "00:00:00",
            },
            dateGrouping: readyDateGrouping,
        };

        try {
            const res = await createHttpRequest({
                method: "POST",
                path: ApiUrls.ANALYTICS.INCIDENTS,
                data: dataWithGrouping,
                silent: true,
                signal: params.signal,
            }) as AxiosResponse<IAnalyticsResponse<IAEventsAndIncidentsResItem>>;

            return res.data;
        } catch (error) {
            return rejectWithValue(error as AxiosError<any>);
        }
    }
);

export const postAnotherEventsAndIncidents = createAsyncThunk<
    IAnalyticsResponse<IAEventsAndIncidentsResItem>,
    IAnalyticsPayload<{filterType: AnalyticsFiltersTypesEnum}>,
    {rejectValue: AxiosError<any>, state: RootState}
>(
    "analytics/anotherEventsAndIncidents",
    async(params, {rejectWithValue, getState}) => {

        const filterState = getState().analyticsReducer[params.fetchData.filterType].filters;
        const data = prepareFiltersToFetch(filterState as Partial<IDefaultAnalyticsFilters>);

        const readyDateGrouping = prepareDateGrouping(data.createdDateTime);

        const dataWithAnotherKind: IFetchFilterOptions & IDateGrouping = {
            ...data,
            kind: filterState.dataFilter.value === DataFilterValuesEnum.incidents
                ? [DataFilterValuesEnum.events]
                : [DataFilterValuesEnum.incidents],
            dateGrouping: readyDateGrouping,
        };

        try {
            const res = await createHttpRequest({
                method: "POST",
                path: ApiUrls.ANALYTICS.INCIDENTS,
                data: dataWithAnotherKind,
                silent: true,
                signal: params.signal,
            }) as AxiosResponse<IAnalyticsResponse<IAEventsAndIncidentsResItem>>;

            return res.data;
        } catch (error) {
            return rejectWithValue(error as AxiosError<any>);
        }
    }
);

export const postListIncidents = createAsyncThunk<
    IAnalyticsResponse<IAListIncidents>,
    IAnalyticsPayload<DefaultAnalyticsFiltersPartialType>,
    {rejectValue: AxiosError<any>}
>(
    "analytics/listIncidents",
    async(params, {rejectWithValue}) => {
        const data = prepareFiltersToFetch(params.fetchData);
        try {
            const res = await createHttpRequest({
                method: "POST",
                path: ApiUrls.ANALYTICS.LIST_INCIDENTS,
                data,
                silent: true,
                signal: params.signal,
            }) as AxiosResponse<IAnalyticsResponse<IAListIncidents>>;

            return res.data;
        } catch (error) {
            return rejectWithValue(error as AxiosError<any>);
        }
    }
);

export const postWeeklyIncidentsByHour = createAsyncThunk<
    IAnalyticsResponse<IAWeeklyIncidentsByHourResItem>,
    IAnalyticsPayload<DefaultAnalyticsFiltersPartialType>,
    {rejectValue: AxiosError<any>}
>(
    "analytics/weeklyIncidentsByHour",
    async(params, {rejectWithValue}) => {
        const data = prepareFiltersToFetch(params.fetchData);
        try {
            const res = await createHttpRequest({
                method: "POST",
                path: ApiUrls.ANALYTICS.WEEKLY_INCIDENTS_BY_HOUR,
                data,
                silent: true,
                signal: params.signal,
            }) as AxiosResponse<IAnalyticsResponse<IAWeeklyIncidentsByHourResItem>>;

            return res.data;
        } catch (error) {
            return rejectWithValue(error as AxiosError<any>);
        }
    }
);

export const postSummary = createAsyncThunk<
    IAnalyticsResponse<IASummaryResItem>,
    IAnalyticsPayload<DefaultAnalyticsFiltersPartialType>,
    {rejectValue: AxiosError<any>}
>(
    "analytics/summary",
    async(params, {rejectWithValue}) => {
        const data = prepareFiltersToFetch(params.fetchData);
        try {
            const res = await createHttpRequest({
                method: "POST",
                path: ApiUrls.ANALYTICS.SUMMARY,
                data,
                silent: true,
                signal: params.signal,
            }) as AxiosResponse<IAnalyticsResponse<IASummaryResItem>>;

            return res.data;
        } catch (error) {
            return rejectWithValue(error as AxiosError<any>);
        }
    }
);

export const postSummaryByZone = createAsyncThunk<
    IAnalyticsResponse<IASummaryResItem>,
    DefaultAnalyticsFiltersPartialType,
    {rejectValue: AxiosError<any>}
>(
    "analytics/summaryByZone",
    async(filters, {rejectWithValue}) => {
        const data = prepareFiltersToFetch(filters);
        try {
            const res = await createHttpRequest({
                method: "POST",
                path: ApiUrls.ANALYTICS.SUMMARY,
                data,
                silent: true,
            }) as AxiosResponse<IAnalyticsResponse<IASummaryResItem>>;

            return res.data;
        } catch (error) {
            return rejectWithValue(error as AxiosError<any>);
        }
    }
);

export const postIncidentsByZone = createAsyncThunk<
    IAnalyticsResponse<IAIncidentsByZone>,
    IAnalyticsPayload<DefaultAnalyticsFiltersPartialType>,
    {rejectValue: AxiosError<any>}
>(
    "analytics/incidentsByZone",
    async(params, {rejectWithValue}) => {
        const data = prepareFiltersToFetch(params.fetchData);
        try {
            const res = await createHttpRequest({
                method: "POST",
                path: ApiUrls.ANALYTICS.INCIDENTS_BY_ZONE,
                data,
                silent: true,
                signal: params.signal,
            }) as AxiosResponse<IAnalyticsResponse<IAIncidentsByZone>>;

            return res.data;
        } catch (error) {
            return rejectWithValue(error as AxiosError<any>);
        }
    }
);

export const postServices = createAsyncThunk<
    IAnalyticsResponse<IAServices>,
    IAnalyticsPayload<DefaultAnalyticsFiltersPartialType>,
    {rejectValue: AxiosError<any>}
>(
    "analytics/services",
    async(params, {rejectWithValue}) => {
        const data = prepareFiltersToFetch(params.fetchData);
        try {
            const res = await createHttpRequest({
                method: "POST",
                path: ApiUrls.ANALYTICS.SERVICES,
                data,
                silent: true,
                signal: params.signal,
            }) as AxiosResponse<IAnalyticsResponse<IAServices>>;

            return res.data;
        } catch (error) {
            return rejectWithValue(error as AxiosError<any>);
        }
    }
);

export const postProcessCenterWorkflow = createAsyncThunk<
    IAnalyticsResponse<IAProcessCenterWorkflowResItem>,
    IAnalyticsPayload<DefaultAnalyticsFiltersPartialType>,
    {rejectValue: AxiosError<any>}
>(
    "analytics/processCenterWorkflow",
    async(params, {rejectWithValue}) => {
        const data = prepareFiltersToFetch(params.fetchData);
        try {
            const res = await createHttpRequest({
                method: "POST",
                path: ApiUrls.ANALYTICS.PROCESS_CENTER_WORKFLOW,
                data,
                silent: true,
                signal: params.signal,
            }) as AxiosResponse<IAnalyticsResponse<IAProcessCenterWorkflowResItem>>;

            return res.data;
        } catch (error) {
            return rejectWithValue(error as AxiosError<any>);
        }
    }
);

export const postStringEventsAndIncidents = createAsyncThunk<
    IAnalyticsResponse<IAStringEventsAndIncidentsResItem>,
    IAnalyticsPayload<DefaultAnalyticsFiltersPartialType>,
    {rejectValue: AxiosError<any>}
>(
    "analytics/stringEventsAndIncidents",
    async(params, {rejectWithValue}) => {
        const data = prepareFiltersToFetch(params.fetchData);
        try {
            const res = await createHttpRequest({
                method: "POST",
                path: ApiUrls.ANALYTICS.STRING_EVENTS_AND_INCIDENTS,
                data,
                silent: true,
                signal: params.signal,
            }) as AxiosResponse<IAnalyticsResponse<IAStringEventsAndIncidentsResItem>>;

            return res.data;
        } catch (error) {
            return rejectWithValue(error as AxiosError<any>);
        }
    }
);

export const postMapEventsAndIncidents = createAsyncThunk<
    IAnalyticsResponse<IAMapEventsAndIncidents>,
    IAnalyticsPayload<DefaultAnalyticsFiltersPartialType>,
    {rejectValue: AxiosError<any>}
>(
    "analytics/mapEventsAndIncidents",
    async(params, {rejectWithValue}) => {
        const data = prepareFiltersToFetch(params.fetchData);
        try {
            const res = await createHttpRequest({
                method: "POST",
                path: ApiUrls.ANALYTICS.MAP_EVENTS_AND_INCIDENTS,
                data,
                silent: true,
                signal: params.signal,
            }) as AxiosResponse<IAnalyticsResponse<IAMapEventsAndIncidents>>;

            return res.data;
        } catch (error) {
            return rejectWithValue(error as AxiosError<any>);
        }
    }
);

export const postPriorityNumbers = createAsyncThunk<
    IAnalyticsResponse<IAPriorityNumbersResItem>,
    IAnalyticsPayload<DefaultAnalyticsFiltersPartialType>,
    {rejectValue: AxiosError<any>}
>(
    "analytics/priorityNumbers",
    async(params, {rejectWithValue}) => {
        const data = prepareFiltersToFetch(params.fetchData);
        try {
            const res = await createHttpRequest({
                method: "POST",
                path: ApiUrls.ANALYTICS.PRIORITY_NUMBERS,
                data,
                silent: true,
                signal: params.signal,
            }) as AxiosResponse<IAnalyticsResponse<IAPriorityNumbersResItem>>;

            return res.data;
        } catch (error) {
            return rejectWithValue(error as AxiosError<any>);
        }
    }
);

export const postTimePriority = createAsyncThunk<
    IAnalyticsResponse<IATimePriority>,
    IAnalyticsPayload<DefaultAnalyticsFiltersPartialType>,
    {rejectValue: AxiosError<any>}
>(
    "analytics/timePriority",
    async(params, {rejectWithValue}) => {
        const data = prepareFiltersToFetch(params.fetchData);
        try {
            const res = await createHttpRequest({
                method: "POST",
                path: ApiUrls.ANALYTICS.TIME_PRIORITY,
                data,
                silent: true,
                signal: params.signal,
            }) as AxiosResponse<IAnalyticsResponse<IATimePriority>>;

            return res.data;
        } catch (error) {
            return rejectWithValue(error as AxiosError<any>);
        }
    }
);

export const postTypes = createAsyncThunk<
    IAnalyticsResponse<IATypes>,
    IAnalyticsPayload<DefaultAnalyticsFiltersPartialType>,
    {rejectValue: AxiosError<any>}
>(
    "analytics/types",
    async(params, {rejectWithValue}) => {
        const data = prepareFiltersToFetch(params.fetchData);
        try {
            const res = await createHttpRequest({
                method: "POST",
                path: ApiUrls.ANALYTICS.TYPES,
                data,
                silent: true,
                signal: params.signal,
            }) as AxiosResponse<IAnalyticsResponse<IATypes>>;

            return res.data;
        } catch (error) {
            return rejectWithValue(error as AxiosError<any>);
        }
    }
);

export const postTags = createAsyncThunk<
    IAnalyticsResponse<IATags>,
    IAnalyticsPayload<DefaultAnalyticsFiltersPartialType>,
    {rejectValue: AxiosError<any>}
>(
    "analytics/tags",
    async(params, {rejectWithValue}) => {
        const data = prepareFiltersToFetch(params.fetchData);
        try {
            const res = await createHttpRequest({
                method: "POST",
                path: ApiUrls.ANALYTICS.TAGS,
                data,
                silent: true,
                signal: params.signal,
            }) as AxiosResponse<IAnalyticsResponse<IATags>>;

            return res.data;
        } catch (error) {
            return rejectWithValue(error as AxiosError<any>);
        }
    }
);

export const postReactionOperators = createAsyncThunk<
    IAnalyticsResponse<IAReactionOperators>,
    IAnalyticsPayload<DefaultAnalyticsFiltersPartialType>,
    {rejectValue: AxiosError<any>}
>(
    "analytics/reactionOperators",
    async(params, {rejectWithValue}) => {
        const data = prepareFiltersToFetch(params.fetchData);
        try {
            const res = await createHttpRequest({
                method: "POST",
                path: ApiUrls.ANALYTICS.REACTION_OPERATORS,
                data,
                silent: true,
                signal: params.signal,
            }) as AxiosResponse<IAnalyticsResponse<IAReactionOperators>>;

            return res.data;
        } catch (error) {
            return rejectWithValue(error as AxiosError<any>);
        }
    }
);

export const postReactionZones = createAsyncThunk<
    IAnalyticsResponse<IAReactionZones>,
    IAnalyticsPayload<DefaultAnalyticsFiltersPartialType>,
    {rejectValue: AxiosError<any>}
>(
    "analytics/reactionZones",
    async(params, {rejectWithValue}) => {
        const data = prepareFiltersToFetch(params.fetchData);
        try {
            const res = await createHttpRequest({
                method: "POST",
                path: ApiUrls.ANALYTICS.REACTION_ZONES,
                data,
                silent: true,
                signal: params.signal,
            }) as AxiosResponse<IAnalyticsResponse<IAReactionZones>>;

            return res.data;
        } catch (error) {
            return rejectWithValue(error as AxiosError<any>);
        }
    }
);

export interface IGetSavedParam {
    Id: number,
    Name: string,
    TypeId: "INCIDENTS_FILTERCOMBINATIONS.1",
    Created: string,
    LastModified: string,
    Data: IFilterSavedParams,
}

interface IGetSavedBody {
    Result: IGetSavedParam[],
    Total: number,
}

export const getSavedParams = createAsyncThunk<IGetSavedBody,
    IAnalyticsPayload<null>,
    {rejectValue: AxiosError<any>}
>(
    "analytics/getSavedParams",
    async(params, {rejectWithValue}) => {

        try {
            const res = await createHttpRequest({
                method: "GET",
                path: ApiUrls.INCIDENT_DICTIONARIES("INCIDENTS_FILTERCOMBINATIONS.1"),
                silent: true,
                signal: params.signal,
            }) as AxiosResponse<IGetSavedBody>;

            return res.data;

        } catch (error) {
            return rejectWithValue(error as AxiosError<any>);
        }
    }
);

interface IPostSavedParams {
    filterType: AnalyticsFiltersTypesEnum,
    name: string,
}

interface IPostSavedParamsPrepareData {
    name: string,
    typeId: "INCIDENTS_FILTERCOMBINATIONS.1",
    data: IFilterSavedParams,
}

export const postSavedParams = createAsyncThunk<void,
    IAnalyticsPayload<IPostSavedParams>,
    {rejectValue: AxiosError<any>, state: RootState}
>(
    "analytics/postSavedParams",
    async(params, {rejectWithValue, getState, dispatch}) => {

        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        const {createdDateTimeFilter, ...filterData} = getState().analyticsReducer[params.fetchData.filterType].filters;

        const preparedData: IPostSavedParamsPrepareData = {
            name: params.fetchData.name,
            typeId: "INCIDENTS_FILTERCOMBINATIONS.1",
            data: {...filterData, filterType: params.fetchData.filterType},
        };

        try {
            await createHttpRequest({
                method: "POST",
                path: ApiUrls.INCIDENT_DICTIONARIES("INCIDENTS_FILTERCOMBINATIONS.1"),
                silent: true,
                data: preparedData,
                signal: params.signal,
            });

            dispatch(getSavedParams({fetchData: null}));
        } catch (error) {
            return rejectWithValue(error as AxiosError<any>);
        }
    }
);

interface IDeleteSavedParams {
    id: number,
}

export const deleteSavedParams = createAsyncThunk<any,
    IAnalyticsPayload<IDeleteSavedParams>,
    {rejectValue: AxiosError<any>}
>(
    "analytics/deleteSavedParams",
    async(params, {rejectWithValue, dispatch}) => {

        try {
            await createHttpRequest({
                method: "DELETE",
                path: ApiUrls.INCIDENT_DICTIONARY(params.fetchData.id),
                silent: true,
                signal: params.signal,
            });

            dispatch(getSavedParams({fetchData: null}));
        } catch (error) {
            return rejectWithValue(error as AxiosError<any>);
        }
    }
);

const analytics = createSlice({
    name: "analytics",
    initialState: initialAnalyticsState,
    reducers: {

        setRoadObjects(state, action: PayloadAction<Feature>) {

            const filterValue =
                [...state[AnalyticsFiltersTypesEnum.EVENTS_AND_INCIDENTS].filters.roadObjectsIds.value];

            if (filterValue.some(item => item.id === action.payload.id)) {
                state[AnalyticsFiltersTypesEnum.EVENTS_AND_INCIDENTS].filters.roadObjectsIds.value =
                    filterValue.filter(item => item.id !== action.payload.id);
            } else {
                state[AnalyticsFiltersTypesEnum.EVENTS_AND_INCIDENTS].filters.roadObjectsIds.value =
                    [...filterValue, action.payload];
            }
        },

        selectSavedParam(state, action: PayloadAction<ISelectSavedParam>) {
            const {savedFilterParams} = action.payload;

            const {filterType, ...readyFiltersParams} = savedFilterParams;

            if (action.payload.filterType === savedFilterParams.filterType) {

                state[filterType].filters = {
                    ...state[filterType].filters,
                    ...readyFiltersParams,
                };

                state.openCreatedDateTimeFilterCounter += 1;
            }
        },

        getAnalyticsFetchInformation(state, action: PayloadAction<IGetAnalyticsFetchInformation>) {
            const {filterType, filter} = action.payload;

            const information = prepareFiltersToFetch(filter);
            const readyDateGrouping = prepareDateGrouping(information.createdDateTime);

            localStorage.setItem(filterType, JSON.stringify(filter));

            state.analyticsFetchInformation = {
                dateGrouping: readyDateGrouping,
                datePeriod: {
                    upperBound: information.createdDateTime.upperBound,
                    lowerBound: information.createdDateTime.lowerBound,
                },
                eventsOrIncidents: information.kind[0],
                modifiedFieldsCount: comparisonOfFilters(filter),
                selectedRoadObjects: filter.roadObjectsIds?.value || [],
                createdDate: filter.createdDateTimeFilter?.value || defaultAnalyticsFilters.createdDateTimeFilter.value,
                selectedPriorities: filter.prioritiesFilter?.value || [],
            };
        },

        clearCompareEventsAndIncidents(state) {
            state.compareEventsAndIncidents = [];
            state.compareEventsAndIncidentsStatus = "idle";
        },

        clearAnotherEventsAndIncidents(state) {
            state.anotherEventsAndIncidents = [];
            state.anotherEventsAndIncidentsStatus = "idle";
        },

        switchAnalyticsSection(state, action: PayloadAction<AnalyticsSectionEnum>) {
            state.section = action.payload;
            localStorage.setItem(storageKeys.analyticsSection, action.payload);
        },

        setAnalyticsFilter(state, action: PayloadAction<ISetAnalyticsFilter>) {
            const {filterType, filterKey, value} = action.payload;

            if (filterKey in state[filterType].filters) {

                const newState = {
                    ...state[filterType].filters as unknown as IDefaultAnalyticsFilters,
                };
                newState[filterKey].value = value;

                (state[filterType].filters as IDefaultAnalyticsFilters) = newState;
            }
        },

        setCheckboxFilter(state, action: PayloadAction<ISetCheckboxFilter>) {
            const {filterType, filterKey, data, addOrRemove} = action.payload;

            if (filterKey in state[filterType].filters) {
                const filterData =
                    new Set((state[filterType].filters as any)[filterKey].value as Set<number | string>);

                data.forEach((value) => {
                    if (addOrRemove === "add") {
                        filterData.add(value);
                    }
                    if (addOrRemove === "remove") {
                        filterData.delete(value);
                    }
                });

                const newStateFilterValue = Array.from(filterData);

                const newState = {
                    ...state[filterType].filters as unknown as IDefaultAnalyticsFilters,
                };
                (newState[filterKey].value as any) = newStateFilterValue;

                (state[filterType].filters as any) = newState;
            }
        },

        toggleModeTagFilter(state, action: PayloadAction<AnalyticsFiltersTypesEnum>) {
            const filterType = action.payload;
            const newState = {...state[filterType].filters};
            newState.tagsFilter.modeTags = newState.tagsFilter.modeTags === "AND" ? "OR" : "AND";

            state[action.payload].filters = newState;
        },

        resetFilter(state, action: PayloadAction<ISetDefaultFilter>) {
            const {filterType, filterKey} = action.payload;
            if (filterKey in state[filterType].filters) {

                const newState = {
                    ...state[filterType].filters as unknown as IDefaultAnalyticsFilters,
                };
                newState[filterKey].value = defaultAnalyticsFilters[filterKey].value;

                (state[filterType].filters as IDefaultAnalyticsFilters) = newState;
            }
        },

        resetAllFilters(state, action: PayloadAction<AnalyticsFiltersTypesEnum>) {
            const filterType = action.payload;
            (state[filterType].filters as any) = initialAnalyticsFiltersList[filterType];
        },

        togglePinFilter(state, action: PayloadAction<ISetDefaultFilter>) {
            const {filterType, filterKey} = action.payload;

            const filterState = state[filterType];

            if (filterState && filterState.pins) {
                const currentPins = {...filterState.pins};

                if (filterKey in currentPins) {
                    const typedFilterKey = filterKey as keyof typeof currentPins;

                    currentPins[typedFilterKey] = !currentPins[typedFilterKey];

                    //Записываем новое значение в localStorage при каждом изменении
                    localStorage.setItem(filterType + "Pins", JSON.stringify(currentPins));

                    (filterState as any).pins = currentPins;
                }
            }
        },

        setFilterFromOverviewToAnother(state, action: PayloadAction<IOverviewToAnother>) {
            const {anotherFilterType, overviewFilterData} = action.payload;

            const newState = {...state[anotherFilterType].filters, ...overviewFilterData};

            state[anotherFilterType].filters = newState;

            //Записываем новое значение в localStorage при каждом изменении
            localStorage.setItem(anotherFilterType, JSON.stringify(newState));

            (state[anotherFilterType].filters as any) = newState;
        },
    },

    extraReducers: (builder) => {
        builder
            //getSavedParams
            .addCase(getSavedParams.pending, (state) => {
                state.savedParamsStatus = "loading";
            })
            .addCase(getSavedParams.fulfilled, (state, action) => {
                state.savedParams = action.payload.Result;
                state.savedParamsStatus = "succeeded";
            })
            .addCase(getSavedParams.rejected, (state) => {
                state.savedParamsStatus = "failed";
            });

        builder
            //postSavedParams
            .addCase(postSavedParams.pending, (state) => {
                state.postSavedParamsLoading = true;
            })
            .addCase(postSavedParams.fulfilled, (state) => {
                state.postSavedParamsLoading = false;
            })
            .addCase(postSavedParams.rejected, (state) => {
                state.postSavedParamsLoading = false;
            });

        builder
            //deleteSavedParams
            .addCase(deleteSavedParams.pending, (state) => {
                state.deleteSavedParamsLoading = true;
            })
            .addCase(deleteSavedParams.fulfilled, (state) => {
                state.deleteSavedParamsLoading = false;
            })
            .addCase(deleteSavedParams.rejected, (state) => {
                state.deleteSavedParamsLoading = false;
            });

        builder
            // postEventsAndIncidents
            .addCase(postEventsAndIncidents.pending, (state) => {
                state.eventsAndIncidentsStatus = "loading";
            })
            .addCase(postEventsAndIncidents.fulfilled, (state, action) => {
                state.eventsAndIncidents = action.payload.items;
                state.eventsAndIncidentsStatus = "succeeded";
            })
            .addCase(postEventsAndIncidents.rejected, (state) => {
                state.eventsAndIncidentsStatus = "failed";
            });

        builder
            // postCompareEventsAndIncidents
            .addCase(postCompareEventsAndIncidents.pending, (state) => {
                state.compareEventsAndIncidentsStatus = "loading";
            })
            .addCase(postCompareEventsAndIncidents.fulfilled, (state, action) => {
                state.compareEventsAndIncidents = action.payload.items;
                state.compareEventsAndIncidentsStatus = "succeeded";
            })
            .addCase(postCompareEventsAndIncidents.rejected, (state) => {
                state.compareEventsAndIncidentsStatus = "failed";
            });

        builder
            // postAnotherEventsAndIncidents
            .addCase(postAnotherEventsAndIncidents.pending, (state) => {
                state.anotherEventsAndIncidentsStatus = "loading";
            })
            .addCase(postAnotherEventsAndIncidents.fulfilled, (state, action) => {
                state.anotherEventsAndIncidents = action.payload.items;
                state.anotherEventsAndIncidentsStatus = "succeeded";
            })
            .addCase(postAnotherEventsAndIncidents.rejected, (state) => {
                state.anotherEventsAndIncidentsStatus = "failed";
            });

        builder
            // postSummary
            .addCase(postSummary.pending, (state) => {
                state.summaryStatus = "loading";
            })
            .addCase(postSummary.fulfilled, (state, action) => {
                state.summary = action.payload.items;
                state.summaryStatus = "succeeded";
            })
            .addCase(postSummary.rejected, (state) => {
                state.summaryStatus = "failed";
            });

        builder
            // postSummary
            .addCase(postSummaryByZone.pending, (state) => {
                state.summaryByZoneStatus = "loading";
            })
            .addCase(postSummaryByZone.fulfilled, (state, action) => {
                state.summaryByZone = action.payload.items;
                state.summaryByZoneStatus = "succeeded";
            })
            .addCase(postSummaryByZone.rejected, (state) => {
                state.summaryByZoneStatus = "failed";
            });

        builder
            // postIncidentsByZone
            .addCase(postIncidentsByZone.pending, (state) => {
                state.incidentsByZoneStatus = "loading";
            })
            .addCase(postIncidentsByZone.fulfilled, (state, action) => {
                state.incidentsByZone = action.payload.items;
                state.incidentsByZoneStatus = "succeeded";
            })
            .addCase(postIncidentsByZone.rejected, (state) => {
                state.incidentsByZoneStatus = "failed";
            });

        builder
            // postServices
            .addCase(postServices.pending, (state) => {
                state.servicesStatus = "loading";
            })
            .addCase(postServices.fulfilled, (state, action) => {
                state.services = action.payload.items;
                state.servicesStatus = "succeeded";
            })
            .addCase(postServices.rejected, (state) => {
                state.servicesStatus = "failed";
            });

        builder
            // postProcessCenterWorkflow
            .addCase(postProcessCenterWorkflow.pending, (state) => {
                state.processCenterWorkflowStatus = "loading";
            })
            .addCase(postProcessCenterWorkflow.fulfilled, (state, action) => {
                state.processCenterWorkflow = action.payload.items;
                state.processCenterWorkflowStatus = "succeeded";
            })
            .addCase(postProcessCenterWorkflow.rejected, (state) => {
                state.processCenterWorkflowStatus = "failed";
            });

        builder
            // postWeeklyIncidentsByHour
            .addCase(postWeeklyIncidentsByHour.pending, (state) => {
                state.weeklyIncidentsByHourStatus = "loading";
            })
            .addCase(postWeeklyIncidentsByHour.fulfilled, (state, action) => {
                state.weeklyIncidentsByHour = action.payload.items;
                state.weeklyIncidentsByHourStatus = "succeeded";
            })
            .addCase(postWeeklyIncidentsByHour.rejected, (state) => {
                state.weeklyIncidentsByHourStatus = "failed";
            });

        builder
            // postStringEventsAndIncidents
            .addCase(postStringEventsAndIncidents.pending, (state) => {
                state.stringEventsAndIncidentsStatus = "loading";
            })
            .addCase(postStringEventsAndIncidents.fulfilled, (state, action) => {
                state.stringEventsAndIncidents = action.payload.items;
                state.stringEventsAndIncidentsStatus = "succeeded";
            })
            .addCase(postStringEventsAndIncidents.rejected, (state) => {
                state.stringEventsAndIncidentsStatus = "failed";
            });

        builder
            // postMapEventsAndIncidents
            .addCase(postMapEventsAndIncidents.pending, (state) => {
                state.mapEventsAndIncidentsStatus = "loading";
                state.mapEventsAndIncidentsLoading = true;
            })
            .addCase(postMapEventsAndIncidents.fulfilled, (state, action) => {
                state.mapEventsAndIncidents = action.payload.items;
                state.mapEventsAndIncidentsStatus = "succeeded";
                state.mapEventsAndIncidentsLoading = false;
            })
            .addCase(postMapEventsAndIncidents.rejected, (state) => {
                state.mapEventsAndIncidentsStatus = "failed";
                state.mapEventsAndIncidentsLoading = false;
            });

        builder
            // postPriorityNumbers
            .addCase(postPriorityNumbers.pending, (state) => {
                state.priorityNumbersStatus = "loading";
            })
            .addCase(postPriorityNumbers.fulfilled, (state, action) => {
                state.priorityNumbers = action.payload.items;
                state.priorityNumbersStatus = "succeeded";
            })
            .addCase(postPriorityNumbers.rejected, (state) => {
                state.priorityNumbersStatus = "failed";
            });

        builder
            // postTimePriority
            .addCase(postTimePriority.pending, (state) => {
                state.timePriorityStatus = "loading";
            })
            .addCase(postTimePriority.fulfilled, (state, action) => {
                state.timePriority = action.payload.items;
                state.timePriorityStatus = "succeeded";
            })
            .addCase(postTimePriority.rejected, (state) => {
                state.timePriorityStatus = "failed";
            });

        builder
            // postTypes
            .addCase(postTypes.pending, (state) => {
                state.typesStatus = "loading";
            })
            .addCase(postTypes.fulfilled, (state, action) => {
                state.types = action.payload.items;
                state.typesStatus = "succeeded";
            })
            .addCase(postTypes.rejected, (state) => {
                state.typesStatus = "failed";
            });

        builder
            // postTags
            .addCase(postTags.pending, (state) => {
                state.tagsStatus = "loading";
            })
            .addCase(postTags.fulfilled, (state, action) => {
                state.tags = action.payload.items;
                state.tagsStatus = "succeeded";
            })
            .addCase(postTags.rejected, (state) => {
                state.tagsStatus = "failed";
            });

        builder
            // postReactionOperators
            .addCase(postReactionOperators.pending, (state) => {
                state.reactionOperatorsStatus = "loading";
            })
            .addCase(postReactionOperators.fulfilled, (state, action) => {
                state.reactionOperators = action.payload.items;
                state.reactionOperatorsStatus = "succeeded";
            })
            .addCase(postReactionOperators.rejected, (state) => {
                state.reactionOperatorsStatus = "failed";
            });

        builder
            // postReactionZones
            .addCase(postReactionZones.pending, (state) => {
                state.reactionZonesStatus = "loading";
            })
            .addCase(postReactionZones.fulfilled, (state, action) => {
                state.reactionZones = action.payload.items;
                state.reactionZonesStatus = "succeeded";
            })
            .addCase(postReactionZones.rejected, (state) => {
                state.reactionZonesStatus = "failed";
            });

        // listIncidents
        builder
            .addCase(postListIncidents.pending, (state) => {
                state.isListIncidentsLoading = true;
                state.listIncidentsStatus = "loading";
            })
            .addCase(postListIncidents.fulfilled, (state, action) => {
                state.listIncidents = action.payload.items;
                state.isListIncidentsLoading = false;
                state.listIncidentsStatus = "succeeded";
            })
            .addCase(postListIncidents.rejected, (state) => {
                state.isListIncidentsLoading = false;
                state.listIncidentsStatus = "failed";
            });
    },
});

export default analytics.reducer;

export const {
    switchAnalyticsSection,
    setAnalyticsFilter,
    setCheckboxFilter,
    resetFilter,
    togglePinFilter,
    resetAllFilters,
    toggleModeTagFilter,
    setFilterFromOverviewToAnother,
    clearCompareEventsAndIncidents,
    getAnalyticsFetchInformation,
    clearAnotherEventsAndIncidents,
    selectSavedParam,
    setRoadObjects,
} = analytics.actions;

const slice = ({analyticsReducer}: RootState) => analyticsReducer;

export const multifiltersSelector = createSelector(
    [
        (state: RootState) => state.analyticsReducer[AnalyticsFiltersTypesEnum.EVENTS_AND_INCIDENTS].filters,
        (state: RootState) => state.analyticsReducer[AnalyticsFiltersTypesEnum.SERVICES].filters,
        (state: RootState) => state.analyticsReducer[AnalyticsFiltersTypesEnum.OVERVIEW].filters,
        (_: RootState, filterType: AnalyticsFiltersTypesEnum) => filterType,
    ],
    (eventsAndIncidentsFilters, servicesFilters, overviewFilters, filterType) => {
        if (filterType === AnalyticsFiltersTypesEnum.EVENTS_AND_INCIDENTS) {
            return eventsAndIncidentsFilters as Partial<IDefaultAnalyticsFilters>;
        }
        if (filterType === AnalyticsFiltersTypesEnum.SERVICES) {
            return servicesFilters as Partial<IDefaultAnalyticsFilters>;
        }
        if (filterType === AnalyticsFiltersTypesEnum.OVERVIEW) {
            return overviewFilters as Partial<IDefaultAnalyticsFilters>;
        }
        return overviewFilters as Partial<IDefaultAnalyticsFilters>;
    }
);

export const switchAnalyticsSectionSelector = createSelector(
    slice,
    (state) => state.section
);

// OVERVIEW
export const overviewFiltersSelector = createSelector(
    slice,
    (state) => state[AnalyticsFiltersTypesEnum.OVERVIEW].filters
);

export const overviewPinsSelector = createSelector(
    slice,
    (state) => state[AnalyticsFiltersTypesEnum.OVERVIEW].pins
);

// EVENTS_AND_INCIDENTS
export const eventsAndIncidentsFiltersSelector = createSelector(
    slice,
    (state) => state[AnalyticsFiltersTypesEnum.EVENTS_AND_INCIDENTS].filters
);

export const eventsAndIncidentsPinsSelector = createSelector(
    slice,
    (state) => state[AnalyticsFiltersTypesEnum.EVENTS_AND_INCIDENTS].pins
);

// SERVICES
export const servicesFiltersSelector = createSelector(
    slice,
    (state) => state[AnalyticsFiltersTypesEnum.SERVICES].filters
);

export const servicesPinsSelector = createSelector(
    slice,
    (state) => state[AnalyticsFiltersTypesEnum.SERVICES].pins
);

// analyticsInformation
export const analyticsFetchInformationSelector = createSelector(
    slice,
    (state) => state.analyticsFetchInformation
);

//eventsAndIncidents
export const eventsAndIncidentsSelector = createSelector(
    [
        (state: RootState) => state.analyticsReducer.eventsAndIncidents,
    ],
    (eventsAndIncidents) => eventsAndIncidents
);

export const eventsAndIncidentsStatusSelector = createSelector(
    slice,
    (state) => state.eventsAndIncidentsStatus
);

// eventsAndIncidents_compare
export const compareEventsAndIncidentsSelector = createSelector(
    [
        (state: RootState) => state.analyticsReducer.compareEventsAndIncidents,
    ],
    (compareEventsAndIncidents) => compareEventsAndIncidents
);

export const compareEventsAndIncidentsStatusSelector = createSelector(
    slice,
    (state) => state.compareEventsAndIncidentsStatus
);

// eventsAndIncidents_another
export const anotherEventsAndIncidentsSelector = createSelector(
    [
        (state: RootState) => state.analyticsReducer.anotherEventsAndIncidents,
    ],
    (anotherEventsAndIncidents) => anotherEventsAndIncidents
);

export const anotherEventsAndIncidentsStatusSelector = createSelector(
    slice,
    (state) => state.anotherEventsAndIncidentsStatus
);

//weeklyIncidentsByHour
export const weeklyIncidentsByHourSelector = createSelector(
    [(state: RootState) => state.analyticsReducer.weeklyIncidentsByHour],
    (weeklyIncidentsByHour) => weeklyIncidentsByHour
);

export const weeklyIncidentsByHourStatusSelector = createSelector(
    slice,
    (state) => state.weeklyIncidentsByHourStatus
);

// reactionZones
export const reactionZonesSelector = createSelector(
    [
        (state: RootState) => state.analyticsReducer.reactionZones,
    ],
    (reactionZones) => reactionZones
);

export const reactionZonesStatusSelector = createSelector(
    [
        (state: RootState) => state.analyticsReducer.reactionZonesStatus,
    ],
    (reactionZonesStatus) => reactionZonesStatus
);

// reactionOperators
export const reactionOperatorsSelector = createSelector(
    [
        (state: RootState) => state.analyticsReducer.reactionOperators,
    ],
    (reactionOperators) => reactionOperators
);

export const reactionOperatorsStatusSelector = createSelector(
    [
        (state: RootState) => state.analyticsReducer.reactionOperatorsStatus,
    ],
    (reactionOperatorsStatus) => reactionOperatorsStatus
);

// processCenterWorkflowSelector
export const processCenterWorkflowSelector = createSelector(
    slice,
    (state) => state.processCenterWorkflow
);

export const processCenterWorkflowStatusSelector = createSelector(
    slice,
    (state) => state.processCenterWorkflowStatus
);

// services
export const servicesSelector = createSelector(
    slice,
    (state) => state.services
);

export const servicesStatusSelector = createSelector(
    slice,
    (state) => state.servicesStatus
);

// summary
export const summarySelector = createSelector(
    slice,
    (state) => state.summary
);

export const summaryStatusSelector = createSelector(
    slice,
    (state) => state.summaryStatus
);

// mapEventsAndIncidents
export const mapEventsAndIncidentsSelector = createSelector(
    slice,
    ({mapEventsAndIncidents}) => mapEventsAndIncidents
);

export const mapEventsAndIncidentsStatusSelector = createSelector(
    slice,
    (state) => state.mapEventsAndIncidentsStatus
);

export const mapEventsAndIncidentsLoadingSelector = createSelector(
    slice,
    ({mapEventsAndIncidentsLoading}) => mapEventsAndIncidentsLoading
);

// priorityNumbers
export const priorityNumbersSelector = createSelector(
    slice,
    (state) => state.priorityNumbers
);

export const priorityNumbersStatusSelector = createSelector(
    slice,
    (state) => state.priorityNumbersStatus
);

//timePriority
export const timePrioritySelector = createSelector(
    slice,
    (state) => state.timePriority
);

export const timePriorityStatusSelector = createSelector(
    slice,
    (state) => state.timePriorityStatus
);

// listIncidents
export const listIncidentsSelector = createSelector(
    slice,
    (state) => state.listIncidents
);

export const isListIncidentsLoadingSelector = createSelector(
    slice,
    (state) => state.isListIncidentsLoading
);

// tags
export const tagsAnalyticsSelector = createSelector(
    slice,
    (state) => state.tags
);

export const tagsAnalyticsStatusSelector = createSelector(
    slice,
    (state) => state.tagsStatus
);

// types
export const typesAnalyticsSelector = createSelector(
    slice,
    (state) => state.types
);

export const typesAnalyticsStatusSelector = createSelector(
    slice,
    (state) => state.typesStatus
);

// stringEventsAndIncidents
export const stringEventsAndIncidentsSelector = createSelector(
    slice,
    (state) => state.stringEventsAndIncidents
);

export const stringEventsAndIncidentsStatusSelector = createSelector(
    slice,
    (state) => state.stringEventsAndIncidentsStatus
);

// summaryByZone
export const summaryByZoneSelector = createSelector(
    slice,
    (state) => state.summaryByZone
);

export const summaryByZoneStatusSelector = createSelector(
    slice,
    (state) => state.summaryByZoneStatus
);

// incidentsByZone
export const incidentsByZoneSelector = createSelector(
    slice,
    (state) => state.incidentsByZone
);

export const incidentsByZoneStatusSelector = createSelector(
    slice,
    (state) => state.incidentsByZoneStatus
);

//savedParams
export const savedParamsSelector = createSelector(
    slice,
    (state) => state.savedParams
);

export const savedParamsStatusSelector = createSelector(
    slice,
    (state) => state.savedParamsStatus
);

export const postSavedParamsLoadingSelector = createSelector(
    slice,
    (state) => state.postSavedParamsLoading
);

export const deleteSavedParamsLoadingSelector = createSelector(
    slice,
    (state) => state.deleteSavedParamsLoading
);

export const openCreatedDateTimeFilterCounterSelector = createSelector(
    slice,
    (state) => state.openCreatedDateTimeFilterCounter
);

export const overviewLoadingPageSelector = createSelector(
    (state: RootState) => {
        const {
            eventsAndIncidentsStatus,
            weeklyIncidentsByHourStatus,
            summaryStatus,
            servicesStatus,
            processCenterWorkflowStatus,
            mapEventsAndIncidentsStatus,
            incidentsByZoneStatus,
        } = state.analyticsReducer;

        return [
            eventsAndIncidentsStatus,
            weeklyIncidentsByHourStatus,
            summaryStatus,
            servicesStatus,
            processCenterWorkflowStatus,
            mapEventsAndIncidentsStatus,
            incidentsByZoneStatus,
        ];
    },
    (statuses) => statuses.some(status => status === "loading")
);

export const eventsAndIncidentsLoadingPageSelector = createSelector(
    (state: RootState) => {
        const {
            stringEventsAndIncidentsStatus,
            priorityNumbersStatus,
            listIncidentsStatus,
            timePriorityStatus,
            tagsStatus,
            typesStatus,
            eventsAndIncidentsStatus,
            weeklyIncidentsByHourStatus,
            mapEventsAndIncidentsStatus,
            incidentsByZoneStatus,
        } = state.analyticsReducer;

        return [
            stringEventsAndIncidentsStatus,
            priorityNumbersStatus,
            listIncidentsStatus,
            timePriorityStatus,
            tagsStatus,
            typesStatus,
            eventsAndIncidentsStatus,
            weeklyIncidentsByHourStatus,
            mapEventsAndIncidentsStatus,
            incidentsByZoneStatus,
        ];
    },
    (statuses) => statuses.some(status => status === "loading")
);

export const servicesPageLoadingSelector = createSelector(
    (state: RootState) => {
        const {
            listIncidentsStatus,
            servicesStatus,
            processCenterWorkflowStatus,
            reactionOperatorsStatus,
        } = state.analyticsReducer;

        return [
            listIncidentsStatus,
            servicesStatus,
            processCenterWorkflowStatus,
            reactionOperatorsStatus,
        ];
    },
    (statuses) => statuses.some(status => status === "loading")
);

export const incidentCardPageLoadingSelector = createSelector(
    (state: RootState) => {
        const {
            stringEventsAndIncidentsStatus,
            servicesStatus,
            processCenterWorkflowStatus,
            priorityNumbersStatus,
            timePriorityStatus,
            reactionOperatorsStatus,
            reactionZonesStatus,
        } = state.analyticsReducer;

        return [
            stringEventsAndIncidentsStatus,
            servicesStatus,
            processCenterWorkflowStatus,
            priorityNumbersStatus,
            timePriorityStatus,
            reactionOperatorsStatus,
            reactionZonesStatus,
        ];
    },
    (statuses) => statuses.some(status => status === "loading")
);