import {createAsyncThunk, createSelector, createSlice} from "@reduxjs/toolkit";

import {RootState} from "store";

import createHttpRequest from "../utils/http";
import {ApiUrls} from "../constants/urls";
import RouteModel from "../models/RouteModel";
import FeatureCollection from "../models/FeatureCollection";
import IncidentTypesModel from "../models/IncidentTypesModel";

interface IInitialState {
  places: IncidentTypesModel[],
  sources: IncidentTypesModel[],
  routes: RouteModel[],
  isPlacesLoading: boolean,
  isRoutesLoading: boolean,
}

const initialState: IInitialState = {
    places: [],
    sources: [],
    routes: [],
    isPlacesLoading: false,
    isRoutesLoading: false,
};

export const fetchPlaces =
  createAsyncThunk<FeatureCollection>("publicTransport/fetchPlaces", async() => {
      const response = await createHttpRequest({
          method: "GET",
          path: `${ApiUrls.ROAD_OBJECT_LIST_FOR_INCIDENTS}?virtualSources=incCamera&mode=OR`,
          errorMessage: "messages:fetch_places_error",
      });

      return response.data;
  });

export const fetchRoutes =
  createAsyncThunk<RouteModel[]>("publicTransport/fetchRoutes", async() => {
      const response = await createHttpRequest({
          method: "GET",
          path: ApiUrls.ROUTES,
          errorMessage: "messages:fetch_routes_error",
      });

      return response.data;
  });

const publicTransport = createSlice({
    reducers: {},
    name: "publicTransport",
    initialState,
    extraReducers: (builder) => {
        builder.addCase(fetchPlaces.pending, (state) => {
            state.places = [];
            state.isPlacesLoading = true;
        });
        builder.addCase(fetchPlaces.fulfilled, (state, {payload}) => {
            const places: IncidentTypesModel[] = [];
            const sources: IncidentTypesModel[] = [];

            payload.features?.forEach(({properties, id}) => {
                places.push({
                    code: id || "", name: properties?.name || "",
                });

                const cameraId = properties?.view?.cameraId;

                if (!cameraId) return;

                sources.push({
                    code: id || "", name: String(cameraId),
                });
            });

            state.places = places;
            state.sources = sources;

            state.isPlacesLoading = false;
        });
        builder.addCase(fetchPlaces.rejected, (state) => {
            state.places = [];
            state.isPlacesLoading = false;
        });

        builder.addCase(fetchRoutes.pending, (state) => {
            state.routes = [];
            state.isRoutesLoading = true;
        });
        builder.addCase(fetchRoutes.fulfilled, (state, {payload}) => {
            state.routes = payload;
            state.isRoutesLoading = false;
        });
        builder.addCase(fetchRoutes.rejected, (state) => {
            state.routes = [];
            state.isRoutesLoading = false;
        });
    },
});

export default publicTransport.reducer;

//Селекторы
const slice = ({publicTransportReducer}: RootState) => publicTransportReducer;

export const placesSelector = createSelector(
    slice,
    ({places}) => places
);

export const isPlacesLoadingSelector = createSelector(
    slice,
    ({isPlacesLoading}) => isPlacesLoading
);

export const sourcesSelector = createSelector(
    slice,
    ({sources}) => sources
);

export const routesSelector = createSelector(
    slice,
    ({routes}) => routes
);

export const isRoutesLoadingSelector = createSelector(
    slice,
    ({isRoutesLoading}) => isRoutesLoading
);
