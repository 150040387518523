/* eslint-disable max-len */
import React, {FC} from "react";

import {ISvgProps} from "../../types/common";

const DashboardIcon: FC<ISvgProps> = ({
    width = 24,
    height = 24,
    ...rest
}) => (
    <svg xmlns="http://www.w3.org/2000/svg" {...rest} width={width} height={height} viewBox="0 0 16 16" fill="none">
        <path fillRule="evenodd" clipRule="evenodd"
            d="M4.66634 8.00065H3.99967C2.52692 8.00065 1.33301 9.19456 1.33301 10.6673V12.0007C1.33301 13.4734 2.52692 14.6673 3.99967 14.6673H4.66634C6.1391 14.6673 7.33301 13.4734 7.33301 12.0007V10.6673C7.33301 9.19456 6.1391 8.00065 4.66634 8.00065ZM2.66634 10.6673C2.66634 9.93094 3.26329 9.33398 3.99967 9.33398H4.66634C5.40272 9.33398 5.99967 9.93094 5.99967 10.6673V12.0007C5.99967 12.737 5.40272 13.334 4.66634 13.334H3.99967C3.26329 13.334 2.66634 12.737 2.66634 12.0007V10.6673Z"
            fill="currentColor"/>
        <path fillRule="evenodd" clipRule="evenodd"
            d="M5.99967 3.66732C5.99967 2.37865 4.95501 1.33398 3.66634 1.33398C2.37768 1.33398 1.33301 2.37865 1.33301 3.66732V4.33398C1.33301 5.62265 2.37768 6.66732 3.66634 6.66732C4.95501 6.66732 5.99967 5.62265 5.99967 4.33398V3.66732ZM2.66634 3.66732C2.66634 3.11503 3.11406 2.66732 3.66634 2.66732C4.21863 2.66732 4.66634 3.11503 4.66634 3.66732V4.33398C4.66634 4.88627 4.21863 5.33398 3.66634 5.33398C3.11406 5.33398 2.66634 4.88627 2.66634 4.33398V3.66732Z"
            fill="currentColor"/>
        <path fillRule="evenodd" clipRule="evenodd"
            d="M11.9997 7.33398H11.333C9.86025 7.33398 8.66634 8.52789 8.66634 10.0007V12.0007C8.66634 13.4734 9.86025 14.6673 11.333 14.6673H11.9997C13.4724 14.6673 14.6663 13.4734 14.6663 12.0007V10.0007C14.6663 8.52789 13.4724 7.33398 11.9997 7.33398ZM9.99967 10.0007C9.99967 9.26427 10.5966 8.66732 11.333 8.66732H11.9997C12.7361 8.66732 13.333 9.26427 13.333 10.0007V12.0007C13.333 12.737 12.7361 13.334 11.9997 13.334H11.333C10.5966 13.334 9.99967 12.737 9.99967 12.0007V10.0007Z"
            fill="currentColor"/>
        <path fillRule="evenodd" clipRule="evenodd"
            d="M12.333 1.33398H9.66634C8.37768 1.33398 7.33301 2.37865 7.33301 3.66732C7.33301 4.95598 8.37768 6.00065 9.66634 6.00065H12.333C13.6217 6.00065 14.6663 4.95598 14.6663 3.66732C14.6663 2.37865 13.6217 1.33398 12.333 1.33398ZM8.66634 3.66732C8.66634 3.11503 9.11406 2.66732 9.66634 2.66732H12.333C12.8853 2.66732 13.333 3.11503 13.333 3.66732C13.333 4.2196 12.8853 4.66732 12.333 4.66732H9.66634C9.11406 4.66732 8.66634 4.2196 8.66634 3.66732Z"
            fill="currentColor"/>
    </svg>
);

export default DashboardIcon;