/* eslint-disable max-len */
import React from "react";

import {ISvgProps} from "../../types/common";

const TimeIcon: React.FC<ISvgProps> = ({
    height = 18,
    width = 18,
    ...rest
}) => {
    return (
        <svg {...rest} width={width} height={height} viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fillRule="evenodd" clipRule="evenodd"
                d="M8.9925 1.5C4.8525 1.5 1.5 4.86 1.5 9C1.5 13.14 4.8525 16.5 8.9925 16.5C13.14 16.5 16.5 13.14 16.5 9C16.5 4.86 13.14 1.5 8.9925 1.5ZM9 15C5.685 15 3 12.315 3 9C3 5.685 5.685 3 9 3C12.315 3 15 5.685 15 9C15 12.315 12.315 15 9 15ZM9.375 5.25H8.25V9.75L12.1875 12.1125L12.75 11.19L9.375 9.1875V5.25Z"
                fill="currentColor"/>
        </svg>
    );
};

export default TimeIcon;