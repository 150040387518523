import React, {FC, forwardRef, Ref} from "react";
import {usePopper} from "react-popper";
import cn from "classnames";

import TrashIconFilled from "../../../Icons/TrashIconFilled";
import EditIconFilled from "../../../Icons/EditIconFilled";

import styles from "./TagGroupDropdown.module.scss";

interface ITagGroupDropdownProps {
    className: any,
    ref: Ref<any>,
    referenceElement: any,
    popperElement: any,
    deleteHandler: any,
    editHandler: any,
}

const TagGroupDropdown:FC<ITagGroupDropdownProps> = forwardRef<HTMLDivElement, ITagGroupDropdownProps>(({
    className, referenceElement, popperElement, deleteHandler, editHandler}, ref) => {

    const {styles: popperStyles, attributes: popperAttributes}
        = usePopper(referenceElement.current, popperElement.current, {
            placement: "bottom-end",
            strategy: "fixed",
            modifiers: [
                {
                    name: "flip",
                    options: {
                        allowedAutoPlacements: ["bottom-end"], // by default, all the placements are allowed
                    },
                },
            ],
        });

    return (
        <div className={cn(styles["dropdown"], className)} ref={ref} style={popperStyles.popper}
            {...popperAttributes.popper}>
            <div className={styles["dropdown__item"]}
                onClick={editHandler}
            >
                <EditIconFilled className={styles["dropdown__item-icon"]}/>
                Изменить
            </div>
            <div className={styles["dropdown__item"]}
                onClick={deleteHandler}>
                <TrashIconFilled className={styles["dropdown__item-icon"]} width={16} height={16}/>
                Удалить
            </div>
        </div>
    );
});

TagGroupDropdown.displayName = "TagGroupDropdown";

export default TagGroupDropdown;