import React, {FC} from "react";
import cn from "classnames";
import {useTranslation} from "react-i18next";
import {useDispatch} from "react-redux";
import useHasPermission from "../../../hook/useHasPermission";
import {Permission} from "../../../models/Permission";
import {setIsQuickSettingsOpen} from "../../../redux/settingsSlice";

import BlueBellIcon from "../../Icons/Notifications/BlueBellIcon";
import BaseButton from "../../UI/Button/BaseButton";
import CrossIcon from "../../Icons/CrossIcon";

import styles from "./CameraDisablingFinishNotification.module.scss";

interface ICameraDisablingFinish {
    cameraId: string | number,
    incidentType: string,
    // eslint-disable-next-line react/no-unused-prop-types
    cameraType: string,
    cameraZone: number,
}

const CameraDisablingFinishNotification: FC<ICameraDisablingFinish> = ({cameraId, incidentType, cameraZone}) => {
    const {t} = useTranslation("common");
    const dispatch = useDispatch();
    const isAdmin = useHasPermission(Permission.ADMIN);
    const isManager = useHasPermission(Permission.MANAGER);

    const handleOpenQuickSettings = (cameraId?: any, cameraType?: any, cameraZone?: number) => {
        dispatch(setIsQuickSettingsOpen({
            isOpen: true,
            selectedTab: isAdmin || isManager ? 4 : 1,
            cameraId: cameraId || null,
            cameraType: cameraType || null,
            cameraZone: cameraZone || null,
        }));
    };

    return (
        <div className={cn(styles["notification"], "disabling__notification")}>
            <div className={styles["notification__image"]}>
                <BlueBellIcon width={24} height={24}/>
            </div>
            <div className={styles["notification__text"]}>
                <span className={styles["notification__text--title"]}>{t("camera_tracking_finish")}</span>
                <span className={styles["notification__text--info"]}>
                    {`Камера #${cameraId}${incidentType !== "Все" ? `, тип инцидента "${incidentType}"` : ""}`}
                </span>
            </div>
            <BaseButton
                className={cn("btn--incidents-blue", "ml-4")}
                onClick={() => handleOpenQuickSettings(cameraId, incidentType, cameraZone)}
                tag={"button"}
            >
                Продлить
            </BaseButton>
            <div className={styles["notification__close"]}>
                <CrossIcon width={14} height={14}/>
            </div>
        </div>
    );
};

export default CameraDisablingFinishNotification;