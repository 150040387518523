import {Action} from "redux";

import {isBrowser} from "../evironment";
import {TypedDispatch} from "../../types";
import {SignalRService} from "./SignalRService";
import {setSignalRSettings} from "../../redux/commonSlice";
import {fetchLastEvents, upsertLastEventIncident} from "../../redux/eventSlice";
// import {upsertJournal} from "../../redux/journalSlice";
import {upsertEfficiency} from "../../redux/efficiencyJournalSlice";
import {updateRoadObjectVehicleFeaturesCollections} from "../../redux/roadObjectSlice";
import {setProTvConfig} from "../../redux/proTvSlice";
import {upsertSharedIncident} from "../../redux/eventSharedSlice";
import {RootState} from "../../store";

const signalRMiddleware = ({dispatch, getState}: {dispatch: TypedDispatch, getState: () => RootState}) => {
    let hasDisconnected = false;

    let lastEventsFetchingStatus: string | null = null; // Текущий статус загрузки events/last
    let lastEventsMessagesQueue: any = []; // Очередь сообщений по инцидентам

    // Данные для обновления транспортных средств на карте
    let vehiclesDataQueue: any = [];
    let isFirstVehiclesMessage = true;
    let vehiclesReceivingTimer: ReturnType<typeof setTimeout>;

    // eslint-disable-next-line no-unused-vars
    return (next: (arg0: Action) => any) =>
        async(action: Action) => {
            // Обновляем текущий статус загрузки последних инцидентов
            if (action.type === "event/fetchLastEvents/pending") lastEventsFetchingStatus = "pending";
            if (action.type === "event/fetchLastEvents/fulfilled") lastEventsFetchingStatus = "fulfilled";
            if (action.type === "event/fetchLastEvents/rejected") lastEventsFetchingStatus = "rejected";

            // register signalR after the user logged in
            if (action.type === "auth/fetchMe/fulfilled" && isBrowser()) {
                const signalRService = SignalRService.getInstance(
                    () => {
                        dispatch(setSignalRSettings({connectionSuccess: true, isDisconnected: false}));
                        // Запросы после восстановления соединения
                        // Пропускаем один запрос
                        if (hasDisconnected) {
                            dispatch(fetchLastEvents({
                                params: {
                                    sort: "registrationDateTime,desc",
                                    getAssignedLastShift: "true",
                                },
                            }));
                            hasDisconnected = false;
                        }
                    }
                );
                signalRService.startConnect();

                signalRService.addSubscriber("IncidentEvent", (message) => {
                    const result = JSON.parse(message);
                    const isDisconnected = getState().commonReducer.signalRSettings.isDisconnected;

                    // Хотфикс. При рестарте соединения не всегда отрабатывает механизм проверки его наличия
                    if (result && isDisconnected) {
                        dispatch(setSignalRSettings({isDisconnected: false, connectionSuccess: true}));
                        hasDisconnected = false;
                    }

                    if (lastEventsFetchingStatus !== "fulfilled") {
                        // Если загрузка еще не начата или незакончена, ставим сообщения в очередь
                        lastEventsMessagesQueue.push(result);
                    } else {
                        if (lastEventsMessagesQueue.length) {
                            // Если очередь не пуста, отправляем ее
                            dispatch(upsertLastEventIncident(lastEventsMessagesQueue));
                            dispatch(upsertSharedIncident(lastEventsMessagesQueue));
                            lastEventsMessagesQueue = [];
                        } else {
                            // Обычные одиночные сообщения
                            dispatch(upsertLastEventIncident(result));
                        }
                    }
                });

                // Обновление истории изменения инцидентов
                signalRService.addSubscriber("HistoryEvent", (message) => {
                    const result = JSON.parse(message);
                    dispatch(upsertLastEventIncident(result));
                    dispatch(upsertSharedIncident(result));
                    // dispatch(upsertJournal(result));
                });

                // Различные оповещения
                signalRService.addSubscriber("Notification", (message) => {
                    const result = JSON.parse(message);
                    dispatch(upsertLastEventIncident(result));
                    dispatch(upsertSharedIncident(result));
                });

                // Передача инцидента другому оператору
                signalRService.addSubscriber("ShareIncidentWithYou", (message) => {
                    const result = JSON.parse(message);
                    dispatch(upsertLastEventIncident(result));
                    dispatch(upsertSharedIncident(result));
                });
                signalRService.addSubscriber("AcceptedShareIncident", (message) => {
                    const result = JSON.parse(message);
                    dispatch(upsertLastEventIncident(result));
                    dispatch(upsertSharedIncident(result));
                });
                signalRService.addSubscriber("RejectedShareIncident", (message) => {
                    const result = JSON.parse(message);
                    dispatch(upsertLastEventIncident(result));
                    dispatch(upsertSharedIncident(result));
                });

                // Обновление справочников
                signalRService.addSubscriber("DictionaryUpdated", (message) => {
                    const result = JSON.parse(message);

                    // Обновление справочника Про ТВ
                    if (result?.name === "proTvConfig") {
                        dispatch(setProTvConfig(result.data));
                    }
                });

                // Обновление дорожных объектов
                signalRService.addSubscriber("RoadObjectEvent", (message) => {
                    const result = JSON.parse(message);
                    // Временно пропускаем сообщения по передвижению машин
                    if (!result?.externalId) {
                        dispatch(upsertEfficiency(result));
                    } else {
                        // Транспортные средства
                        // Делим данные на массивы каждые 3 секунды
                        if (isFirstVehiclesMessage) {
                            // При получении первого сообщения, включаем таймер, по истечение которого
                            // отправляем данные, очищаем очередь, удаляем таймер
                            vehiclesReceivingTimer = setTimeout(() => {
                                dispatch(updateRoadObjectVehicleFeaturesCollections(vehiclesDataQueue));
                                vehiclesDataQueue = [];
                                isFirstVehiclesMessage = true;
                                clearTimeout(vehiclesReceivingTimer);
                            }, 3000);
                            isFirstVehiclesMessage = false;
                        }

                        // Собираем массив из дорожных объектов т/с
                        vehiclesDataQueue.push(result);
                    }
                });

                signalRService.connection?.onclose(() => {
                    dispatch(setSignalRSettings({isDisconnected: true, connectionSuccess: false}));
                    hasDisconnected = true;
                });

                // Иногда соединение восстанавливается, а сообщение о его потере остается
                // Событие выполняется при автоматическом повторном установке транспортного подключения после потери
                // НЕ после OnDisconnected
                signalRService.connection?.onreconnected(() => {
                    dispatch(setSignalRSettings({isDisconnected: false, connectionSuccess: true}));
                    hasDisconnected = false;
                });
            }
            return next(action);
        };
};

export default signalRMiddleware;