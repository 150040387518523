import {createSelector, createSlice, PayloadAction} from "@reduxjs/toolkit";

import {RootState} from "../store";
import {getLocalStorageValue} from "../utils/common";
import {storageKeys} from "../constants/common";

export type ArchivePageSwitcherType = "favorites" | "archive";

interface IInitialState {
    page: ArchivePageSwitcherType,
}

const initialState: IInitialState = {
    page: (getLocalStorageValue(storageKeys.archivePageSwitcher) || "archive") as ArchivePageSwitcherType,
};

const archiveSwitcherSlice = createSlice({
    name: "archiveSwitcher",
    initialState,
    reducers: {
        switchPage(state, action: PayloadAction<ArchivePageSwitcherType>) {
            state.page = action.payload;
            localStorage.setItem(storageKeys.archivePageSwitcher, action.payload);
        },
    },

});

export default archiveSwitcherSlice.reducer;

export const {
    switchPage,
} = archiveSwitcherSlice.actions;

const slice = ({archiveSwitcherReducer}: RootState) => archiveSwitcherReducer;

export const archiveSwitcherPageSelector = createSelector(
    slice,
    (state) => state.page
);
