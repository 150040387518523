import React, {FC, ReactNode, RefObject} from "react";
import cn from "classnames";

import DotsVIcon from "../../Icons/DotsVIcon";
import MoreActions, {Placement4, Placement8, PlacementAuto} from "./MoreActions";

import styles from "../../ArchivePage/ArchiveTable/ArchiveTable.module.scss";

interface IMoreActionsDotsProps {
    className?: string,
    dropdownClassname?: string,
    children: ReactNode | null | undefined,
    placement?: Placement4 | Placement8 | PlacementAuto,
    arrow?: boolean,
    popoverStyles?: {[key: string]: any},
    width?: number,
    height?: number,
    containerRef?: RefObject<HTMLDivElement>,
}

const MoreActionsDots: FC<IMoreActionsDotsProps> = (
    {
        className,
        dropdownClassname,
        children,
        placement = "autoVertical",
        arrow,
        popoverStyles,
        width,
        height,
        containerRef,
    }
) => {

    const anchor = <div className={cn(styles["more"], "more")}>
        <DotsVIcon className={"more-icon"} width={width} height={height}/>
    </div>;

    return (
        <MoreActions
            className={className}
            dropdownClassname={dropdownClassname}
            anchor={anchor}
            placement={placement}
            arrow={arrow}
            popoverStyles={popoverStyles}
            containerRef={containerRef}
        >
            {children}
        </MoreActions>
    );
};

export default React.memo(MoreActionsDots);