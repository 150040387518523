/* eslint-disable max-len */
import React, {FC} from "react";

import {ISvgProps} from "../../types/common";

const LeadIcon: FC<ISvgProps> = ({
    width = 16,
    height = 16,
    ...rest
}) => (
    <svg xmlns="http://www.w3.org/2000/svg" {...rest} width={width} height={height} viewBox="0 0 16 16" fill="none">
        <path fillRule="evenodd" clipRule="evenodd"
            d="M14.6663 4.66732V3.33398H1.33301V4.66732H14.6663ZM10.6663 6.00065V7.33398H1.33301V6.00065H10.6663ZM14.6663 10.0007V8.66732H1.33301V10.0007H14.6663ZM10.6663 12.6673V11.334H1.33301V12.6673H10.6663Z"
            fill="currentColor"/>
    </svg>
);

export default LeadIcon;