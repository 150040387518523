/* eslint-disable max-len */
import React, {FC} from "react";

import {ISvgProps} from "../../types/common";

// import icon from "images/icons/double-chevron-left.svg";

const DoubleChevronLeft: FC<ISvgProps> = ({
    width = 24,
    height = 24,
    ...rest
}) => (
    <svg {...rest} width={width} height={height} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path fillRule="evenodd" clipRule="evenodd" d="M11.293 18.707L12.7073 17.2928L7.41436 11.9999L12.7073 6.70703L11.293 5.29282L4.58594 11.9999L11.293 18.707ZM17.7073 17.2928L12.4144 11.9999L17.7073 6.70703L16.293 5.29282L9.58594 11.9999L16.293 18.707L17.7073 17.2928Z" fill="currentColor"/>
    </svg>
);

export default DoubleChevronLeft;