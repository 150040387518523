import React from "react";
import {useTranslation} from "react-i18next";

import {DEFAULT_PAGE_SIZE} from "../constants/common";

// eslint-disable-next-line no-unused-vars
export type RowFactory<T extends object> = (item: T | null, isLoading: boolean, idx: number) => JSX.Element;

export function tableRowsWithLoader<T extends object>(
    items: T[],
    isLoading: boolean,
    colNum: number,
    rowFactory: RowFactory<T>,
    rowsNum: number | null
): JSX.Element[] | JSX.Element {
    const {t} = useTranslation("common");

    if (items?.length === 0 && !isLoading) {
        const noDataMsg = t("common:no_data");
        return (
            <tr>
                <td colSpan={colNum} className={"text-center"}>
                    {noDataMsg}
                </td>
            </tr>
        );
    }

    if (items?.length === 0) {
        // Данные еще не загружены
        const placeholderRows = new Array(rowsNum ?? DEFAULT_PAGE_SIZE).fill(null);
        return placeholderRows.map((item, idx) => rowFactory(item, isLoading, idx));
    }

    return items?.map((item, idx) => rowFactory(item, isLoading, idx));
}