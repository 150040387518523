import React, {Suspense} from "react";
import {ToastContainer} from "react-toastify";
import {HelmetProvider} from "react-helmet-async";

import {useSecondScreen} from "./utils/customHooks";

import SpinnerWithOverlay from "./components/UI/SpinnerWithOverlay";

import "./styles/globals.scss";
import "react-toastify/dist/ReactToastify.css";
import "rsuite/dist/rsuite.min.css";

import Router from "./Router";

export default function App() {
    useSecondScreen();

    return (
        <HelmetProvider>
            <Suspense fallback={<SpinnerWithOverlay/>}>
                <Router/>
                <ToastContainer/>
            </Suspense>
        </HelmetProvider>
    );
}
