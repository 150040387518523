import React, {FC, useCallback, useRef, useState} from "react";
import {useTranslation} from "react-i18next";
import cn from "classnames";
import Popover from "@mui/material/Popover";
import {PopoverOrigin} from "@mui/material/Popover/Popover";
import {useDispatch, useSelector} from "react-redux";
import {unwrapResult} from "@reduxjs/toolkit";

import {getSharedEvents} from "../../../redux/eventSharedSlice";
import {
    handoverRejectLoadingSelector,
    handoverTakeLoadingSelector,
    rejectHandoverEvent, setSelectedLastEvent,
    takeHandoverEvent,
} from "../../../redux/eventSlice";
import {TypedDispatch} from "../../../types";
import {ApiUrls} from "constants/urls";

import Image from "../../Icons/Image";
import BaseButton from "../../UI/Button/BaseButton";
import triangleIcon from "../../../images/icons/triangle.svg";
import CrossIcon from "../../Icons/CrossIcon";
import EventNewIcon from "../../Icons/Events/Statuses/EventNewIcon";
import Spinner from "../../UI/Spinner/Spinner";
import TypeIcon from "../../Icons/TypeIcon";
import ImageWithToken from "components/Icons/ImageWithToken";

import commonStyles from "../../Dashboard/DashboardTarget/DashboardTarget.module.scss";
import styles from "./HandoverNotification.module.scss";

interface IHandoverNotification {
    request: any,
}

const HandoverNotification: FC<IHandoverNotification> = ({request}) => {
    const {t} = useTranslation(["dashboard", "warnings", "common"]);
    const dispatch = useDispatch<TypedDispatch>();
    const handoverTakeLoading = useSelector(handoverTakeLoadingSelector);
    const handoverRejectLoading = useSelector(handoverRejectLoadingSelector);

    const popoverPlacement = useRef<{anchorOrigin: PopoverOrigin, transformOrigin: PopoverOrigin}>({
        anchorOrigin: {
            vertical: "bottom",
            horizontal: "left",
        },
        transformOrigin: {
            vertical: "top",
            horizontal: "left",
        },
    });

    const [isPopoverOpen, setIsPopoverOpen] = useState(false);
    const [anchorHandoverDropdownEl, setAnchorHandoverDropdownEl] = useState<null | HTMLElement>(null);
    const isHandoverDropdownOpen = Boolean(anchorHandoverDropdownEl);

    const handleHandoverDropdownClick = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorHandoverDropdownEl(event.currentTarget);
        setIsPopoverOpen(true);
    };

    const handleHandoverDropdownClose = useCallback(() => {
        setAnchorHandoverDropdownEl(null);
    }, []);

    const takeRequest = async() => {
        try {
            unwrapResult(await dispatch(takeHandoverEvent(request.share.Id)));
            dispatch(setSelectedLastEvent(request.incident));
        } catch (e) {
            //
        } finally {
            dispatch(getSharedEvents());
        }
    };

    const rejectRequest = () => {
        dispatch(rejectHandoverEvent(request.share.Id));
    };

    return (
        <>
            <BaseButton
                className={cn(
                    {[commonStyles["info__btn--opened"]]: isHandoverDropdownOpen}, styles["notification__button"]
                )}
                tag={"button"}
                //@ts-ignore
                onClick={handleHandoverDropdownClick}
            >
                <>
                    <EventNewIcon className={styles["notification__button--icon"]}/>
                    <div className={styles["notification__button--text"]}>
                        Заявка на передачу
                    </div>
                    <div className={commonStyles["info__btn-icon"]}>
                        <Image
                            src={triangleIcon}
                            width={14}
                            height={14}
                            alt={"Triangle"}
                        />
                    </div>
                </>
            </BaseButton>

            <Popover
                className={styles["notification__dropdown"]}
                open={isHandoverDropdownOpen}
                anchorEl={anchorHandoverDropdownEl}
                onClose={handleHandoverDropdownClose}
                anchorOrigin={popoverPlacement.current.anchorOrigin}
                transformOrigin={popoverPlacement.current.transformOrigin}
            >
                <div
                    className={cn(styles["notification__dropdown"], {[styles["dropdown--opened"]]: isPopoverOpen})}
                >
                    <div className={styles["notification__dropdown--header"]}>
                        {t("handover_request")}

                        <div
                            className={styles["dropdown__header-close-icon"]}
                            onClick={handleHandoverDropdownClose}
                        >
                            <CrossIcon/>
                        </div>
                    </div>

                    <div className={cn(styles["notification__dropdown--body"], styles["body"])}>
                        <div className={styles["body__operator"]}>
                            <ImageWithToken
                                src={ApiUrls.USER_AVATAR(request.share.Sender.id)}
                                alt={"Аватар"}
                                width={36}
                                height={36}
                            />

                            <div className={styles["body__operator--data"]}>
                                <p className={styles["body__operator--name"]}>{request.share.Sender.name}</p>
                                {request.share.Sender.email && (
                                    <p className={styles["body__operator--email"]}>{request.share.Sender.email}</p>
                                )}
                            </div>
                        </div>
                        <div className={styles["body__comment"]}>
                            {request.share.Comment}
                        </div>
                        <div className={styles["body__event"]}>
                            <TypeIcon
                                type={request.incident.type}
                                width={36}
                                height={36}
                                withTooltip={true}
                                priority={request.incident.priority}
                                tooltipClassname={styles["tooltip"]}
                            />
                            {request.incident?.thumbnail?.thumbUrl && <Image
                                src={`${process.env.REACT_APP_BASE_HOST}${request.incident.thumbnail.thumbUrl}`}
                                className={styles["body__event--image"]}
                                alt="media"
                                width={36}
                                height={36}
                            />}
                            <span>{request.incident.location.addressText}</span>
                        </div>
                        <div className={styles["body__buttons"]}>
                            <BaseButton
                                tag={"button"}
                                className={cn("btn--success", styles["body__buttons-button"])}
                                onClick={takeRequest}
                            >
                                {handoverTakeLoading ? <Spinner/> : t("common:accept_btn")}
                            </BaseButton>

                            <BaseButton
                                tag={"button"}
                                className={cn("btn--danger", styles["body__buttons-button"])}
                                onClick={rejectRequest}
                            >
                                {handoverRejectLoading ? <Spinner/> : t("common:reject_btn")}
                            </BaseButton>
                        </div>
                    </div>

                </div>
            </Popover>
        </>
    );
};

export default HandoverNotification;