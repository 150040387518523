import {createAsyncThunk, createSelector, createSlice} from "@reduxjs/toolkit";
import createHttpRequest from "../utils/http";
import {ApiUrls} from "../constants/urls";
import {RootState} from "../store";
import {toast} from "react-toastify";
import {setLocalStorageValue} from "../utils/common";

interface IMediaSize {
    width: number,
    height: number,
}

interface IInitialState {
    config: {
        settings: any,
        queue: any[],
        pastQueue: any[],
        nextChangeDate: number | null,
    },
    changingBlock: {
        block?: number,
        stream?: number,
        type?: string,
    } | null | undefined,
    dictionaryId: string | null,
    isLoading: boolean,
    lastZoomedEvent: any,
    skipUpdate: boolean,
    showImage: boolean,
    activeMediaSize?: IMediaSize | null,
}

const initialState: IInitialState = {
    config: {
        settings: {
            period: null,
        },
        queue: [],
        pastQueue: [],
        nextChangeDate: null,
    },
    changingBlock: null,
    dictionaryId: null,
    isLoading: false,
    lastZoomedEvent: null,
    skipUpdate: false,
    showImage: true,
    activeMediaSize: null,
};

export const fetchProTvConfig =
    createAsyncThunk<any>("proTv/fetchProTvConfig", async() => {
        const response = await createHttpRequest({
            method: "GET",
            path: ApiUrls.INCIDENT_DICTIONARIES("INCIDENTS_TVSETTINGS.1"),
            errorMessage: "messages:pro_tv_fetch_config_error",
        });

        return response.data;
    });

export const createProTvConfig =
    createAsyncThunk<any>("proTv/createProTvConfig", async() => {
        const response = await createHttpRequest({
            method: "POST",
            path: ApiUrls.INCIDENT_DICTIONARIES("INCIDENTS_TVSETTINGS.1"),
            data: {
                data: initialState.config,
                name: "proTvConfig",
                typeId: "INCIDENTS_TVSETTINGS.1",
            },
            errorMessage: "messages:pro_tv_create_dictionary_error",
        });

        return response.data;
    });

export const updateProTvConfig =
    createAsyncThunk<any, any>("proTv/updateProTvConfig", async({data, id}) => {
        try {
            const response = await createHttpRequest({
                method: "PUT",
                path: ApiUrls.INCIDENT_DICTIONARY(id),
                data: {
                    data: data,
                    name: "proTvConfig",
                    typeId: "INCIDENTS_TVSETTINGS.1",
                },
                errorMessage: "messages:pro_tv_update_dictionary_error",
            });

            return response.data;
        } catch (e) {
            createProTvConfig();
        }
    });

const proTv = createSlice({
    reducers: {
        appendBlock(state, {payload}) {
            state.skipUpdate = true;
            state.config.queue = [...state.config.queue, payload];
        },
        removeBlock(state, {payload}) {
            if (payload.isActiveQueue) {
                state.config.queue = state.config.queue
                    ?.filter((item: any, index: number) => index !== payload.index);
            } else {
                state.config.pastQueue = state.config.pastQueue
                    ?.filter((item: any, index: number) => index !== payload.index);
            }
        },
        setChangingBlock(state, {payload}) {
            state.changingBlock = payload;
        },
        setProTvStream(state, {payload}) {
            const blockIndex = state.changingBlock?.block;
            const streamIndex = state.changingBlock?.stream;
            if (blockIndex === undefined || streamIndex === undefined) {
                toast.error("Выберите ячейку для изменения");
                return;
            }

            if (state.changingBlock?.type === "incident" && payload?.sourceType === "camera") {
                toast.error("Недопустимый тип отображения стрима");
                return;
            }

            state.config.queue[blockIndex].roadObjectIds[streamIndex] = payload;
            if (state.changingBlock) {
                state.changingBlock.block = undefined;
                state.changingBlock.stream = undefined;
            }
        },
        changeStreamContentType(state, {payload}) {
            const {blockIndex, streamIndex} = payload;
            state.config.queue[blockIndex].roadObjectIds[streamIndex].displayMediaType = payload.value;
        },
        setRepeatBlock(state, {payload}) {
            const {blockIndex, value, isActiveQueue} = payload;
            isActiveQueue ? state.config.queue[blockIndex].repeatBlock = value
                : state.config.pastQueue[blockIndex].repeatBlock = value;
        },
        removeProTvStream(state, {payload}) {
            const {blockIndex, streamIndex} = payload;
            state.config.queue[blockIndex].roadObjectIds[streamIndex] = null;
        },
        updateBlockType(state, {payload}) {
            state.config.queue[payload.blockIndex].type = payload.type;
            state.changingBlock = null;
            if (payload.type === "incident") {
                state.config.queue[payload.blockIndex].roadObjectIds = [null];
            } else {
                state.config.queue[payload.blockIndex].roadObjectIds = [null, null, null, null];
            }
        },
        moveToNextBlock(state) {
            const queue = [...state.config.queue];
            const nearestFullBlock = state.config.queue?.findIndex((block: any, index: number) => {
                if (index === 0) return false;
                return block.roadObjectIds.every((stream: any) => stream);
            });

            const changePeriodDate = () => {
                const periodMinutes = state.config.settings.period;
                const now = new Date().getTime();
                const nextChangeDate = new Date(now + periodMinutes * 60 * 1000);
                state.config.nextChangeDate = nextChangeDate.getTime();
            };

            // Если дальше в очереди нет заполненных стримами блоков
            if (nearestFullBlock === -1 || queue.length < 2) {
                if (state.config.pastQueue.length !== 0) {
                    changePeriodDate();
                } else {
                    return;
                }
                const firstRemovedBlock = state.config.pastQueue.shift();
                const showedStream = state.config.queue.shift();
                state.config.queue = [firstRemovedBlock, ...state.config.queue];
                state.config.pastQueue = [...state.config.pastQueue, showedStream];
                return;
            }

            changePeriodDate();

            const currentBlock = queue[0];
            state.config.pastQueue = [...state.config.pastQueue, currentBlock];

            // Перенос ближайщих незаполненных блоков в конец очереди
            state.config.queue = queue.slice(nearestFullBlock).concat(queue.slice(1, nearestFullBlock));
        },
        revertFirstDeletedStream(state) {
            const firstRemovedBlock = state.config.pastQueue.shift();
            if (!firstRemovedBlock) {
                toast.error("Нет прошедших стримов");
                return;
            }
            state.config.queue = [firstRemovedBlock, ...state.config.queue];
        },
        moveBlockToPast(state, {payload}) {
            const movedBlock = state.config.queue[payload];
            state.config.queue = state.config.queue?.filter((item: any, index: number) => index !== payload);
            state.config.pastQueue = [...state.config.pastQueue, movedBlock];
        },
        resetDictionaryId(state) {
            state.dictionaryId = null;
        },
        setNextChangeDate(state, {payload}) {
            const periodMinutes = state.config.settings.period ?? 15;
            const now = new Date().getTime();
            const nextChangeDate = new Date(now - (payload * 1000) + periodMinutes * 60000);
            state.config.nextChangeDate = nextChangeDate.getTime();

        },
        resetNextChangeDate(state) {
            state.config.nextChangeDate = null;
        },
        revertLastDeletedStream(state) {
            const lastDeletedStream = state.config.pastQueue.pop();
            if (!lastDeletedStream) {
                toast.error("Нет прошедших стримов");
                return;
            }
            state.config.queue = [lastDeletedStream, ...state.config.queue];
        },
        setSettings(state, {payload}) {
            state.config.settings = {...state.config.settings, ...payload};
        },
        setLastZoomedEvent(state, {payload}) {
            state.lastZoomedEvent = payload;
        },
        setProTvConfig(state, {payload}) {
            if (window.location.pathname !== "/incidents-on-control"
                && window.location.pathname !== "/incidents2/incidents-on-control") {
                state.config = payload;
            } else {
                state.skipUpdate = true;
                state.config = payload;
            }
        },
        setSkipUpdate(state, {payload}) {
            state.skipUpdate = payload;
        },
        updateIncidentData(state, {payload}) {
            const {block, stream, isActiveQueue, ...rest} = payload;
            const currentBlock = isActiveQueue ? state.config.queue[block].roadObjectIds[stream]
                : state.config.pastQueue[block].roadObjectIds[stream];
            isActiveQueue ? state.config.queue[block].roadObjectIds[stream] = {
                ...currentBlock,
                ...rest,
            } : state.config.pastQueue[block].roadObjectIds[stream] = {
                ...currentBlock,
                ...rest,
            };
        },
        setShowImage(state, {payload}) {
            state.showImage = payload;
        },
        updateFourStreamsComment(state, {payload}) {
            const {blockIndex, isActiveQueue, ...rest} = payload;
            const currentBlock = isActiveQueue ? state.config.queue[blockIndex] : state.config.pastQueue[blockIndex];
            isActiveQueue ? state.config.queue[blockIndex] = {
                ...currentBlock,
                ...rest,
            } : state.config.pastQueue[blockIndex] = {
                ...currentBlock,
                ...rest,
            };
        },
        setActiveMediaSize(state, {payload}) {
            state.activeMediaSize = payload;
        },
    },
    name: "proTv",
    initialState,
    extraReducers: (builder) => {
        builder.addCase(fetchProTvConfig.pending, (state) => {
            state.isLoading = true;
        });
        builder.addCase(fetchProTvConfig.fulfilled, (state, {payload}) => {
            if (payload?.Result[0]?.Data) {
                state.config = payload?.Result[0]?.Data;
                state.config.nextChangeDate = payload?.Result[0]?.Data?.nextChangeDate;
                state.dictionaryId = payload.Result[0].Id;
                setLocalStorageValue("proTvConfig", JSON.stringify(payload?.Result[0]?.Data));
            }
            state.isLoading = false;
        });
        builder.addCase(fetchProTvConfig.rejected, (state) => {
            state.isLoading = false;
        });

        builder.addCase(updateProTvConfig.pending, (state) => {
            state.isLoading = true;
        });
        builder.addCase(updateProTvConfig.fulfilled, (state) => {
            state.isLoading = false;
        });
        builder.addCase(updateProTvConfig.rejected, (state) => {
            state.isLoading = false;
        });
    },
});

export default proTv.reducer;

export const {
    setProTvConfig,
    appendBlock,
    removeBlock,
    setChangingBlock,
    setProTvStream,
    removeProTvStream,
    updateBlockType,
    moveToNextBlock,
    revertFirstDeletedStream,
    resetDictionaryId,
    setNextChangeDate,
    moveBlockToPast,
    revertLastDeletedStream,
    setSettings,
    setLastZoomedEvent,
    setSkipUpdate,
    updateIncidentData,
    updateFourStreamsComment,
    setRepeatBlock,
    changeStreamContentType,
    setShowImage,
    setActiveMediaSize,
} = proTv.actions;

const slice = ({proTvReducer}: RootState) => proTvReducer;

export const proTvConfigSelector = createSelector(
    slice,
    ({config}) => config
);

export const queueSelector = createSelector(
    slice,
    ({config}) => config.queue
);

export const pastQueueSelector = createSelector(
    slice,
    ({config}) => config.pastQueue
);

export const changingBlockSelector = createSelector(
    slice,
    ({changingBlock}) => changingBlock
);

export const proTvDictionaryIdSelector = createSelector(
    slice,
    ({dictionaryId}) => dictionaryId
);

export const isLoadingSelector = createSelector(
    slice,
    ({isLoading}) => isLoading
);

export const activeBlockSelector = createSelector(
    slice,
    ({config}) => {
        if (config.queue[0]) {
            return config.queue[0];
        } else {
            return config.pastQueue[0];
        }
    }
);

export const changingPeriodSelector = createSelector(
    slice,
    ({config}) => config.settings.period
);

export const nextChangeDateSelector = createSelector(
    slice,
    ({config}) => config.nextChangeDate
);

export const proTvSettingsSelector = createSelector(
    slice,
    ({config}) => config.settings
);

export const lastZoomedEventSelector = createSelector(
    slice,
    ({lastZoomedEvent}) => lastZoomedEvent
);

export const skipUpdateSelector = createSelector(
    slice,
    ({skipUpdate}) => skipUpdate
);

export const showImageSelector = createSelector(
    slice,
    ({showImage}) => showImage
);

export const activeMediaSizeSelector = createSelector(
    slice,
    ({activeMediaSize}) => activeMediaSize
);