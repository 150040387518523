import React, {FC, useEffect} from "react";
import cn from "classnames";
import {useDispatch, useSelector} from "react-redux";
import {isEmpty} from "lodash";

import {TypedDispatch} from "../../../types";
import {fetchResponseServices, responseServicesSelector} from "../../../redux/responseServiceSlice";
import {fetchResponsibilityZones, responsibilityZonesSelector} from "../../../redux/responsibilitySlice";
import {fetchIncidentTypes, incidentTypesSelector} from "../../../redux/incidentTypeSlice";
import {fetchUserSettings, userSettingsSelector} from "../../../redux/userSettingsSlice";
import {fetchIndicators} from "../../../redux/indicatorsSlice";
import {fetchSettings, settingsSelector} from "../../../redux/settingsSlice";

import Header from "../../DashboardDemonstration/Header/Header";
import Sidebar from "../../Sidebar";

import stylesCommon from "styles/Common.module.scss";

interface ILayoutMainProps {
    children: React.ReactNode | string,
    isDemonstrationDashboard: boolean,
}

const LayoutDemonstration: FC<ILayoutMainProps> = ({children, isDemonstrationDashboard}) => {
    const dispatch = useDispatch<TypedDispatch>();
    const responseServices = useSelector(responseServicesSelector);
    const responsibilityZones = useSelector(responsibilityZonesSelector); // Зоны контроля
    const settings = useSelector(settingsSelector);
    const userSettings = useSelector(userSettingsSelector);
    const incidentTypes = useSelector(incidentTypesSelector);

    useEffect(() => {
        if (isEmpty(settings)) dispatch(fetchSettings()); // Настройки (содержит список типов камер)

        // Пользовательские настройки (в т.ч. localUrl для стримов)
        if (!userSettings.widgetsLayout) dispatch(fetchUserSettings());

        // Получение списка служб
        if (isEmpty(responseServices)) dispatch(fetchResponseServices());
        if (isEmpty(incidentTypes)) dispatch(fetchIncidentTypes()); // Типы инцидентов
        if (isEmpty(responsibilityZones)) dispatch(fetchResponsibilityZones());
        dispatch(fetchIndicators()); // Получение индикаторов
    }, []);

    return (
        <>
            <Header/>

            <div className={stylesCommon["main__content"]}>
                <Sidebar isDemonstrationDashboard={isDemonstrationDashboard}/>

                <div className={cn(
                    stylesCommon["dashboard-content"],
                    stylesCommon["dashboard-content--demonstration"]
                )}>
                    {children}
                </div>
            </div>
        </>
    );
};

export default React.memo(LayoutDemonstration);