import React from "react";
import {useTranslation} from "react-i18next";

import {HandleSortType} from "types/table";

import SortableTh from "./SortableTh";

interface ISortableTableHeadProps {
    columns: string[],
    sortableColumns: string[],
    sort: { direction: string, field: string },
    /* eslint-disable */
    handleSort: HandleSortType
    columnsTranslationNamespace: string,
    customStyles?: object,
    headerCustomComponent?: React.ReactNode,
    headerCustomCheckbox?: React.ReactNode,
    headerCustomHide?: React.ReactNode,
}

const SortableTableHead: React.FC<ISortableTableHeadProps> = (
    {
        columns,
        sortableColumns,
        sort,
        handleSort,
        columnsTranslationNamespace,
        customStyles,
        headerCustomComponent,
        headerCustomCheckbox,
        headerCustomHide,
    }) => {
    const {t} = useTranslation(columnsTranslationNamespace);

    return (
        <thead>
        <tr style={customStyles}>
            {columns.map((col, idx) => {
                if (col === "headerCustomComponent") return <th key={idx}>{headerCustomComponent}</th>;
                if (col === "headerCustomCheckbox") return <th key={idx}>{headerCustomCheckbox}</th>;
                if (col === "headerCustomHide") return <th key={idx}>{headerCustomHide}</th>;


                return sortableColumns.includes(col)
                    ? (
                        <SortableTh
                            key={idx}
                            sort={sort}
                            handleSort={handleSort}
                            sortableCol={col}
                            columnsTranslationNamespace={columnsTranslationNamespace}
                        />
                    )
                    : <th key={idx}>{t(`${columnsTranslationNamespace}:table.${col}`)}</th>
            })}
        </tr>
        </thead>
    );
};

export default React.memo(SortableTableHead);