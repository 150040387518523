import React from "react";
import cn from "classnames";

import {ApiUrls} from "../../constants/urls";
import {IncidentEventType} from "../../models/IncidentEventType";

import ImageWithToken from "./ImageWithToken";
import Unknown from "images/icons/type/unknown.svg";
import LightTooltip from "../UI/Tooltip/LightTooltip";

import styles from "../ArchivePage/ArchiveTable/ArchiveTable.module.scss";

interface ITypeIconProps {
    type?: IncidentEventType,
    typeCode?: string,
    withTooltip?: boolean,
    classname?: string,
    customStyles?: string,
    priority?: number,
    bigImg?: boolean,
    width?: number,
    height?: number,
    tooltipClassname?: string,
    backgroundColor?: string,
}

const TypeIcon: React.FC<ITypeIconProps> = ({
    type,
    typeCode,
    withTooltip = true,
    classname,
    customStyles,
    priority,
    bigImg,
    width,
    height,
    tooltipClassname,
    backgroundColor,
}) => {
    const icon = typeCode
        ? ApiUrls.INCIDENT_TYPE_ICON(typeCode)
        : type?.code
            ? ApiUrls.INCIDENT_TYPE_ICON(type?.code)
            : "";
    const title = type?.name ?? "N/A";
    const isLocalType = type?.code === "local";
    const image = (
        <div
            className={
                customStyles ? customStyles : cn(
                    styles["event-icon"],
                    styles["event-icon--circle"],
                    "icon-filter",
                    classname,
                    String(priority) && styles[`priority--${priority}`],
                    bigImg && styles["event-icon--big"]
                )
            }
            style={{backgroundColor}}
        >
            {!isLocalType && (
                <ImageWithToken
                    src={icon}
                    width={width || 22}
                    height={height || 22}
                    alt={title}
                    fallbackSrc={Unknown}
                    caching={true}
                />
            )}
        </div>
    );
    return (
        (withTooltip && !isLocalType)
            ? (
                <LightTooltip
                    title={title}
                    className={tooltipClassname ?? ""}
                >
                    {image}
                </LightTooltip>
            ) : image
    );
};

export default React.memo(TypeIcon);