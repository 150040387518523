import React, {FC, ReactNode, RefObject} from "react";
import cn from "classnames";
import {Popover, Whisper} from "rsuite";

import styles from "./MoreActions.module.scss";

export type Placement4 = "top" | "bottom" | "right" | "left";
// eslint-disable-next-line max-len
export type Placement8 = "bottomStart" | "bottomEnd" | "topStart" | "topEnd" | "leftStart" | "rightStart" | "leftEnd" | "rightEnd";
// eslint-disable-next-line max-len
export type PlacementAuto = "auto" | "autoVertical" | "autoVerticalStart" | "autoVerticalEnd" | "autoHorizontal" | "autoHorizontalStart" | "autoHorizontalEnd";
export type OverlayTriggerType = "click" | "hover" | "focus" | "active" | "contextMenu" | "none";

interface IMoreActionsProps {
    className?: string,
    dropdownClassname?: string,
    children: ReactNode | null | undefined,
    anchor: JSX.Element,
    arrow?: boolean,
    placement?: Placement4 | Placement8 | PlacementAuto,
    popoverStyles?: {[key: string]: any},
    containerRef?: RefObject<HTMLDivElement>,
}

const MoreActions: FC<IMoreActionsProps> = (
    {
        className,
        dropdownClassname,
        children,
        anchor,
        placement = "autoVertical",
        arrow = true,
        popoverStyles,
        containerRef,
    }
) => {

    const handleClick = (event: React.MouseEvent<HTMLElement>) => {
        event.preventDefault();
        event.stopPropagation();
    };

    const typePopover = <Popover arrow={arrow ?? true} style={popoverStyles}>
        <div className={cn(styles["dropdown"], dropdownClassname)}>
            {children}
        </div>
    </Popover>;

    return (
        <Whisper
            placement={placement}
            trigger="click"
            speaker={typePopover}
            container={containerRef?.current ? containerRef?.current : undefined}
        >
            <div className={className} onMouseUp={handleClick}>
                {anchor}
            </div>
        </Whisper>
    );
};

export default React.memo(MoreActions);