/* eslint-disable max-len */
import React from "react";

import {ISvgProps} from "../../../../types/common";

const EventResolvedIcon: React.FC<ISvgProps> = ({
    height = 24,
    width = 24,
    ...rest
}) => (
    <svg {...rest} width={width} height={height} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <rect x="1" y="1" width="22" height="22" rx="4" fill="currentColor"/>
        <path fillRule="evenodd" clipRule="evenodd" d="M10.4712 13.5283L16.6665 7.33301L17.6093 8.27582L10.4712 15.4139L6.6665 11.6092L7.60931 10.6663L10.4712 13.5283Z" fill="white" stroke="white" strokeWidth="1"/>
    </svg>
);

export default EventResolvedIcon;