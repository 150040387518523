import React, {Dispatch, FC, SetStateAction, useEffect} from "react";
import cn from "classnames";
import {isNumber} from "lodash";

import CrossIcon from "components/Icons/CrossIcon";

import styles from "./DashboardRocketChat.module.scss";

interface IDashboardRocketChat {
    closeHandler: () => void,
    chatToken?: string | null,
    isChatOpen: boolean,
    setUnreadMessagesCount: Dispatch<SetStateAction<number>>,
}

const DashboardRocketChat: FC<IDashboardRocketChat> = (
    {
        closeHandler,
        chatToken,
        isChatOpen,
        setUnreadMessagesCount,
    }
) => {

    useEffect(() => {
        const handler = (event: MessageEvent) => {
            if (event.origin === window.location.origin) {
                if (event.data.eventName === "startup") {
                    if (chatToken) {
                        const rocketChat = document.querySelector(".rocket-chat");
                        const rocketChatIframe = rocketChat?.querySelector("iframe");

                        if (rocketChatIframe && rocketChatIframe.contentWindow) {
                            rocketChatIframe?.contentWindow?.postMessage({
                                externalCommand: "login-with-token",
                                token: chatToken,
                            }, "*");
                        }
                    }
                }

                // Изменение индикатора непрочитанных сообщений
                if (event.data.eventName === "unread-changed") {
                    if (isNumber(event.data.data)) {
                        setUnreadMessagesCount(event.data.data);
                    } else {
                        setUnreadMessagesCount(0);
                    }
                }
            }
        };
        window.addEventListener("message", handler);

        return () => {
            window.removeEventListener("message", handler);
        };
    }, [chatToken]);

    return (
        <div className={cn(styles["chat"], "rocket-chat", {[styles["chat--opened"]]: isChatOpen})}>
            <div className={styles["chat__header"]}>
                <div>
                    <div className={styles["chat__header-title"]}>Чат</div>
                    <div className={styles["chat__header-description"]}>Чат между операторами</div>
                </div>
                <CrossIcon onClick={closeHandler} className={styles["chat__header-close"]}/>
            </div>

            <div className={styles["chat__body"]}>
                {chatToken && (
                    <iframe
                        src={`${window.location.origin}/chat/channel/general?layout=embedded`}
                        title="Чат операторов"
                        name={"rocketChatWindow"}
                    />
                )}
            </div>
        </div>
    );
};

export default DashboardRocketChat;