import React, {FC, useEffect, useState} from "react";
import {Tab, TabList, TabPanel, Tabs} from "react-tabs";
import {Link} from "react-router-dom";
import {useDispatch, useSelector} from "react-redux";
import useHasPermission from "../../../hook/useHasPermission";
import {Permission} from "../../../models/Permission";

import {setSettingsBackUrl} from "../../../redux/userSettingsSlice";
import {TypedDispatch} from "../../../types";
import {
    adminResponsibilityZonesSelector,
    fetchAdminResponsibilityZones,
} from "../../../redux/responsibilitySlice";

import BaseButton from "../../UI/Button/BaseButton";
import ViewSettingsBlock from "../ViewSettingsBlock";
import {ICard} from "../SettingsViewPage/SettingsViewPage";
import ArrowRightSmallIcon from "../../Icons/ArrowRightSmallIcon";
import CrossIcon from "../../Icons/CrossIcon";
import TagsIcon from "../../Icons/TagsIcon";
import TabIcon1 from "../../Icons/Settings/TabIcon1";
import SettingsTags from "../SettingsTags";
import HeadUserIcon from "../../Icons/HeadUserIcon";
import SettingsOperators from "../SettingsOperators";
import SettingsComments from "../SettingsComments";
import CommentsIcon from "../../Icons/CommentsIcon";
import PrioritiesIcon from "../../Icons/PrioritiesIcon";
import SettingsCams from "../SettingsCams";

import styles from "./QuickSettingsTabs.module.scss";

interface IQuickSettingsTabsProps {
    // eslint-disable-next-line no-unused-vars
    setCardList: (_: ICard[]) => void,
    cardList: ICard[],
    isAdmin?: boolean,
    onClose?: () => void,
    selectedTab?: number | null,
    cameraId?: number | null | undefined,
    cameraType?: string | null | undefined,
    isManager?: boolean,
    cameraZone?: number | null | undefined,
}

const QuickSettingsTabs: FC<IQuickSettingsTabsProps> = ({
    setCardList,
    cardList,
    isAdmin,
    onClose,
    selectedTab,
    cameraId,
    cameraType,
    isManager,
    cameraZone,
}) => {
    const dispatch = useDispatch<TypedDispatch>();
    const [tabIndex, setTabIndex] = useState(selectedTab ?? 0);
    const isService = useHasPermission(Permission.SERVICE);
    const responsibilityZones = useSelector(adminResponsibilityZonesSelector);

    const setSettingsBackUrlHandler = () => {
        dispatch(setSettingsBackUrl(window.location.pathname));
    };

    useEffect(() => {
        if (!responsibilityZones?.length) {
            dispatch(fetchAdminResponsibilityZones());
        }
    }, []);

    return (
        <div className={styles["tabs"]}>
            <div className={styles["tabs-header"]}>
                <div className={styles["tabs-header--left"]}>
                    <div className={styles["tabs-header__title"]}>
                        Быстрые настройки
                    </div>
                    {!isService && (
                        <Link to={"/settings/view"}>
                            <BaseButton
                                onClick={setSettingsBackUrlHandler}
                                className={styles["tabs-header__link"]}
                                tag="button"
                            >
                                Перейти во все
                                <ArrowRightSmallIcon className={styles["tabs-header__link-icon"]}/>
                            </BaseButton>
                        </Link>
                    )}
                </div>
                <CrossIcon
                    className={styles["close"]}
                    onClick={onClose}
                    width={20}
                    height={20}
                />
            </div>

            <Tabs selectedIndex={tabIndex} onSelect={(index) => setTabIndex(index)}>
                <TabList>
                    {(isAdmin || isManager || isService) && (
                        <Tab>
                            <TagsIcon className={styles["tabs-list__icon"]}/>
                            Теги
                        </Tab>
                    )}
                    {(isAdmin || isManager || isService) && (
                        <Tab>
                            <HeadUserIcon
                                width={16}
                                height={16}
                                className={styles["tabs-list__icon"]}
                            />
                            Операторы
                        </Tab>
                    )}
                    <Tab>
                        <TabIcon1
                            width={16}
                            height={16}
                            className={styles["tabs-list__icon"]}
                        />
                        Интерфейс
                    </Tab>

                    {(isAdmin || isManager || isService) && (
                        <Tab>
                            <CommentsIcon className={styles["tabs-list__icon"]}/>
                            Комментарии
                        </Tab>
                    )}
                    {!isService && (
                        <Tab>
                            <PrioritiesIcon className={styles["tabs-list__icon"]}/>
                            Камеры
                        </Tab>
                    )}
                </TabList>

                {(isAdmin || isManager || isService) && (
                    <TabPanel>
                        <SettingsTags isService={isService}/>
                    </TabPanel>
                )}

                {(isAdmin || isManager || isService) && (
                    <TabPanel>
                        <SettingsOperators/>
                    </TabPanel>
                )}

                <TabPanel>
                    <ViewSettingsBlock
                        previewMode={true}
                        setCardList={setCardList}
                        cardList={cardList}
                        isAdmin={isAdmin}
                    />
                </TabPanel>
                {(isAdmin || isManager || isService) && (
                    <TabPanel>
                        <SettingsComments isService={isService}/>
                    </TabPanel>
                )}
                {!isService && (
                    <TabPanel>
                        <SettingsCams
                            cameraId={cameraId}
                            cameraType={cameraType}
                            isManager={isManager}
                            isAdmin={isAdmin}
                            cameraZone={cameraZone}
                        />
                    </TabPanel>
                )}
            </Tabs>
        </div>
    );
};

export default React.memo(QuickSettingsTabs);