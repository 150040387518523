/* eslint-disable no-unused-vars */
import {createAsyncThunk, createSelector, createSlice, PayloadAction} from "@reduxjs/toolkit";
import {toast} from "react-toastify";
import {isArray, uniqBy} from "lodash";
import {RootState} from "store";
import moment from "moment";

import createHttpRequest from "../utils/http";
import {ApiUrls} from "../constants/urls";
import LastIncidentEventModel from "../models/LastIncidentEventModel";
import {UpdateIncidentRequest} from "../models/UpdateIncidentRequest";
import {AssignIncidentRequest} from "../models/AssignIncidentRequest";
import {RejectIncidentRequest} from "../models/RejectIncidentRequest";
import {IncidentEventStatus} from "../models/IncidentEventStatus";
import {EventsSummary} from "../models/EventsSummary";
import IncidentEventInfoModel from "../models/IncidentEventInfoModel";
import Pagination from "../models/Pagination";
import {buildQuery, buildQueryObject} from "../utils/query";
import IncidentEventInfoModelPagedList from "../models/IncidentEventInfoModelPagedList";
import IncidentEventModel from "../models/IncidentEventModel";
import IncidentCommentPagedList from "../models/IncidentCommentPagedList";
import IncidentComment, {IAttachment, TypeComments} from "../models/IncidentComment";
import {DEFAULT_EVENTS_SORT, DEFAULT_PAGE_SIZE} from "../constants/common";
import {CloseIncidentRequest} from "../models/CloseIncidentRequest";
import {roadObjectFeaturesHashMapSelector} from "./roadObjectSlice";
import {getDefaultDate, getMs} from "../utils/datetime";
import {ISelectOption} from "../components/UI/Select/CustomSelectWithLabel";
import {
    IncidentsActualProTvModel,
    IncidentsFavoriteModel,
    IncidentsOnControlModel,
} from "../models/incidentsOnControlModel";
import Notification from "../models/Notification";
import {IFileType} from "../components/ArchiveIncidentPage/Comments/AddCommentForm";
import IncidentEventMedia from "../models/IncidentEventMedia";
import {IncidentEventAdditionalModel} from "../models/IncidentEventAdditionalModel";
import {IncidentLocation} from "../models/IncidentEventObject";
import {AxiosResponse} from "axios";
import ITag from "../models/TagModel";
import {IncidentEventType} from "../models/IncidentEventType";
import {formatDateForFilters} from "../utils/FormatDateForFilters";
import ArchiveAnalytics from "../models/ArchiveAnalytics";
import {transformFiltersToAnalytics} from "../utils/transformFiltersToAnalytics";
import IncidentOperator from "../models/IncidentOperator";

export type ModeTagsType = "OR" | "AND";

type CapitalizeKeys<T> = {
    [K in keyof T as Capitalize<K & string>]: T[K]
};

export type ImportantIncidentsType = "actual" | string | null;

export interface IEventsFilters {
    incidentTypeCodes: string[], // Тип событий
    deviceTypes: string[], // Тип устройства
    incidentSubtypeCodes: string | null, // Тип камеры - строка {CAM: ["static", "sphere"]}
    eventPriority: number[], // Приоритет [0, 1, 2]
    status: IncidentEventStatus[], // Статус
    roadObjectIds: number[], // Место
    zoneIds: number[], // Зона
    sinceDay: string | null, // Дата от
    untilDay: string | null, // Дата до
    dateKind: string | null,
    tracked: ImportantIncidentsType, // На контроле
    favorite: ImportantIncidentsType, // ПроТВ
    modeTags: ModeTagsType,
    tags: string[],
    incidentSource: string[],
    content: string,
    onlyWithNested: boolean, // Инциденты/События
}

export interface IArchiveAnalyticsFilters {
    createdDateTime: {
        lowerBound: string | null,
        upperBound: string | null,
        excludingUpperBound: boolean,
    },
    kind: string[],
    tracked: ImportantIncidentsType, // На контроле
    favorite: ImportantIncidentsType, // ПроТВ
    priorities?: number[],
    tags?: number[],
    tagsMode?: ModeTagsType,
    sourcesTags?: number[],
    segmentIds?: number[],
    types?: string[],
    zones?: number[],
    responseServices?: string[],
    operators?: number[],
    roadObjectsIds?: number[],

    [key: string]: unknown,
}

interface IEventsQueryParamsCapitalizedPayload
    extends Omit<CapitalizeKeys<IEventsQueryParams>, "Favorite" | "Tracked"> {
    Favorite: boolean,
    Tracked: boolean,
}

interface IEventsQueryParamsPayload extends Omit<IEventsQueryParams, "tracked" | "favorite"> {
    favorite: boolean,
    tracked: boolean,
}

interface FetchLastEventsProps {
    params?: {[key: string]: string[] | string},
    currentLoader?: "isLastEventsLoading" | "roadObjectsForMarkersIsLoading",
    signal?: AbortSignal,
}

interface ILastEventsFilters {
    [key: string]: (number | string | IncidentEventStatus)[] | [],
}

interface IEventsSort {
    field: "registrationDateTime" | "processingDateTime" | "deviceSubType" | "incType" | "priority" | "status",
    direction: "desc" | "asc",
}

interface IEventsQueryParams extends IEventsFilters {
    page?: number | null,
    pageSize: number,
    sort: string,
}

interface ILastEventsStatusCounters {
    [IncidentEventStatus.Resolved]: number,
    [IncidentEventStatus.Rejected]: number,
    [IncidentEventStatus.Processing]: number,
    [IncidentEventStatus.New]: number,
}

export interface IAddEventCommentOperator {
    id: string,
    typeComments: TypeComments,
    data: {
        content: string,
        responseService?: string | null,
        notifyResponseServices?: string[] | null,
        avoidEmptyContentCheck?: boolean,
        files?: File[],
        attachments?: string[],
    },
}

interface IAddCommentFile {
    id: string,
    commentId?: string,
    contentTypes?: string,
    files?: File[],
    notifyResponseServices?: string[],
}

interface ICommentNotifyResponseService {
    incidentId: string,
    commentId: string,
    data: {
        notifyResponseServices: string[],
    },
}

interface IFetchMediaTypeComments {
    id: string,
    contentTypes: string,
    typeComments: TypeComments,
    showDeleted: boolean,
}

interface ICommentAttachments {
    id: string,
    commentId: string,
}

export interface IAddAndRemoveEventCamera {
    incidentId: string,
    camId: string,
}

export interface ICreatedIncident {
    incident: IncidentEventModel,
    status: any,
}

interface IRemoveIncidentMediaRequest {
    id: string,
    data: {
        index_files: string[],
    },
}

interface IAddIncidentMediaRequest {
    id: string,
    data: FormData,
}

interface ISetTrackedIncidentRequest {
    id: string,
    message?: string,
}

interface IRemoveTrackedIncidentRequest {
    id: string,
}

interface ISetMasterRepresentationEventRequest {
    masterIncidentId: string,
    incidentId: string | null,
}

interface ISetAggregatorRequest {
    id: string,
    reloadAfter?: boolean,
}

export interface IIncidentInfoFilters {
    type: string[],
    deviceSubType: string[],
    registrationDateTime: {
        sinceTime: ISelectOption,
        untilTime: ISelectOption,
    }[],
}

export interface IUpdateEventVisibility {
    hideIncident: boolean,
    incidentIds: (string | null | undefined)[],
    masterIncidentId: string | null | undefined,
    includeHiddenEvents: boolean,
}

export interface IFetchEventRequest {
    id?: string | null,
    includeHiddenEvents?: boolean,
    updateVisibility?: boolean,
}

interface IUpdateIncidentName {
    incidentId: string | null,
    incidentName: string,
}

interface IUpdateIncidentLocation {
    incidentId: string | null,
    data: {
        Point: {
            longitude: number | null,
            latitude: number | null,
        },
    },
}

/**
 * @fixationTime ISOString
 * @tags name тегов
 */
export interface ICreateIncident {
    title: string,
    zone: number,
    fixationTime: string,
    eventPriority: number,
    incidentTypeCode: string,
    file?: File | null,
    location: IncidentLocation,
    description?: string,
    tags: string[],
    roadObjectIds?: number[],
    Data: string,
    autoAssign?: boolean,
}

interface IIncidentCommentsProps {
    id: string,
    showDeleted?: boolean,
}

interface IIncidentDeleteProps {
    commentId: string,
    incidentId: string,
    typeComments: TypeComments,
    activeComments?: TypeComments,
    showDeleted?: boolean,
}

interface IRestoreCommentProps {
    incidentId: string,
    commentId: string,
    activeComments?: TypeComments,
}

interface IIncidentEditProps extends IIncidentDeleteProps {
    data: {
        Content: string,
        AddFiles: File[] | IFileType[],
    },
}

interface IAttachmentDeleteProps {
    incidentId: string,
    fileId: string,
}

interface FetchLastEventsProps {
    params?: {[key: string]: string[] | string},
}

interface IAddAttachmentsToMedia {
    incidentId: string,
    fileIds: string[],
}

interface IUpdateAdditionalInfo {
    data: IncidentEventAdditionalModel,
    incidentId: string,
}

interface IHandoverEventParams {
    eventId: string,
    userId: string,
    comment: string,
}

interface ILoginAttemptsCountExceededMessage {
    attemptsCount: number,
    login: string,
    userFullName: string,
}

export interface ISetFilters {
    filterType: keyof IEventsFilters,
    selectedFilters: any,
}

export interface IToiNotification {
    id: string,
    incidentId: string,
    comment: string,
    date: string,
    operator: IncidentOperator,
}

export interface IInitialState {
    resetCounterArchive: number,
    incidentsOnControl: IncidentsOnControlModel[],
    incidentsFavorite: IncidentsFavoriteModel[],
    lastEvents: LastIncidentEventModel[], // Последние инциденты на Главной
    roadObjectsForMarkers: LastIncidentEventModel[],
    lastEventsFilters: ILastEventsFilters,
    demoLastEventsFilters: ILastEventsFilters,
    lastEventsStatusFilters: IncidentEventStatus[],
    selectedLastEventId: string | null,
    selectedLastEvent: LastIncidentEventModel | null,
    selectedLastIncidentLocation: {
        coordinates: [number, number],
        incident: any,
    },
    events: IncidentEventInfoModel[], // Архив
    pagination: Pagination,
    filters: IEventsFilters, // Фильтры в Архиве
    savedArchiveFilters: IEventsFilters | null,
    summary: EventsSummary | null, // Статистика в Архиве
    analyticsFilters: IArchiveAnalyticsFilters, // Фильтр аналитики в архиве
    analytics: ArchiveAnalytics | null, // Блок аналитики в архиве
    event: IncidentEventModel | null, // Страница инцидента
    incidentInfoFilters: IIncidentInfoFilters, // Фильтры в группе инцидентов
    comments: IncidentComment[],
    secondScreenActiveEventId: string | null,
    mediaComments: IncidentComment[],
    documentComments: IncidentComment[],
    notifications: Notification[],
    selectedMapCamera: number[] | null, // Выбранная камера в группе событий
    nearbyIncidents: null | LastIncidentEventModel[], // Инциденты вблизи
    isMultipleSelect: boolean, // Выбор нескольких инцидентов в группе
    isLastEventsLoading: boolean,
    isSummaryLoading: boolean,
    isUpdateEventLoading: boolean,
    isAssignEventLoading: boolean,
    isRejectEventLoading: boolean,
    isEventsLoading: boolean,
    isAnalyticsLoading: boolean,
    isEventLoading: boolean,
    isEventScreenshotAdded: boolean,
    isCommentsLoading: boolean,
    isCommentAdding: boolean,
    isEventAggregatorLoading: boolean,
    isToIncidentLoading: boolean,
    isToEventLoading: boolean,
    isIncidentMediaFileAdding: boolean,
    isIncidentMediaFileRemoving: boolean,
    isIncidentClosing: boolean,
    isIncidentTracking: boolean,
    addCameraLoading: boolean,
    addCameraSuccess: boolean,
    removeCameraLoading: boolean,
    removeCameraSuccess: boolean,
    isIncidentsOnControlLoading: boolean,
    isActualIncidentsLoading: boolean,
    isIncidentsFavoriteLoading: boolean,
    isNearbyIncidentsLoading: boolean,
    pathCoordinates: any,
    isIncidentCreating: boolean,
    roadObjectsForMarkersIsLoading: boolean,
    isNewCommentAttachmentsLoading: boolean,
    newCommentAttachments: IAttachment[] | null,
    isSecondOperatorScreen: boolean,
    isIncidentReopening: boolean,
    requestTagSuccess: boolean | null,
    requestTagLoading: boolean | null,
    isAdditionalInfoLoading: boolean,
    handoverRequestSendingLoading: boolean | null,
    handoverRequests: any[] | [],
    handoverTakeLoading: boolean,
    handoverRejectLoading: boolean,
    handoverResult: {
        type: boolean,
        receiver: string,
    } | null,
    loginAttemptsCountExceededMessage: ILoginAttemptsCountExceededMessage | null,
    isLoadedMap: boolean,
    actualProTvWidgetsIncidents: IncidentsActualProTvModel[],
    toiNotifications: IToiNotification[],
}

const initialArchiveFilters: IEventsFilters = {
    incidentTypeCodes: [],
    deviceTypes: [],
    incidentSubtypeCodes: null,
    eventPriority: [],
    status: [],
    roadObjectIds: [],
    zoneIds: [],
    sinceDay: moment().format("YYYY-MM-DD") || null,
    untilDay: moment().format("YYYY-MM-DD") || null,
    dateKind: "interval",
    tracked: null,
    favorite: null,
    modeTags: "AND",
    tags: [],
    incidentSource: [],
    content: "",
    onlyWithNested: false,
};

const initialArchiveAnalyticsFilters: IArchiveAnalyticsFilters = {
    createdDateTime: {
        lowerBound: moment().utc().startOf("day").toISOString(),
        upperBound: moment().utc().add(1, "day").startOf("day").toISOString(),
        excludingUpperBound: true,
    },
    kind: ["Incident"],
    tracked: null,
    favorite: null,
    tagsMode: "AND",
};

export const initialState: IInitialState = {
    resetCounterArchive: 1,
    incidentsOnControl: [],
    incidentsFavorite: [],
    lastEvents: [],
    roadObjectsForMarkers: [],
    roadObjectsForMarkersIsLoading: false,
    lastEventsFilters: {
        priority: ["2"],
        type: [],
        zoneId: [],
        tags: [],
    },
    demoLastEventsFilters: {
        priority: ["2"],
        type: [],
        time: [getDefaultDate("00:00:00"), getDefaultDate("23:59:59")],
    },
    incidentInfoFilters: {
        type: [],
        deviceSubType: [],
        registrationDateTime: [],
    },
    lastEventsStatusFilters: [],
    selectedLastEventId: null,
    selectedLastEvent: null,
    selectedLastIncidentLocation: {
        coordinates: [37.6269933, 55.7682965] as [number, number],
        incident: null,
    },
    events: [],
    comments: [],
    secondScreenActiveEventId: null, // Id активного инцидента на втором экране
    mediaComments: [],
    documentComments: [],
    notifications: [],
    pagination: {
        page: 1,
        firstPage: true,
        lastPage: true,
        pageSize: DEFAULT_PAGE_SIZE,
        totalElements: 0,
        totalPages: 1,
        sort: DEFAULT_EVENTS_SORT,
    },
    filters: initialArchiveFilters,
    savedArchiveFilters: null,
    summary: null,
    analyticsFilters: initialArchiveAnalyticsFilters,
    analytics: null,
    event: null,
    selectedMapCamera: null,
    nearbyIncidents: null,
    isMultipleSelect: false,
    isLastEventsLoading: false,
    isSummaryLoading: false,
    isUpdateEventLoading: false,
    isAssignEventLoading: false,
    isRejectEventLoading: false,
    isEventsLoading: false,
    isAnalyticsLoading: false,
    isEventLoading: false,
    isEventScreenshotAdded: false,
    isCommentsLoading: false,
    isCommentAdding: false,
    isEventAggregatorLoading: false,
    isToIncidentLoading: false,
    isToEventLoading: false,
    isIncidentMediaFileAdding: false,
    isIncidentMediaFileRemoving: false,
    isIncidentClosing: false,
    isIncidentsOnControlLoading: false,
    isActualIncidentsLoading: false,
    isIncidentsFavoriteLoading: false,
    isIncidentTracking: false,
    addCameraLoading: false,
    addCameraSuccess: false,
    removeCameraLoading: false,
    removeCameraSuccess: false,
    isNearbyIncidentsLoading: false,
    pathCoordinates: null,
    isIncidentCreating: false,
    isNewCommentAttachmentsLoading: false,
    newCommentAttachments: null,
    isSecondOperatorScreen: false,
    isIncidentReopening: false,
    requestTagSuccess: false,
    requestTagLoading: false,
    isAdditionalInfoLoading: false,
    handoverRequestSendingLoading: null,
    handoverRequests: [],
    handoverTakeLoading: false,
    handoverRejectLoading: false,
    handoverResult: null,
    isLoadedMap: false,
    loginAttemptsCountExceededMessage: null,
    actualProTvWidgetsIncidents: [],
    toiNotifications: [],
};

// Получить инциденты на контроле
export const fetchIncidentsOnControl = createAsyncThunk("event/fetchIncidentsOnControl", async() => {
    const {data} = await createHttpRequest({
        method: "GET",
        path: ApiUrls.INDENTS_ON_CONTROL,
        errorMessage: "messages:fetch_incidents_on_control",
    });

    return data;
});

// Получить избранные инциденты
export const fetchFavoriteIncidents = createAsyncThunk<
    {data: IncidentsFavoriteModel[], isFilter?: boolean},
    {isFilter?: boolean}
>("event/fetchFavoriteIncidents", async({isFilter = true}) => {
    const {data} = await createHttpRequest({
        method: "GET",
        path: ApiUrls.INDENTS_FAVORITE,
        errorMessage: "messages:fetch_favorite_incidents",
    });

    return {data: data as IncidentsFavoriteModel[], isFilter};
});

// Создать инцидент
export const createIncident = createAsyncThunk<ICreatedIncident, ICreateIncident>("event/createIncident",
    async(data) => {
        const response = await createHttpRequest({
            method: "POST",
            path: ApiUrls.CREATE_INCIDENT,
            data,
            errorMessage: "messages:create_incident",
            headers: {
                "Content-Type": "multipart/form-data",
            },
        }) as AxiosResponse<ICreatedIncident>;

        return response.data;
    });

// Получить последние события
export const fetchLastEvents = createAsyncThunk<LastIncidentEventModel[], FetchLastEventsProps>("event/fetchLastEvents",
    async(args) => {
        const path = buildQuery(ApiUrls.LAST_EVENTS, args?.params);

        const response = await createHttpRequest({
            method: "GET",
            path: path,
            errorMessage: "messages:fetch_last_events_error",
        });

        return response.data;
    });

export const fetchLastEventsWithFilters = createAsyncThunk<LastIncidentEventModel[], FetchLastEventsProps | undefined>(
    "event/fetchLastEventsWithFilters",
    async(args) => {
        const path = buildQuery(ApiUrls.LAST_EVENTS, args?.params);

        const response = await createHttpRequest({
            method: "GET",
            signal: args?.signal,
            path,
            errorMessage: "messages:fetch_last_events_error",
        });

        return response.data;
    }
);

// Обновить имя инцидента
export const updateIncidentName = createAsyncThunk<IncidentEventModel, IUpdateIncidentName>(
    "event/updateIncidentName",
    async({incidentId, incidentName}) => {
        const response = await createHttpRequest({
            method: "PUT",
            path: ApiUrls.EVENT_UPDATE_INCIDENT_NAME(incidentId as string),
            data: {incidentId, IncidentName: incidentName},
            errorMessage: "messages:fetch_last_events_error",
        });

        return response.data;
    }
);

// Обновить координаты инцидента
export const updateIncidentLocation = createAsyncThunk<IncidentEventModel, IUpdateIncidentLocation>(
    "event/updateIncidentLocation",
    async({incidentId, data}) => {
        const response = await createHttpRequest({
            method: "PUT",
            path: ApiUrls.EVENT_UPDATE_INCIDENT_LOCATION(incidentId as string),
            data,
            errorMessage: "messages:fetch_last_events_error",
        });

        return response.data;
    }
);

// Обновить состояние Скрыть/Показать инцидента в группе событий
export const updateEventVisibility = createAsyncThunk<IncidentEventModel, IUpdateEventVisibility>(
    "event/updateEventVisibility",
    async({
        hideIncident,
        incidentIds,
        masterIncidentId,
        includeHiddenEvents,
    }) => {
        const {data} = await createHttpRequest({
            method: "POST",
            path: ApiUrls[hideIncident ? "EVENT_HIDE" : "EVENT_UNHIDE"](masterIncidentId as string),
            data: {
                incidentIds,
                includeHiddenEvents,
            },
            errorMessage: "messages:hiding_event_error",
        });

        return data;
    }
);

// Обновить событие
export const updateEvent = createAsyncThunk<UpdateIncidentRequest, UpdateIncidentRequest>("event/updateEvent",
    async(requestData) => {
        // eslint-disable-next-line no-unused-vars,@typescript-eslint/no-unused-vars
        const {id, reloadAfter, ...data} = requestData;
        await createHttpRequest({
            method: "PUT",
            path: ApiUrls.EVENT(id),
            data,
            errorMessage: "messages:update_event_error",
        });

        return requestData;
    });

// Принять событие
export const assignEvent = createAsyncThunk<AssignIncidentRequest, AssignIncidentRequest>("event/assignEvent",
    async(requestData) => {
        // eslint-disable-next-line no-unused-vars,@typescript-eslint/no-unused-vars
        const {id, reloadAfter, ...data} = requestData;
        await createHttpRequest({
            method: "PUT",
            path: ApiUrls.EVENT_ASSIGNMENT(id),
            data,
            errorMessage: "messages:assign_event_error",
        });

        return requestData;
    });

// Отклонить событие
export const rejectEvent = createAsyncThunk<RejectIncidentRequest, RejectIncidentRequest>("event/rejectEvent",
    async(requestData) => {
        // eslint-disable-next-line no-unused-vars,@typescript-eslint/no-unused-vars
        const {id, reloadAfter, ...data} = requestData;
        await createHttpRequest({
            method: "PUT",
            path: ApiUrls.EVENT_REJECTION(id),
            data,
            errorMessage: "messages:reject_event_error",
        });

        return requestData;
    });

// Получить событие
export const fetchEvent = createAsyncThunk<IncidentEventModel, IFetchEventRequest>("event/fetchEvent",
    async({id, includeHiddenEvents}) => {
        const response = await createHttpRequest({
            method: "GET",
            path: ApiUrls.EVENT(id as string, includeHiddenEvents),
            errorMessage: "messages:fetch_event_error",
        });

        return response.data;
    });

export const fetchAdditionalInfo = createAsyncThunk<IncidentEventAdditionalModel, IFetchEventRequest>(
    "event/fetchAdditionalInfo",
    async({id, includeHiddenEvents}) => {
        const response = await createHttpRequest({
            method: "GET",
            path: ApiUrls.EVENT(id as string, includeHiddenEvents),
            errorMessage: "messages:fetch_event_error",
        });

        return response.data.data;  // Возвращаем только объект data
    }
);

// Обновить доп. информацию события
export const updateAdditionalInfo = createAsyncThunk<IncidentEventAdditionalModel, IUpdateAdditionalInfo>(
    "event/updateAdditionalInfo", async({data, incidentId}) => {
        const response = await createHttpRequest({
            method: "PUT",
            path: ApiUrls.UPDATE_ADDITIONAL_INFO(incidentId),
            data,
            errorMessage: "messages:update_additional_info_error",
        });

        return response.data;
    }
);

// Закрыть инцидент
export const closeEvent = createAsyncThunk<CloseIncidentRequest, CloseIncidentRequest>("event/closeEvent",
    async(requestData) => {
        await createHttpRequest({
            method: "PUT",
            path: ApiUrls.EVENT_FINALIZATION(requestData.id),
            data: requestData,
            errorMessage: "messages:close_event_error",
        });

        return requestData;
    });

// Убрать инцидент из избранного
export const removeFavorite = createAsyncThunk<any, any>("event/removeFavorite",
    async(id) => {
        await createHttpRequest({
            method: "POST",
            path: ApiUrls.REMOVE_FAVORITE(id),
            errorMessage: "messages:remove_favorite_error",
        });

        return id;
    });

// Добавить инцидент в избранное
export const setFavorite = createAsyncThunk<any, any>("event/setFavorite",
    async({incidentId, data}) => {
        await createHttpRequest({
            method: "POST",
            path: ApiUrls.SET_FAVORITE(incidentId),
            data,
            errorMessage: "messages:add_favorite_error",
        });

        return incidentId;
    });

export interface IFetchEventsRequest {
    FoldersIds?: number[],
    signal: AbortSignal,
}

// Получить список событий
export const fetchEvents =
    createAsyncThunk<IncidentEventInfoModelPagedList, IFetchEventsRequest>(
        "event/Events",
        async({FoldersIds, signal}, {getState}) => {
            const filters = getQueryParams(getState() as RootState);

            const importantTags: string[] = [];

            if (filters.favorite && !isNaN(+filters.favorite)) importantTags.push(String(filters.favorite));
            if (filters.tracked && !isNaN(+filters.tracked)) importantTags.push(String(filters.tracked));

            const data: Required<IEventsQueryParamsCapitalizedPayload> = {
                Content: filters.content,
                DateKind: filters.dateKind,
                IncidentSource: filters.incidentSource,
                EventPriority: filters.eventPriority,
                DeviceTypes: filters.deviceTypes,
                Favorite: filters.favorite === "actual",
                ModeTags: filters.modeTags,
                Page: filters.page || null,
                PageSize: filters.pageSize,
                RoadObjectIds: filters.roadObjectIds,
                SinceDay: filters.sinceDay,
                Status: filters.status,
                Tags: [...filters.tags, ...importantTags],
                Tracked: filters.tracked === "actual",
                UntilDay: filters.untilDay,
                IncidentTypeCodes: filters.incidentTypeCodes,
                IncidentSubtypeCodes: filters.incidentSubtypeCodes,
                ZoneIds: filters.zoneIds,
                Sort: filters.sort,
                OnlyWithNested: filters.onlyWithNested,
            };

            const response = await createHttpRequest({
                method: "POST",
                path: ApiUrls.EVENTS,
                data: {...buildQueryObject(data), FoldersIds: FoldersIds ? FoldersIds : []},
                signal: signal,
                errorMessage: "messages:fetch_events_error",
            });

            return response.data;
        }
    );

export const fetchActualProTvWidgetsIncidents =
    createAsyncThunk<IncidentsActualProTvModel[], string[]>(
        "events/actualIncidentsOnControl",
        async(tagsIds) => {
            const now = new Date();
            const yesterday = new Date(now.getTime() - 24 * 60 * 60 * 1000);
            const untilDay = formatDateForFilters(now);
            const sinceDay = formatDateForFilters(yesterday);
            const response = await createHttpRequest({
                method: "POST",
                path: ApiUrls.EVENTS,
                data: {
                    Content: "",
                    DateKind: "custom",
                    Page: 1,
                    PageSize: 300,
                    FoldersIds: [],
                    ModeTags: "OR",
                    SinceDay: sinceDay,
                    Sort: "registrationDateTime,desc",
                    Tags: tagsIds,
                    UntilDay: untilDay,
                },
                errorMessage: "messages:fetch_events_error",
            });

            return response.data.data;
        }
    );

// Архив. Блок статистики
export const fetchSummary = createAsyncThunk<EventsSummary, {signal?: AbortSignal}>("event/fetchSummary",
    async(args, {getState}) => {
        const filters = getQueryParams(getState() as RootState);

        const importantTags: string[] = [];

        if (filters.favorite && !isNaN(+filters.favorite)) importantTags.push(String(filters.favorite));
        if (filters.tracked && !isNaN(+filters.tracked)) importantTags.push(String(filters.tracked));

        const payloadFilters: IEventsQueryParamsPayload = {
            ...filters,
            tags: [...filters.tags, ...importantTags],
            favorite: filters.favorite === "actual",
            tracked: filters.tracked === "actual",
        };

        const path = buildQuery(ApiUrls.SUMMARY_EVENTS, payloadFilters);
        const response = await createHttpRequest({
            method: "GET",
            path,
            signal: args.signal,
            errorMessage: "messages:fetch_summary_error",
        });

        return response.data;
    });

// Архив. Блок аналитики работы операторов
export const fetchStringEventsAndIncidents =
    createAsyncThunk<ArchiveAnalytics, {signal?: AbortSignal}>("event/fetchStringEventsAndIncidents",
        async(params, {getState}) => {
            const filters = getQueryParams(getState() as RootState);
            const transformedFilters: Record<string, unknown> = transformFiltersToAnalytics(filters);

            const response = await createHttpRequest({
                method: "POST",
                path: ApiUrls.ANALYTICS.STRING_EVENTS_AND_INCIDENTS,
                data: {...buildQueryObject(transformedFilters)},
                silent: true,
                signal: params.signal,
            });

            return response.data;
        });

// Получить комментарии (оператора и служб)
export const fetchComments = createAsyncThunk<IncidentCommentPagedList, IIncidentCommentsProps>(
    "event/fetchComments",
    async({id, showDeleted}) => {
        const response = await createHttpRequest({
            method: "GET",
            path: ApiUrls.EVENT_COMMENTS(id, showDeleted),
            errorMessage: "messages:fetch_comments_error",
        });

        return response.data;
    }
);

// Добавить в основное
export const addAttachmentsToMedia = createAsyncThunk<IncidentEventMedia[], IAddAttachmentsToMedia>(
    "event/addAttachmentsToMedia",
    async({fileIds, incidentId}) => {
        const {data} = await createHttpRequest({
            method: "POST",
            path: ApiUrls.COMMENT_ATTACHMENTS_TO_MEDIA(incidentId),
            data: {fileIds},
            errorMessage: "messages:add_attachments_to_media_error",
        });

        return data;
    }
);

// Обновить комментарий
export const updateComment = createAsyncThunk<IncidentComment, IIncidentEditProps>("event/updateComment",
    async({incidentId, typeComments, commentId, data}) => {
        const response = await createHttpRequest({
            method: "PUT",
            path: ApiUrls.EVENT_UPDATE_COMMENT(incidentId, typeComments, commentId),
            data,
            errorMessage: `messages:${
                typeComments === TypeComments.Service
                    ? "update_comment_response_service_error"
                    : "update_comment_operator_error"
            }`,
            headers: {
                "Content-Type": "multipart/form-data",
            },
        });

        return response.data;
    });

// Восстановить комментарий
export const restoreComment = createAsyncThunk<IncidentCommentPagedList, IRestoreCommentProps>(
    "event/restoreComment",
    async({incidentId, commentId}) => {
        const response = await createHttpRequest({
            method: "PUT",
            path: ApiUrls.EVENT_RESTORE_COMMENT(incidentId, commentId),
            errorMessage: "messages:restore_comment",
        });

        return response.data;
    }
);

// Удалить комментарий
export const deleteComment = createAsyncThunk<IncidentCommentPagedList, IIncidentDeleteProps>(
    "event/deleteComment",
    async({commentId, incidentId, typeComments, showDeleted}) => {
        const response = await createHttpRequest({
            method: "DELETE",
            path: ApiUrls.EVENT_DELETE_COMMENT(commentId, incidentId, typeComments, showDeleted),
            errorMessage: "messages:delete_comment_operator",
        });

        return response.data;
    }
);

// Удалить прикрепленный файл
export const deleteAttachment = createAsyncThunk<IncidentCommentPagedList, IAttachmentDeleteProps>(
    "event/deleteAttachment",
    async({incidentId, fileId}) => {
        const response = await createHttpRequest({
            method: "DELETE",
            path: ApiUrls.EVENT_DELETE_ATTACHMENT(incidentId, fileId),
            errorMessage: "messages:delete_attachment",
        });

        return response.data;
    }
);

// Добавить комментарий
export const addComment =
    createAsyncThunk<IncidentComment, IAddEventCommentOperator>("event/addComment", async(
        {id, data, typeComments}
    ) => {
        const response = await createHttpRequest({
            method: "POST",
            path: ApiUrls.EVENT_COMMENTS_ADD_WITH_ATTACHMENTS(id, typeComments),
            data: data,
            errorMessage: "messages:add_comment_operator_error",
            headers: {
                "Content-Type": "multipart/form-data",
            },
        });

        return response.data;
    });

// Фильтрация комментариев по mediaType
export const fetchMediaTypeComments =
    createAsyncThunk<IncidentCommentPagedList, IFetchMediaTypeComments>("event/fetchMediaTypeComments", async(
        {id, contentTypes, showDeleted}
    ) => {
        const path = buildQuery(ApiUrls.EVENT_COMMENTS_MEDIA(id), {contentTypes, showDeleted});
        const response = await createHttpRequest({
            method: "GET",
            path,
            errorMessage: "messages:fetchMediaTypeComments",
        });

        return response.data;
    });

// Добавить файл к комментарию
export const addCommentFile =
    createAsyncThunk<IAttachment[], IAddCommentFile>("event/addCommentFile", async(
        {id, commentId, files, notifyResponseServices}
    ) => {
        const path = buildQuery(ApiUrls.EVENT_NEW_COMMENT_ATTACHMENTS(id), {commentId, notifyResponseServices});
        const response = await createHttpRequest({
            method: "POST",
            path,
            data: files,
            errorMessage: "messages:add_comment_file_error",
            headers: {
                "Content-Type": "multipart/form-data",
            },
        });

        return response.data;
    });

// Переслать комментарий службам
export const commentNotifyResponseService =
    createAsyncThunk<null, ICommentNotifyResponseService>("event/commentNotifyResponseService", async(
        {incidentId, commentId, data}
    ) => {
        const response = await createHttpRequest({
            method: "POST",
            path: ApiUrls.EVENT_COMMENT_NOTIFY_RESPONSE_SERVICE(incidentId, commentId),
            data: data,
            errorMessage: "messages:comment_notify_response_service_error",
        });

        return response.data;
    });

// Возвращает вложения для указанного коммента внутри заданного инцидента
export const fetchCommentAttachments =
    createAsyncThunk<IAttachment[], ICommentAttachments>("event/commentAttachments", async(
        {id, commentId}
    ) => {
        const response = await createHttpRequest({
            method: "GET",
            path: ApiUrls.EVENT_COMMENT_ATTACHMENTS(id, commentId),
            errorMessage: "messages:get_comment_file_error",
        });

        return response.data;
    });

// Включение агрегации событий
export const setAggregator = createAsyncThunk<ISetAggregatorRequest, ISetAggregatorRequest>("event/setAggregator",
    async({id}) => {
        const response = await createHttpRequest({
            method: "POST",
            path: ApiUrls.SET_AGGREGATOR(id),
            errorMessage: "messages:set_aggregator_error",
        });

        return response.data;
    });

// Отключение агрегации событий
export const removeAggregator =
    createAsyncThunk<IncidentEventModel, string>("event/removeAggregator", async(id) => {
        const response = await createHttpRequest({
            method: "POST",
            path: ApiUrls.REMOVE_AGGREGATOR(id),
            errorMessage: "messages:remove_aggregator_error",
        });

        return response.data;
    });

export const handoverEvent =
    createAsyncThunk<any, IHandoverEventParams>("event/handoverEvent", async({eventId, userId, comment}) => {
        const response = await createHttpRequest({
            method: "POST",
            path: ApiUrls.HANDOVER_EVENT(eventId),
            data: {
                userId,
                comment,
            },
            errorMessage: "messages:handover_error",
        });
        return response.data;
    });

export const takeHandoverEvent =
    createAsyncThunk<any, string>("event/takeHandoverEvent", async(handoverId) => {
        const response = await createHttpRequest({
            method: "PUT",
            path: ApiUrls.TAKE_HANDOVER(handoverId),
            errorMessage: "messages:take_handover_error",
        });
        return response.data;
    });

export const rejectHandoverEvent =
    createAsyncThunk<any, string>("event/rejectHandoverEvent", async(handoverId) => {
        const response = await createHttpRequest({
            method: "PUT",
            path: ApiUrls.REJECT_HANDOVER(handoverId),
            errorMessage: "messages:reject_handover_error",
        });
        return response.data;
    });

// Удаление события из группы
export const toIncident =
    createAsyncThunk<IncidentEventModel, string>("event/toIncident", async(id) => {
        const response = await createHttpRequest({
            method: "POST",
            path: ApiUrls.TO_INCIDENT(id),
            errorMessage: "messages:to_incident_error",
        });
        return response.data;
    });

// Массовое удаление инцидентов из группы
export const toIncidents = createAsyncThunk<IncidentEventModel, string[]>(
    "event/toIncidents",
    async(ids) => {
        const response = await createHttpRequest({
            method: "POST",
            path: ApiUrls.TO_INCIDENTS,
            errorMessage: "messages:to_incident_error",
            data: {
                incidentIds: ids,
            },
        });
        return response.data;
    }
);

// Массовое добавление инцидентов в группу
export const toEvent = createAsyncThunk<null, {id: string, masterId: string, incidentIds: string[]}>(
    "event/toEvent",
    async({id, masterId, incidentIds}) => {
        const response = await createHttpRequest({
            method: "POST",
            path: ApiUrls.TO_EVENT(id, masterId, incidentIds),
            errorMessage: "messages:to_event_error",
        });
        return response.data;
    }
);

// Добавление медиа файла инцидента
export const addIncidentMedia =
    createAsyncThunk<IncidentEventModel, IAddIncidentMediaRequest>("event/addMedia", async({id, data}) => {
        const response = await createHttpRequest({
            method: "PUT",
            path: ApiUrls.ADD_MEDIA(id),
            data: data,
            errorMessage: "messages:add_media_error",
            // @ts-ignore
            headers: {
                Accept: "*/",
                "Content-type": "multipart/form-data",
            },
        });

        return response.data;
    });

// Удаление медиа файла инцидента
export const removeIncidentMedia =
    createAsyncThunk<IncidentEventModel, IRemoveIncidentMediaRequest>("event/removeMedia", async({id, data}) => {
        const response = await createHttpRequest({
            method: "PUT",
            path: ApiUrls.REMOVE_MEDIA(id),
            data: data,
            errorMessage: "messages:remove_media_error",
        });

        return response.data;
    });

// Постановка инцидента на контроль
export const setTrackedIncident =
    createAsyncThunk<IncidentEventModel, ISetTrackedIncidentRequest>("event/setTracked", async({id, message}) => {
        const response = await createHttpRequest({
            method: "POST",
            path: ApiUrls.SET_TRACKED(id),
            data: {trackedMessage: message},
            errorMessage: "messages:add_tracked_error",
        });

        return response.data;
    });

// Снятие инцидента с контроля
export const removeTrackedIncident =
    createAsyncThunk<IncidentEventModel, IRemoveTrackedIncidentRequest>("event/removeTracked", async({id}) => {
        const response = await createHttpRequest({
            method: "POST",
            path: ApiUrls.REMOVE_TRACKED(id),
            errorMessage: "messages:remove_tracked_error",
        });

        return response.data;
    });

// Назначение основного инцидента в группе событий
export const setMasterRepresentationEvent =
    // eslint-disable-next-line max-len
    createAsyncThunk<ISetMasterRepresentationEventRequest, ISetMasterRepresentationEventRequest>("event/setMasterRepresentationEvent",
        async({masterIncidentId, incidentId}) => {
            const response = await createHttpRequest({
                method: "POST",
                path: ApiUrls.SET_MASTER_REPRESENTATION_EVENT(masterIncidentId, incidentId),
                errorMessage: "messages:set_master_representation_event_error",
            });

            return response.data;
        });

function getQueryParams(rootState: RootState): IEventsQueryParams {
    const state = rootState.eventReducer;
    return {
        page: Number(state.pagination.page),
        pageSize: Number(state.pagination.pageSize) || DEFAULT_PAGE_SIZE,
        sort: state.pagination.sort ?? "",
        ...state.filters,
    };
}

// Добавление задействованной камеры в группе событий
export const addInvolvedCam =
    createAsyncThunk<IncidentEventModel, IAddAndRemoveEventCamera>("event/addInvolvedCam", async(
        {incidentId, camId}
    ) => {
        const response = await createHttpRequest({
            method: "POST",
            path: ApiUrls.EVENT_ADD_CAMERA(incidentId),
            data: {
                roadObjectIds: [camId],
            },
            errorMessage: "messages:add_camera_error",
        });

        return response.data;
    });

// Удаление задействованной камеры в группе событий
export const removeInvolvedCam =
    createAsyncThunk<IncidentEventModel, IAddAndRemoveEventCamera>("event/removeInvolvedCam", async(
        {incidentId, camId}
    ) => {
        const response = await createHttpRequest({
            method: "POST",
            path: ApiUrls.EVENT_REMOVE_CAMERA(incidentId),
            data: {
                roadObjectIds: [camId],
            },
            errorMessage: "messages:remove_camera_error",
        });

        return response.data;
    });

// Инциденты вблизи
export const fetchNearbyIncidents =
    createAsyncThunk<LastIncidentEventModel[], string>("event/fetchNearbyIncidents", async(
        incidentId
    ) => {
        const response = await createHttpRequest({
            method: "GET",
            path: ApiUrls.NEARBY_INCIDENTS(incidentId),
            errorMessage: "messages:fetch_nearby_incidents_error",
        });

        return response.data;
    });

// Вернуть инцидент в работу
export const reopenIncident = createAsyncThunk<{id: string}, any>(
    "event/reopenIncident",
    async(id) => {
        const response = await createHttpRequest({
            method: "PUT",
            path: ApiUrls.EVENT_REOPEN(id as string),
            data: {},
            errorMessage: "messages:reopen_event_error",
        });

        return response.data;
    }
);

// Обновление тегов инцидента
export const editIncidentTags = createAsyncThunk<any, any>(
    "event/editIncidentTags",
    async({id, data}) => {
        const response = await createHttpRequest({
            method: "PUT",
            path: ApiUrls.UPDATE_INCIDENT_TAGS(id),
            data,
            errorMessage: "messages:edit_incident_tags_error",
        });

        return response.data;
    }
);

function getQueryParamsFromState(state: IInitialState): IEventsQueryParams {
    return {
        page: Number(state.pagination.page),
        pageSize: Number(state.pagination.pageSize) || DEFAULT_PAGE_SIZE,
        sort: state.pagination.sort ?? "",
        ...state.filters,
    };
}

export interface ISetPagination {
    page?: number,
    pageSize?: number,
    sort?: string,
}

const event = createSlice({
    reducers: {
        updateEventFolder(state, action: PayloadAction<IncidentEventModel>) {

            state.events = state.events.map(event => event.id === action.payload.id ? {
                ...event,
                folders: action.payload.folders,
            } : event);
        },

        updateIncidentEvent(state, action: PayloadAction<IncidentEventModel>) {
            if (state.event?.id && state.event.id === action.payload.id) {
                state.event = action.payload;
            }
        },

        setPagination(state, action: PayloadAction<ISetPagination>) {
            state.pagination = {
                ...state.pagination,
                ...action.payload,
            };
        },
        setInitQueryParams(state, {payload: {stateUpdateFields, params}}) {
            stateUpdateFields?.forEach((field: keyof typeof state) => {
                const stateField = state[field] as keyof IInitialState;
                const newState: keyof IInitialState | {[key: string]: any} = {};
                // Перебираем переданные квери параметры и заменяем значения в существующих полях стейт
                Object.entries(params).forEach((([paramKey, paramValue]) => {
                    const key = paramKey as keyof IEventsFilters;
                    const value = paramValue as string;
                    if (Object.prototype.hasOwnProperty.call(stateField, key)) {
                        // @ts-ignore
                        stateField[key] && Array.isArray(stateField[key])
                            ? newState[key] = value.split(",")
                            : newState[key] = value;
                    }
                }));

                if (Object.keys(newState).length) {
                    (state[field] as IInitialState) = {
                        ...state[field] as IInitialState,
                        ...newState,
                    };
                }
            });
        },
        // Фильтры для архива инцидентов
        setFilters(state, action: PayloadAction<ISetFilters>) {
            const payload = action.payload;

            const filtersItem = state.filters?.[payload.filterType as keyof typeof state.filters];
            const nextValue = typeof payload.selectedFilters === "string"
                ? payload.selectedFilters
                : JSON.stringify(payload.selectedFilters);
            const currentValue = typeof filtersItem === "string" ? filtersItem : JSON.stringify(filtersItem);

            if (nextValue !== currentValue) {
                state.filters = {
                    ...state.filters,
                    [payload.filterType]: payload.selectedFilters,
                };
                state.analyticsFilters = transformFiltersToAnalytics(state.filters);
            }
        },

        resetArchiveFilters(state) {
            state.filters = initialArchiveFilters;
            state.resetCounterArchive += 1;
        },

        setFavoriteFilters(state) {
            state.filters = {
                ...state.filters,
                untilDay: moment().format("YYYY-MM-DD") || null,
                sinceDay: moment().subtract(14, "days").format("YYYY-MM-DD") || null,
            };
        },

        setArchiveFilters(state) {
            state.filters = {
                ...state.filters,
                untilDay: moment().format("YYYY-MM-DD") || null,
                sinceDay: moment().format("YYYY-MM-DD") || null,
            };
        },

        // Фильтры последних инцидентов
        setLastEventsFilters(state, {payload}) {
            state.lastEventsFilters = {
                ...state.lastEventsFilters,
                [payload.filterType]: payload.selectedFilters,
            };
        },
        demoSetLastEventsFilters(state, {payload}) {
            state.demoLastEventsFilters = {
                ...state.demoLastEventsFilters,
                [payload.filterType]: payload.selectedFilters,
            };
        },
        // Фильтры для группы событий
        setIncidentInfoFilters(state, {payload}) {
            state.incidentInfoFilters = {
                ...state.incidentInfoFilters,
                [payload.filterType]: payload.selectedFilters,
            };
        },
        setSelectedLastEventId(state, {payload}) {
            state.selectedLastEventId = payload;
        },

        setSelectedLastEvent(state, {payload}) {
            state.selectedLastEvent = payload;
        },

        setNearbyIncidents(state, {payload}) {
            state.nearbyIncidents = payload;
        },

        setSelectedLastIncidentLocation(state, {payload}) {
            state.selectedLastIncidentLocation = payload;
        },
        upsertLastEventIncident(state, {payload}) {
            const upsertLastEventIncidentHandler = (state: IInitialState, payload: any) => {
                // Обновление инцидентов через signalR
                switch (payload.eventModelType) {
                    // Обновление последних инцидентов
                    case "lastIncident": {
                        const isTracked = payload.tracked.enabled;
                        const isFavorite = payload.favorite.enabled;

                        const index = state.lastEvents.findIndex(event => event.id === payload.id);
                        if (index > -1) {
                            Object.assign(state.lastEvents[index], payload);
                        } else {
                            state.lastEvents.push(payload);
                        }

                        // Обновляем открытый инцидент (например, при редактировании тегов)
                        // Если изменения принадлежат текущему инциденту
                        if (state.event && payload.id === state.event?.id) {
                            Object.assign(state.event, payload);
                        }

                        // Обновляем выбранный последний инцидент
                        // Если изменения принадлежат текущему инциденту
                        if (state.selectedLastEvent && payload.id === state.selectedLastEvent?.id) {
                            Object.assign(state.selectedLastEvent, payload);
                        }

                        // Обновление списка инцидентов на контроле
                        if (isTracked) {
                            const index = state.incidentsOnControl.findIndex(event => event.id === payload.id);
                            if (index > -1) {
                                Object.assign(state.incidentsOnControl[index], payload);

                            } else {
                                state.incidentsOnControl.push(payload);
                            }
                        } else {
                            const index = state.incidentsOnControl.findIndex(event => event.id === payload.id);
                            if (index > -1) state.incidentsOnControl.splice(index, 1);
                        }

                        // Обновление списка инцидентов для проТВ
                        if (isFavorite) {
                            const index = state.incidentsFavorite.findIndex(event => event.id === payload.id);
                            if (index > -1) {
                                Object.assign(state.incidentsFavorite[index], payload);

                            } else {
                                state.incidentsFavorite.push(payload);
                            }
                        } else {
                            const index = state.incidentsFavorite.findIndex(event => event.id === payload.id);
                            if (index > -1) state.incidentsFavorite.splice(index, 1);
                        }
                        break;
                    }
                    case "incidentShareCreatedEvent": {
                        state.handoverRequests = [...state.handoverRequests, payload];
                        break;
                    }
                    case "incidentShareAcceptedEvent": {
                        state.handoverResult = {
                            type: true,
                            receiver: payload.share.Receiver,
                        };
                        break;
                    }
                    case "incidentShareRejectedEvent": {
                        state.handoverResult = {
                            type: false,
                            receiver: payload.share.Receiver,
                        };
                        break;
                    }
                    case "incidentAggregator": {
                        state.lastEvents = state.lastEvents.filter(event => !payload.relIncidents?.includes(event.id));

                        // Удаляем инф-ию о выбранном инциденте, которые стал частью группы
                        if (payload.relIncidents?.includes(state.selectedLastEvent?.id)) {
                            state.selectedLastEvent = null;
                        }
                        break;
                    }

                    case "CommentChangeModel": { // Обновление комментариев в карточке инцидента
                        const newComment = {
                            id: payload.id,
                            operator: payload.operator,
                            content: payload.comment,
                            created: payload.dateTime,
                            contentSource: payload.source,
                            attachments: payload.attachments,
                            isDeleted: payload.isDelete,
                        };
                        // Если изменение принадлежит текущему инциденту
                        if (state.event && payload.incidentId === state.event?.id) {
                            // Считаем количество непрочитанных комментариев
                            const commentCounter = state.event?.commentCounter;

                            if (payload.source !== "system") {
                                state.event.commentCounter = commentCounter ? commentCounter + 1 : 1;
                            }

                            // Обновляем статус инцидента в случае его автоматического завершения
                            state.event.status = payload.status;

                            // Обновляем список оповещенных служб инцидента
                            state.event.responseServicesFull = payload.responseServices;

                            const commentIndex = state.comments.findIndex(({id}) => id === newComment.id);

                            if (commentIndex === -1) {
                                if (newComment.isDeleted && !state.event.isShowCommentsDeleted) return;
                                state.comments.unshift(newComment);
                            } else if (state.comments[commentIndex]) {
                                if (newComment.isDeleted && !state.event.isShowCommentsDeleted) {
                                    state.comments = state.comments.filter(comment => comment.id !== newComment.id);
                                    return;
                                }

                                if (state.comments[commentIndex]?.attachments?.length) {
                                    state.comments[commentIndex] = {
                                        ...state.comments[commentIndex],
                                        attachments: uniqBy([
                                            ...state.comments[commentIndex].attachments,
                                            ...newComment.attachments,
                                        ], (item) => item.id),
                                    };
                                } else {
                                    state.comments[commentIndex] = {
                                        ...state.comments[commentIndex],
                                        ...newComment,
                                    };
                                }
                            }
                        }
                        // Обновление комментариев у инцидентов для второго экрана оператора
                        if (payload.incidentId && state.isSecondOperatorScreen) {
                            const incidentIndex = state.lastEvents.findIndex(({id}) => id === payload.incidentId);

                            if (incidentIndex !== -1 && state.lastEvents) {
                                // В открытое окно комментариев добавляем только комменты активного инцидента
                                if (state.secondScreenActiveEventId === payload.incidentId) {
                                    state.comments = [
                                        newComment,
                                        ...state.comments,
                                    ];
                                }

                                if (payload.source !== "system") {
                                    state.lastEvents[incidentIndex] = {
                                        ...state.lastEvents[incidentIndex],
                                        //@ts-ignore
                                        lastHistoryItem: {
                                            ...state.lastEvents[incidentIndex].lastHistoryItem,
                                            comment: newComment.content,
                                            commentOperator: newComment.operator,
                                        },
                                        commentCounter: (state.lastEvents[incidentIndex]
                                            && state.lastEvents[incidentIndex]?.commentCounter)
                                            ? state.lastEvents[incidentIndex]?.commentCounter as number + 1
                                            : 1,
                                    };
                                } else {
                                    state.lastEvents[incidentIndex] = {
                                        ...state.lastEvents[incidentIndex],
                                        //@ts-ignore
                                        lastHistoryItem: {
                                            ...state.lastEvents[incidentIndex].lastHistoryItem,
                                            comment: newComment.content,
                                            commentOperator: newComment.operator,
                                        },
                                    };
                                }
                            }
                        }
                        // Обновление комментариев инцидентов на контроле
                        if (state.incidentsOnControl.length) {
                            const incidentIndex = state.incidentsOnControl
                                .findIndex(({id}) => id === payload.incidentId);

                            if (incidentIndex !== -1) {
                                state.incidentsOnControl[incidentIndex] = {
                                    ...state.incidentsOnControl[incidentIndex],
                                    //@ts-ignore
                                    lastHistoryItem: {
                                        ...state.incidentsOnControl[incidentIndex].lastHistoryItem,
                                        comment: newComment.content,
                                        commentOperator: newComment.operator,
                                    },
                                };
                            }
                        }
                        // Фейковые уведомления о комментирямх служб (не используется)
                        if (payload.type === "comment" && payload.source === "responseService") {
                            const notification: Notification = {
                                id: payload.id,
                                incidentType: payload.incidentType,
                                content: payload.comment,
                                incidentId: payload.incidentId,
                                created: payload.dateTime,
                                completed: false,
                            };
                            state.notifications.unshift(notification);
                        }

                        // Уведомления об операциях с ТОИ
                        if (payload.type === "toiRelatedness") {
                            state.toiNotifications.push({
                                id: payload.id,
                                incidentId: payload.incidentId,
                                comment: payload.comment,
                                date: payload.dateTime,
                                operator: payload.operator,
                            });
                        }

                        break;
                    }

                    // Сообщение о вводе пароля более трех раз неверно
                    case "loginAttemptsCountExceededEvent": {
                        state.loginAttemptsCountExceededMessage = {
                            attemptsCount: payload.attemptsCount,
                            login: payload.login,
                            userFullName: payload.userFullName,
                        };
                        break;
                    }
                }
            };

            if (isArray(payload)) {
                payload.forEach((item) => upsertLastEventIncidentHandler(state, item));
            } else upsertLastEventIncidentHandler(state, payload);
        },
        updateEventCommentsCounter(state, {payload}) {
            const incidentIndex = state.lastEvents.findIndex(({id}) => id === payload.incidentId);
            if (incidentIndex !== -1) {
                state.lastEvents[incidentIndex] = {
                    ...state.lastEvents[incidentIndex],
                    commentCounter: null,
                };
            }
        },
        resetEventCommentsCounter(state) {
            if (state.event && state.event.commentCounter) {
                state.event.commentCounter = null;
            }
        },
        addLastEventsStatusFilter(state, {payload}) {
            state.lastEventsStatusFilters.push(payload);
        },
        removeLastEventsStatusFilter(state, {payload}) {
            state.lastEventsStatusFilters = state.lastEventsStatusFilters.filter(item => item !== payload);
        },
        setIsMultipleSelect(state, {payload}) {
            state.isMultipleSelect = payload;
        },
        setSelectedMapCamera(state, {payload}) {
            state.selectedMapCamera = payload;
        },
        setPinnedIncident(state, {payload}) {
            state.incidentsFavorite = state.incidentsFavorite.map(item => item.id === payload.incidentId
                ? {...item, isPinned: !payload.isPinned} : item);
        },
        setPathCoordinates(state, {payload}) {
            state.pathCoordinates = payload;
        },
        markNotificationCompleted(state, {payload}) {
            const index = state.notifications.findIndex(notification => notification.id === payload.id);
            if (index > -1) {
                state.notifications[index].completed = true;
            }
        },

        clearMediaComments(state) {
            state.mediaComments = [];
            state.documentComments = [];
        },

        setIsSecondOperatorScreen(state, {payload}) {
            state.isSecondOperatorScreen = payload;
        },
        setRoadObjectsForMarkers(state, {payload}) {
            state.roadObjectsForMarkers = payload;
        },
        setSecondScreenActiveEventId(state, {payload}) {
            state.secondScreenActiveEventId = payload;
        },
        setEvent(state, {payload}) {
            state.event = payload;
        },
        setAdditionalInfoData(state, {payload}) {
            if (state.event) {
                state.event.data = payload;
            }
        },
        resetHandoverResult(state) {
            state.handoverResult = null;
        },
        resetHandoverLoading(state) {
            state.handoverRequestSendingLoading = null;
        },
        setLoginAttemptsCountExceededMessage(state, {payload}) {
            state.loginAttemptsCountExceededMessage = payload;
        },
        resetAddAndRemoveCameraStatus(state) {
            state.addCameraSuccess = false;
            state.removeCameraSuccess = false;
        },
        setLoadedMap(state, {payload}) {
            state.isLoadedMap = payload;
        },
        setIsEventScreenshotAdded(state, {payload}) {
            state.isEventScreenshotAdded = payload;
        },
        setToiNotifications(state, {payload}) {
            state.toiNotifications = payload;
        },
    },
    name: "event",
    initialState,
    extraReducers: (builder) => {
        // Создать инцидент
        builder.addCase(createIncident.pending, (state) => {
            state.isIncidentCreating = true;
        });
        builder.addCase(createIncident.fulfilled, (state) => {
            state.isIncidentCreating = false;
        });
        builder.addCase(createIncident.rejected, (state) => {
            state.isIncidentCreating = false;
        });
        // Фильтрация комментариев по mediaType
        builder.addCase(fetchMediaTypeComments.pending, (state) => {
            state.isCommentsLoading = true;
        });
        builder.addCase(fetchMediaTypeComments.fulfilled, (state, {payload, meta: {arg: {typeComments}}}) => {
            state.isCommentsLoading = false;

            if (payload.data) {
                // @ts-ignore
                state[typeComments as keyof typeof state] = payload.data;
            }
        });
        builder.addCase(fetchMediaTypeComments.rejected, (state) => {
            state.isCommentsLoading = false;
        });
        // Инциденты на контроле
        builder.addCase(fetchIncidentsOnControl.pending, (state) => {
            state.isIncidentsOnControlLoading = true;
        });
        builder.addCase(fetchIncidentsOnControl.fulfilled, (state, {payload}) => {
            state.incidentsOnControl = payload;
            state.isIncidentsOnControlLoading = false;
        });
        builder.addCase(fetchIncidentsOnControl.rejected, (state) => {
            state.isIncidentsOnControlLoading = false;
        });
        // Инциденты избранные
        builder.addCase(fetchFavoriteIncidents.pending, (state) => {
            state.isIncidentsFavoriteLoading = true;
        });
        builder.addCase(fetchFavoriteIncidents.fulfilled, (state, {payload}) => {
            state.incidentsFavorite = payload.isFilter
                ? payload.data.filter((item) => item?.object)
                : payload.data;
            state.isIncidentsFavoriteLoading = false;
        });
        builder.addCase(fetchFavoriteIncidents.rejected, (state) => {
            state.isIncidentsFavoriteLoading = false;
        });
        // Последние события
        builder.addCase(fetchLastEvents.pending, (state) => {
            state.isLastEventsLoading = true;
        });
        builder.addCase(fetchLastEvents.fulfilled, (state, {payload}) => {
            state.lastEvents = payload;
            state.isLastEventsLoading = false;
        });
        builder.addCase(fetchLastEvents.rejected, (state) => {
            state.lastEvents = [];
            state.isLastEventsLoading = false;
        });

        // Последние события
        builder.addCase(fetchLastEventsWithFilters.pending, (state) => {
            state.roadObjectsForMarkersIsLoading = true;
        });
        builder.addCase(fetchLastEventsWithFilters.fulfilled, (state, {payload}) => {
            state.roadObjectsForMarkers = payload;
            state.roadObjectsForMarkersIsLoading = false;
        });
        builder.addCase(fetchLastEventsWithFilters.rejected, (state) => {
            state.roadObjectsForMarkers = [];
            state.roadObjectsForMarkersIsLoading = false;
        });

        builder.addCase(fetchSummary.pending, (state) => {
            state.summary = null;
            state.isSummaryLoading = true;
        });
        builder.addCase(fetchSummary.fulfilled, (state, {payload}) => {
            state.summary = payload;
            state.isSummaryLoading = false;
        });
        builder.addCase(fetchSummary.rejected, (state) => {
            state.summary = null;
            state.isSummaryLoading = false;
        });

        // Получить аналитику для архива
        builder.addCase(fetchStringEventsAndIncidents.pending, (state) => {
            state.analytics = null;
            state.isAnalyticsLoading = true;
        });

        builder.addCase(fetchStringEventsAndIncidents.fulfilled, (state, {payload}) => {
            state.analytics = payload;
            state.isAnalyticsLoading = false;
        });

        builder.addCase(fetchStringEventsAndIncidents.rejected, (state) => {
            state.analytics = null;
            state.isAnalyticsLoading = false;
        });

        // Получить событие
        builder.addCase(fetchEvent.pending, (state, action) => {
            !action?.meta?.arg?.updateVisibility && (state.event = null);
            state.isEventLoading = true;
        });
        builder.addCase(fetchEvent.fulfilled, (state: IInitialState, {payload}) => {
            state.isEventLoading = false;
            state.event = payload;
        });
        builder.addCase(fetchEvent.rejected, (state) => {
            state.isEventLoading = false;
        });

        // Получаем доп. информацию
        builder.addCase(fetchAdditionalInfo.pending, (state) => {
            state.isEventLoading = true;
        });
        builder.addCase(fetchAdditionalInfo.fulfilled, (state, {payload}) => {
            state.isEventLoading = false;
            if (state.event) state.event.data = payload;
        });
        builder.addCase(fetchAdditionalInfo.rejected, (state) => {
            state.isEventLoading = false;
        });

        // Обновить доп. информацию события
        builder.addCase(updateAdditionalInfo.pending, (state) => {
            state.isAdditionalInfoLoading = true;
        });
        builder.addCase(updateAdditionalInfo.fulfilled, (state, {payload}) => {
            state.isAdditionalInfoLoading = false;
            if (state.event?.data) state.event.data = payload;
        });
        builder.addCase(updateAdditionalInfo.rejected, (state) => {
            state.isAdditionalInfoLoading = false;
        });

        // Обновить событие
        builder.addCase(updateEvent.pending, (state) => {
            state.isUpdateEventLoading = true;
        });
        builder.addCase(updateEvent.fulfilled, (state, action) => {
            // Редактирование архивного инцидента (без signalR, в отличие от Главной)
            if (state.event && state.event.id === action.payload.id) {
                state.event = state.event && Object.assign(state.event, action.payload);
            }
            state.isUpdateEventLoading = false;

            if (action.meta.arg.reloadAfter) {
                window.location.reload();
            }
        });
        builder.addCase(updateEvent.rejected, (state) => {
            state.isUpdateEventLoading = false;
        });

        // Принять событие
        builder.addCase(assignEvent.pending, (state) => {
            state.isAssignEventLoading = true;
        });
        builder.addCase(assignEvent.fulfilled, (state, action) => {
            state.isAssignEventLoading = false;
            if (action.payload.reloadAfter) {
                window.location.reload();
            }
            if (state.event) {
                state.event.incidentOpenDateTime = String(new Date());
            }
        });
        builder.addCase(assignEvent.rejected, (state) => {
            state.isAssignEventLoading = false;
        });

        // Отклонить событие
        builder.addCase(rejectEvent.pending, (state) => {
            state.isRejectEventLoading = true;
        });
        builder.addCase(rejectEvent.fulfilled, (state, action) => {
            state.isRejectEventLoading = false;
            if (action.payload.reloadAfter) {
                window.location.reload();
            }
        });
        builder.addCase(rejectEvent.rejected, (state) => {
            state.isRejectEventLoading = false;
        });

        // Закрыть инцидент
        builder.addCase(closeEvent.pending, (state) => {
            state.isIncidentClosing = true;
        });
        builder.addCase(closeEvent.fulfilled, (state, {payload}) => {
            if (payload.reloadAfter) {
                window.location.reload();
            }
            if (state.event) {
                state.event.status = IncidentEventStatus.Resolved;
                state.event.incidentCloseDateTime = String(new Date());
            }
            state.isIncidentClosing = false;
        });
        builder.addCase(closeEvent.rejected, (state) => {
            state.isIncidentClosing = false;
        });

        // Получить список событий
        builder.addCase(fetchEvents.pending, (state) => {
            state.isEventsLoading = true;
        });
        builder.addCase(fetchEvents.fulfilled, (state, {payload}) => {
            state.isEventsLoading = false;
            state.events = payload.data;
            state.pagination = payload.pagination;
        });
        builder.addCase(fetchEvents.rejected, (state) => {
            state.isEventsLoading = false;
        });

        // Получить актуальные инциденты для виджетов в АРМ ПроТВ
        builder.addCase(fetchActualProTvWidgetsIncidents.pending, (state) => {
            state.isActualIncidentsLoading = true;
        });
        builder.addCase(fetchActualProTvWidgetsIncidents.fulfilled, (state, {payload}) => {
            state.actualProTvWidgetsIncidents = payload;
            state.isActualIncidentsLoading = false;
        });
        builder.addCase(fetchActualProTvWidgetsIncidents.rejected, (state) => {
            state.isActualIncidentsLoading = false;
        });

        // Получить комментарии
        builder.addCase(fetchComments.pending, (state) => {
            state.isCommentsLoading = true;
        });
        builder.addCase(fetchComments.fulfilled, (state, {payload, meta: {arg: {showDeleted}}}) => {
            if (payload.data) {
                state.comments = payload.data;
            }

            // В инцидент добавляем флаг о режиме показа удаленных комментариев
            if (state.event) {
                state.event = {
                    ...state.event,
                    isShowCommentsDeleted: showDeleted,
                };
            }
            state.isCommentsLoading = false;
        });
        builder.addCase(fetchComments.rejected, (state) => {
            state.isCommentsLoading = false;
        });

        // Обновить комментарий
        builder.addCase(updateComment.pending, (state) => {
            state.isCommentsLoading = true;
        });
        builder.addCase(updateComment.fulfilled, (state, {payload}) => {
            state.isCommentsLoading = false;

            const commentIndex = state.comments.findIndex(({id}) => id === payload.id);

            state.comments[commentIndex] = payload;
        });
        builder.addCase(updateComment.rejected, (state) => {
            state.isCommentsLoading = false;
        });

        // Восстановить комментарий
        builder.addCase(restoreComment.pending, (state) => {
            state.isCommentsLoading = true;
        });
        builder.addCase(restoreComment.fulfilled, (state, {payload, meta: {arg: {activeComments, commentId}}}) => {
            const commentIndex = state[activeComments as keyof typeof state].findIndex(({id}: {id: string}) => (
                id === commentId
            ));

            if (commentIndex !== -1) {
                state[activeComments as keyof typeof state][commentIndex] = payload;
            }

            state.isCommentsLoading = false;
        });
        builder.addCase(restoreComment.rejected, (state) => {
            state.isCommentsLoading = false;
        });

        // Удалить комментарий
        builder.addCase(deleteComment.pending, (state) => {
            state.isCommentsLoading = true;
        });
        builder.addCase(deleteComment.fulfilled, (state, {payload, meta: {arg: {activeComments, commentId}}}) => {
            state.isCommentsLoading = false;

            const commentIndex = state[activeComments as keyof typeof state].findIndex(({id}: {id: string}) => (
                id === commentId
            ));

            if (payload) {
                state[activeComments as keyof typeof state][commentIndex] = payload;
                return;
            }

            state[activeComments as keyof typeof state].splice(commentIndex, 1);
        });
        builder.addCase(deleteComment.rejected, (state) => {
            state.isCommentsLoading = false;
        });

        // Добавить комментарий
        builder.addCase(addComment.pending, (state) => {
            state.isCommentAdding = true;
        });
        builder.addCase(addComment.fulfilled, (state, {payload}) => {
            state.isCommentAdding = false;
            // Комментарий добавиться в массив через SignalR

            const commentIndex = state.comments.findIndex(({id}) => id === payload.id);

            if (commentIndex === -1) {
                state.comments.unshift(payload);
            }
        });
        builder.addCase(addComment.rejected, (state) => {
            state.isCommentAdding = false;
        });

        // Добавить прикрепленные файлы к комментарию
        builder.addCase(addCommentFile.fulfilled, (state, {payload, meta: {arg}}) => {
            const commentIndex = state.comments.findIndex(({id}) => id === arg.commentId);

            if (commentIndex !== -1) {
                // @ts-ignore
                state.comments[commentIndex].attachments = payload;
            }
            // Комментарий добавиться в массив через SignalR
        });

        // Пересылка сообщения службам
        builder.addCase(commentNotifyResponseService.pending, (state) => {
            state.isCommentAdding = true;
        });
        builder.addCase(commentNotifyResponseService.fulfilled, (state) => {
            state.isCommentAdding = false;
            toast.success("Сообщение успешно отправлено");
        });
        builder.addCase(commentNotifyResponseService.rejected, (state) => {
            state.isCommentAdding = false;
            toast.error("Ошибка отправки сообщения");
        });

        // Включение агрегации
        builder.addCase(setAggregator.pending, (state) => {
            state.isEventAggregatorLoading = true;
        });
        builder.addCase(setAggregator.fulfilled, (state, action) => {
            if (state.event?.grouping) {
                state.event.grouping.aggregatorSet = true;
            }
            state.isEventAggregatorLoading = false;

            if (action.meta.arg.reloadAfter) {
                window.location.reload();
            }
        });
        builder.addCase(setAggregator.rejected, (state) => {
            state.isEventAggregatorLoading = false;
        });

        // Отключение агрегации
        builder.addCase(removeAggregator.pending, (state) => {
            state.isEventAggregatorLoading = true;
        });
        builder.addCase(removeAggregator.fulfilled, (state) => {
            if (state.event?.grouping) {
                state.event.grouping.aggregatorSet = false;
            }
            state.isEventAggregatorLoading = false;
        });
        builder.addCase(removeAggregator.rejected, (state) => {
            state.isEventAggregatorLoading = false;
        });

        // Удаление события из группы
        builder.addCase(toIncident.pending, (state) => {
            state.isToIncidentLoading = true;
        });
        builder.addCase(toIncident.fulfilled, (state, action) => {
            if (state.event?.grouping?.relatedEvents) {
                state.event.grouping.relatedEvents = state.event?.grouping?.relatedEvents.filter(
                    (event) => event.id !== action.meta.arg
                );
            }
            state.isToIncidentLoading = false;
        });
        builder.addCase(toIncident.rejected, (state) => {
            state.isToIncidentLoading = false;
        });

        // Массовое удаление события из группы
        builder.addCase(toIncidents.pending, (state) => {
            state.isToIncidentLoading = true;
        });

        builder.addCase(toIncidents.fulfilled, (state, action) => {
            if (state.event?.grouping?.relatedEvents) {
                state.event.grouping.relatedEvents = state.event?.grouping?.relatedEvents.filter(
                    // @ts-ignore
                    (event) => !action.meta.arg.includes(event.id)
                );
            }
            state.isToIncidentLoading = false;
        });

        builder.addCase(toIncidents.rejected, (state) => {
            state.isToIncidentLoading = false;
        });

        // Добавление событий в группу
        builder.addCase(toEvent.pending, (state) => {
            state.isToEventLoading = true;
        });

        builder.addCase(toEvent.fulfilled, (state, action) => {
            if (state.event && action.meta.arg.masterId === state.event?.id) {
                action.meta.arg.incidentIds.forEach((incidentId) => {
                    const incident = state.nearbyIncidents?.find((event) => event.id === incidentId);

                    if (incident && state.event?.grouping?.relatedEvents && incident?.beginDateTime) {
                        // @ts-ignore
                        state.event.grouping.relatedEvents.push({
                            ...incident,
                            registrationDateTime: String(incident.beginDateTime),
                        });
                    }
                });
            }

            state.isToEventLoading = false;
        });

        builder.addCase(toEvent.rejected, (state) => {
            state.isToEventLoading = false;
        });

        // Добавление медиа файла инцидента
        builder.addCase(addIncidentMedia.pending, (state) => {
            state.isIncidentMediaFileAdding = true;
        });
        builder.addCase(addIncidentMedia.fulfilled, (state) => {
            state.isIncidentMediaFileAdding = false;
        });
        builder.addCase(addIncidentMedia.rejected, (state) => {
            state.isIncidentMediaFileAdding = false;
        });

        // Удаление медиа файла инцидента
        builder.addCase(removeIncidentMedia.pending, (state) => {
            state.isIncidentMediaFileRemoving = true;
        });
        builder.addCase(removeIncidentMedia.fulfilled, (state) => {
            state.isIncidentMediaFileRemoving = false;
        });
        builder.addCase(removeIncidentMedia.rejected, (state) => {
            state.isIncidentMediaFileRemoving = false;
        });

        // Постановка инцидента на контроль
        builder.addCase(setTrackedIncident.pending, (state) => {
            state.isIncidentTracking = true;
        });
        builder.addCase(setTrackedIncident.fulfilled, (state) => {
            if (state.event?.tracked) state.event.tracked.enabled = true;
            state.isIncidentTracking = false;

            if (state.event?.tracked?.enabled) {
                state.isEventScreenshotAdded = true;
            }
        });
        builder.addCase(setTrackedIncident.rejected, (state) => {
            state.isIncidentTracking = false;
        });

        // Снятие инцидента с контроля
        builder.addCase(removeTrackedIncident.pending, (state) => {
            state.isIncidentTracking = true;
        });
        builder.addCase(removeTrackedIncident.fulfilled, (state) => {
            if (state.event?.tracked) state.event.tracked.enabled = false;
            state.isIncidentTracking = false;
        });
        builder.addCase(removeTrackedIncident.rejected, (state) => {
            state.isIncidentTracking = false;
        });

        // Назначение основного инцидента в группе событий
        builder.addCase(setMasterRepresentationEvent.fulfilled, (state, action) => {
            if (state.event?.grouping) {
                state.event.grouping.masterRepresentationIncidentId = action.meta.arg.incidentId;
            }
        });

        // Скрыть/Показать инцидент в группе событий
        builder.addCase(updateEventVisibility.fulfilled, (state, {payload}) => {
            state.event = payload;
        });

        // Добавление камеры в группу событий
        builder.addCase(addInvolvedCam.pending, (state) => {
            state.addCameraLoading = true;
            state.addCameraSuccess = false;
        });
        builder.addCase(addInvolvedCam.fulfilled, (state, action) => {
            state.addCameraLoading = false;
            if (state?.event?.grouping) {
                //TODO Хот фикс. Не на всех контурах данные инцидента приходят в ответ на запрос
                action.payload.grouping
                    ? state.event.grouping = action.payload.grouping
                    : state.event.grouping.cameraObjects?.push(+action.meta.arg.camId);
            }
            state.addCameraSuccess = true;
        });
        builder.addCase(addInvolvedCam.rejected, (state) => {
            state.addCameraLoading = false;
            state.addCameraSuccess = false;
        });

        // Удаление камеры из группы
        builder.addCase(removeInvolvedCam.pending, (state) => {
            state.removeCameraLoading = true;
            state.removeCameraSuccess = false;
        });
        builder.addCase(removeInvolvedCam.fulfilled, (state, action) => {
            state.removeCameraLoading = false;
            if (state?.event?.grouping) {
                state.event.grouping.cameraObjects = state.event?.grouping?.cameraObjects?.filter(
                    item => (item !== +action.meta.arg.camId)
                );
            }
            state.removeCameraSuccess = true;
        });
        builder.addCase(removeInvolvedCam.rejected, (state) => {
            state.removeCameraLoading = false;
            state.removeCameraSuccess = false;
        });

        // Получение инцидентов вблизи
        builder.addCase(fetchNearbyIncidents.pending, (state) => {
            state.isNearbyIncidentsLoading = true;
        });
        builder.addCase(fetchNearbyIncidents.fulfilled, (state, {payload}) => {
            state.nearbyIncidents = payload;
            state.isNearbyIncidentsLoading = false;
        });
        builder.addCase(fetchNearbyIncidents.rejected, (state) => {
            state.isNearbyIncidentsLoading = false;
        });

        // Удаление инцидента из избранного
        builder.addCase(removeFavorite.pending, (state) => {
            state.isIncidentClosing = true;
        });
        builder.addCase(removeFavorite.fulfilled, (state) => {
            if (state.event?.favorite) state.event.favorite.enabled = false;
            state.isIncidentClosing = false;
        });
        builder.addCase(removeFavorite.rejected, (state) => {
            state.isIncidentClosing = false;
        });

        // Добавление инцидента в избранное
        builder.addCase(setFavorite.pending, (state) => {
            state.isIncidentClosing = true;
        });
        builder.addCase(setFavorite.fulfilled, (state) => {
            if (state.event?.favorite) state.event.favorite.enabled = true;
            state.isIncidentClosing = false;

            if (state.event?.favorite?.enabled) {
                state.isEventScreenshotAdded = true;
            }
        });
        builder.addCase(setFavorite.rejected, (state) => {
            state.isIncidentClosing = false;
        });

        // Добавление вложенных файлов в основное
        builder.addCase(addAttachmentsToMedia.fulfilled, (state, {payload}) => {
            if (state?.event) {
                state.event.media = payload;
            }
        });

        // Вернуть инцидент в работу
        builder.addCase(reopenIncident.pending, (state) => {
            state.isIncidentReopening = true;
        });
        builder.addCase(reopenIncident.fulfilled, (state) => {
            if (state?.event) state.event.status = IncidentEventStatus.Processing;
            state.isIncidentReopening = false;
            toast.success("Инцидент успешно возвращен в работу");
        });
        builder.addCase(reopenIncident.rejected, (state) => {
            state.isIncidentReopening = false;
        });

        builder.addCase(editIncidentTags.pending, (state) => {
            state.requestTagSuccess = null;
            state.requestTagLoading = true;
        });
        builder.addCase(editIncidentTags.fulfilled, (state, {payload}) => {
            state.requestTagLoading = false;
            state.requestTagSuccess = true;
            toast.success("Теги успешно обновлены");
            if (state?.event) state.event.tags = payload;
        });
        builder.addCase(editIncidentTags.rejected, (state) => {
            state.requestTagLoading = false;
            state.requestTagSuccess = null;
        });

        builder.addCase(handoverEvent.pending, (state) => {
            state.handoverRequestSendingLoading = true;
        });
        builder.addCase(handoverEvent.fulfilled, (state) => {
            state.handoverRequestSendingLoading = false;
        });
        builder.addCase(handoverEvent.rejected, (state) => {
            state.handoverRequestSendingLoading = false;
        });

        builder.addCase(takeHandoverEvent.pending, (state) => {
            state.handoverTakeLoading = true;
        });
        builder.addCase(takeHandoverEvent.fulfilled, (state) => {
            state.handoverRequests = state.handoverRequests.slice(1) || [];
            state.handoverTakeLoading = false;
        });
        builder.addCase(takeHandoverEvent.rejected, (state) => {
            state.handoverTakeLoading = false;
        });

        builder.addCase(rejectHandoverEvent.pending, (state) => {
            state.handoverRejectLoading = true;
        });
        builder.addCase(rejectHandoverEvent.fulfilled, (state) => {
            state.handoverRequests = state.handoverRequests.slice(1) || [];
            state.handoverRejectLoading = false;
        });
        builder.addCase(rejectHandoverEvent.rejected, (state) => {
            state.handoverRejectLoading = false;
        });
    },
});

export default event.reducer;

// Экшены
export const {
    setInitQueryParams,
    setFilters,
    resetArchiveFilters,
    setSelectedLastEventId,
    setSelectedLastIncidentLocation,
    setSelectedLastEvent,
    setNearbyIncidents,
    upsertLastEventIncident,
    setLastEventsFilters,
    demoSetLastEventsFilters,
    addLastEventsStatusFilter,
    removeLastEventsStatusFilter,
    setPagination,
    setIncidentInfoFilters,
    setIsMultipleSelect,
    setSelectedMapCamera,
    setPinnedIncident,
    setPathCoordinates,
    markNotificationCompleted,
    clearMediaComments,
    setIsSecondOperatorScreen,
    updateEventCommentsCounter,
    resetEventCommentsCounter,
    setRoadObjectsForMarkers,
    setSecondScreenActiveEventId,
    updateEventFolder,
    updateIncidentEvent,
    setEvent,
    setFavoriteFilters,
    setArchiveFilters,
    setAdditionalInfoData,
    resetHandoverResult,
    resetHandoverLoading,
    setLoginAttemptsCountExceededMessage,
    resetAddAndRemoveCameraStatus,
    setLoadedMap,
    setIsEventScreenshotAdded,
    setToiNotifications,
} = event.actions;

// Селекторы
const slice = ({eventReducer}: RootState) => eventReducer;

// Последние инциденты (с добавлением данных из дорожных объектов)
export const lastEventsSelector = createSelector(
    slice,
    roadObjectFeaturesHashMapSelector,
    ({lastEvents}, roadObjects) => lastEvents.map(event => {
        const type = roadObjects[event.object?.roadObjectId || "0"]?.properties?.type || null;
        const subType = roadObjects[event.object?.roadObjectId || "0"]?.properties?.subType || null;

        return {
            ...event,
            object: {
                ...event.object,
                type: type,
                subType: subType,
            },
        };
    })
);

// Фильтры последних инцидентов
export const lastEventsFiltersSelector = createSelector(
    slice,
    ({lastEventsFilters}) => lastEventsFilters
);

export const demoLastEventsFiltersSelector = createSelector(
    slice,
    ({demoLastEventsFilters}) => demoLastEventsFilters
);

// Фильтры инцидентов
export const incidentInfoFiltersSelector = createSelector(
    slice,
    ({incidentInfoFilters}) => incidentInfoFilters
);

// Фильтрация последних инцидентов
export const filteredLastEventsSelector = createSelector(
    lastEventsSelector,
    lastEventsFiltersSelector,
    (events, filters) => {
        const filterKeysArr = Object.keys(filters);
        let eventsAcc: LastIncidentEventModel[] = events;

        for (let i = 0; i < filterKeysArr.length; i++) {
            const filterKey = filterKeysArr[i];
            if (filters[filterKey].length > 0) {
                eventsAcc = eventsAcc.filter((event) => {

                    if (filterKey === "tags") {
                        return filters[filterKey].some((filterItem) => {
                            return (event[filterKey] as ITag[]).some((tag) => tag.id === filterItem);
                        });
                    }

                    if (filterKey === "type") {
                        return filters[filterKey].some((filterItem) => {
                            return ((event[filterKey] as IncidentEventType).code === filterItem);
                        });
                    }

                    return filters[filterKey].some((filter) => {
                        // Преобразование к json для сравнения объектов вида {code: "trafficAccident", name: "ДТП"}
                        // @ts-ignore
                        return JSON.stringify(event[filterKey]) === filter;
                    });
                });
            }
        }

        return eventsAcc;
    }
);

export const demoFilteredLastEventsSelector = createSelector(
    lastEventsSelector,
    demoLastEventsFiltersSelector,
    (events, filters) => {
        const filterKeysArr = Object.keys(filters);

        return filterKeysArr.reduce((eventsAcc: LastIncidentEventModel[], filterKey) => {
            if (filters[filterKey].length > 0) {
                return eventsAcc.filter((event) => {
                    return filters[filterKey].some((filter) => {
                        if (filterKey === "time") {
                            return getMs(event.beginDateTime) > filters[filterKey][0]
                                && getMs(event.beginDateTime) < filters[filterKey][1];
                        }
                        // Преобразование к json для сравнения объектов вида {code: "trafficAccident", name: "ДТП"}
                        // @ts-ignore
                        return JSON.stringify(event[filterKey]) === filter;
                    });
                });
            } else return eventsAcc;
        }, events);
    }
);

export const selectedLastEventIdSelector = createSelector(
    slice,
    filteredLastEventsSelector,
    ({selectedLastEventId}, events) => selectedLastEventId ?? events[0]?.id ?? null
);

// Выбранный последний инцидент
export const selectedLastEventSelector = createSelector(
    slice,
    ({selectedLastEvent}) => selectedLastEvent
);

// Счетчики отфильтрованных инцидентов по статусам
export const lastEventStatusCountersSelector = createSelector(
    filteredLastEventsSelector,
    (events) => events.reduce((acc: ILastEventsStatusCounters, item: LastIncidentEventModel) => {
        if (item.status && Object.hasOwn(acc, item.status)) {
            acc[item.status] += 1;
        }
        return acc;
    }, {resolved: 0, rejected: 0, processing: 0, new: 0})
);

export const lastEventsStatusFiltersSelector = createSelector(
    slice,
    ({lastEventsStatusFilters}) => lastEventsStatusFilters
);

export const filteredLastEventsByStatusSelector = createSelector(
    filteredLastEventsSelector, lastEventsStatusFiltersSelector, (events, statuses) => statuses.length > 0
        ? events.filter(event => event.status && statuses.includes(event.status))
        : events
);

export const isLastEventsLoadingSelector = createSelector(
    slice,
    ({isLastEventsLoading}) => isLastEventsLoading
);

export const summaryEventsSelector = createSelector(
    slice,
    ({summary}) => summary
);

export const eventsSelector = createSelector(
    slice,
    ({events}) => events
);

// Комментарии в карточке инцидента
export const commentsSelector = createSelector(
    slice,
    ({comments}) => comments
);

// Напоминания в хедере
export const notificationsSelector = createSelector(
    slice,
    ({notifications}) => {
        if (notifications.length > 0) {
            return [...notifications].sort((a: Notification, b: Notification) => {
                return Date.parse(b?.created || "0") - Date.parse(a?.created || "0");
            });
        }

        return [];
    }
);

export const eventsPaginationSelector = createSelector(
    slice,
    ({pagination}) => ({
        ...pagination,
        page: Number(pagination.page),
        totalElements: Number(pagination.totalElements),
        totalPages: Number(pagination.totalPages),
    })
);

export const eventSelector = createSelector(
    slice,
    ({event}) => event
);

// Фильтры на странице Архива
export const filtersSelector = createSelector(
    slice,
    ({filters}) => filters
);

export const sortSelector = createSelector(
    slice,
    ({pagination}): IEventsSort => {
        const [field, direction] = (pagination?.sort ?? DEFAULT_EVENTS_SORT).split(",");
        return {field, direction} as IEventsSort;
    }
);

export const isSummaryEventsLoadingSelector = createSelector(
    slice,
    ({isSummaryLoading}) => isSummaryLoading
);

export const isUpdateEventLoadingSelector = createSelector(
    slice,
    ({isUpdateEventLoading}) => isUpdateEventLoading
);

export const isAssignEventLoadingSelector = createSelector(
    slice,
    ({isAssignEventLoading}) => isAssignEventLoading
);

export const isRejectEventLoadingSelector = createSelector(
    slice,
    ({isRejectEventLoading}) => isRejectEventLoading
);

export const isEventsLoadingSelector = createSelector(
    slice,
    ({isEventsLoading}) => isEventsLoading
);

export const isEventLoadingSelector = createSelector(
    slice,
    ({isEventLoading}) => isEventLoading
);

export const isEventScreenshotAddedSelector = createSelector(
    slice,
    ({isEventScreenshotAdded}) => isEventScreenshotAdded
);

export const isIncidentClosingSelector = createSelector(
    slice,
    ({isIncidentClosing}) => isIncidentClosing
);

export const isCommentsLoadingSelector = createSelector(
    slice,
    ({isCommentsLoading}) => isCommentsLoading
);

export const isEventAggregatorLoadingSelector = createSelector(
    slice,
    ({isEventAggregatorLoading}) => isEventAggregatorLoading
);

export const isCommentAddingSelector = createSelector(
    slice,
    ({isCommentAdding}) => isCommentAdding
);

export const isToIncidentLoadingSelector = createSelector(
    slice,
    ({isToIncidentLoading}) => isToIncidentLoading
);

export const isIncidentMediaFileAddingSelector = createSelector(
    slice,
    ({isIncidentMediaFileAdding}) => isIncidentMediaFileAdding
);

export const isIncidentTrackingSelector = createSelector(
    slice,
    ({isIncidentTracking}) => isIncidentTracking
);

export const isIncidentMediaFileRemovingSelector = createSelector(
    slice,
    ({isIncidentMediaFileRemoving}) => isIncidentMediaFileRemoving
);

// Включен ли мульти выбор инцидентов в группе событий
export const isMultipleSelectSelector = createSelector(
    slice,
    ({isMultipleSelect}) => isMultipleSelect
);

export const selectedMapCameraSelector = createSelector(
    slice,
    ({selectedMapCamera}) => selectedMapCamera
);

// Успешное добавление камеры в группу
export const addCameraSuccessSelector = createSelector(
    slice,
    ({addCameraSuccess}) => addCameraSuccess
);

export const removeCameraLoadingSelector = createSelector(
    slice,
    ({removeCameraLoading}) => removeCameraLoading
);

// Успешное удаление камеры из группы
export const removeCameraSuccessSelector = createSelector(
    slice,
    ({removeCameraSuccess}) => removeCameraSuccess
);

// Получение инцидентов вблизи
export const nearbyIncidentsSelector = createSelector(
    slice,
    ({nearbyIncidents}) => nearbyIncidents
);

export const isNearbyIncidentsLoadingSelector = createSelector(
    slice,
    ({isNearbyIncidentsLoading}) => isNearbyIncidentsLoading
);
export const incidentsOnControlSelector = createSelector(
    slice,
    ({incidentsOnControl}) => incidentsOnControl
);

export const actualProTvWidgetsIncidentsSelector = createSelector(
    slice,
    ({actualProTvWidgetsIncidents}) => actualProTvWidgetsIncidents
);

export const isActualIncidentsLoadingSelector = createSelector(
    slice,
    ({isActualIncidentsLoading}) => isActualIncidentsLoading
);

export const isIncidentsOnControlLoadingSelector = createSelector(
    slice,
    ({isIncidentsOnControlLoading}) => isIncidentsOnControlLoading
);

export const isIncidentsFavoriteLoadingSelector = createSelector(
    slice,
    ({isIncidentsFavoriteLoading}) => isIncidentsFavoriteLoading
);

export const incidentsFavoriteSelector = createSelector(
    slice,
    ({incidentsFavorite}) => incidentsFavorite
);

export const pathCoordinatesSelector = createSelector(
    slice,
    ({pathCoordinates}) => pathCoordinates
);
export const isIncidentCreatingSelector = createSelector(
    slice,
    ({isIncidentCreating}) => isIncidentCreating
);

export const roadObjectsForMarkersSelector = createSelector(
    slice,
    ({roadObjectsForMarkers}) => roadObjectsForMarkers
);
export const roadObjectsForMarkersIsLoadingSelector = createSelector(
    slice,
    ({roadObjectsForMarkersIsLoading}) => roadObjectsForMarkersIsLoading
);

export const filteredRoadObjectsForMarkersSelector = createSelector(
    roadObjectsForMarkersSelector,
    lastEventsFiltersSelector,
    (events, filters) => {
        const filterKeysArr = Object.keys(filters);

        return filterKeysArr.reduce((eventsAcc: LastIncidentEventModel[], filterKey) => {
            if (filters[filterKey].length > 0) {
                return eventsAcc.filter((event) => {
                    return filters[filterKey].some((filter) => {
                        // Преобразование к json для сравнения объектов вида {code: "trafficAccident", name: "ДТП"}
                        // @ts-ignore
                        return JSON.stringify(event[filterKey]) === filter;
                    });
                });
            } else return eventsAcc;
        }, events);
    }
);

export const downloadLinkSelector = createSelector(
    slice,
    (state) => buildQuery(
        ApiUrls.EVENTS_DOWNLOAD,
        getQueryParamsFromState(state)
    )
);

export const newCommentAttachmentsSelector = createSelector(
    slice,
    ({newCommentAttachments}) => newCommentAttachments
);

export const mediaCommentsSelector = createSelector(
    slice,
    ({mediaComments}) => mediaComments
);
export const documentCommentsSelector = createSelector(
    slice,
    ({documentComments}) => documentComments
);

export const isIncidentReopeningSelector = createSelector(
    slice,
    ({isIncidentReopening}) => isIncidentReopening
);

export const requestTagLoadingSelector = createSelector(
    slice,
    ({requestTagLoading}) => requestTagLoading
);

export const requestTagSuccessSelector = createSelector(
    slice,
    ({requestTagSuccess}) => requestTagSuccess
);

export const isAdditionalInfoLoadingSelector = createSelector(
    slice,
    ({isAdditionalInfoLoading}) => isAdditionalInfoLoading
);

export const resetCounterArchiveSelector = createSelector(
    slice,
    ({resetCounterArchive}) => resetCounterArchive
);

export const selectedLastIncidentLocationSelector = createSelector(
    slice,
    ({selectedLastIncidentLocation}) => selectedLastIncidentLocation
);

export const handoverRequestsSelector = createSelector(
    slice,
    ({handoverRequests}) => handoverRequests
);

export const handoverTakeLoadingSelector = createSelector(
    slice,
    ({handoverTakeLoading}) => handoverTakeLoading
);

export const handoverRejectLoadingSelector = createSelector(
    slice,
    ({handoverRejectLoading}) => handoverRejectLoading
);

export const handoverResultSelector = createSelector(
    slice,
    ({handoverResult}) => handoverResult
);

export const handoverRequestSendingLoadingSelector = createSelector(
    slice,
    ({handoverRequestSendingLoading}) => handoverRequestSendingLoading
);

export const loginAttemptsCountExceededMessageSelector = createSelector(
    slice,
    ({loginAttemptsCountExceededMessage}) => loginAttemptsCountExceededMessage
);

export const isLoadedMapSelector = createSelector(
    slice,
    ({isLoadedMap}) => isLoadedMap
);

export const analyticsSelector = createSelector(
    slice,
    ({analytics}) => analytics
);

export const isAnalyticsLoadingSelector = createSelector(
    slice,
    ({isAnalyticsLoading}) => isAnalyticsLoading
);

export const toiNotificationSelector = createSelector(
    slice,
    ({toiNotifications}) => toiNotifications
);