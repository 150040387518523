import styled from "styled-components";

import checkIcon from "../../../../images/icons/check-white.svg";
import checkIconGray from "../../../../images/icons/check.svg";

export const CheckboxContainer = styled.div<{$bgColor?: string, $bgCheckedColor?: string}>`
    span {
        &::before {
            border: 2px solid ${props => (props.$bgColor || "var(--border-primary)")};
        }
    }

    input {
        display: none;

        &:not(:disabled):not(:checked) + span:hover::before {
            border-color: ${props => (props.$bgColor || "var(--bg-accent)")};
        }


        &:checked + span::before {
            border-color: ${props => (props.$bgCheckedColor || props.$bgColor || "var(--bg-accent)")};
            background-color: ${props => (props.$bgCheckedColor || props.$bgColor || "var(--bg-accent)")};
            background-image: url('${checkIcon}');
        }


        &:disabled + span::before {
            box-shadow: unset;
            background-color: ${() => "var(--bg-disabled)"};
            border-color: ${() => "var(--bg-disabled)"};
            cursor: auto;
        }

        &:checked:disabled + span::before {
            box-shadow: unset;
            background-image: url('${checkIconGray}');
            background-color: ${() => "var(--bg-disabled)"};
            border-color: ${() => "var(--bg-disabled)"};
            cursor: auto;
        }
    }
`;