// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".TagGroupDropdown_dropdown__1ZL3k {\n  background: var(--bg-tertiary);\n  padding: 10px;\n  border: 1px solid var(--divider-color);\n  border-radius: 4px;\n}\n.TagGroupDropdown_dropdown__item__kkaMF {\n  padding: 6px;\n  border-radius: 4px;\n  display: flex;\n  align-items: center;\n  font-size: 14px;\n}\n.TagGroupDropdown_dropdown__item-icon__ywtzc {\n  margin-right: 10px;\n  color: var(--icon-secondary);\n}\n.TagGroupDropdown_dropdown__item__kkaMF:hover {\n  background-color: var(--bg-quinary);\n}", "",{"version":3,"sources":["webpack://./src/components/Settings/SettingsTags/TagGroupDropdown/TagGroupDropdown.module.scss"],"names":[],"mappings":"AAAA;EACE,8BAAA;EACA,aAAA;EACA,sCAAA;EACA,kBAAA;AACF;AACE;EACE,YAAA;EACA,kBAAA;EACA,aAAA;EACA,mBAAA;EACA,eAAA;AACJ;AACI;EACE,kBAAA;EACA,4BAAA;AACN;AAEI;EACE,mCAAA;AAAN","sourcesContent":[".dropdown {\r\n  background: var(--bg-tertiary);\r\n  padding: 10px;\r\n  border: 1px solid var(--divider-color);\r\n  border-radius: 4px;\r\n\r\n  &__item {\r\n    padding: 6px;\r\n    border-radius: 4px;\r\n    display: flex;\r\n    align-items: center;\r\n    font-size: 14px;\r\n\r\n    &-icon {\r\n      margin-right: 10px;\r\n      color: var(--icon-secondary);\r\n    }\r\n\r\n    &:hover {\r\n      background-color: var(--bg-quinary);\r\n    }\r\n  }\r\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"dropdown": "TagGroupDropdown_dropdown__1ZL3k",
	"dropdown__item": "TagGroupDropdown_dropdown__item__kkaMF",
	"dropdown__item-icon": "TagGroupDropdown_dropdown__item-icon__ywtzc"
};
export default ___CSS_LOADER_EXPORT___;
