/* eslint-disable max-len */
import React, {FC} from "react";

import {ISvgProps} from "../../types/common";

const QuoteIcon:FC<ISvgProps> = ({
    height = 16,
    width = 16,
    ...rest
}) => (
    <svg {...rest} width={width} height={height} viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
            d="M6.16151 4C5.97824 4.87973 5.68499 6.19931 5.28179 7.95876H7.01375V12H3V8.61856L4.15464 4H6.16151ZM11.5223 4C11.3391 4.87973 11.0458 6.19931 10.6426 7.95876H12.3746V12H8.36083V8.61856L9.51546 4H11.5223Z"
            fill="currentColor"/>
    </svg>
);

export default QuoteIcon;