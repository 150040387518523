import React, {useEffect, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import SimpleBar from "simplebar-react";
import "simplebar/dist/simplebar.min.css";

import useHasPermission from "../../../hook/useHasPermission";
import {Permission} from "../../../models/Permission";
import {
    adminResponsibilityZonesIsLoadingSelector,
    adminResponsibilityZonesSelector,
} from "../../../redux/responsibilitySlice";
import {TypedDispatch} from "../../../types";
import {fetchRoles, rolesSelector} from "../../../redux/superadminSlice";
import {Role} from "../../../models/Role";
import {
    fetchZoneOperators,
    zoneOperatorsSelector,
    updateResponsibilityZoneUsersSuccessSelector, isZoneOperatorsLoadingSelector,
} from "../../../redux/zoneOperatorsSlice";

import Spinner from "../../UI/Spinner/Spinner";
import AddButton from "../SettingsTags/AddButton";
import Zone from "./Zone/Zone";
import Operator from "./Operator";
import AddOperatorsForm from "./AddOperatorsForm";

import styles from "./SettingsOperators.module.scss";

const SettingsOperators = () => {
    const dispatch = useDispatch<TypedDispatch>();

    const responsibilityZones = useSelector(adminResponsibilityZonesSelector);
    const roles = useSelector(rolesSelector);
    const operators = useSelector(zoneOperatorsSelector);
    const isZonesLoading = useSelector(adminResponsibilityZonesIsLoadingSelector);
    const isOperatorsLoading = useSelector(isZoneOperatorsLoadingSelector);
    const success = useSelector(updateResponsibilityZoneUsersSuccessSelector);

    const [operatorListVisible, setOperatorListVisible] = useState<boolean>(false);
    const [activeZone, setActiveZone] = useState<null | number>();
    const [operatorRole, setOperatorRole] = useState<null | number>(null); // id роли оператора инцидентов
    const isService = useHasPermission(Permission.SERVICE);

    useEffect(() => {
        if (responsibilityZones?.length) {
            setActiveZone(responsibilityZones[0]?.id);
        }
    }, [responsibilityZones]);

    useEffect(() => {
        if (!roles?.length) {
            dispatch(fetchRoles());
        } else {
            setOperatorRole(roles.find(role => role.Name === Role.INCIDENT_OPERATOR)?.Id ?? null);
        }
    }, [roles]);

    // Первоначальная загрузка операторов + подгрузка при переключении зон
    useEffect(() => {
        if ((operatorRole && activeZone) || success) {
            dispatch(fetchZoneOperators({roleId: operatorRole, zoneId: activeZone}));
        }
    }, [activeZone, operatorRole, success]);

    return (
        <div className={styles["zones"]}>
            {isZonesLoading
                ?  <Spinner size={32} className="mt-4"/>
                : (
                    <>
                        <div className={styles["zones-groups"]}>
                            <SimpleBar style={{maxHeight: "470px", height: "100%", paddingRight: "20px"}}>
                                {responsibilityZones?.length > 0
                                    ? <div>
                                        <div className={styles["zones-groups__title"]}>
                                            <div>Все зоны</div>
                                            <div className={styles["zones-groups__title-count"]}>
                                                {responsibilityZones.length}
                                            </div>
                                        </div>
                                        <div>
                                            {responsibilityZones.map(zone => (
                                                <div key={zone.id}>
                                                    <Zone
                                                        name={zone.name}
                                                        bgColor={JSON.parse(zone?.additionalProperties?.viewJson
                                                            || "{\"bgColor\": \"#6342E7\"}").bgColor}
                                                        isActive={activeZone === zone.id}
                                                        onClick={() => {
                                                            if (operatorListVisible) {
                                                                setOperatorListVisible(false);
                                                            }
                                                            setActiveZone(zone.id);
                                                        }}
                                                    />
                                                </div>
                                            ))}
                                        </div>
                                    </div>
                                    : <div className={styles["no-data"]}>Нет данных</div>
                                }
                            </SimpleBar>
                        </div>

                        <div className={styles["zones-items"]}>
                            {operatorListVisible && !isOperatorsLoading
                                ? <AddOperatorsForm
                                    onClose={() => setOperatorListVisible(false)}
                                    operatorRole={operatorRole}
                                    activeZone={activeZone}
                                />
                                : <>
                                    {isOperatorsLoading
                                        ? <Spinner size={32} className="mt-4"/>
                                        : (
                                            <SimpleBar
                                                style={{maxHeight: "470px", height: "100%", paddingRight: "20px"}}
                                            >
                                                {operators && operators?.length > 0
                                                    ? (
                                                        operators.map(operator => (
                                                            <div key={operator.id}>
                                                                <Operator name={operator.fullName}/>
                                                            </div>
                                                        ))
                                                    )
                                                    : <div className={styles["no-data"]}>Нет данных</div>
                                                }
                                            </SimpleBar>
                                        )}

                                    {!isService && (
                                        <AddButton
                                            text={"Изменить список операторов"}
                                            onClick={() => setOperatorListVisible(true)}
                                        />
                                    )}
                                </>
                            }
                        </div>
                    </>
                )
            }
        </div>
    );
};

export default SettingsOperators;