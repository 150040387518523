import React, {useRef, useState} from "react";
import {Popover, Whisper, WhisperInstance} from "rsuite";
import {useDispatch, useSelector} from "react-redux";

import useHasPermission from "../../../hook/useHasPermission";
import {Permission} from "../../../models/Permission";
import {usePortalOutsideClick} from "../../../utils/customHooks";
import {isQuickSettingsOpenSelector, setIsQuickSettingsOpen} from "../../../redux/settingsSlice";

import {ICard} from "../../Settings/SettingsViewPage/SettingsViewPage";
import {DEFAULT_CARD_LIST} from "../../Settings/constants";
import SettingsIcon from "../../Icons/SettingsIcon";
import QuickSettingsTabs from "../../Settings/QuickSettingsTabs";

import styles from "./QuickSettings.module.scss";
import addInputStyles from "../../Settings/SettingsTags/AddInput/AddInput.module.scss";

const QuickSettings = () => {
    const isAdmin = useHasPermission(Permission.ADMIN);
    const isManager = useHasPermission(Permission.MANAGER);
    const dispatch = useDispatch();
    const whisperRef = useRef<WhisperInstance>(null);

    const isQuickSettingsOpen = useSelector(isQuickSettingsOpenSelector);
    // Список превью карточек
    const [cardList, setCardList] = useState<ICard[]>(DEFAULT_CARD_LIST);

    usePortalOutsideClick(
        `.${styles["dropdown-cnt"]}`,
        `.${addInputStyles["input-group__autocomplete"]}`,
        () => dispatch(setIsQuickSettingsOpen({
            isOpen: false,
            selectedTab: null,
        }))
    );

    const handleClose = () => {
        dispatch(setIsQuickSettingsOpen({
            isOpen: false,
            selectedTab: null,
        }));
    };

    const handleOpenQuickSettings = (cameraId?: any, cameraType?: any, cameraZone?: any) => {
        dispatch(setIsQuickSettingsOpen({
            isOpen: true,
            selectedTab: null,
            cameraId: cameraId || null,
            cameraType: cameraType || null,
            cameraZone: cameraZone || null,
        }));
    };

    return (
        <>
            <Whisper
                ref={whisperRef}
                open={isQuickSettingsOpen.isOpen}
                placement="bottomEnd"
                trigger="click"
                speaker={<Popover
                    arrow={false}
                    className={styles["dropdown-cnt"]}
                >
                    <QuickSettingsTabs
                        setCardList={setCardList}
                        cardList={cardList}
                        isAdmin={isAdmin}
                        onClose={handleClose}
                        selectedTab={isQuickSettingsOpen.selectedTab}
                        cameraId={isQuickSettingsOpen.cameraId}
                        cameraType={isQuickSettingsOpen.cameraType}
                        cameraZone={isQuickSettingsOpen.cameraZone}
                        isManager={isManager}
                    />
                </Popover>
                }
            >
                <div className={styles["header-settings"]} onClick={handleOpenQuickSettings}>
                    <SettingsIcon className={styles["header-settings__icon"]}/>
                </div>
            </Whisper>
        </>
    );
};

export default QuickSettings;