/* eslint-disable max-len */
import React from "react";

import {ISvgProps} from "../../types/common";

const LineChartIcon: React.FC<ISvgProps> = ({
    width = 16,
    height = 16,
    ...rest
}) => (
    <svg xmlns="http://www.w3.org/2000/svg" {...rest} width={width} height={height} viewBox="0 0 16 16" fill="none">
        <path
            d="M2 10L5.86965 6.61406C5.94447 6.54859 6.05602 6.54802 6.13151 6.61272L9.33163 9.35568C9.42167 9.43286 9.55872 9.41518 9.62623 9.31767L14 3"
            stroke="currentColor" strokeWidth="1.5" strokeLinecap="round"/>
    </svg>
);

export default LineChartIcon;