import React, {forwardRef, PropsWithChildren, useEffect, useState} from "react";
import {useDispatch} from "react-redux";
import {debounce} from "lodash";
import {createPortal} from "react-dom";
import SimpleBar from "simplebar-react";

import {usePortalOutsideClick} from "../../../../utils/customHooks";
import {TypedDispatch} from "../../../../types";

import styles from "./AddInput.module.scss";

interface IAddInputProps {
    placeholder: string,
    addData?: any,
    editData?: any,
    defaultValue?: string,
    count?: number,
    id?: number | "default",
    type: "tag" | "comment" | "group",
    groupData?: any,
    hideInput: any,
    requestSuccess: any,
    requestGroupSuccess: any,
    resetSuccess: any,
    groupId?: any,
    autocomplete?: boolean,
    tagsList?: any,
}

const AddInput = forwardRef<HTMLInputElement, PropsWithChildren<IAddInputProps>>((
    {
        placeholder,
        addData,
        editData,
        defaultValue,
        count,
        id,
        type,
        groupData,
        hideInput,
        requestSuccess,
        requestGroupSuccess,
        resetSuccess,
        groupId, 
        autocomplete,
        tagsList,
    }, ref
) => {

    const dispatch = useDispatch<TypedDispatch>();

    usePortalOutsideClick(
        `.${styles["input-group"]}`,
        `.${styles["input-group__autocomplete"]}`,
        () => hideInput(false)
    );

    const [autocompleteList, setAutocompleteList] = useState([]);
    const [showAutocompleteMenu, setShowAutocompleteMenu] = useState(false);
    const [inputValue, setInputValue] = useState("");

    const handleKeyDown = (e: any) => {
        if (e.key === "Enter" && e.target.value.length > 0) {
            if (id) {
                editData(id, e.target.value, groupData ?? groupId);
            } else {
                addData(e.target.value);
            }
        }
    };

    // @ts-ignore
    const handleInputFocus = () => ref?.current?.focus();

    useEffect(() => {
        if (!tagsList) return;

        setAutocompleteList(tagsList);
    }, [tagsList]);

    useEffect(() => {
        if (ref) {
            handleInputFocus();
        }
    }, [ref]);

    useEffect(() => {
        if (requestSuccess || requestGroupSuccess) {
            hideInput(false);
            dispatch(resetSuccess());
        }
    }, [requestSuccess, requestGroupSuccess]);

    const debounced = debounce(
        // @ts-ignore
        (value: string) => setAutocompleteList(tagsList?.filter(({description}) => description.includes(value))),
        300
    );

    const handleInputChange = (e: any) => {
        //Заменяем несколько пробелов между словами на 1
        const tagDescription = e.target.value.replace(/\s{2,}/g, " ");

        setShowAutocompleteMenu(true);
        setInputValue(tagDescription);

        debounced(tagDescription);
    };

    const handleAutocompleteItem = (tagDescription: string) => {
        setInputValue(tagDescription);
    };

    return (
        <div className={styles["input-group"]}>
            <input
                defaultValue={defaultValue}
                ref={ref}
                autoFocus={true}
                type="text"
                placeholder={placeholder}
                onKeyDown={handleKeyDown}
                value={inputValue}
                onChange={handleInputChange}
            />

            {(autocomplete && showAutocompleteMenu) && createPortal(
                <div
                    style={{
                        // @ts-ignore
                        width: ref?.current?.offsetParent?.offsetWidth,
                        // @ts-ignore
                        top: `${ref?.current?.offsetParent?.getBoundingClientRect().bottom + window.scrollY + 1}px`,
                        // @ts-ignore
                        left: `${ref?.current?.offsetParent?.getBoundingClientRect().left + window.scrollX}px`,
                    }}
                    className={styles["input-group__autocomplete"]}
                >
                    {autocompleteList.length
                        ? (
                            <SimpleBar style={{height: "100%"}}>
                                {
                                    autocompleteList?.map(({id, description}) => (
                                        <div
                                            key={id}
                                            className={styles["input-group__autocomplete-item"]}
                                            onClick={() => {
                                                handleAutocompleteItem(description);
                                                setShowAutocompleteMenu(false);
                                                handleInputFocus();
                                            }}
                                        >
                                            {description}
                                        </div>
                                    ))
                                }
                            </SimpleBar>
                        )
                        : <div className={styles["no-data"]}>Нет тегов</div>
                    }
                </div>
                , document.body
            )}

            {type === "group" && count && (
                <span>{count}</span>
            )}
        </div>
    );
});

AddInput.displayName = "AddInput";

export default AddInput;