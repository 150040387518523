/* eslint-disable max-len */
import React, {FC} from "react";

import {ISvgProps} from "../../types/common";

const AnalyticsIcon: FC<ISvgProps> = (
    {
        width = 24,
        height = 24,
        ...rest
    }
) => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" {...rest} width={width} height={height} viewBox="0 0 24 24" fill="none">
            <g clipPath="url(#clip0_28216_53452)">
                <path
                    d="M12.2763 7.39448C12.0758 7.39448 11.9133 7.55703 11.9133 7.75753V13.0823C11.9133 13.2828 12.0758 13.4453 12.2763 13.4453H13.9706C14.1711 13.4453 14.3336 13.2828 14.3336 13.0823V7.75753C14.3336 7.55703 14.1711 7.39448 13.9706 7.39448H12.2763Z"
                    fill="currentColor"/>
                <path
                    d="M9.19421 8.60465C8.9937 8.60465 8.83116 8.76719 8.83116 8.9677V13.0823C8.83116 13.2828 8.9937 13.4453 9.19421 13.4453H10.8884C11.0889 13.4453 11.2515 13.2828 11.2515 13.0823V8.9677C11.2515 8.76719 11.0889 8.60465 10.8884 8.60465H9.19421Z"
                    fill="currentColor"/>
                <path
                    d="M5.74902 10.1779C5.74902 9.97735 5.91157 9.81481 6.11207 9.81481H7.8063C8.00681 9.81481 8.16935 9.97735 8.16935 10.1779V13.0823C8.16935 13.2828 8.00681 13.4453 7.8063 13.4453H6.11207C5.91157 13.4453 5.74902 13.2828 5.74902 13.0823V10.1779Z"
                    fill="currentColor"/>
                <path fillRule="evenodd" clipRule="evenodd"
                    d="M17.532 16.0887C18.7548 14.5061 19.4823 12.5213 19.4823 10.3667C19.4823 5.1936 15.2888 1 10.1157 1C4.94262 1 0.749023 5.1936 0.749023 10.3667C0.749023 15.5397 4.94262 19.7333 10.1157 19.7333C12.2704 19.7333 14.2551 19.0058 15.8377 17.783L20.7038 22.6491C21.1717 23.117 21.9302 23.117 22.3981 22.6491C22.866 22.1812 22.866 21.4227 22.3981 20.9548L17.532 16.0887ZM10.1157 3.39612C6.26596 3.39612 3.14515 6.51694 3.14515 10.3667C3.14515 14.2164 6.26596 17.3372 10.1157 17.3372C12.0252 17.3372 13.7554 16.5694 15.0144 15.3256C15.024 15.3153 15.0339 15.305 15.044 15.295C15.0541 15.2849 15.0643 15.275 15.0746 15.2654C16.3184 14.0064 17.0862 12.2762 17.0862 10.3667C17.0862 6.51694 13.9654 3.39612 10.1157 3.39612Z"
                    fill="currentColor"/>
            </g>
            <defs>
                <clipPath id="clip0_28216_53452">
                    <rect width="22.5" height="24" fill="white" transform="translate(0.75)"/>
                </clipPath>
            </defs>
        </svg>

    );
};

export default AnalyticsIcon;