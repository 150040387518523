/* eslint-disable max-len */
import React from "react";

import {ISvgProps} from "../../types/common";

// import "images/icons/search-icon.svg";

const SearchIcon: React.FC<ISvgProps> = ({
    width = 20,
    height = 21,
    ...rest
}) => (
    <svg {...rest} width={width} height={height} viewBox="0 0 20 21" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g id="MagnifyingGlass" clipPath="url(#clip0_9049_375098)">
            <path id="Vector" d="M8.75 15.5C12.2018 15.5 15 12.7018 15 9.25C15 5.79822 12.2018 3 8.75 3C5.29822 3 2.5 5.79822 2.5 9.25C2.5 12.7018 5.29822 15.5 8.75 15.5Z" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
            <path id="Vector_2" d="M13.1699 13.6699L17.5004 18.0004" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
        </g>
        <defs>
            <clipPath id="clip0_9049_375098">
                <rect width="20" height="20" fill="white" transform="translate(0 0.5)"/>
            </clipPath>
        </defs>
    </svg>
);

export default SearchIcon;