/* eslint-disable max-len */
import React from "react";

import {ISvgProps} from "../../../types/common";

const TabIcon1: React.FC<ISvgProps> = ({
    width = 20,
    height = 20,
    ...rest
}) => (
    <svg {...rest} width={width} height={height} viewBox="0 0 21 20" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path fillRule="evenodd" clipRule="evenodd" d="M6.33268 9.99935H5.49935C3.6584 9.99935 2.16602 11.4917 2.16602 13.3327V14.9993C2.16602 16.8403 3.6584 18.3327 5.49935 18.3327H6.33268C8.17363 18.3327 9.66602 16.8403 9.66602 14.9993V13.3327C9.66602 11.4917 8.17363 9.99935 6.33268 9.99935ZM3.83268 13.3327C3.83268 12.4122 4.57887 11.666 5.49935 11.666H6.33268C7.25316 11.666 7.99935 12.4122 7.99935 13.3327V14.9993C7.99935 15.9198 7.25316 16.666 6.33268 16.666H5.49935C4.57887 16.666 3.83268 15.9198 3.83268 14.9993V13.3327Z" fill="currentColor"/>
        <path fillRule="evenodd" clipRule="evenodd" d="M7.99935 4.58268C7.99935 2.97185 6.69351 1.66602 5.08268 1.66602C3.47185 1.66602 2.16602 2.97185 2.16602 4.58268V5.41602C2.16602 7.02685 3.47185 8.33268 5.08268 8.33268C6.69351 8.33268 7.99935 7.02685 7.99935 5.41602V4.58268ZM3.83268 4.58268C3.83268 3.89233 4.39233 3.33268 5.08268 3.33268C5.77304 3.33268 6.33268 3.89233 6.33268 4.58268V5.41602C6.33268 6.10637 5.77304 6.66602 5.08268 6.66602C4.39233 6.66602 3.83268 6.10637 3.83268 5.41602V4.58268Z" fill="currentColor"/>
        <path fillRule="evenodd" clipRule="evenodd" d="M15.4993 9.16602H14.666C12.8251 9.16602 11.3327 10.6584 11.3327 12.4993V14.9993C11.3327 16.8403 12.8251 18.3327 14.666 18.3327H15.4993C17.3403 18.3327 18.8327 16.8403 18.8327 14.9993V12.4993C18.8327 10.6584 17.3403 9.16602 15.4993 9.16602ZM12.9993 12.4993C12.9993 11.5789 13.7455 10.8327 14.666 10.8327H15.4993C16.4198 10.8327 17.166 11.5789 17.166 12.4993V14.9993C17.166 15.9198 16.4198 16.666 15.4993 16.666H14.666C13.7455 16.666 12.9993 15.9198 12.9993 14.9993V12.4993Z" fill="currentColor"/>
        <path fillRule="evenodd" clipRule="evenodd" d="M15.916 1.66602H12.5827C10.9719 1.66602 9.66602 2.97185 9.66602 4.58268C9.66602 6.19351 10.9719 7.49935 12.5827 7.49935H15.916C17.5268 7.49935 18.8327 6.19351 18.8327 4.58268C18.8327 2.97185 17.5268 1.66602 15.916 1.66602ZM11.3327 4.58268C11.3327 3.89233 11.8923 3.33268 12.5827 3.33268H15.916C16.6064 3.33268 17.166 3.89233 17.166 4.58268C17.166 5.27304 16.6064 5.83268 15.916 5.83268H12.5827C11.8923 5.83268 11.3327 5.27304 11.3327 4.58268Z" fill="currentColor"/>
    </svg>
);

export default TabIcon1;