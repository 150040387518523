/* eslint-disable max-len */
import React from "react";

import {ISvgProps} from "../../types/common";

const EmailIcon: React.FC<ISvgProps> = ({
    width = 12,
    height = 12,
    ...rest
}) => (
    <svg xmlns="http://www.w3.org/2000/svg" {...rest} width={width} height={height} viewBox="0 0 12 12" fill="none">
        <path fillRule="evenodd" clipRule="evenodd"
            d="M1.5 1.5H10.5C11.0523 1.5 11.5 1.94772 11.5 2.5V9.5C11.5 10.0523 11.0523 10.5 10.5 10.5H1.5C0.947715 10.5 0.5 10.0523 0.5 9.5V2.5C0.5 1.94772 0.947715 1.5 1.5 1.5ZM1.5 4.80852V9.5H10.5V4.8097L6.00096 7.05922L1.5 4.80852ZM1.5 3.69046L6.00098 5.94118L10.5 3.69167V2.5H1.5V3.69046Z"
            fill="#F4F5F5"/>
    </svg>
);

export default EmailIcon;