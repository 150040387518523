import {createAsyncThunk, createSelector, createSlice, PayloadAction} from "@reduxjs/toolkit";
import {AxiosResponse} from "axios";
import {RootState} from "store";

import createHttpRequest from "../utils/http";
import {ApiUrls} from "../constants/urls";
import {IncidentEventPriority} from "../models/IncidentEventPriority";
import {isValidJSON} from "../utils/common";

interface ICreateResponsibilityZonePayload {
    name: string,
    viewJson: string,
    geometry: {
        coordinates: [[number, number]],
        type: string,
    },
    userIds: number[],
    roadObjectIds: number[],
}

export interface ICreateResponsibilityZoneResponse {
    id: number,
}

export interface IResponsibilityZone {
    code: number,
    name: string | null,
    cameraCount: number | null,
    mapRoadObjectId: number | null,
    isDeleted?: boolean,
    viewJson: {[key: string]: string},
}

export interface IAdminResponsibilityZones {
    id: number,
    name: string | null,
    mapRoadObjectId: number | null,
    roadObjectIds: null | number[],
    additionalProperties: {
        responsibilityZoneId: number,
        viewJson: null | string,
    },
    incidentTypes: {
        incidentTypes: {
            [key: string]: {
                name: string,
                priority: number,
            },
        },
    },
}

export interface IUpdateResponsibilityZonePayload {
    id: number,
    data: {
        name: string,
        viewJson: string,
        geometry: {
            coordinates: [[number, number]] | null,
            type: string,
        },
        userIds: number[],
        roadObjectIds: number[],
    },
}

interface IResponsibilityZonesUpdatePrioritiesPayload {
    ids: number[],
    data: {
        incidentTypes: {
            [key: string]: {
                name?: string,
                priority: IncidentEventPriority | null | undefined,
            },
        },
    },
}

interface IInitialState {
    responsibilityZones: IResponsibilityZone[],
    adminResponsibilityZones: IAdminResponsibilityZones[],
    responsibilityZonesBgColorHashMap: Map<number, string | undefined> | null,
    isLoading: boolean,
    responsibilityZonesIsLoading: boolean,
    adminResponsibilityZonesIsLoading: boolean,
    responsibilityZonesIsDeleting: boolean,
    responsibilityZonesUpdatePrioritiesLoading: boolean,
}

const initialState: IInitialState = {
    responsibilityZones: [],
    responsibilityZonesBgColorHashMap: null,
    adminResponsibilityZones: [],
    adminResponsibilityZonesIsLoading: false,
    isLoading: false,
    responsibilityZonesIsLoading: false,
    responsibilityZonesIsDeleting: false,
    responsibilityZonesUpdatePrioritiesLoading: false,
};

export const fetchResponsibilityZones = createAsyncThunk<IResponsibilityZone[]>("responsibility/zones", async() => {
    const response = await createHttpRequest({
        method: "GET",
        path: ApiUrls.RESPONSIBILITY_ZONES,
        errorMessage: "messages:fetch_responsibility_zones_error",
    });

    return response.data;
});

export interface IFetchRespZoneByCoord {
    lon: number,
    lat: number,
}

export interface IFetchRespZoneByCoordResponse {
    "ZoneId": number,
    "Name": string,
}

export const fetchRespZoneByCoord = createAsyncThunk<IFetchRespZoneByCoordResponse, IFetchRespZoneByCoord>(
    "responsibility/zoneByCoord",
    async({lon, lat}) => {
        const response = await createHttpRequest({
            method: "GET",
            path: ApiUrls.RESPONSIBILITY_ZONE_BY_COORD({lat, lon}),
            errorMessage: "messages:fetch_responsibility_zones_error_by_coordinates",
        }) as AxiosResponse<IFetchRespZoneByCoordResponse>;

        return response.data;
    }
);

// Получение зон контроля (admin api)
// eslint-disable-next-line max-len
export const fetchAdminResponsibilityZones = createAsyncThunk<IAdminResponsibilityZones[]>("responsibility/fetchAdminResponsibilityZones",
    async() => {
        const response = await createHttpRequest({
            method: "GET",
            path: ApiUrls.ADMIN_RESPONSIBILITY_ZONES,
            errorMessage: "messages:fetch_responsibility_zones_error",
        });

        return response.data;
    });

// Создание зоны контроля (ответственности)
export const createResponsibilityZone =
    // eslint-disable-next-line max-len
    createAsyncThunk<ICreateResponsibilityZoneResponse, ICreateResponsibilityZonePayload>("roadObject/createResponsibilityZone",
        async(data) => {
            const response = await createHttpRequest({
                method: "POST",
                path: ApiUrls.RESPONSIBILITY_ZONES,
                data: data,
                errorMessage: "messages:create_responsibility_zone_error",
            });

            return response.data;
        });

// Получить данные одной зоны
export const getAdminResponsibilityZone =
    createAsyncThunk<IAdminResponsibilityZones, {id: number}>("responsibility/getAdminResponsibilityZone",
        async({id}) => {
            const response = await createHttpRequest({
                method: "GET",
                path: ApiUrls.ADMIN_RESPONSIBILITY_ZONE(id),
                errorMessage: "messages:get_responsibility_zone_error",
            });

            return response.data;
        });

// Обновить данные одной зоны
export const updateResponsibilityZone =
    createAsyncThunk<IResponsibilityZone, IUpdateResponsibilityZonePayload>("responsibility/updateResponsibilityZone",
        async({id, data}) => {

            const response = await createHttpRequest({
                method: "PUT",
                path: ApiUrls.RESPONSIBILITY_ZONE(id),
                data: data,
                errorMessage: "messages:update_responsibility_zone_error",
                headers: {
                    accept: "*/*",
                    "Content-Type": "application/json",
                },
            });

            return response.data;
        });

// Удалить данные одной зоны (мягкое удаление)
export const deleteResponsibilityZone =
    createAsyncThunk<IResponsibilityZone, {id: number}>("responsibility/deleteResponsibilityZone",
        async({id}) => {

            const response = await createHttpRequest({
                method: "DELETE",
                path: ApiUrls.RESPONSIBILITY_ZONE(id),
                errorMessage: "messages:delete_responsibility_zone_error",
            });

            return response.data;
        });

// Обновление приоритетов типов инцидентов у зон контроля
export const responsibilityZonesUpdatePriorities =
    // eslint-disable-next-line max-len
    createAsyncThunk<null, IResponsibilityZonesUpdatePrioritiesPayload>("responsibility/responsibilityZonesUpdatePriorities",
        async({ids, data}) => {
            const response = await createHttpRequest({
                method: "PUT",
                path: ApiUrls.RESPONSIBILITY_ZONES_UPDATE_PRIORITIES(ids),
                data: JSON.stringify(data),
                headers: {
                    accept: "*/*",
                    "Content-Type": "application/json",
                },
                errorMessage: "messages:update_incident_type_priority_error",
            });

            return response.data;
        });

const responsibility = createSlice({
    reducers: {
        setResponsibilityZonesBgColorHashMap: (
            state,
            {payload}: PayloadAction<any[]>
        ) => {
            if (state.responsibilityZonesBgColorHashMap?.size) return;

            const hashMap = new Map();

            payload.forEach(({code, viewJson}) => {
                if (viewJson) {
                    hashMap.set(code, viewJson?.bgColor);
                }
            });

            state.responsibilityZonesBgColorHashMap = hashMap;
        },
    },
    name: "responsibility",
    initialState,
    extraReducers: (builder) => {
        builder.addCase(fetchResponsibilityZones.pending, (state) => {
            state.responsibilityZones = [];
            state.responsibilityZonesIsLoading = true;
        });

        builder.addCase(fetchResponsibilityZones.fulfilled, (state, {payload}) => {
            state.responsibilityZones = payload;
            state.responsibilityZonesIsLoading = false;
        });

        builder.addCase(fetchResponsibilityZones.rejected, (state) => {
            state.responsibilityZones = [];
            state.responsibilityZonesIsLoading = false;
        });

        // Получение зон контроля (admin api)
        builder.addCase(fetchAdminResponsibilityZones.pending, (state) => {
            state.adminResponsibilityZonesIsLoading = true;
        });

        builder.addCase(fetchAdminResponsibilityZones.fulfilled, (state, {payload}) => {
            state.adminResponsibilityZones = payload;
            state.adminResponsibilityZonesIsLoading = false;
        });

        builder.addCase(fetchAdminResponsibilityZones.rejected, (state) => {
            state.adminResponsibilityZonesIsLoading = false;
        });

        // Удаление зоны контроля
        builder.addCase(deleteResponsibilityZone.pending, (state) => {
            state.responsibilityZonesIsDeleting = true;
        });
        builder.addCase(deleteResponsibilityZone.fulfilled, (state) => {
            state.responsibilityZonesIsDeleting = false;
        });
        builder.addCase(deleteResponsibilityZone.rejected, (state) => {
            state.responsibilityZonesIsDeleting = false;
        });

        // Обновление приоритетов типов инцидентов у зон контроля
        builder.addCase(responsibilityZonesUpdatePriorities.pending, (state) => {
            state.responsibilityZonesUpdatePrioritiesLoading = true;
        });
        builder.addCase(responsibilityZonesUpdatePriorities.fulfilled, (state) => {
            state.responsibilityZonesUpdatePrioritiesLoading = false;
        });
        builder.addCase(responsibilityZonesUpdatePriorities.rejected, (state) => {
            state.responsibilityZonesUpdatePrioritiesLoading = false;
        });
    },
});

export default responsibility.reducer;

export const {
    setResponsibilityZonesBgColorHashMap,
} = responsibility.actions;

// Селекторы
const slice = ({responsibilityReducer}: RootState) => responsibilityReducer;

// Зоны контроля (отфильтровываем удаленные зоны)
export const responsibilityZonesSelector = createSelector(
    slice,
    ({responsibilityZones}) => {
        // Фильтруем зоны, которые удалялись по старому api
        if (responsibilityZones.length) {
            return responsibilityZones.filter((zone: IResponsibilityZone) => {
                // Зона должна быть не скрыта и не удалена
                return !(Object.prototype.hasOwnProperty.call(zone?.viewJson || {}, "visible")
                    && !zone.viewJson?.["visible"]) && !zone.viewJson?.["isDeleted"];
            });
        }
        return responsibilityZones;
    }
);

// Зоны контроля (Admin api)
export const adminResponsibilityZonesSelector = createSelector(
    slice,
    ({adminResponsibilityZones}) => {
        // Фильтруем зоны, которые удалялись по старому api (isDeleted во viewJson)
        if (adminResponsibilityZones.length) {
            return adminResponsibilityZones.filter((zone: IAdminResponsibilityZones) => {
                if (zone.additionalProperties?.viewJson && isValidJSON(zone.additionalProperties?.viewJson)) {
                    const viewJson = JSON.parse(zone.additionalProperties?.viewJson);
                    // Зона должна быть не скрыта и не удалена
                    return !viewJson?.["isDeleted"]
                        && !(Object.prototype.hasOwnProperty.call(viewJson, "visible")
                            && !viewJson?.["visible"]);
                }
            });
        }
        return adminResponsibilityZones;
    }
);

export const responsibilityZonesIsLoadingSelector = createSelector(
    slice,
    ({responsibilityZonesIsLoading}) => responsibilityZonesIsLoading
);

export const adminResponsibilityZonesIsLoadingSelector = createSelector(
    slice,
    ({adminResponsibilityZonesIsLoading}) => adminResponsibilityZonesIsLoading
);

export const responsibilityZonesIsDeletingSelector = createSelector(
    slice,
    ({responsibilityZonesIsDeleting}) => responsibilityZonesIsDeleting
);

export const responsibilityZonesUpdatePrioritiesLoadingSelector = createSelector(
    slice,
    ({responsibilityZonesUpdatePrioritiesLoading}) => responsibilityZonesUpdatePrioritiesLoading
);

export const responsibilityZonesBgColorHashMapSelector = createSelector(
    slice,
    ({responsibilityZonesBgColorHashMap}) => responsibilityZonesBgColorHashMap
);