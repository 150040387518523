/* eslint-disable max-len */
import React from "react";

const RejectIcon: React.FC<object> = () => {
    return (
        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M8 1H16C19.866 1 23 4.13401 23 8V16C23 19.866 19.866 23 16 23H8C4.13401 23 1 19.866 1 16V8C1 4.13401 4.13401 1 8 1Z" fill="#FC5A5A"/>
            <path fillRule="evenodd" clipRule="evenodd" d="M10.4142 11.8284L8.29289 9.70711C7.90237 9.31658 7.90237 8.68342 8.29289 8.29289C8.68342 7.90237 9.31658 7.90237 9.70711 8.29289L11.8284 10.4142L13.9497 8.29289C14.3403 7.90237 14.9734 7.90237 15.364 8.29289C15.7545 8.68342 15.7545 9.31658 15.364 9.70711L13.2426 11.8284L15.364 13.9497C15.7545 14.3403 15.7545 14.9734 15.364 15.364C14.9734 15.7545 14.3403 15.7545 13.9497 15.364L11.8284 13.2426L9.70711 15.364C9.31658 15.7545 8.68342 15.7545 8.29289 15.364C7.90237 14.9734 7.90237 14.3403 8.29289 13.9497L10.4142 11.8284Z" fill="white"/>
        </svg>
    );
};

export default RejectIcon;