import {combineReducers} from "@reduxjs/toolkit";

import localeReducer from "./localeSlice";
import settingsReducer from "./settingsSlice";
import userSettingsReducer from "./userSettingsSlice";
import authReducer from "./authSlice";
import incidentTypeReducer from "./incidentTypeSlice";
import responsibilityReducer from "./responsibilitySlice";
import responseServiceReducer from "./responseServiceSlice";
import publicTransportReducer from "./publicTransportSlice";
import eventReducer from "./eventSlice";
import fixationReducer from "./fixationSlice";
import roadObjectReducer from "./roadObjectSlice";
import indicatorsReducer from "./indicatorsSlice";
import violationReducer from "./violationSlice";
import mediaReducer from "./mediaSlice";
import commonReducer from "./commonSlice";
import incidentsJournalReducer from "./journalSlice";
import superadminReducer from "./superadminSlice";
import organizationsSettingsReducer from "./organizationsSettingsSlice";
import efficiencyJournal from "./efficiencyJournalSlice";
import dashboardReducer from "./dashboardSlice";
import dashboardSecondScreenReducer from "./dashboardSecondScreenSlice";
import tagsReducer from "./tagsSlice";
import commentsReducer from "./commentsSlice";
import zoneOperatorsReducer from "./zoneOperatorsSlice";
import prioritiesReducer from "./prioritiesSlice";
import disablingCameraReducer from "./disablingCameraIncidentsSlice";
import favoriteFoldersReducer from "./favoiteFoldersSlice";
import archiveSwitcherReducer from "./archiveSwitcherSlice";
import analyticsReducer from "./analyticsSlice";
import proTvReducer from "./proTvSlice";
import eventSharedReducer from "./eventSharedSlice";
import toiReducer from "./toiSlice";
import mapRoutingReducer from "./mapRoutingSlice";

export default combineReducers({
    localeReducer,
    settingsReducer,
    userSettingsReducer,
    authReducer,
    incidentTypeReducer,
    responsibilityReducer,
    responseServiceReducer,
    publicTransportReducer,
    eventReducer,
    fixationReducer,
    roadObjectReducer,
    indicatorsReducer,
    violationReducer,
    mediaReducer,
    commonReducer,
    incidentsJournalReducer,
    superadminReducer,
    efficiencyJournal,
    dashboardReducer,
    organizationsSettingsReducer,
    dashboardSecondScreenReducer,
    tagsReducer,
    commentsReducer,
    zoneOperatorsReducer,
    prioritiesReducer,
    disablingCameraReducer,
    favoriteFoldersReducer,
    archiveSwitcherReducer,
    analyticsReducer,
    proTvReducer,
    eventSharedReducer,
    toiReducer,
    mapRoutingReducer,
});