/* eslint-disable max-len */
import React, {FC} from "react";

//import icon from "../../images/icons/check-in-green-rounded-box.svg";

import {ISvgProps} from "../../types/common";

interface ICheckInRoundedBoxProps extends ISvgProps {
    boxColor?: string,
}

const CheckInRoundedBox: FC<ICheckInRoundedBoxProps> = ({
    width = 24,
    height = 25,
    boxColor = "#3DD598",
}) => {
    return (
        <svg width={width} height={height} viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fillRule="evenodd" clipRule="evenodd" d="M3.26172 3.76172C4.58232 2.44112 6.37343 1.69922 8.24103 1.69922H16.1574C18.025 1.69922 19.8161 2.44112 21.1367 3.76172C22.4573 5.08232 23.1992 6.87343 23.1992 8.74103V16.6574C23.1992 18.525 22.4573 20.3161 21.1367 21.6367C19.8161 22.9573 18.025 23.6992 16.1574 23.6992H8.24103C6.37343 23.6992 4.58232 22.9573 3.26172 21.6367C1.94112 20.3161 1.19922 18.525 1.19922 16.6574V8.74103C1.19922 6.87343 1.94112 5.08232 3.26172 3.76172Z" fill={boxColor}/>
            <path d="M8.27875 11.3127C7.75267 10.7806 6.90522 10.7862 6.38592 11.3253C5.86661 11.8643 5.8721 12.7326 6.39817 13.2647L9.79914 16.7046C10.3587 17.2705 11.2714 17.2228 11.7718 16.6014L17.2939 9.74431C17.7644 9.1601 17.6836 8.29571 17.1134 7.81366C16.5432 7.3316 15.6996 7.41441 15.2292 7.99862L10.6383 13.6993L8.27875 11.3127Z" fill="currentColor"/>
        </svg>
    );
};

export default CheckInRoundedBox;