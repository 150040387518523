/* eslint-disable max-len */
import React, {FC} from "react";

import {ISvgProps} from "../../types/common";

const MessageIcon:FC<ISvgProps> = ({
    height = 33,
    width = 33,
    ...rest
}) => (
    <svg {...rest} width={width} height={height} viewBox="0 0 33 33" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path fillRule="evenodd" clipRule="evenodd"
            d="M29.5447 31.1346C30.4974 31.3463 31.3473 30.4964 31.1356 29.5438L29.6721 22.9582C30.6504 20.9668 31.1673 18.7692 31.1673 16.4997C31.1673 8.3995 24.6008 1.83301 16.5007 1.83301C8.40047 1.83301 1.83398 8.3995 1.83398 16.4997C1.83398 24.5999 8.40047 31.1663 16.5007 31.1663C18.7702 31.1663 20.9678 30.6494 22.9592 29.6711L29.5447 31.1346ZM27.0905 22.1494C26.9401 22.4305 26.8955 22.7562 26.9646 23.0674L28.0779 28.0769L23.0683 26.9637C22.7571 26.8945 22.4315 26.9392 22.1504 27.0895C20.4272 28.0109 18.5006 28.4997 16.5007 28.4997C9.87323 28.4997 4.50065 23.1271 4.50065 16.4997C4.50065 9.87226 9.87323 4.49967 16.5007 4.49967C23.1281 4.49967 28.5007 9.87226 28.5007 16.4997C28.5007 18.4997 28.0119 20.4262 27.0905 22.1494Z"
            fill="currentColor"/>
        <path fillRule="evenodd" clipRule="evenodd"
            d="M12.5007 19.1663C13.9734 19.1663 15.1673 17.9724 15.1673 16.4997C15.1673 15.0269 13.9734 13.833 12.5007 13.833C11.0279 13.833 9.83398 15.0269 9.83398 16.4997C9.83398 17.9724 11.0279 19.1663 12.5007 19.1663Z"
            fill="currentColor"/>
        <path fillRule="evenodd" clipRule="evenodd"
            d="M20.5007 19.1663C21.9734 19.1663 23.1673 17.9724 23.1673 16.4997C23.1673 15.0269 21.9734 13.833 20.5007 13.833C19.0279 13.833 17.834 15.0269 17.834 16.4997C17.834 17.9724 19.0279 19.1663 20.5007 19.1663Z"
            fill="currentColor"/>
    </svg>
);

export default MessageIcon;