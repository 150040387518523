/* eslint-disable max-len */
import React, {FC} from "react";

import {ISvgProps} from "../../types/common";

const PinRoundIcon: FC<ISvgProps> = (
    {
        width = 16,
        height = 16,
        ...rest
    }
) => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" {...rest} width={width} height={height} viewBox="0 0 17 16" fill="none">
            <path fillRule="evenodd" clipRule="evenodd"
                d="M9.0215 12.1709L8.29069 12.8091L7.55988 12.1709C4.94964 9.89172 3.62402 7.87479 3.62402 6.00065C3.62402 3.34826 5.69302 1.33398 8.29069 1.33398C10.8884 1.33398 12.9574 3.34826 12.9574 6.00065C12.9574 7.87479 11.6317 9.89172 9.0215 12.1709ZM4.08106 9.86914C4.35478 10.2439 4.6597 10.6251 4.99567 11.0128C3.75419 11.2925 2.95736 11.6893 2.95736 12.0002C2.95736 12.5382 5.34345 13.3335 8.29069 13.3335C11.2379 13.3335 13.624 12.5382 13.624 12.0002C13.624 11.6893 12.8272 11.2925 11.5857 11.0128C11.9217 10.6251 12.2266 10.2439 12.5003 9.86914C13.9995 10.3241 14.9574 11.0538 14.9574 12.0002C14.9574 13.6714 11.9709 14.6669 8.29069 14.6669C4.61051 14.6669 1.62402 13.6714 1.62402 12.0002C1.62402 11.0538 2.58185 10.3241 4.08106 9.86914ZM8.29069 2.66732C10.1599 2.66732 11.624 4.09276 11.624 6.00065C11.624 7.36529 10.5391 9.04458 8.29069 11.038C6.04224 9.04458 4.95736 7.36529 4.95736 6.00065C4.95736 4.09276 6.42152 2.66732 8.29069 2.66732ZM8.29069 4.00065C9.39526 4.00065 10.2907 4.89608 10.2907 6.00065C10.2907 7.10522 9.39526 8.00065 8.29069 8.00065C7.18612 8.00065 6.29069 7.10522 6.29069 6.00065C6.29069 4.89608 7.18612 4.00065 8.29069 4.00065ZM7.62402 6.00065C7.62402 5.63246 7.9225 5.33398 8.29069 5.33398C8.65888 5.33398 8.95736 5.63246 8.95736 6.00065C8.95736 6.36884 8.65888 6.66732 8.29069 6.66732C7.9225 6.66732 7.62402 6.36884 7.62402 6.00065Z"
                fill="currentColor"/>
        </svg>
    );
};

export default PinRoundIcon;