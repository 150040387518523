import React from "react";
import moment from "moment";

import {isDev} from "./evironment";
import {
    AnalyticsFilterKeys,
    DataFilterValuesEnum,
    DefaultAnalyticsFiltersPartialType,
    DefaultAnalyticsFiltersPinsType,
    IAEventsAndIncidentsOriginalAndCompare,
    ITimeFilterValue,
} from "../models/Analytics";
import {
    IInheritedCheckboxFilterProps,
} from "../components/AnalyticsPage/AnalyticsFilters/Filters/FiltersWithCheckbox/CheckboxFilter";
import {ITabItem} from "../components/UI/TabsUi/TabsUi";

import LineChartIcon from "../components/Icons/LineChartIcon";
import BarChartIcon from "../components/Icons/BarChartIcon";
import LeadIcon from "../components/Icons/LeadIcon";
import TimeChartIcon from "../components/Icons/TimeChartIcon";
import DashboardIcon from "../components/Icons/DashboardIcon";
import PinRoundIcon from "../components/Icons/PinRoundIcon";
import TimeIcon from "../components/Icons/TimeIcon";

import styles from "../components/AnalyticsPage/Widgets/IncidentsByTimeWidget/IncidentsByTimeWidget.module.scss";

export interface ICreateCheckboxFilter extends Omit<IInheritedCheckboxFilterProps,
    "selectedValues" | "isPinned" | "maxTagItemsInField" | "tagsInField"
> {
    filterPins: DefaultAnalyticsFiltersPinsType | undefined,
    currentFilterValues: DefaultAnalyticsFiltersPartialType,
    Component: React.ComponentType<IInheritedCheckboxFilterProps>,
}

export interface IReadyFilter {
    filterKey: AnalyticsFilterKeys,
    pinned: boolean,
    children: JSX.Element,
}

export const createCheckboxFilter = ({
    currentFilterValues,
    filterKey,
    filterType,
    filterPins,
    Component,
    withSearch = false,
    childrenBeforeDropdownItems,
}: ICreateCheckboxFilter): IReadyFilter | undefined => {

    const currentFilterValue = currentFilterValues[filterKey];

    if (currentFilterValue === undefined) {
        return undefined;
    }

    if (!Array.isArray(currentFilterValue.value)) {
        // eslint-disable-next-line no-console
        isDev() && console.log(`${currentFilterValue.value} is not Array`);
        return undefined;
    }

    return {
        filterKey: filterKey,
        pinned: filterPins?.[filterKey] ?? false,
        children: (
            <Component
                filterType={filterType}
                selectedValues={currentFilterValue.value as (number | string)[]}
                isPinned={filterPins?.[filterKey] ?? false}
                filterKey={filterKey}
                withSearch={withSearch}
                childrenBeforeDropdownItems={childrenBeforeDropdownItems}
            />
        ),
    };
};

export interface ICalendarPreset {
    title: string,
    from: string,
    to: string,
    default?: boolean,
}

// календарь
export const defaultAnalyticsFromTime = "00:00";
export const defaultAnalyticsToTime = "00:00";

export const dateFormatAnalytics = "DD-MM-YYYY";

export const nowDateAnalytics = moment().format(dateFormatAnalytics);

export const calendarPresets: ICalendarPreset[] = [
    {
        title: "За неделю",
        from: moment().subtract(6, "days").format(dateFormatAnalytics),
        to: nowDateAnalytics,
        default: true,
    },
    {
        title: "C начала недели",
        from: moment().startOf("isoWeek").format(dateFormatAnalytics),
        to: nowDateAnalytics,
    },
    {
        title: "За месяц",
        from: moment().subtract(29, "days").format(dateFormatAnalytics),
        to: nowDateAnalytics,
    },
    {
        title: "С начала месяца",
        from: moment().startOf("month").format(dateFormatAnalytics),
        to: nowDateAnalytics,
    },
    {
        title: "За квартал",
        from: moment().subtract(3, "months").add(1, "day").format(dateFormatAnalytics),
        to: nowDateAnalytics,
    },
    {
        title: "За год",
        from: moment().subtract(1, "year").add(1, "day").format(dateFormatAnalytics),
        to: nowDateAnalytics,
    },
];

export const createdDateTimeFilterDefaultValue = () => {
    const foundDefaultDate = calendarPresets.find(item => item.default);

    const readyDate: ITimeFilterValue = foundDefaultDate
        ? {
            from: foundDefaultDate.from,
            to: foundDefaultDate.to,
        }
        : {
            from: moment().subtract(1, "week").format(dateFormatAnalytics),
            to: moment().format(dateFormatAnalytics),
        };

    return readyDate;
};

interface IChartTypesProps {
    withText: boolean,
    tabClassName: string,
    textClassName: string,
}

export type ChartType = "bar" | "line" | "week";
export const chartTypes = ({
    withText,
    tabClassName,
    textClassName,
}: IChartTypesProps): ITabItem<ChartType>[] => [
    {
        value: "line",
        children: (
            <div className={tabClassName}>
                <LineChartIcon width={16} height={16}/>
                {withText && <div className={textClassName}>Динамика</div>}
            </div>
        ),
    },
    {
        value: "bar",
        children: (
            <div className={styles["tab"]}>
                <BarChartIcon width={16} height={16}/>
                {withText && <div className={textClassName}>Столбики</div>}
            </div>
        ),
    },
    {
        value: "week",
        children: (
            <div className={styles["tab"]}>
                <TimeChartIcon width={16} height={16}/>
                {withText && <div className={textClassName}>По часам</div>}
            </div>
        ),
    },
];

interface IShownDataTypeProps extends IChartTypesProps {
    evOrInc: DataFilterValuesEnum,
    // eslint-disable-next-line no-unused-vars
    onClick: (value: ShownDataType) => void,
}

export type ShownDataType = keyof Pick<IAEventsAndIncidentsOriginalAndCompare,
    "incidentCount" | "injuredCount" | "deadCount">;
export const shownDataTypes = ({
    withText,
    tabClassName,
    textClassName,
    evOrInc = DataFilterValuesEnum.incidents,
    onClick,
}: IShownDataTypeProps): ITabItem<ShownDataType>[] => [
    {
        value: "incidentCount",
        children: (
            <div className={tabClassName} onClick={() => onClick("incidentCount")}>
                {withText && (
                    <div className={textClassName}>
                        {
                            evOrInc === DataFilterValuesEnum.incidents
                                ? "Инциденты"
                                : "События"
                        }
                    </div>
                )}
            </div>
        ),
    },
    {
        value: "injuredCount",
        children: (
            <div className={styles["tab"]} onClick={() => onClick("injuredCount")}>
                <DashboardIcon width={16} height={16}/>
                {withText && <div className={textClassName}>Ранено</div>}
            </div>
        ),
    },
    {
        value: "deadCount",
        children: (
            <div className={styles["tab"]} onClick={() => onClick("deadCount")}>
                <LeadIcon width={16} height={16}/>
                {withText && <div className={textClassName}>Погибло</div>}
            </div>
        ),
    },
];

export type WidgetType = "map" | "chart";
export const widgetTypes = ({
    withText,
    tabClassName,
    textClassName,
}: IChartTypesProps): ITabItem<WidgetType>[] => [
    {
        value: "chart",
        children: (
            <div className={tabClassName}>
                <TimeIcon width={16} height={16}/>
                {withText && <div className={textClassName}>Во времени</div>}

            </div>
        ),
    },
    {
        value: "map",
        children: (
            <div className={styles["tab"]}>
                <PinRoundIcon width={16} height={16}/>
                {withText && <div className={textClassName}>На карте</div>}
            </div>
        ),
    },

];