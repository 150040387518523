/* eslint-disable max-len */
import React from "react";

import {ISvgProps} from "../../types/common";

//import icon from "images/icons/arrow-top.svg";

const ArrowTopRightIcon: React.FC<ISvgProps> = ({
    width = 20,
    height = 20,
    ...rest
}) => (
    <svg {...rest} width={width} height={height} viewBox="0 0 12 12" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
        <path fillRule="evenodd" clipRule="evenodd"
            d="M9.50004 3.20711L2.85359 9.85355L2.14648 9.14645L8.79293 2.5H5.00004V1.5H10.5V7H9.50004V3.20711Z"
            fill="#F9FAFA"/>
    </svg>
);

export default ArrowTopRightIcon;