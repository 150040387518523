import {createAsyncThunk, createSelector, createSlice, PayloadAction} from "@reduxjs/toolkit";

import {RootState} from "store";
import createHttpRequest from "../utils/http";
import {ApiUrls} from "../constants/urls";
import {IEventsFilters} from "./eventSlice";
import {SignalRConfig} from "../models/SignalRConfig";
import {gridLayoutScheme} from "../models/adaptiveGridLayout";

export interface IMapParams {
    mapCenter: [number, number],
    zoomInit: number,
}

/* eslint-disable no-unused-vars */
export enum RTSPtoWebProtocol {
    hls = "hls",
    mse = "mse",
    webrtc = "webrtc"
}

export interface IAppConfigDataDashboardFilters {
    priority?: string[],
    type?: string[],
}

export interface IAppConfigData {
    url: string,
    signalRConfig: SignalRConfig,
    videoStream: {
        webSocket: {
            enabled: boolean,
            url: string,
        },
        webRTC: {
            enabled: boolean,
            url: string,
        },
        RTSPtoWeb: {
            enabled: boolean,
            url: string,
            defaultProtocol: RTSPtoWebProtocol,
        },
    },
    defaultDashboardFilters: IAppConfigDataDashboardFilters,
    defaultEventFilters?: IEventsFilters,
    eventFocusDisable?: boolean,
    useMnemonicMap?: number,
}

export type Theme = "light" | "dark";

interface IInitialState {
    theme: Theme,
    isSidebarShow: boolean,
    isHeaderCollapsed: boolean,
    settings: {
        dashboard: {
            fullMap: boolean | string,
        },
        secondScreen: {
            displayMode: "one" | "two",
            layout: {
                [key: string]: {
                    component: string | null,
                    fullHeight: boolean,
                    hidden: boolean,
                },
            },
        },
        layout: {
            [key: string]: {
                component: string | null,
                fullHeight: boolean,
                hidden: boolean,
            },
        },
        gridLayout: gridLayoutScheme,
        armGridLayout: gridLayoutScheme,
    },
    signalRSettings: {
        connectionSuccess: boolean,
        isDisconnected: boolean,
    },
    environments: IAppConfigData | null,
    isNewIncidentAdding?: boolean,
    newIncidentAddingFile: File | null,
    isNewIncidentAddingCameraMode: boolean, // Создание инцидента вручную. Режим добавления камеры
    isStreamOpen: boolean,
    mapParams: IMapParams | null,
}

const initialState: IInitialState = {
    theme: localStorage.getItem("theme") as Theme || "dark",
    isSidebarShow: false,
    isHeaderCollapsed: false,
    settings: {
        dashboard: {
            fullMap: false,
        },
        secondScreen: {
            displayMode: "one",
            layout: {
                lastIncidents: {
                    component: "IncidentsInProcessing",
                    fullHeight: true,
                    hidden: false,
                },
            },
        },
        layout: {
            topLeft: {
                component: "TopIncidentsPerDay",
                fullHeight: false,
                hidden: false,
            },
            middleLeft: {
                component: "OperatorsOnline",
                fullHeight: false,
                hidden: false,
            },
            bottomLeft: {
                component: "IncidentsSystemInfo",
                fullHeight: false,
                hidden: false,
            },
            topRight: {
                component: "IncidentsOnControl",
                fullHeight: false,
                hidden: false,
            },
            /*middleRight: {
                component: "IncidentsSystemInfo",
                fullHeight: false,
                hidden: false,
            },*/
            bottomRight: {
                component: "CurrentEventStream",
                fullHeight: false,
                hidden: false,
            },
        },
        gridLayout: {
            TopIncidentsPerDay: {
                fullHeight: false,
                hidden: false,
                isLastColumn: false,
            },
            OperatorsOnline: {
                fullHeight: false,
                hidden: false,
                isLastColumn: false,
            },
            IncidentsSystemInfo: {
                fullHeight: false,
                hidden: false,
                isLastColumn: false,
            },
            IncidentsOnControl: {
                fullHeight: false,
                hidden: false,
                isLastColumn: true,
            },
            // CurrentEventStream: {
            //     fullHeight: false,
            //     hidden: false,
            //     isLastColumn: false,
            // },
        },
        armGridLayout: {
            IncidentsOnControl: {
                fullHeight: false,
                hidden: false,
                isLastColumn: false,
            },
            IncidentsProTv: {
                fullHeight: false,
                hidden: false,
                isLastColumn: false,
            },
            ProTvPreview: {
                fullHeight: false,
                hidden: false,
                isLastColumn: true,
            },
        },
    },
    signalRSettings: {
        connectionSuccess: false,
        isDisconnected: false,
    },
    environments: null,
    isStreamOpen: true,
    isNewIncidentAdding: false,
    newIncidentAddingFile: null,
    isNewIncidentAddingCameraMode: false,
    mapParams: null,
};

export const fetchEnvironment =
    createAsyncThunk<IAppConfigData>("fetchEnvironment", async() => {
        const response = await createHttpRequest({
            method: "GET",
            baseURL: `${process.env.REACT_APP_BASE_HOST}`,
            path: ApiUrls.ENVIRONMENT,
            errorMessage: "messages:fetch_environments_error",
        });

        return response.data;
    });

const common = createSlice({
    reducers: {
        setNewIncidentAddingFile(state, action: PayloadAction<File | null>) {
            state.newIncidentAddingFile = action.payload;
        },
        setIsHeaderCollapsed(state, {payload}) {
            state.isHeaderCollapsed = payload;
        },
        toggleTheme(state, {payload}) {
            state.theme = payload;
        },
        toggleSidebarShow(state) {
            state.isSidebarShow = !state.isSidebarShow;
        },
        setSidebarShow(state, {payload}) {
            state.isSidebarShow = payload;
        },
        setDashboardQueryParams(state, {payload}) {
            const dashboard = state.settings.dashboard;

            Object.keys(payload).forEach((key) => {
                dashboard[key as keyof typeof dashboard] = payload[key];
            });
        },
        setSecondScreenParams(state, {payload}) {
            const dashboard = state.settings.secondScreen;

            Object.keys(payload).forEach((key) => {
                dashboard[key as keyof typeof dashboard] = payload[key];
            });
        },
        changeLayout(state, {payload}) {
            const layout = state.settings.layout;

            Object.keys(payload).forEach((key: keyof typeof layout) => {
                layout[key] = {...layout[key], ...payload[key]};
            });
        },
        changeGridLayout(state, {payload}) {
            const layout = state.settings.gridLayout;

            Object.keys(payload).forEach((key: keyof typeof layout) => {
                layout[key] = {...layout[key], ...payload[key]};
            });
        },
        changeArmGridLayout(state, {payload}) {
            const layout = state.settings.armGridLayout;

            Object.keys(payload).forEach((key: keyof typeof layout) => {
                layout[key] = {...layout[key], ...payload[key]};
            });
        },
        setSignalRSettings(state, {payload}) {
            state.signalRSettings = {
                ...state.signalRSettings,
                ...payload,
            };
        },
        toggleIsNewIncidentAdding(state, {payload}) {
            state.isNewIncidentAdding = payload;
        },
        setMapCenter(state, {payload}) {
            state.mapParams = payload;
        },
        resetMapCenter(state) {
            state.mapParams = null;
        },
        setIsNewIncidentAddingCameraMode(state, {payload}) {
            state.isNewIncidentAddingCameraMode = payload;
        },
    },
    name: "common",
    initialState,
    extraReducers: (builder) => {
        builder.addCase(fetchEnvironment.fulfilled, (state, {payload}) => {
            state.environments = payload;
        });
    },
});

// Экшены
export const {
    toggleTheme,
    toggleSidebarShow,
    setSidebarShow,
    setDashboardQueryParams,
    changeLayout,
    changeGridLayout,
    changeArmGridLayout,
    setSignalRSettings,
    setIsHeaderCollapsed,
    setSecondScreenParams,
    toggleIsNewIncidentAdding,
    setMapCenter,
    resetMapCenter,
    setNewIncidentAddingFile,
    setIsNewIncidentAddingCameraMode,
} = common.actions;

export default common.reducer;

//Селекторы
const slice = ({commonReducer}: RootState) => commonReducer;

export const themeSelector = createSelector(
    slice,
    ({theme}) => theme
);

export const isSidebarShowSelector = createSelector(
    slice,
    ({isSidebarShow}) => isSidebarShow
);

export const dashboardSettingsSelector = createSelector(
    slice,
    ({settings}) => settings.dashboard
);

export const fullMapSelector = createSelector(
    slice,
    ({settings}) => settings.dashboard.fullMap
);

export const layoutSelector = createSelector(
    slice,
    ({settings}) => settings.layout
);

export const gridLayoutSelector = createSelector(
    slice,
    ({settings}) => settings.gridLayout
);
export const armGridLayoutSelector = createSelector(
    slice,
    ({settings}) => settings.armGridLayout
);
export const signalRSettingsSelector = createSelector(
    slice,
    ({signalRSettings}) => signalRSettings
);

export const isHeaderCollapsedSelector = createSelector(
    slice,
    ({isHeaderCollapsed}) => isHeaderCollapsed
);

export const secondScreenSelector = createSelector(
    slice,
    ({settings}) => settings.secondScreen
);

export const isNewIncidentAddingSelector = createSelector(
    slice,
    ({isNewIncidentAdding}) => isNewIncidentAdding
);

export const mapParamsSelector = createSelector(
    slice,
    ({mapParams}) => mapParams
);

export const displayModeSelector = createSelector(
    slice,
    ({settings}) => settings.secondScreen.displayMode
);

export const newIncidentAddingFileSelector = createSelector(
    slice,
    ({newIncidentAddingFile}) => newIncidentAddingFile
);

export const isNewIncidentAddingCameraModeSelector = createSelector(
    slice,
    ({isNewIncidentAddingCameraMode}) => isNewIncidentAddingCameraMode
);