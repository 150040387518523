import IncidentEventInfoModel from "../models/IncidentEventInfoModel";
import {IncidentsOnControlModel} from "../models/incidentsOnControlModel";
import {mediaType} from "../types";

export const wordDeclension = (number: number, txt: string[], cases = [2, 0, 1, 1, 1, 2]) => (
    txt[(number % 100 > 4 && number % 100 < 20) ? 2 : cases[(number % 10 < 5) ? number % 10 : 5]]
);

export const getMediaType = (incident: IncidentEventInfoModel | IncidentsOnControlModel | null): mediaType =>
    incident?.fixation === null ? "incident" : "fixation";

export const getLocalStorageValue = <T = string>(value: string): T | null => {
    const item = localStorage.getItem(value);
    return item ? (item as unknown as T) : null;
};

export const setLocalStorageValue = (key: string, value: string) => localStorage.setItem(key, value);

export const isPrimitive = <T>(data: T) => {
    return data !== Object(data);
};

/**
 * Очистка localStorage за исключением передаваемых полей
 * @param arrayValues - Массив исключений
 */
export const clearLocalStorageWithExceptions = (arrayValues: string[]) => {
    Object.keys(localStorage).forEach(key => {
        if (!arrayValues.includes(key)) localStorage.removeItem(key);
    });
};

/**
 * Проверка JSON на валидность
 * @param obj
 */
export const isValidJSON = (obj: string): boolean => {
    try {
        JSON.parse(obj);
        return true;
    } catch (e) {
        return false;
    }
};

// Работает с объектами, массивами
export function getObjectFromLocalStorage<T>(key: string): T | null {
    const value = localStorage.getItem(key);
    if (value) {
        try {
            return JSON.parse(value) as T;
        } catch (error) {
            return value as T;
        }
    }
    return null;
}