/* eslint-disable max-len */
import React from "react";

import {ISvgProps} from "../../types/common";

const PhoneIcon: React.FC<ISvgProps> = ({
    width = 12,
    height = 12,
    ...rest
}) => (
    <svg xmlns="http://www.w3.org/2000/svg" {...rest} width={width} height={height} viewBox="0 0 12 12" fill="none">
        <path fillRule="evenodd" clipRule="evenodd"
            d="M5.42874 4.16944C5.6878 3.82033 5.78069 3.45241 5.48771 3.12764C4.83023 2.20765 4.38806 1.63873 4.11071 1.36476C3.58343 0.84389 2.71605 0.914346 2.25938 1.36431C2.01411 1.60599 1.93107 1.68899 1.67922 1.94479C0.276396 3.34837 1.13191 6.31549 3.40618 8.592C5.67994 10.868 8.64677 11.724 10.0525 10.3174C10.2849 10.0934 10.4817 9.8965 10.6369 9.73248C11.0844 9.25947 11.1523 8.43034 10.6339 7.8917C10.3682 7.61554 9.82565 7.19472 8.86695 6.50896C8.57333 6.2464 8.22519 6.30331 7.90601 6.51271C7.75247 6.61344 7.64074 6.71533 7.42976 6.92652L7.04664 7.30985C6.99618 7.36034 6.31098 7.01719 5.64584 6.3514C4.98035 5.68525 4.63744 5.00002 4.68766 4.9498L5.07105 4.5662C5.13799 4.4992 5.16999 4.46676 5.21103 4.42362C5.29653 4.33376 5.36733 4.25221 5.42874 4.16944ZM7.75238 8.01686L8.13558 7.63345C8.25169 7.51723 8.32695 7.44576 8.38816 7.39649C9.22799 7.99959 9.71438 8.37882 9.91304 8.58524C10.0323 8.70918 10.0138 8.93532 9.9101 9.04492C9.7666 9.19659 9.58017 9.38312 9.35139 9.60379C8.44236 10.5132 6.04746 9.82223 4.11208 7.88495C2.17609 5.94705 1.48546 3.55179 2.38761 2.64914C2.63844 2.39438 2.71791 2.31495 2.95969 2.07672C3.05034 1.9874 3.29727 1.96734 3.40755 2.07628C3.62108 2.28721 4.01718 2.79439 4.59987 3.60606C4.5697 3.64351 4.53203 3.68583 4.48618 3.73402C4.45253 3.76938 4.42449 3.7978 4.3632 3.85915L3.98019 4.24237C3.32862 4.89389 3.88352 6.00275 4.938 7.05826C5.99175 8.11305 7.10103 8.66858 7.75238 8.01686Z"
            fill="#F4F5F5"/>
    </svg>
);

export default PhoneIcon;