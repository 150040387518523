import React, {FC, useState} from "react";
import {connect, useDispatch} from "react-redux";
import cn from "classnames";
import {createStructuredSelector} from "reselect";
import {SubmitHandler, useForm} from "react-hook-form";
import {useTranslation} from "react-i18next";
import {useNavigate, useSearchParams} from "react-router-dom";

import {authLoadingSelector, loginUser} from "../../redux/authSlice";
import {redirectToDashboard, redirectToReturnUrl} from "../../utils/redirects";
import IAuthForm from "../../models/form/IAuthForm";
import {TypedDispatch} from "../../types";

import Layout from "components/Layout";
import TextFormInput from "../UI/Input/TextFormInput";
import PasswordFormInput from "../UI/Input/PasswordFormInput";
import PrimaryButton from "../UI/Button/PrimaryButton";
import Spinner from "../UI/Spinner/Spinner";
import ArrowTriangularDownIcon from "../Icons/ArrowTriangularDownIcon";
import PhoneIcon from "../Icons/PhoneIcon";
import EmailIcon from "../Icons/EmailIcon";

import styles from "./AuthPage.module.scss";

interface IAuthPageProps {
    loginLoading: boolean,
}

const AuthPage: FC<IAuthPageProps> = ({loginLoading}) => {
    const dispatch = useDispatch<TypedDispatch>();
    const navigate = useNavigate();
    const [searchParams] = useSearchParams();
    const [toggle, setToggle] = useState(false);
    const {handleSubmit, formState, control} = useForm<IAuthForm>({mode: "onChange"});
    const {t} = useTranslation(["auth", "warnings"]);

    const {isValid, isDirty} = formState;

    const onSubmit: SubmitHandler<IAuthForm> = async(data) => {
        await dispatch(loginUser(data));
        redirectToReturnUrl(navigate, searchParams) || redirectToDashboard(navigate);
    };

    const handleToggle = () => {
        setToggle(!toggle);
    };

    return (
        <Layout type="authorization">
            <div className={styles["login"]}>
                <div className={styles["login__inner"]}>
                    <div className={styles["login__title"]}>
                        {t("title")}
                    </div>

                    <div className={styles["login__form-container"]}>
                        <form onSubmit={handleSubmit(onSubmit)}>
                            <TextFormInput
                                name={"Login"}
                                placeholder=" "
                                control={control}
                                label={t("login")}
                                rules={{
                                    required: {
                                        value: true,
                                        message: t("warnings:field_req"),
                                    },
                                }}
                            />

                            <PasswordFormInput
                                name={"Password"}
                                placeholder=" "
                                control={control}
                                label={t("password")}
                                rules={{
                                    required: {
                                        value: true,
                                        message: t("warnings:field_req"),
                                    },
                                }}
                            />

                            <PrimaryButton
                                type={"submit"}
                                className={"btn--lg"}
                                disabled={(!isDirty || !isValid)}
                            >
                                {loginLoading
                                    ? <Spinner/>
                                    : t("sign_in")
                                }
                            </PrimaryButton>
                        </form>
                        <div className={styles["support"]}>
                            <div className={cn(styles["support__inner"], toggle && styles["support__inner-toggle"])}
                                onClick={handleToggle}>
                                Тех. поддержка <ArrowTriangularDownIcon width={12} height={12}/>
                            </div>
                            {toggle && (
                                <div className={styles["support__info"]}>
                                    <div className={styles["support__info-tel"]}>
                                        <PhoneIcon/> +7(960)856-33-07
                                    </div>
                                    <div className={styles["support__info-email"]}>
                                        <EmailIcon/> KrasheninnikovAY@transport.mos.ru
                                    </div>
                                </div>
                            )}
                        </div>
                    </div>
                </div>
            </div>
        </Layout>
    );
};

const mapStateToProps = createStructuredSelector({
    loginLoading: authLoadingSelector,
});

export default connect(mapStateToProps)(React.memo(AuthPage));