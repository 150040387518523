/* eslint-disable max-len */
import React, {FC} from "react";

import {ISvgProps} from "../../types/common";

// import "../../images/icons/roadCondition/roadworks.svg";

const RoadWorksIcon:FC<ISvgProps> = ({
    width = 24,
    height = 24,
    ...rest
}) => (
    <svg width={width} height={height} {...rest} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="none">
        <path d="M19.2 21.0016L12.925 14.7266L14.35 13.3016L20.625 19.5766L19.2 21.0016ZM4.675 21.0016L3.25 19.5766L10.5 12.3266L7.825 9.65156L7.25 10.2266L6.15 9.12656V11.2516L5.55 11.8516L2.5 8.80156L3.1 8.20156H5.25L4.05 7.00156L7.325 3.72656C7.60833 3.44323 7.91667 3.25156 8.25 3.15156C8.58333 3.05156 8.95 3.00156 9.35 3.00156C9.75 3.00156 10.1167 3.0724 10.45 3.21406C10.7833 3.35573 11.0917 3.56823 11.375 3.85156L8.7 6.52656L9.9 7.72656L9.3 8.32656L11.9 10.9266L14.95 7.87656C14.8167 7.6599 14.7125 7.4099 14.6375 7.12656C14.5625 6.84323 14.525 6.54323 14.525 6.22656C14.525 5.34323 14.8458 4.58073 15.4875 3.93906C16.1292 3.2974 16.8917 2.97656 17.775 2.97656C18.025 2.97656 18.2375 3.00156 18.4125 3.05156C18.5875 3.10156 18.7333 3.16823 18.85 3.25156L16.725 5.37656L18.6 7.25156L20.725 5.12656C20.8083 5.2599 20.8792 5.4224 20.9375 5.61406C20.9958 5.80573 21.025 6.02656 21.025 6.27656C21.025 7.1599 20.7042 7.9224 20.0625 8.56406C19.4208 9.20573 18.6583 9.52656 17.775 9.52656C17.475 9.52656 17.2167 9.50573 17 9.46406C16.7833 9.42239 16.5833 9.35989 16.4 9.27656L4.675 21.0016Z" fill="currentColor"/>
    </svg>
);

export default RoadWorksIcon;