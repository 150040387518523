import React, {PropsWithChildren, RefObject, useEffect, useRef, useState} from "react";
import {isEmpty} from "lodash";
import cn from "classnames";
import SimpleBar from "simplebar-react";
import {useSelector} from "react-redux";

import {HandleSortType} from "types/table";
import IPagination from "models/Pagination";
import {RowFactory, tableRowsWithLoader} from "utils/tableHelper";
import {layoutSelector} from "../../../redux/commonSlice";

import Pagination from "components/UI/Pagination";
import TableHead from "./TableHead";
import SortableTableHead from "./SortableTableHead";

import styles from "./Table.module.scss";

export interface ITableSort {
    direction: "desc" | "asc",
    field: string,
}

export interface ITableProps<T extends object> {
    cols: string[],
    sortableCols?: object,
    items: T[],
    rowFactory: RowFactory<T>,
    isLoading: boolean,
    pagination?: IPagination,
    customStyles?: string,
    sort?: ITableSort | null,
    handleSort?: HandleSortType,
    columnsTranslationNamespace: string,
    tableRef?: RefObject<HTMLTableElement>,
    minRowHeight?: number,
    totalItems?: number,
    visibleItemsCount?: number,
    buffer?: number,
    simpleBarStyles?: React.CSSProperties,
    headerCustomComponent?: React.ReactNode,
    headerCustomCheckbox?: React.ReactNode,
    headerCustomHide?: React.ReactNode,
    customScrollRef?: RefObject<HTMLDivElement>,
}

// Таблица с виртуальным скроллом
function TableWithVirtualScroll<T extends object>(
    {
        cols,
        sortableCols,
        items,
        rowFactory,
        isLoading,
        pagination,
        customStyles,
        sort,
        handleSort,
        columnsTranslationNamespace,
        tableRef,
        minRowHeight = 30, // Минимальная высота строки
        totalItems = 10, // Общее кол-во строк
        visibleItemsCount = 10, // Кол-во видимых строк
        buffer = 2, // Доп. элементы До и После видимых
        simpleBarStyles,
        headerCustomComponent,
        headerCustomCheckbox,
        headerCustomHide,
        customScrollRef,
    }: PropsWithChildren<ITableProps<T>>
) {

    const scrollElRef = customScrollRef ? customScrollRef : useRef<HTMLDivElement | null>(null);
    const [calculatedVisibleItems, setCalculatedVisibleItems] = useState<number>(visibleItemsCount);

    // Общая высота блока со всеми элементами
    const totalHeight = minRowHeight * totalItems + 38;
    const [scrollTop, setScrollTop] = useState(0);
    // Высота буфера - строки снизу/сверху помимо видимой части
    const bufferHeight = buffer * minRowHeight;
    // Индекс первого ОТОБРАЖАЕМОГО элемента списка
    const startEl = Math.max(0, Math.floor(scrollTop / minRowHeight - buffer));
    // Список элементов, которые отображаются в данный момент + buffer
    const renderedItems = items.slice(startEl, startEl + calculatedVisibleItems + 2 * buffer);
    // Чтобы список оставался на месте при скролле
    const transformValue = scrollTop > bufferHeight ? startEl * minRowHeight : 0;

    const {bottomLeft} = useSelector(layoutSelector);

    // Расчет количества строк, которое помещается в таблице при изменении её высоты
    const getRenderedItemsItemsCount = (ref: React.RefObject<HTMLDivElement>) => {
        const tBodyHeight = ref?.current?.offsetHeight || 0;
        let rowsCount = Math.round(tBodyHeight / minRowHeight);
        rowsCount = visibleItemsCount > rowsCount ? visibleItemsCount : rowsCount;

        setCalculatedVisibleItems(rowsCount);
    };

    useEffect(() => {
        getRenderedItemsItemsCount(scrollElRef);
    }, [bottomLeft.fullHeight]);

    useEffect(() => {
        scrollElRef.current?.addEventListener("scroll", scrollItemsHandler);

        return () => {
            scrollElRef.current?.removeEventListener("scroll", scrollItemsHandler);
        };
    }, []);

    // Обработчик скролла списка
    const scrollItemsHandler = () => {
        if (scrollElRef.current) {
            setScrollTop(scrollElRef.current?.scrollTop);
        }
    };

    // Стили оберток таблицы
    const totalHeightStyle = {height: `${totalHeight}px`};
    const translateStyle = {transform: `translateY(${transformValue}px)`};

    // THead
    const tableHead = isEmpty(sortableCols)
        ? <TableHead
            columns={cols}
            headerCustomComponent={headerCustomComponent}
            headerCustomCheckbox={headerCustomCheckbox}
            headerCustomHide={headerCustomHide}
            columnsTranslationNamespace={columnsTranslationNamespace}
            customStyles={{top: `-${transformValue}px`}}
        />
        : <SortableTableHead
            columns={cols}
            headerCustomComponent={headerCustomComponent}
            headerCustomCheckbox={headerCustomCheckbox}
            headerCustomHide={headerCustomHide}
            sortableColumns={sortableCols}
            // @ts-ignore
            sort={sort}
            // @ts-ignore
            handleSort={handleSort}
            columnsTranslationNamespace={columnsTranslationNamespace}
            customStyles={{top: `-${transformValue}px`}}
        />;

    return (
        <SimpleBar
            style={{maxHeight: "100%", height: "100%", ...simpleBarStyles}}
            scrollableNodeProps={{ref: scrollElRef}}
        >
            <div className={styles["table-wrap"]} style={totalHeightStyle}>
                <div className={cn(styles["table-wrap__inner"], "table-wrap__inner")} style={translateStyle}>
                    <table ref={tableRef} className={cn(styles["table"], customStyles)}>

                        {tableHead}

                        <tbody>
                            {tableRowsWithLoader<T>(
                                renderedItems,
                                isLoading,
                                cols.length,
                                rowFactory,
                                calculatedVisibleItems
                            )}
                        </tbody>

                        {!isEmpty(pagination) && (
                            <tfoot>
                                <tr>
                                    <td colSpan={cols.length}>
                                        <div className={styles["pagination"]}>
                                            <Pagination
                                                initialPage={pagination?.page ?? 1}
                                                lastPage={pagination?.totalPages ?? 1}
                                                onClick={pagination?.handlePagination}
                                            />
                                        </div>
                                    </td>
                                </tr>
                            </tfoot>
                        )}
                    </table>
                </div>
            </div>
        </SimpleBar>
    );
}

export default React.memo(TableWithVirtualScroll) as typeof TableWithVirtualScroll;