import {createAsyncThunk, createSelector, createSlice} from "@reduxjs/toolkit";
import {toast} from "react-toastify";

import {RootState} from "../store";
import {ApiUrls} from "../constants/urls";
import createHttpRequest from "../utils/http";

export interface ICommentCroup {
    Id: number,
    Created: string,
    LastModified: string,
    Type: string,
    Name: string,
    Data?: {
        [key: string]: any,
    },
}

export interface IQuickComment {
    Id: number,
    Created: string,
    LastModified: string,
    Type: string,
    Name: string,
    Data: {
        code?: string,
        description?: string,
        text?: string,
        view?: {
            groupId?: number | string,
        },
    },
}

export interface IQuickComments {
    Result: IQuickComment[],
    Total: number,
}

export interface IDictionary<T = any> {
    Id: number,
    Created: string,
    LastModified: string,
    Type: string,
    Name: string,
    Data?: T,
}

export interface IDictionaries<T = any> {
    Result: IDictionary<T>[],
    Total: number,
}

interface IInitialState {
    commentsGroups: ICommentCroup[] | null,
    commentsGroupsLoading: boolean,
    comments: IQuickComment[] | null,
    commentsLoading: boolean,
    success: boolean | null,
    requestCommentLoading: boolean,
    requestCommentSuccess: boolean | null,
    requestGroupLoading: boolean,
    requestGroupSuccess: boolean | null,
}

const initialState: IInitialState = {
    commentsGroups: null,
    commentsGroupsLoading: false,
    comments: null,
    commentsLoading: false,
    success: null,
    requestCommentLoading: false,
    requestCommentSuccess: null,
    requestGroupLoading: false,
    requestGroupSuccess: null,
};

// Получение списка групп комментов
export const fetchCommentsGroups = createAsyncThunk<IDictionaries>(
    "comments/fetchCommentsGroups",
    async() => {
        const response = await createHttpRequest({
            method: "GET",
            path: ApiUrls.INCIDENT_DICTIONARIES("INCIDENTS_QUICKRESPONSEGROUP.1"),
            errorMessage: "messages:fetch_quick_comments_group_error",
        });

        return response.data;
    }
);

// Получение списка быстрых комментов
export const fetchQuickComments = createAsyncThunk<IQuickComments>(
    "comments/fetchQuickComments",
    async() => {
        const response = await createHttpRequest({
            method: "GET",
            path: ApiUrls.INCIDENT_DICTIONARIES("INCIDENTS_QUICKRESPONSE.1"),
            errorMessage: "messages:fetch_quick_comments_error",
        });

        return response.data;
    }
);

// Создание группы
export const createGroup = createAsyncThunk<any, any>(
    "comments/createGroup",
    async({data}) => {
        const response = await createHttpRequest({
            method: "POST",
            path: ApiUrls.INCIDENT_DICTIONARIES(data.typeId),
            data,
            errorMessage: "messages:create_tag_groups_error",
        });

        return response.data;
    }
);

// Обновление названия группы
export const editGroup = createAsyncThunk<any, any>(
    "comments/editGroup",
    async({id, data}) => {
        const response = await createHttpRequest({
            method: "PUT",
            path: ApiUrls.INCIDENT_DICTIONARY(id),
            data,
            errorMessage: "messages:edit_tag_groups_error",
        });

        return response.data;
    }
);

// Удаление группы
export const deleteGroup
    = createAsyncThunk<any, any>("comments/deleteGroup", async(id) => {
        const response = await createHttpRequest({
            method: "DELETE",
            path: ApiUrls.INCIDENT_DICTIONARY(id),
            errorMessage: "messages:delete_tag_groups_error",
        });

        return response.data;
    });

// Создание коммента
export const createComment = createAsyncThunk<any, any>(
    "comments/createComment",
    async({data}) => {
        const response = await createHttpRequest({
            method: "POST",
            path: ApiUrls.INCIDENT_DICTIONARIES(),
            data,
            errorMessage: "messages:create_tag_error",
        });

        return response.data;
    }
);

// Обновление текста коммента
export const editComment = createAsyncThunk<any, any>(
    "comments/editComment",
    async({id, data}) => {
        const response = await createHttpRequest({
            method: "PUT",
            path: ApiUrls.INCIDENT_DICTIONARY(id),
            data,
            errorMessage: "messages:edit_tag_error",
        });

        return response.data;
    }
);

// Удаление коммента
export const deleteComment
    = createAsyncThunk<any, any>("comments/deleteComment", async(id) => {
        const response = await createHttpRequest({
            method: "DELETE",
            path: ApiUrls.INCIDENT_DICTIONARY(id),
            errorMessage: "messages:delete_tag_error",
        });

        return response.data;
    });

const comments = createSlice({
    reducers: {
        resetSuccess(state) {
            if (state.requestCommentSuccess) state.requestCommentSuccess = null;
            if (state.requestGroupSuccess) state.requestGroupSuccess = null;
        },
        resetComments(state) {
            state.comments = null;
            state.commentsGroups = null;
        },
    },
    name: "comments",
    initialState,
    extraReducers: (builder) => {
        builder.addCase(fetchCommentsGroups.pending, (state) => {
            state.success = null;
            state.commentsGroupsLoading = true;
        });
        builder.addCase(fetchCommentsGroups.fulfilled, (state, {payload}) => {
            state.commentsGroups = payload.Result;
            state.success = true;
            state.commentsGroupsLoading = false;
        });
        builder.addCase(fetchCommentsGroups.rejected, (state) => {
            state.success = null;
            state.commentsGroupsLoading = false;
        });

        builder.addCase(fetchQuickComments.pending, (state) => {
            state.success = null;
            state.commentsLoading = true;
        });
        builder.addCase(fetchQuickComments.fulfilled, (state, {payload}) => {
            state.comments = payload.Result;
            state.success = true;
            state.commentsLoading = false;
        });
        builder.addCase(fetchQuickComments.rejected, (state) => {
            state.success = null;
            state.commentsLoading = false;
        });

        builder.addCase(createComment.pending, (state) => {
            state.requestCommentLoading = true;
            state.requestCommentSuccess = null;
        });
        builder.addCase(createComment.fulfilled, (state) => {
            state.requestCommentSuccess = true;
            state.requestCommentLoading = false;
            toast.success("Комментарий успешно создан", {position: "bottom-right"});
        });
        builder.addCase(createComment.rejected, (state) => {
            state.requestCommentSuccess = null;
            state.requestCommentLoading = false;
            toast.error("Не удалось создать комментарий", {position: "bottom-right"});
        });

        builder.addCase(editComment.pending, (state) => {
            state.requestCommentSuccess = null;
            state.requestCommentLoading = true;
        });
        builder.addCase(editComment.fulfilled, (state) => {
            state.requestCommentSuccess = true;
            state.requestCommentLoading = false;
            toast.success("Комментарий успешно изменен", {position: "bottom-right"});
        });
        builder.addCase(editComment.rejected, (state) => {
            state.requestCommentSuccess = null;
            state.requestCommentLoading = false;
            toast.error("Не удалось изменить комментарий", {position: "bottom-right"});
        });

        builder.addCase(deleteComment.pending, (state) => {
            state.requestCommentSuccess = null;
            state.requestCommentLoading = true;
        });
        builder.addCase(deleteComment.fulfilled, (state) => {
            state.requestCommentSuccess = true;
            state.requestCommentLoading = false;
            toast.success("Комментарий успешно удален", {position: "bottom-right"});
        });
        builder.addCase(deleteComment.rejected, (state) => {
            state.requestCommentSuccess = null;
            state.requestCommentLoading = false;
            toast.error("Не удалось удалить комментарий", {position: "bottom-right"});
        });

        builder.addCase(createGroup.pending, (state) => {
            state.requestGroupSuccess = null;
            state.requestGroupLoading = true;
        });
        builder.addCase(createGroup.fulfilled, (state) => {
            state.requestGroupSuccess = true;
            state.requestGroupLoading = false;
            toast.success("Группа комментариев успешно создана", {position: "bottom-right"});
        });
        builder.addCase(createGroup.rejected, (state) => {
            state.requestGroupSuccess = null;
            state.requestGroupLoading = false;
            toast.error("Не удалось создать группу комментариев", {position: "bottom-right"});
        });

        builder.addCase(editGroup.pending, (state) => {
            state.requestGroupSuccess = null;
            state.requestGroupLoading = true;
        });
        builder.addCase(editGroup.fulfilled, (state) => {
            state.requestGroupSuccess = true;
            state.requestGroupLoading = false;
            toast.success("Группа комментариев успешно изменена", {position: "bottom-right"});
        });
        builder.addCase(editGroup.rejected, (state) => {
            state.requestGroupSuccess = null;
            state.requestGroupLoading = false;
            toast.error("Не удалось изменить группу комментариев", {position: "bottom-right"});
        });

        builder.addCase(deleteGroup.pending, (state) => {
            state.requestGroupSuccess = null;
            state.requestGroupLoading = true;
        });
        builder.addCase(deleteGroup.fulfilled, (state) => {
            state.requestGroupSuccess = true;
            state.requestGroupLoading = false;
            toast.success("Группа комментариев успешно удалена", {position: "bottom-right"});
        });
        builder.addCase(deleteGroup.rejected, (state) => {
            state.requestGroupSuccess = null;
            state.requestGroupLoading = false;
            toast.error("Не удалось удалить группу комментариев", {position: "bottom-right"});
        });
    },
});

export const {
    resetSuccess,
    resetComments,
} = comments.actions;

export default comments.reducer;

const slice = ({commentsReducer}: RootState) => commentsReducer;

export const commentGroupsSelector = createSelector(
    slice,
    ({commentsGroups}) => commentsGroups
);

export const commentsGroupsLoadingSelector = createSelector(
    slice,
    ({commentsGroupsLoading}) => commentsGroupsLoading
);

export const commentsSelector = createSelector(
    slice,
    ({comments}) => comments
);

export const commentsLoadingSelector = createSelector(
    slice,
    ({commentsLoading}) => commentsLoading
);

export const successSelector = createSelector(
    slice,
    ({success}) => success
);

export const requestCommentSuccessSelector = createSelector(
    slice,
    ({requestCommentSuccess}) => requestCommentSuccess
);

export const requestCommentLoadingSelector = createSelector(
    slice,
    ({requestCommentLoading}) => requestCommentLoading
);

export const requestGroupSuccessSelector = createSelector(
    slice,
    ({requestGroupSuccess}) => requestGroupSuccess
);

export const requestGroupLoadingSelector = createSelector(
    slice,
    ({requestGroupLoading}) => requestGroupLoading
);